import React from "react";

export default () => (
<svg
width="425.199px" height="459px" viewBox="0 -143.46 425.199 459">
    <path d="M383.997,83.325c-5.684,0-10.295,4.61-10.295,10.294V277.05h-110.48V161.904c0-5.686-4.609-10.297-10.295-10.297h-80.651
c-5.684,0-10.295,4.611-10.295,10.297v91.301c0,5.687,4.612,10.296,10.295,10.296c5.686,0,10.295-4.609,10.295-10.296V172.2h60.06
v104.85H51.498V25.041L212.6-104.067L377.56,28.131c4.436,3.557,10.915,2.839,14.473-1.597c3.555-4.437,2.842-10.914-1.597-14.473
L219.038-125.296c-3.763-3.016-9.116-3.016-12.876,0L34.765,12.062c-2.439,1.956-3.857,4.909-3.857,8.035v267.246
c0,5.684,4.609,10.296,10.295,10.296h342.794c5.688,0,10.298-4.612,10.298-10.296V93.619
C394.295,87.936,389.686,83.325,383.997,83.325z"/>
</svg>
);


