
import laptopImg from './../images/laptop.png';

const NotFoundStyle = theme => ({
    notFoundPage: {
        // background: `url(${laptopImg}) center 30% no-repeat` + '#ffffff',
        // minHeight: 'calc(100vh - 150px)',
        minHeight: '100vh',
        background:'#cdcdcd',
        // background:'#f7f7f7',
        color: theme.palette.secondary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'0 10px 0'
    },
    styleH1: {
        fontSize: 135,
        margin: '0px 0 0',
        fontWeight: 400,
        textShadow: `5px 5px 5px` + theme.palette.primary.light,
        color:'#2a9a53'
    },
    styleH3: {
        fontSize: 25,
        margin: 0,
        fontWeight: 400,
        
    },
    styleH2: {
        fontSize: '25',
        margin: '0px 0 15px',
        fontWeight: 400,
        color:'#131313'
        
    },
    WarningIcon: {
        margin: '0px',
        fontSize: 100,
        color:'#2a9a53',
        textShadow: `10px 10px 10px` + theme.palette.primary.light,
    },
    defaultButton: {
        width: 'auto',
        minWidth: 120,
        minHeight: 42,
        lineHeight: '42px',
        textAlign: 'center',
        padding: '0 12px',
        border: 0,
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.light,
        float: 'right',
        marginBottom: 5,
        borderRadius: 0
    },
    
    rightLogo:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        height:'20',
        '& img':{
            width:180,
            height:'auto',
            marginTop:-55
        }
    },
    backArrow:{
        margin: '0 5px 0 0'
    },
    activeButton: {
        minHeight: 46,
        lineHeight: '46px',
        textAlign: 'center',
        padding: '0 40px',
        transition: 'box-shadow .45s',
        border: 0,
        color: '#fff',
        background: theme.palette.primary.color,
        marginBottom: 5,
        borderRadius: '2px',
        textTransform: 'capitalize',
        minWidth: 'inherit',
        '&.Mui-disabled':{
           color:'rgba(255, 255, 255, 0.4)',
            '& img':{
               opacity:0.3
            }
        },
        '& img': {
            width: 18,
            marginRight: 8
        },
        '&:hover': {
            transition: 'box-shadow .45s',
            background: theme.palette.primary.color,
            boxShadow:'0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19)'
        },
        '@media (max-width:767px)':{
            marginTop:15
        }
    },
    
});

export default NotFoundStyle;
