import { httpservice } from '../services';
import { dateFormat } from '../helper/StringUtils';
import { constants } from '../constant/constant';
import { SOFTWARE, WHAT_I_CAN_DO } from '../constant/actionTypes';

export default object => dispatch => {
  let query;
  if (object) {
    query = `${constants.API.WHAT_I_CAN_DO}/${object.id}`;
  } else {
    query = `${constants.API.WHAT_I_CAN_DO}`;
  }
  dispatch({
    payload: [],
    type: WHAT_I_CAN_DO.GET.INITIATE
  });
  return httpservice()
    .get(query)
    .then(response => {
      console.log('data in get API-=-=-=-=-=-=-', response.data.response);
      const { rows = [], count = 0 } = response.data.response;
      // let softwareIdArray = [];
      const responseData = rows.map(data => {
        //   if (data.clientId) {
        //     if (softwareIdArray.includes(data.softwareId)) {
        //       return;
        //     } else {
        //       softwareIdArray.push(data.softwareId);
        //       return {
        //         id: data.softwareId,
        //         submoduleId: data.submoduleId,
        //         name: data.software.name,
        //         description: data.software.description,
        //         guid: data.software.guid,
        //         type: data.software.type,
        //         clientId: data.software.clientId,
        //         created_at: dateFormat(data.software.createdAt),
        //         createdBy: data.software.creator
        //           ? data.software.creator['firstName'] + ' ' + data.software.creator['lastName']
        //           : 'admin'
        //       };
        //     }
        //   } else {
        //     data.isPublicKey = data.isPublicKey ? 'Available' : 'Not Available';
        data.created_at = dateFormat(data.createdAt);
        data.created_by = data.creator
          ? data.creator.firstName + ' ' + data.creator.lastName
          : 'admin';
        //     data.subModuleId = null;
        return data;
        // }
      });

      const dataArray = responseData.filter(element => typeof element !== 'undefined');
      const respObject = { data: dataArray, count: count };
      dispatch({
        payload: respObject,
        type: WHAT_I_CAN_DO.DATA.SUCCESS
      });
      return respObject;
    })
    .catch(function(error) {
      dispatch({
        payload: error,
        type: WHAT_I_CAN_DO.DATA.ERROR
      });
    });
};
