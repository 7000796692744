
import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import styles from '../assets/jss/NotFoundStyle';
import WarningIcon from '@material-ui/icons/Warning';
import {Trans, withTranslation} from 'react-i18next';
import {ROUTE} from "../constant";
import Button from '@material-ui/core/Button';
import userIcon from './../assets/images/login-update-icon.png';

// import userIcon from './../assets/images/login-update-icon.png';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class NotFound extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
        document.title = "Therame Dashboard - Page Not Found";
    }
    /*
    *  function to return back to previous page
    * */
    backToPreviousPage = () => {
        this.props.history.goBack();
    };

    /*
    *  function to return back to dashboard
    * */
    
    backToHomePage = () => {
        let redirectURI = localStorage.getItem('pltfmUrl');
        let credData = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')) : false;
   
        if(redirectURI){
            window.location.href = redirectURI;
        }else{
            if(credData){
                console.log(credData);
                if (credData.userData.roleId === 3) {
                    this.props.history.push(ROUTE.BILLING);
                    return
                } 
            }
            this.props.history.push(ROUTE.ROOT);
        }
    };

    render() {
        const {classes} = this.props;
        let redirectURI = localStorage.getItem('pltfmUrl');
        let credData = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')) : false;
        return (
            <div className={classes.notFoundPage}>
                <div className={classes.rightLogo}>
                    <img src={userIcon} alt="" />
                </div>
                <h1 className={classes.styleH1}>
                    403
                </h1>
                <h2 className={classes.styleH2}>
                    {redirectURI ? "This Platform is not register!" :<Trans>NOT_FOUND.MSG</Trans>}
                    <div style={{fontSize: '18px', textAlign: "center"}} >
                    {credData && credData.userData.billingStatus === "unpaid" ? "Buy a plan to use this platform" : ""}
                    </div>
                </h2>
                
                <Button variant="contained" className={classes.activeButton} onClick={()=> {this.backToHomePage()}}>
                    <ArrowBackIcon fontSize="small" className={classes.backArrow}/>
                    Go Back</Button>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(NotFound));
