import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { dateFormat } from "../helper/StringUtils";

export default (obj, clientObj) => dispatch => {
    let query;
    if(clientObj){
        query = `${constants.API.LICENSE_INSTANCES}/${clientObj.clientId}/${clientObj.softwareId}?page=${obj.page}&size=${obj.size}`;
    }else{
        query = `${constants.API.LICENSE_INSTANCES}/${obj.id}?page=${obj.page}&size=${obj.size}`;
    }
    dispatch({
        payload: [],
        type: 'GET_LICENSE_INSTANCES_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (instanceData) {
            const rows = instanceData.data.response.data.licenses;
            const count = instanceData.data.response.data.totalItems;
            const finalData = rows.map(data => {
                data.status = data.status.charAt(0).toUpperCase() + data.status.slice(1);
                data.created_at = dateFormat(data.createdAt);
                data.activationDate = dateFormat(data.activationDate);
                return data;
            });
            dispatch({
                payload: { data: finalData, count: count },
                type: 'GET_LICENSE_INSTANCES_REQUEST_SUCCESS'
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_LICENSE_INSTANCES_REQUEST_ERROR'
            });
        });
}