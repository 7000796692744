
/* jshint esversion: 6 */

const headerStyle = theme => ({
    headerBar: {
        background: theme.palette.headerBgColor.color,
        zIndex: '1101',
        boxShadow:'0 0 2px rgba(0,0,0,.3)',
        height:60,
    },
    rootToolbar: {
        justifyContent: 'flex-end',
        minHeight:'inherit',
        '@media (max-width:767px)':{
            paddingLeft:8,
            paddingRight:0
        }
    },
    logoImg: {
        width: '250px',
        height: 32,//60
        position:'absolute',
        left:87,
        display:'flex',
        alignItems:'center',
        // top:2,
        '& img':{
            width:'100%',
            height:'100%',
            objectFit:'cover'
        },
        '@media (max-width:767px)':{
            margin: 0,
            width: '120px',
            // width: '160px',
            height: 'auto',
            left:45,
            // top:-4
        },
        '@media (min-width:768px) and (max-width:1024px)':{
            margin: 0,
            left:60
        }
    },
    headerRightBlock: {
        display: 'flex',
        height: 60,
        '& .cipDashboard': {
            color: theme.palette.modalTextColor.color,
            height: 35,
            borderRadius: 5,
            alignItems: 'center',
            display: 'flex',
            alignSelf: 'center',
            width: 50,
            justifyContent: 'center',
            position: 'relative',
            marginRight: 0,
            transition: '0.3s all linear',
            '& .switchPlatform': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '& svg': {
                    fill: theme.palette.modalTextColor.color,
                    transition: '0.3s all linear',
                    width: 20,
                    height: 20
                }
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '@media (max-width:767px)': {
                width: 30,
                height: 30,
                '& .switchPlatform': {
                    '& svg': {
                        width: 18,
                        height: 18
                    }
                },
            }
        },
        '& button': {
            display:'flex',
            alignSelf:'center',
            width:50,
            height:35,
            marginLeft:0,
            minWidth:'inherit',
            borderRadius: 0,
            borderRight:'1px solid ' + theme.palette.headerBorder.color,
            '& svg':{
            //    transform:'rotate(170deg)'
            },
            '&:hover':{
                backgroundColor:'transparent',
            },
            '& > span': {
                width: '100%',
                height: '100%',
                justifyContent: 'inherit',
                display: 'flex',
                '& img':{
                    width:28
                }
            }
        },
        '@media (max-width:767px)':{
            '& button':{
                minWidth:40,
                '& .MuiAvatar-circle':{
                    width:30,
                    height:30,
                }
            }
        }
    },
    linkColor: {
        color: theme.palette.secondary.contrastText
    },
    userName: {
        marginLeft: 0,
        textTransform: 'capitalize',
        fontWeight: 400
    },
    switchTheme: {
        width: 50,
        float: 'right',
        margin: '20px 0 0 35px',
        position: 'relative'
    },
    switchIcon: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        zIndex: 9
    },
    removePaddingTB: {
        padding: '0 10px',
    },
    subMenuDropdown: {
        top: '45px !important'
    },
    profileBtn:{
        background:'none !important',
        borderRight:'0 !important',
        marginLeft:'15px !important',
        '& div':{
            backgroundColor: theme.palette.profileLogo.color,
            color: theme.palette.modalTextColor.color
        },
        '& img':{
            width:'100% !important',
        },
        '&:hover':{
            backgroundColor:'transparent !important'
        }
    },
    menuDropdown:{
        position:'relative',
        '& div:nth-child(3)':{
            top:'62px !important',
            backgroundColor:theme.palette.platform.color,
            '& ul':{
                padding:0,
                '& li':{
                    padding:'15px 18px',
                    borderBottom: '1px solid ' + theme.palette.counterBorder.color,
                    '&:last-child':{
                        borderBottom:0,
                    },
                    '& svg':{
                        fontSize:20,
                        width:24,
                        height:24,
                        marginRight:15,
                        fill:theme.palette.modalTextColor.color
                    }
                }
            }
        },
        '& div:nth-child(2)':{
            transition:'unset !important',
            transform:'none !important',
            transformOrigin:'unset !important'
        }
    },
    flagDropdown:{
        '& div:nth-child(3)':{
         width: '170px',
         '& ul':{
             padding: 0,
             '& li':{
                 padding: '8px 16px',
                 '&:nth-child(1)': {
                     borderBottom: '1px solid ' + theme.palette.counterBorder.color,
                 }
                }
            },
        },
        '& div:nth-child(2)':{
            transition:'unset !important',
            transform:'none !important',
            transformOrigin:'unset !important'
        }
    },
    flagIcon:{
        width: '32px',
        marginRight: '12px',
        pointerEvents:'none'
    },
    cipDashboardDropdown: {
        width: 280,
        right: 0,
        left: 'auto',
        zIndex: 99999,
        top: 50,
        backgroundColor: theme.palette.platform.color,
        position: 'absolute',
        maxHeight: 0,
        minHeight: 0,
        overflow: 'hidden',
        borderRadius: 0,
        transition: '0.3s linear',
        '& ul': {
            padding: 0,
            listStyle: 'none',
            margin: 0,
            '& li': {
                borderBottom: '1px solid ' + theme.palette.counterBorder.color,
                '&:last-child': {
                    borderBottom: '1px solid transparent'
                }
            },
            '& .mainLinks': {
                color: theme.palette.modalTextColor.color,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                padding: '0 15px',
                textDecoration: 'none',
                position: 'relative',
                '& .links': {
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '& div': {
                        display: 'flex',
                        flexDirection: 'column',
                        '& p': {
                            color: theme.palette.modalTextColor.color,
                            margin: 0,
                            fontSize: 20,
                            fontWeight: 700,
                            '& span': {
                                display: 'inline-block',
                                fontSize: 15,
                                fontWeight: 'normal',
                            }
                        },
                        '& > span': {
                            color: '#0065f7',
                            display: 'inline-block',
                            fontSize: 13,
                            transform: 'scaleY(0.9)',
                            fontWeight: 'bold',
                            lineHeight: 1,
                            textTransform: 'uppercase'
                        }
                    },
                    '& img': {
                        width: '100%'
                    },
                    '& span': {
                        margin: 0,
                        fontSize: 14,
                        color: theme.palette.modalTextColor.color
                    },
                },
                '&:hover': {
                    background: theme.palette.secondary.light,
                    color: theme.palette.modalTextColor.color,
                }
            }
        }
    },
    cipOpen: {
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        maxHeight: '460px',
        minHeight: 47,
        transition: '0.3s linear',
    },
});

export default headerStyle;
