
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default data => {
    return httpservice()
        .post(constants.API.VERIFY_2FA_TOKEN, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response.data;
        });
};