
import React, { Component } from 'react';
import './../../App.css';
import { Card, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import SInput from '../Utils/SInput';
import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/LoginScreenStyles';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import GridContainer from './../../assets/jss/GridContainer';
import userIcon from '../../assets/images/userIcon.png';
import Button from '@material-ui/core/Button';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@material-ui/icons';

import LoginBackButton from './../../assets/images/login-button.svg';
import PageLoader from '../Utils/PageLoader';
import { base64Conversion, CheckIfNameIsValid } from '../../helper/StringUtils';
import { Trans, withTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import CImageCrop from '../Utils/CImageCrop';
import CButton from '../Utils/CButton';
import { toasterInstance, ValidationService, EncryptionServices } from '../../services';
import { Avatar } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import EditIcon from '@material-ui/icons/Edit';
import Mail from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import LoopIcon from '@material-ui/icons/Loop';
import Lock from '@material-ui/icons/LockOpen';
import { constants } from '../../constant/constant';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: '',
      isEnable: false,
      selectedRole: 'none',
      userImage: userIcon,
      showLoader: false,
      inEdit: false,
      isBlock: null,
      imgSrc: '',
      openModal: true,
      showPassword: false,
      showConfirmPassword: false,
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      error: {
        firstName: false,
        lastName: false,
        email: false,
        newPassword: false,
        confirmPassword: false,
        phoneNumber: false
      },
      errorCheck: this.props.id
        ? {
          firstName: false,
          lastName: false,
          email: false,
          newPassword: false,
          confirmPassword: false,
          phoneNumber: false
        }
        : {
          firstName: true,
          lastName: true,
          email: true,
          newPassword: true,
          confirmPassword: true,
          phoneNumber: true
        },
      errorMsg: {
        firstName: 'VALIDATION.REQ_FNAME',
        lastName: 'VALIDATION.REQ_LNAME',
        email: 'VALIDATION.REQ_EMAIL',
        newPassword: 'VALIDATION.REQ_EMAIL',
        confirmPassword: 'VALIDATION.REQ_EMAIL',
        phoneNumber: 'VALIDATION.REQ_PHONE_NUMBER'
      },
      errorCount: this.props.id ? 0 : 3,
      isChangeStart: false
    };
    this.uploadImage = '';
    this.role = '';
    this.loginUserId = '';
    this.phone = '';
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.baseImagePath = this.props.serverDetail && this.props.serverDetail.data && this.props.serverDetail.data.message && this.props.serverDetail.data.message === "PROD" ? `${process.env.REACT_APP_ADMIN_ENDPOINT}${process.env.REACT_APP_USER_IMAGE_PATH}` : `${constants.API.IMAGE + '/user/'}`;
  }

  /**
   * componentWillMount function we are getting response
   */
  componentWillMount() {
    if (this.props.id) {
      this.setState({ inEdit: true, showLoader: false });
    }
    // EncryptionServices.decodeJwtToken(localStorage.authToken).then(data => {
    //   this.role = data.role;
    //   this.loginUserId = data.id;
    // });
  }

  /**
   * handleFormSubmit function executed when form submit
   */
  handleRoleChange(event) {
    this.setState({ selectedRole: event.target.value });
  }
  handleFormSubmit = event => {
    event.preventDefault();
    
    let errorCount = 0;
    let error = this.state.error;
    if (!ValidationService.validateForm(event.target.firstName)) {
      error.firstName = true;
      errorCount++;
    }
    if (!ValidationService.validateForm(event.target.lastName)) {
      error.lastName = true;
      errorCount++;
    }
    if (!ValidationService.validateForm(event.target.email)) {
      error.email = true;
      errorCount++;
    }
    if (!event.target.newPassword ) {
      error.newPassword = true;
      errorCount++;
    }
    if (!event.target.confirmPassword ) {
      error.confirmPassword = true;
      errorCount++;
    }

    if (
      event.target.newPassword &&
      event.target.newPassword.value !== event.target.confirmPassword.value
    ) {
      error.newPassword = true;
      error.confirmPassword = true;
      errorCount++;
    }
    this.setState({ error: error, errorCount: errorCount });
    if (!errorCount) {
      this.setState({ showLoader: true });
      this.encryptFormCredential(event.target);
    } else {
      toasterInstance(<Trans>TOASTER.FORM_FAIL</Trans>, 4, 'failedUser');
    }
  };

  /**
   * encryptFormCredential function is to encrypt password
   */
  encryptFormCredential = domElement => {
    
    let email = domElement.email.value;
    let password = domElement.newPassword.value;
    // let password = EncryptionServices.randomPassword();
    let iterationCount = 512;
    const userData = {
      name: `${domElement.firstName.value} ${domElement.lastName.value}`,
      id: this.loginUserId
    };
    let roleIdVal;
    if (domElement.role.value == 'admin') {
      roleIdVal = 2;
    }
    else if (domElement.role.value == 'therapist') {
      roleIdVal = 3;
    }
    else if (domElement.role.value == 'user') {
      roleIdVal = 4;
    }
    let formData = {
      firstName: domElement.firstName.value,
      lastName: domElement.lastName.value,
      iterationCount: 55,
      email: email,
      phoneNumber:
        this.phone.length > 0
          ? this.phone.split(' ').join('')
          : domElement.phoneNumber.value?.split(' ').join(''),
      // roleId: this.props.userData && this.props.userData.role === 'superAdmin' ? 1 : 2
      roleId: roleIdVal
    };

    EncryptionServices.generateCredential(email, password, iterationCount).then(encryptionData => {
      if (this.props.id) {
        formData.id = this.props.id;
      } else {
        formData.password = password;
        // formData.password = encryptionData.password;
      }
      this.setState({ showLoader: true });
      this.props.handleSubmit(formData, userData, this.uploadImage);
    });
  };

  /*
   * Block User Checkbox
   */
  handleCheckBoxChange = event => {
    this.enableEncryption = false;
    this.setState({ [event.target.name]: event.target.checked });
  };

  /*
   * redirectForm redirect the form to manage user list
   */
  redirectForm = () => {
    // this.props.handleClose();
  };

  /**
   * changeUserImage change user image\
   */
  changeUserImage = event => {
    if (event.target && event.target.files && event.target.files.length) {
      const fileUpload = event.target.files[0];
      if (fileUpload && fileUpload['type'].includes('image')) {
        base64Conversion(fileUpload).then(data => {
          this.setState({
            imgSrc: data,
            openModal: true,
            errorCount: 0,
            isChangeStart: true
          });
        });
        this.uploadImage = fileUpload;
      } else {
        toasterInstance(<Trans>TOASTER.SELECT_IMAGE_ONLY</Trans>, 4, 'failedUser');
      }
    }
  };

  /**
   * Hiding the error border\
   */
  checkError = (domElement, boolean, errMsg = null) => {
    if (domElement.name === 'firstName' || domElement.name === 'lastName') {
      if (!CheckIfNameIsValid(domElement.value)) {
        boolean = true;
        errMsg = <Trans>VALIDATION.INVALID_NAME</Trans>;
      }
    }

    let error = this.state.error;
    let errorCheck = this.state.errorCheck;
    let newerrorMsg = this.state.errorMsg;
    error[domElement.name] = boolean;
    errorCheck[domElement.name] = boolean;
    let newErrCount = 0;
    Object.keys(error).forEach(function (key) {
      if (error[key]) {
        newErrCount++;
      }
    });
    this.setState({
      error: error,
      isChangeStart: true,
      errorCount: newErrCount
    });
    if (errMsg) {
      newerrorMsg[domElement.name] = errMsg;
      this.setState({ errMsg: newerrorMsg });
    }
  };

  //Get Image
  getImage = image => {
    this.uploadImage = image;
    base64Conversion(image).then(data => {
      if (image) {
        this.setState({
          userImage: data,
          inEdit: false
        });
      }
    });
  };

  closeCropImageModal = () => {
    this.setState({
      openModal: false,
      imgSrc: userIcon
    });
  };

  closeCropImageModal1 = () => {
    this.setState({
      openModal: false
    });
  };

  changeNumberInField = event => {
    if (
      (this.phone !== '' && isValidPhoneNumber(this.phone) && isPossiblePhoneNumber(this.phone)) ||
      (event && event.target && event.target.value !== '') ||
      this.state.changeImage
    ) {
      this.setState({ isEnable: true, isChangeStart: true, errorCount: 0 });
    } else this.setState({ isEnable: false, isChangeStart: false, errorCount: 1 });
  };
  setUserPhoneNumber = event => {
    // event.preventDefault()
    if (typeof event === 'number') {
      this.phone = event;
      this.setState({ phoneNumber: event, isError: false });
      this.changeNumberInField();
    }
  };

  render() {
    const { selectedRole } = this.state;
    const { classes } = this.props;
    const { token } = this.props;
    let id,
      userData = {};
    if (this.props.id) {
      userData = this.props.userData;
      id = userData && userData.id ? userData.id : '';
    }
    const { errorMsg, isChangeStart, errorCount } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment key={id}>
        {this.state.imgSrc && (
          <CImageCrop
            imageToCrop={this.state.imgSrc}
            openModal={this.state.openModal}
            closeCropImageModal={this.closeCropImageModal}
            closeCropImageModal1={this.closeCropImageModal1}
            getImage={this.getImage}
          />
        )}

        <form name="userForm" className={classes.loginRight } onSubmit={this.handleFormSubmit}>
          <PageLoader isLoading={this.props.isLoading} />
          {
            <Grid item xs={12}>
              <div className={`${classes.fileUploadSection} ${classes.extraTopMarginAvtar}`}>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    this.state.inEdit && userData.avatar
                      ? `${this.baseImagePath}${userData.avatar}`
                      : this.state.userImage
                  }
                  // src={
                  //   this.state.inEdit && userData.avatar
                  //     ? `${constants.API.IMAGE + '/user/'}${userData.avatar}`
                  //     : this.state.userImage
                  // }
                  className={classes.avatar2 + " " + classes.loginRightTop}
                />
                <div className={classes.setUploadAvatar}>
                  <Tooltip title={<Trans>USER.PIC_UPLOAD</Trans>} placement="right-start">
                    <Input
                      className={classes.fileUploadSectionInput}
                      onChange={this.changeUserImage}
                      type="file"
                      onClick={event => {
                        event.target.value = null;
                      }}
                      name={'image'}
                    />
                  </Tooltip>
                  <EditIcon className={classes.changeAvatar2} />
                </div>
              </div>
            </Grid>
          }
          {/* <GridContainer> */}
          <div className={classes.forEachQ}>
            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              <PersonIcon />
            </Grid>
            <SInput
              name={'firstName'}
              className={classes.formInput}
              classes={classes}
              style={{ color: 'black' }}
              info={userData && userData.firstName ? userData.firstName : ''}
              validateForm={true}
              label=''
              placeholder={t('USER.FNAME') + "*"}

              randomKey={userData.id}
              isError={this.state.error.firstName}
              errorVal={errorMsg.firstName}
              checkError={this.checkError}
            />
          </div>
          <div className={classes.forEachQ}>
            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              <PersonIcon />
            </Grid>
            <SInput
              className={classes.formInput}
              name={'lastName'}
              label={
                <React.Fragment>
                  <Trans>USER.LNAME</Trans>*
                </React.Fragment>
              }
              placeholder={t('USER.LNAME') + "*"}
              classes={classes}
              info={userData && userData.lastName ? userData.lastName : ''}
              validateForm={true}
              randomKey={userData.id}
              isError={this.state.error.lastName}
              errorVal={errorMsg.lastName}
              checkError={this.checkError}
            />
          </div>
          <div className={classes.forEachQ}>
            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              <Mail />
            </Grid>
            <SInput
              className={classes.formInput + ' ' + classes.inputBorder}
              name={'email'}
              type={'email'}
              label={
                <React.Fragment>
                  <Trans>USER.EMAIL_SHORT</Trans>*
                </React.Fragment>
              }
              placeholder={t('USER.EMAIL_SHORT') + "*"}
              classes={classes}
              info={userData && userData.email ? userData.email : ''}
              readonly={userData && userData.email ? true : false}
              validateForm={true}
              randomKey={userData.id}
              isError={this.state.error.email}
              errorVal={errorMsg.email}
              checkError={this.checkError}
            />
          </div>
          <div className={classes.forEachQ}>

            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              {/* <Lock /> */}
              {this.state.showPassword ? <LockOpenIcon onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> : <LockIcon onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}

            </Grid>
            <SInput
              className={classes.formInput}
              classes={classes}
              name={this.state.showPassword ? '' : 'newPassword'}
              type={this.state.showPassword ? "text" : 'password'}
              label={
                <React.Fragment>
                  {/* <Trans>USER.EMAIL_SHORT</Trans>* */}
                  Password*
                </React.Fragment>
              }
              placeholder={t('Password*')}
              info={userData && userData.password ? userData.password : ''}
              readonly={userData && userData.password ? true : false}
              validateForm={true}
              randomKey={userData.id}
              isError={this.state.error.newPassword}
              errorVal={errorMsg.newPassword}
              checkError={this.checkError}
            />
          </div>
          <div className={classes.forEachQ}>
            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              {this.state.showConfirmPassword ? <LockOpenIcon onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} /> : <LockIcon onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} />}
            </Grid>
            <SInput
              className={classes.formInput}
              classes={classes}
              name={this.state.showConfirmPassword ? '' : 'confirmPassword'}
              type={'password'}
              label={
                <React.Fragment>
                  {/* <Trans>USER.EMAIL_SHORT</Trans>* */}
                  Confirm Password*
                </React.Fragment>
              }
              placeholder={t('Confirm Password*')}
              info={userData && userData.confirmPassword ? userData.confirmPassword : ''}
              readonly={userData && userData.confirmPassword ? true : false}
              validateForm={true}
              randomKey={userData.id}
              isError={this.state.error.confirmPassword}
              errorVal={errorMsg.confirmPassword}
              checkError={this.checkError}
            />
          </div>
          {/*  */}



          {/* <div className={classes.forEachQ}>
            <InputLabel><Trans>USER.ROLE</Trans>*</InputLabel>
            <Select
              value={selectedRole}
              onChange={this.handleRoleChange}
              className={classes.formInput}
              name="role"
            >
              <MenuItem value="" >
                <em><Trans>USER.SELECT_ROLE</Trans></em>
              </MenuItem>
              <MenuItem value="user" ><Trans>USER.USER_ROLE</Trans></MenuItem>
              <MenuItem value="therapist" ><Trans>USER.THERAPIST_ROLE</Trans></MenuItem>
            </Select>
          </div> */}
          <div className={classes.forEachQ}>
            <InputLabel><Trans>USER.ROLE</Trans>*</InputLabel>
            <Select
              value={token ? 'user' : 'therapist'} // If token is not empty, set value to 'user', otherwise set it to 'therapist'
              onChange={this.handleRoleChange}
              className={classes.formInput + " " + classes.rolediv}
              name="role"
              disabled={!!token} // Disable the Select component if token is present
            >
              <MenuItem value="user" ><Trans>USER.USER_ROLE</Trans></MenuItem>
              <MenuItem value="therapist" ><Trans>USER.THERAPIST_ROLE</Trans></MenuItem>
            </Select>
          </div>



          {/*  */}
          {/* <div className={classes.forEachQ}>
            <Grid item className={classes.inputIcon + ' ' + classes.inputIconPadding}>
              <LoopIcon />
            </Grid>
            <SInput
              className={classes.formInput}
              classes={classes}
              name={'iterationCount'}
              type={'text'}
              label={
                <React.Fragment>
                  Iteration Count*
                </React.Fragment>
              }
              placeholder={t('Iteration Count*')}
              info={userData && userData.iterationCount ? userData.iterationCount : '55'}
              readonly={userData && userData.iterationCount ? true : false}
              validateForm={true}
              randomKey={userData.id}
              isError={this.state.error.iterationCount}
              errorVal={errorMsg.confirmPassword}
              checkError={this.checkError}
            />
          </div> */}

          <div className={classes.forEachQ}>
            <Grid item className={classes.formInput + ' ' + classes.phoneNumber + " " + classes.marginBottom + " " + classes.phoneNo}>
              <InputLabel>
                <React.Fragment>
                  <Trans>SETTINGS.ACC_PHONE</Trans>*
                </React.Fragment>
              </InputLabel>
              <PhoneInput
                name={'phoneNumber'}
                classes={classes}
                value={userData && userData.phoneNumber ? userData.phoneNumber : ''}
                onChange={this.setUserPhoneNumber}
                defaultCountry="US"
              />
            </Grid>
          </div>
          {/* <Grid container alignItems="flex-end" className={classes.marginBottom}></Grid> */}

          {/* <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                            <SInput name={'firstName'}
                                                info={userData && userData.firstName ? userData.firstName : ''}
                                                validateForm={true}
                                                label={<React.Fragment><Trans>USER.FNAME</Trans>*</React.Fragment>}
                                                randomKey={userData.id}
                                                isError={this.state.error.firstName}
                                                errorVal={errorMsg.firstName}
                                                checkError={this.checkError} />
                                        </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                            <SInput className={classes.formInput}
                                                name={'lastName'}
                                                label={<React.Fragment><Trans>USER.LNAME</Trans>*</React.Fragment>}
                                                info={userData && userData.lastName ? userData.lastName : ''}
                                                validateForm={true}
                                                randomKey={userData.id}
                                                isError={this.state.error.lastName}
                                                errorVal={errorMsg.lastName}
                                                checkError={this.checkError} />
                                        </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                            <SInput className={classes.formInput}
                                                name={'email'}
                                                type={'email'}
                                                label={
                                                    <React.Fragment><Trans>USER.EMAIL_SHORT</Trans>*</React.Fragment>}
                                                info={userData && userData.email ? userData.email : ''}
                                                readonly={userData && userData.email ? true : false}
                                                validateForm={true}
                                                randomKey={userData.id}
                                                isError={this.state.error.email}
                                                errorVal={errorMsg.email}
                                                checkError={this.checkError} />
                                        </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing + ' ' + classes.phoneNumber}>
                                            <InputLabel className={classes.fontManage}>
                                                <React.Fragment><Trans>SETTINGS.ACC_PHONE</Trans>*</React.Fragment>
                                            </InputLabel>
                                            <PhoneInput
                                                name={'phoneNumber'}
                                                value={userData && userData.phoneNumber ? userData.phoneNumber : ''}
                                                onChange={this.setUserPhoneNumber}
                                                defaultCountry="US"
                                            />
                                        </Grid> */}
          <Grid
            container
            justifyContent="flex-end"
            className={classes.linkCss + ' ' + classes.registerUser + ' ' + classes.loginUser}
          >
            <Button
              type="submit"
              disabled={isChangeStart ? errorCount > 0 || this.props.isLoading : true}
              className={classes.activeButton}
            >
              {/* <Trans>LOGIN.BTN</Trans> */}
              Signup
              <img src={LoginBackButton} style={{ width: '20px', marginRight: '5px' }} alt="" />
            </Button>
            {/* <CButton
                                disabled={isChangeStart ? errorCount > 0 || this.props.isLoading : true}
                                type={this.props.id ? 'BUTTON.EDIT' : 'BUTTON.CREATE'}
                                onCancel={this.redirectForm} isFull={true} /> */}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            className={classes.linkCss + ' ' + classes.forgotPassword}
          >
            <Link component={RouterLink} to="/login">
            Already have account ? <span className={classes.linkText}> Login</span>
            </Link>
          </Grid>
          {/* </GridContainer> */}
          {/* </Grid> */}
          {/* </div> */}
          {/* <CButton
                                disabled={isChangeStart ? errorCount > 0 || this.props.isLoading : true}
                                type={this.props.id ? 'BUTTON.EDIT' : 'BUTTON.CREATE'}
                                onCancel={this.redirectForm} isFull={true} /> */}
          {/* </Card> */}
          {/* </div> */}
        </form>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    serverDetail: state.getServerDetail && state.getServerDetail.data ? state.getServerDetail.data : [],
  };
}
export default withTranslation()(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps
)(SignupForm)));
