import React from 'react';

const DashboardNewIcon = () => (
  <svg
  version="1.1"
  className="MuiSvgIcon-root"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 122.88 108.06"
  style={{enableBackground:"new 0 0 122.88 108.06"}}
>
  <g>
    <path
      class="st0"
      d="M15.61,12.79v64.24h91.57V12.79H15.61L15.61,12.79z M24.49,65.88c-0.96,0-1.75-0.78-1.75-1.75 c0-0.96,0.78-1.75,1.75-1.75h11.05V36.23c0-0.2,0.08-0.39,0.22-0.52c0.03-0.03,0.06-0.05,0.09-0.08c0.12-0.09,0.27-0.14,0.43-0.14 h5.58c0.2,0,0.39,0.08,0.52,0.22c0.03,0.03,0.06,0.07,0.08,0.1c0.08,0.12,0.13,0.26,0.13,0.42v26.16h7.88V44.03 c0-0.2,0.08-0.39,0.22-0.52c0.13-0.13,0.32-0.22,0.52-0.22h5.58c0.21,0,0.39,0.08,0.52,0.22c0.13,0.13,0.22,0.32,0.22,0.52v18.36 h7.88V28.43c0-0.2,0.08-0.39,0.22-0.52c0.13-0.13,0.32-0.22,0.52-0.22h5.58c0.2,0,0.39,0.08,0.52,0.22l0,0 c0.13,0.13,0.22,0.32,0.22,0.52v33.96h7.88V38.46c0-0.2,0.08-0.39,0.22-0.52l0,0c0.13-0.13,0.32-0.22,0.52-0.22h5.58 c0.2,0,0.38,0.08,0.52,0.22l0,0l0,0c0.13,0.13,0.22,0.32,0.22,0.52v23.93h7.74c0.96,0,1.75,0.78,1.75,1.75 c0,0.96-0.78,1.75-1.75,1.75H24.49L24.49,65.88z M58.67,90.98c0.19-0.18,0.41-0.33,0.64-0.43v-9.08H2.22 C0.99,81.47,0,80.47,0,79.25c0-1.23,0.99-2.22,2.22-2.22h8.94V12.79H2.22C0.99,12.79,0,11.79,0,10.57c0-1.23,0.99-2.22,2.22-2.22 h56.76v-6c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34v6h56.76c1.23,0,2.22,0.99,2.22,2.22c0,1.23-0.99,2.22-2.22,2.22 h-8.81v64.24h9.04c1.23,0,2.22,0.99,2.22,2.22c0,1.23-0.99,2.22-2.22,2.22H63.99v9.33c0.07,0.06,0.15,0.12,0.21,0.18l13.1,12.45 c0.94,0.89,0.97,2.37,0.08,3.31c-0.89,0.94-2.37,0.97-3.31,0.08l-10.09-9.59v8.48c0,1.29-1.05,2.34-2.34,2.34 c-1.29,0-2.34-1.05-2.34-2.34v-8.88l-10.51,9.99c-0.94,0.89-2.42,0.85-3.31-0.08c-0.89-0.94-0.85-2.42,0.08-3.31L58.67,90.98 L58.67,90.98z"
    />
  </g>
</svg>
);

export default DashboardNewIcon;
