

import {constants} from '../constant/constant';
import {Interseptor} from '.';

const axios = require('axios');


/**
 * httpservice for get and post json formatted data
 * @returns {*}
 */
const httpservice = () => {
    axios.create({
        baseURL:localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData && JSON.parse(localStorage.getItem('cred')).userData.role.roleName==="client" ? constants.CLIENT_URL : constants.ADMIN_URL  :constants.ADMIN_URL,
        transformRequest: [
            function (data, headers) {
                headers['Authorization'] = 'Bearer ' + localStorage['authToken'];
                return JSON.stringify(data);
            }
        ],
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma' : 'no-cache'
        }
    }).interceptors.request.use(request => Interseptor.requestHandler(request));
    axios.create({
        baseURL:localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData && JSON.parse(localStorage.getItem('cred')).userData.role.roleName==="client" ? constants.CLIENT_URL : constants.ADMIN_URL  :constants.ADMIN_URL,
        transformRequest: [
            function (data, headers) {
                headers['Authorization'] = 'Bearer ' + localStorage['authToken'];
                return JSON.stringify(data);
            }
        ],
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma' : 'no-cache'
        }
    }).interceptors.response.use(
        response => Interseptor.successHandler(response),
        error => Interseptor.errorHandler(error)
    );
    let instanceUrl = axios.create({
        baseURL:localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData && JSON.parse(localStorage.getItem('cred')).userData.role.roleName==="client" ? constants.CLIENT_URL : constants.ADMIN_URL  :constants.ADMIN_URL,
        transformRequest: [
            function (data, headers) {
                headers['Authorization'] = 'Bearer ' + localStorage['authToken'];
                return JSON.stringify(data);
            }
        ],
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma' : 'no-cache'
        }
    })
    instanceUrl.interceptors.request.use(request => Interseptor.requestHandler(request));
    instanceUrl.interceptors.response.use(
        response => Interseptor.successHandler(response),
        error => Interseptor.errorHandler(error)
    );
    return instanceUrl;
};

/**
 * httpserviceWithMultipart for sending form data
 * @param method
 * @param usersInfo
 * @param url
 * @returns {never}
 */
const httpserviceWithMultipart = (method, usersInfo, url) => {
    const sendMulipartRequest = axios({
        method: method,
        url: url,
        headers: {
            Authorization: 'Bearer ' + localStorage['authToken'],
            'Content-Type': 'multipart/form-data',
            'Cache-Control': 'no-cache',
            'Pragma' : 'no-cache'
        },
        data: usersInfo
    });
    return sendMulipartRequest;
};

const createApiUrl = (queryObject, api, id) => {
    let query;
    let url = `${constants.API[api]}`;
    if (api === 'VULNERABILITY_PROJECT') {
        url = `${constants.API[api]}/${id}`;
    }
    if (!queryObject) {
        query = `${url}?queryOptions=paginate:1:10`;
    } else {
        if (queryObject.getBy === 'getIdData') {
            url = `${url}/${queryObject.id}`
        }
        switch (queryObject.purpose) {
            case 'statusSearch':
                query = `${url}?queryOptions=like:${queryObject.option}:=:${queryObject.page}`;
                break;
            case 'search':
                query = `${url}?queryOptions=like:${queryObject.option}:=$:${queryObject.page}`;
                break;
            case 'pagination':
                query = `${url}?queryOptions=paginate:${queryObject.page}:${queryObject.limit}`;
                break;
            case 'sort':
                query = `${url}?queryOptions=sort:${queryObject.page}:${queryObject.limit}`;
                break;
            case 'totalRecords':
                query = `${url}`;
                break;
            case 'searchVulnerability':
                query = `${url}?queryOptions=like:${queryObject.option}:=:${queryObject.page}`;
                break;
            default:
                query = url
        }
    }
    return query;
};

export {
    httpservice,
    httpserviceWithMultipart,
    createApiUrl,
};
