
/* jshint esversion: 6 */

import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from '@material-ui/core/styles';
import styles from '../../assets/jss/sideNavigationStyle';
import {Link} from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import {hideOtherSubMenu, manageIcon} from '../../helper/NavigationMenu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {Trans, withTranslation} from 'react-i18next';

class ShortMenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CLIENT: false,
            PROJECT: false,
            SETTINGS: false,
            USER: false,
            VULNERABILITY: false,
            REMEDIATION: false,
            activeTab: ''
        };
    }

    /*
     * function to sets the current state of a menu item
     * i.e whether it is in expanded or collapsed or a collapsed state
     * */
    handleClick(item) {
        let newState = hideOtherSubMenu(item, this.state[item]);
        this.setState(newState);
    }

    componentDidMount() {
        if(!this.state.activeTab && this.props.defaultSelectedMenu){
            this.checkActiveMenu(this.props.defaultSelectedMenu);
        }
    }

    /**
     * function to check and update for current active menu
     * @param item
     */
    checkActiveMenu = item => {
        this.props.onMenuSelect(item);
        this.setState({
            activeTab: item
        });
    };

    /*
     * function to handle menu , if the menu item doesn't have any child,
     * this method simply returns a clickable menu item that redirects to any location
     * and if there is no child this method uses recursion to go until the last level of
     * children and then returns the item by the first condition.
     * */
    shortMenuHandler(children) {
        const {classes, menuList} = this.props;
        const {state} = this;
        if (children) {
            
            return children.map(subOption => {
                if (menuList.indexOf(subOption.module) > -1) {
                    if (!subOption.children) {
                        return (
                            <div key={subOption.title} onClick={() => this.checkActiveMenu(subOption.module)}>
                               <Link to={subOption.path} className={classes.links}>
                                    <ListItem
                                        className={`${classes.menuColors}  ${subOption.isSubMenu ? classes.subMenuList : ''} ${(this.state.activeTab === subOption.module) ? classes.activeMenu : ''} `}
                                        button
                                        selected={this.state.activeTab === subOption.module}
                                        key={subOption.title}
                                    >
                                        {manageIcon(subOption.icon)}
                                        {subOption.isSubMenu ? (
                                            <ListItemText inset onClick={() => this.handleClick(subOption.id)}>
                                                <Trans i18nKey={subOption.title}></Trans>
                                            </ListItemText>
                                        ) : (
                                            ''
                                        )}
                                    </ListItem>
                                </Link>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                key={subOption.title}
                                className={classes.sidebarParentMenu}
                                onClick={() => this.checkActiveMenu(subOption.module)}
                            >
                               <ListItem
                                    className={`${classes.menuColor}  ${(this.state.activeTab === subOption.module) ? classes.activeMenu : ''} `}
                                    button
                                    selected={this.state.activeTab === subOption.module}
                                    onClick={() => this.handleClick(subOption.title)}
                                >
                                    {manageIcon(subOption.icon)}
                                    {state[subOption.title] ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse
                                    className={classes.sidebarUlUl}
                                    in={state[subOption.title]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {this.shortMenuHandler(subOption.children)}
                                </Collapse>
                            </div>
                        );
                    }
                } else {
                    return '';
                }
            });
        }
    }

    render() {
        const {classes} = this.props;
        let menuList = this.props.menuItems;
        return (
            <div className={classes.list}>
                <Drawer variant="persistent" anchor="left" open classes={{paper: classes.list}}>
                    <div>
                        <List>{this.shortMenuHandler(menuList)}</List>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const stylesheets = withStyles(styles, {withTheme: true})(ShortMenuBar);
export default withTranslation()(stylesheets);
