
export default (state, action) => {
    if (typeof state == 'undefined') {
        state = [];
    }
    switch (action.type) {
        case 'GET_GENERATED_LICENSES_REQUEST_INITIATED':
            return {isFetching: true};
        case 'GET_GENERATED_LICENSES_REQUEST_SUCCESS':
            return {isFetching: false, data: action.payload};
        case 'GET_GENERATED_LICENSES_REQUEST_ERROR':
            return {isFetching: false, data: action.payload};
        default:
            return state;
    }
};