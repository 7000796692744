import { httpservice } from '../services';
import { constants } from '../constant/constant';
import { COMMENTS } from '../constant/actionTypes';
import {dateFormat} from '../helper/StringUtils';

export default data => dispatch => {
  if (Object.prototype.toString.call(data) === '[object Object]') {
    data = data.id || null;
  }
   dispatch({
        payload: {data: [], count: 0},
        type: COMMENTS.GET.INITIATE
    });

  return httpservice()
    .get(`${constants.API.COMMENTS}/${data}`)
    .then(response => {
      console.log("hi",response)
      const responseData = response.data.response.map(data2 => {
        data2.id = data2.id;
        data2.userId = data2.userId;
        data2.name = data2.userDetail[0].firstName + ' ' + data2.userDetail[0].lastName;
        data2.createdAt = dateFormat(data2.createdAt);
        data2.comment = data2.comment;

        return data2;
    });
    console.log("00000000000000000000000",responseData)
      dispatch({
        payload: responseData,
        type: COMMENTS.DATA.SUCCESS
      });
    })
    .catch(function(error) {
      console.log("hi")
      dispatch({
        payload: error,
        type: COMMENTS.DATA.ERROR
      });
    });
};
