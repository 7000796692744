
import {constants} from "../constant/constant";

/**
 * CheckIfNotEmpty is for checking text is empty or not
 * @param text
 * @returns {*|boolean}
 * @constructor
 */
export const CheckIfNotEmpty = text => {
    return !(text == null || /^\s*$/.test(text));
};

// Function for check Email Validation
export const CheckIfEmailIsValid = email => {
    const regex = new RegExp(constants.PATTERN.EMAIL, 'g');
    return regex.test(email);
};

// Function for check Date Validation
export const CheckIfDateIsValid = date => {
    date = new Date(date)
    return (date.getFullYear() <= 9999) && (date.getFullYear() >= 1900)
};

// Function for check password format
export const CheckPasswordFormat = password => {
	var strongRegex = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@.#$!%*?&]{8,15}$/; // Password should be small and capital alphabet and length betweetn 8 to 15
	// var strongRegex = /^(?=.*\d)(?=.*[!@#$%^&*:-])(?=.*[a-z])(?=.*[A-Z]).{12,}$/;
	return strongRegex.test(password);
}

// Function to check new password should not be same as old password
export const CheckIfNewPasswordIsValid = (password,newPassword) => {
	return newPassword !== password
}

// Function for check Password and Confirm Password Should be equal
export const CheckIfPasswordIsValid = (password, confirmPassword) => {
    return password === confirmPassword;
};
export const CheckIfResetPasswordIsValid = (password, confirmPassword) => {
    return password === confirmPassword;
};
// Function for check Iteration count should be in range of 50 and 500
export const CheckIfIterationCountIsValid = iterationCount => {
    return iterationCount >= 50 && iterationCount <= 5000;
};

//Function for check phone number validation
export const CheckIfphoneNumberIsValid = phoneNumber => {
    const regex = new RegExp(constants.PATTERN.PHONE, 'g');
    return regex.test(phoneNumber);
};

export const CheckIfNumbericValid = val => {
    const regex = new RegExp(constants.PATTERN.NUMERICONLY, 'g');
    return regex.test(val);
};

export const CheckIfNameIsValid = val => {
    const regex = new RegExp(/^[A-Za-z]+$/, 'g')
    return regex.test(val);
}

export const CheckIfValidIp = val => {
    const regex = new RegExp(constants.PATTERN.IP, 'g');
    return regex.test(val);
};

export const CheckIfValidPort = val => {
    return val <= 65535;
};
/**
 * base64Conversion is for converting image file object to base64 data url
 * @param file
 * @returns {Promise<any>}
 */
export const base64Conversion = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
};

/**
 * dateFormat is used to format date in "dd/mm/yyyy"
 * @param date
 */

export const dateFormat = date => {
    const newDate = new Date(date);
    const month =
        (newDate.getMonth() + 1).toLocaleString().length === 1
            ? '0' + (newDate.getMonth() + 1)
            : (newDate.getMonth() + 1);
    const properDate =
        (newDate.getDate()).toLocaleString().length === 1
            ? '0' + (newDate.getDate())
            : (newDate.getDate());
    return `${properDate}/${month}/${newDate.getFullYear()}`;
};

export const utcFormat = date1 => {
    const date = new Date(date1);
    return date.toUTCString();
};

export const getTime = date => {
    const newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
};
/**
 * dateTimeFormat is used to format date in "MMM DD YYYY HH:MM:SS"
 * @param date
 */

export const dateTimeFormat = date => {
    const newDate = new Date(date);
    const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthName[newDate.getMonth()];
    return `${month} ${newDate.getDate()} ${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;
};

/**
 * convertBlobToFileObject is for converting blog objetc to file
 * @param blob
 * @returns {Promise<any>}
 */
export const convertBlobToFileObject = blob => {
    return new Promise((resolve, reject) => {
        const tempFormData = new FormData();
        tempFormData.set('file', blob)
        resolve(tempFormData.get('file'))
    });
};
