
export const SOFTWARE = {
    GET: {
        INITIATE: 'SOFTWARE_GET_INITIATE',
        SUCCESS: 'SOFTWARE_GET_SUCCESS',
        ERROR: 'SOFTWARE_GET_ERROR',
    },
    DATA: {
        INITIATE: 'SOFTWARE_DATA_INITIATE',
        SUCCESS: 'SOFTWARE_DATA_SUCCESS',
        ERROR: 'SOFTWARE_DATA_ERROR',
    },
    VERSION: {
        INITIATE: 'SOFTWARE_VERSION_INITIATE',
        SUCCESS: 'SOFTWARE_VERSION_SUCCESS',
        ERROR: 'SOFTWARE_VERSION_ERROR',
    },
    VERSION_DATA: {
        INITIATE: 'SOFTWARE_VERSION_DATA_INITIATE',
        SUCCESS: 'SOFTWARE_VERSION_DATA_SUCCESS',
        ERROR: 'SOFTWARE_VERSION_DATA_ERROR',
    },
    SUBMODULE: {
        INITIATE: 'SOFTWARE_SUBMODULE_INITIATE',
        SUCCESS: 'SOFTWARE_SUBMODULE_SUCCESS',
        ERROR: 'SOFTWARE_SUBMODULE_ERROR',
    },
    SUBMODULE_VERSION: {
        INITIATE: 'SOFTWARE_SUBMODULE_VERSION_INITIATE',
        SUCCESS: 'SOFTWARE_SUBMODULE_VERSION_SUCCESS',
        ERROR: 'SOFTWARE_SUBMODULE_VERSION_ERROR',
    },
    SUBMODULE_DATA: {
        INITIATE: 'SOFTWARE_SUBMODULE_DATA_INITIATE',
        SUCCESS: 'SOFTWARE_SUBMODULE_DATA_SUCCESS',
        ERROR: 'SOFTWARE_SUBMODULE_DATA_ERROR',
    },
    SUBMODULE_LIST: {
        INITIATE: 'SOFTWARE_SUBMODULE_LIST_INITIATE',
        SUCCESS: 'SOFTWARE_SUBMODULE_LIST_SUCCESS',
        ERROR: 'SOFTWARE_SUBMODULE_LIST_ERROR',
    },
    DOWNLOAD_VERSION: {
        INITIATE: 'SOFTWARE_DOWNLOAD_VERSION_INITIATE',
        SUCCESS: 'SOFTWARE_DOWNLOAD_VERSION_SUCCESS',
        ERROR: 'SOFTWARE_DOWNLOAD_VERSION_ERROR',
    },
    PLATFORMS: {
        INITIATE: 'GET_SOFTWARE_PLATFORM_REQUEST_INITIATE',
        SUCCESS: 'GET_SOFTWARE_PLATFORM_SUCCESS',
        ERROR: 'GET_SOFTWARE_PLATFORM_ERROR',
    }
};

export const EMOTION = {
    GET: {
        INITIATE: 'EMOTION_GET_INITIATE',
        SUCCESS: 'EMOTION_GET_SUCCESS',
        ERROR: 'EMOTION_GET_ERROR',
    },
    DATA: {
        INITIATE: 'EMOTION_DATA_INITIATE',
        SUCCESS: 'EMOTION_DATA_SUCCESS',
        ERROR: 'EMOTION_DATA_ERROR',
    },
};
export const COMMENTS = {
    GET: {
        INITIATE: 'COMMENT_GET_INITIATE',
        SUCCESS: 'COMMENT_GET_SUCCESS',
        ERROR: 'COMMENT_GET_ERROR',
    },
    DATA: {
        INITIATE: 'COMMENT_DATA_INITIATE',
        SUCCESS: 'COMMENT_DATA_SUCCESS',
        ERROR: 'COMMENT_DATA_ERROR',
    },
};

export const VIDEO = {
    GET: {
        INITIATE: 'VIDEO_GET_INITIATE',
        SUCCESS: 'VIDEO_GET_SUCCESS',
        ERROR: 'VIDEO_GET_ERROR',
    },
    DATA: {
        INITIATE: 'VIDEO_DATA_INITIATE',
        SUCCESS: 'VIDEO_DATA_SUCCESS',
        ERROR: 'VIDEO_DATA_ERROR',
    },
};
export const THIRD_EMOTION = {
    GET: {
        INITIATE: 'THIRD_EMOTION_GET_INITIATE',
        SUCCESS: 'THIRD_EMOTION_GET_SUCCESS',
        ERROR: 'THIRD_EMOTION_GET_ERROR',
    },
    DATA: {
        INITIATE: 'THIRD_EMOTION_DATA_INITIATE',
        SUCCESS: 'THIRD_EMOTION_DATA_SUCCESS',
        ERROR: 'THIRD_EMOTION_DATA_ERROR',
    },
};
export const GALLERY = {
    GET: {
        INITIATE: 'GALLERY_GET_INITIATE',
        SUCCESS: 'GALLERY_GET_SUCCESS',
        ERROR: 'GALLERY_GET_ERROR',
    },
    DATA: {
        INITIATE: 'GALLERY_DATA_INITIATE',
        SUCCESS: 'GALLERY_DATA_SUCCESS',
        ERROR: 'GALLERY_DATA_ERROR',
    },
};
export const JOURNAL_ENTRIES = {
    GET: {
        INITIATE: 'JOURNAL_ENTRIES_GET_INITIATE',
        SUCCESS: 'JOURNAL_ENTRIES_GET_SUCCESS',
        ERROR: 'JOURNAL_ENTRIES_GET_ERROR',
    },
    DATA: {
        INITIATE: 'JOURNAL_ENTRIES_DATA_INITIATE',
        SUCCESS: 'JOURNAL_ENTRIES_DATA_SUCCESS',
        ERROR: 'JOURNAL_ENTRIES_DATA_ERROR',
    },
};
export const WHAT_I_CAN_DO = {
    GET: {
        INITIATE: 'WHAT_I_CAN_DO_GET_INITIATE',
        SUCCESS: 'WHAT_I_CAN_DO_GET_SUCCESS',
        ERROR: 'WHAT_I_CAN_DO_GET_ERROR',
    },
    DATA: {
        INITIATE: 'WHAT_I_CAN_DO_DATA_INITIATE',
        SUCCESS: 'WHAT_I_CAN_DO_DATA_SUCCESS',
        ERROR: 'WHAT_I_CAN_DO_DATA_ERROR',
    },
};
export const SECOND_EMOTION = {
    GET: {
        INITIATE: 'SECOND_EMOTION_GET_INITIATE',
        SUCCESS: 'SECOND_EMOTION_GET_SUCCESS',
        ERROR: 'SECOND_EMOTION_GET_ERROR',
    },
    DATA: {
        INITIATE: 'SECOND_EMOTION_DATA_INITIATE',
        SUCCESS: 'SECOND_EMOTION_DATA_SUCCESS',
        ERROR: 'SECOND_EMOTION_DATA_ERROR',
    },
};

export const CLIENT = {
    GET: {
        INITIATE: 'CLIENT_GET_INITIATE',
        SUCCESS: 'CLIENT_GET_SUCCESS',
        ERROR: 'CLIENT_GET_ERROR',
    },
    DATA: {
        INITIATE: 'GET_CLIENT_DATA_REQUEST_INITIATE',
        SUCCESS: 'GET_CLIENT_DATA_SUCCESS',
        ERROR: 'GET_CLIENT_DATA_ERROR',
    },
    SOFTWARES: {
        INITIATE: 'GET_CLIENT_SOFTWARE_REQUEST_INITIATE',
        SUCCESS: 'GET_CLIENT_SOFTWARE_SUCCESS',
        ERROR: 'GET_CLIENT_SOFTWARE_ERROR',
    },
    SOFTWARE_SUBMODULES: {
        INITIATE: 'GET_CLIENT_SOFTWARE_SUBMODULE_REQUEST_INITIATE',
        SUCCESS: 'GET_CLIENT_SOFTWARE_SUBMODULE_SUCCESS',
        ERROR: 'GET_CLIENT_SOFTWARE_SUBMODULE_ERROR',
    },
    SOFTWARES_INSTALLED_LIST: {
        INITIATE: 'GET_CLIENT_SOFTWARE_REQUEST_INITIATE',
        SUCCESS: 'GET_CLIENT_SOFTWARE_SUCCESS',
        ERROR: 'GET_CLIENT_SOFTWARE_ERROR',
    },
    WHITE_IP: {
        INITIATE: 'CLIENT_WHITE_IP_INITIATE',
        SUCCESS: 'CLIENT_WHITE_IP_SUCCESS',
        ERROR: 'CLIENT_WHITE_IP_ERROR',
    },
    WHITE_IP_LIST: {
        INITIATE: 'CLIENT_WHITE_IP_LIST_INITIATE',
        SUCCESS: 'CLIENT_WHITE_IP_LIST_SUCCESS',
        ERROR: 'CLIENT_WHITE_IP_LIST_ERROR',
    },
    USER_LIST: {
        INITIATE: 'CLIENT_USER_LIST_INITIATE',
        SUCCESS: 'CLIENT_USER_LIST_SUCCESS',
        ERROR: 'CLIENT_USER_LIST_ERROR',
    },
};

export const CLIENT_SOFTWARE = {
    GET: {
        INITIATE: 'GET_CLIENT_SOFTWARE_DATA_REQUEST_INITIATE',
        SUCCESS: 'GET_CLIENT_SOFTWARE_DATA_SUCCESS',
        ERROR: 'GET_CLIENT_SOFTWARE_DATA_ERROR',
    },
    DATA: {
        INITIATE: 'SOFTWARE_DATA_INITIATE',
        SUCCESS: 'SOFTWARE_DATA_SUCCESS',
        ERROR: 'SOFTWARE_DATA_ERROR',
    },
};

export const DOWNLOAD_SOFTWARE = {
    GET: {
        INITIATE: 'GET_DOWNLOAD_SOFTWARE_DATA_REQUEST_INITIATE',
        SUCCESS: 'GET_DOWNLOAD_SOFTWARE_DATA_SUCCESS',
        ERROR: 'GET_DOWNLOAD_SOFTWARE_DATA_ERROR',
    }
};

export const USER = {
    ROLE:{
        INITIATE: 'GET_ROLE_LIST_REQUEST_INITIATE',
        SUCCESS: 'GET_ROLE_LIST_SUCCESS',
        ERROR: 'GET_ROLE_LIST_ERROR',
    },
    ROLE_LIST:{
        INITIATE: 'GET_USER_ROLE_LIST_REQUEST_INITIATE',
        SUCCESS: 'GET_USER_ROLE_LIST_REQUEST_SUCCESS',
        ERROR: 'GET_USER_ROLE_LIST_REQUEST_ERROR',
    },
    USER_TOKEN:{
        INITIATE: 'GET_USER_TOKEN_REQUEST_INITIATE',
        SUCCESS: 'GET_USER_TOKEN_REQUEST_SUCCESS',
        ERROR: 'GET_USER_TOKEN_REQUEST_ERROR',
    },
    DATA:{
        INITIATE: 'GET_USER_DATA_REQUEST_INITIATE',
        SUCCESS: 'GET_USER_DATA_SUCCESS',
        ERROR: 'GET_USER_DATA_ERROR',
    },

    GET:{
        INITIATE: 'GET_USER_LIST_REQUEST_INITIATE',
        SUCCESS: 'GET_USER_LIST_SUCCESS',
        ERROR: 'GET_USER_LIST_ERROR',
    }
};



export const DASHBOARD = {
    USER: {
        INITIATE: 'GET_USER_DASHBOARD_REQUEST_INITIATE',
        SUCCESS: 'GET_USER_DASHBOARD_SUCCESS',
        ERROR: 'GET_USER_DASHBOARD_ERROR',
    }
};

export const MAIL = {
    DATA: {
        INITIATE: 'GET_MAIL_REQUEST_INITIATE',
        SUCCESS: 'GET_MAIL_SUCCESS',
        ERROR: 'GET_MAIL_ERROR',
    }
};

export const PLATFORM = {
    GET: {
        INITIATE: 'PLATFORM_GET_INITIATE',
        SUCCESS: 'PLATFORM_GET_SUCCESS',
        ERROR: 'PLATFORM_GET_ERROR',
    },
    DATA: {
        INITIATE: 'PLATFORM_DATA_INITIATE',
        SUCCESS: 'PLATFORM_DATA_SUCCESS',
        ERROR: 'PLATFORM_DATA_ERROR',
    },
    USER: {
        INITIATE: 'PLATFORM_USER_DATA_INITIATE',
        SUCCESS: 'PLATFORM_USER_DATA_SUCCESS',
        ERROR: 'PLATFORM_USER_DATA_ERROR',
    }
};

export const SYSTEM_EVENT = {
    DATA: {
        INITIATE: 'SYSTEM_EVENT_DATA_INITIATE',
        SUCCESS: 'SYSTEM_EVENT_DATA_SUCCESS',
        ERROR: 'SYSTEM_EVENT_DATA_ERROR',
    }
};

export const SYSLOG_SETTING = {
    GET: {
        INITIATE: 'SYSLOG_SETTING_GET_INITIATE',
        SUCCESS: 'SYSLOG_SETTING_GET_SUCCESS',
        ERROR: 'SYSLOG_SETTING_GET_ERROR',
    },
    DATA: {
        INITIATE: 'SYSLOG_SETTING_DATA_INITIATE',
        SUCCESS: 'SYSLOG_SETTING_DATA_SUCCESS',
        ERROR: 'SYSLOG_SETTING_DATA_ERROR',
    }
};

export const SERVER_DETAIL = {
    GET: {
        INITIATE: 'SYSLOG_SETTING_GET_INITIATE',
        SUCCESS: 'SYSLOG_SETTING_GET_SUCCESS',
        ERROR: 'SYSLOG_SETTING_GET_ERROR',
    }
};

export const ML_SCAN = {
    GET: {
        INITIATE: 'GET_ML_SCAN_DATA_REQUEST_INITIATE',
        SUCCESS: 'GET_ML_SCAN_DATA_SUCCESS',
        ERROR: 'GET_ML_SCAN_DATA_ERROR',
    }
}

export const SHARED_AUTH_URLS = {
    GET: {
        INITIATE: 'GET_SHARED_AUTH_URLS_REQUEST_INITIATE',
        SUCCESS: 'GET_SHARED_AUTH_URLS_SUCCESS',
        ERROR: 'GET_SHARED_AUTH_URLS_ERROR',
    }
};

export const ACTIVE_SESSIONS = {
    GET: {
        INITIATE: 'ACTIVE_SESSIONS_REQUEST_INITIATE',
        SUCCESS: 'ACTIVE_SESSIONS_SUCCESS',
        ERROR: 'ACTIVE_SESSIONS_ERROR',
    }
};