
import {httpservice} from '../services';
import {constants} from "../constant/constant";
import { SHARED_AUTH_URLS } from "../constant/actionTypes";
let apiEndPoint = constants.CLIENT_URL;

export default object => dispatch => {
    return httpservice()
        .get(`${apiEndPoint}${constants.API.SHARED_AUTH_URLS}`)
        .then(result => {
            dispatch({
                payload: result.data.response.data,
                type: SHARED_AUTH_URLS.GET.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: SHARED_AUTH_URLS.GET.ERROR
            });
        })
};
