
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default platformInfo => {
    return httpservice()
        .post(`${constants.API.SOFTWARE_PLATFORM}`, {platforms: platformInfo})
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};