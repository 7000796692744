
/* jshint esversion: 6 */

import React, { Component } from 'react';
// import {Link as RouterLink} from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/LoginScreenStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CheckIfNotEmpty, CheckIfIterationCountIsValid } from '../../helper/StringUtils';
// import {ROUTE} from '../../constant';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { doLogin } from '../../actions';
import { AuthService, EncryptionServices } from '../../services';
import loginMainLogo from '../../assets/images/TEA-logo.png';
import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';
// import {renderTextField} from '../Form/Fields';
import { required, email } from '../Form/validate';
// import Mail from '@material-ui/icons/MailOutline';
// import Lock from '@material-ui/icons/LockOpen';
// import Autorenew from '@material-ui/icons/Autorenew'
// import LoginBackButton from './../../assets/images/login-button.svg';
// import CustomMessages from './CustomMessages';
import MobileLogo from './../../assets/images/software_light_mobile.svg';
import webLines from './../../assets/images/update_login.svg';
import userIcon from './../../assets/images/login-update-icon.png';
import { constants } from '../../constant/constant';
import SignupForm from './SignupForm';
import { MenuService, toasterInstance } from '../../services';
import { doSignup, doUpdateUser } from '../../actions';
// import { withRouter } from 'react-router-dom'; 
const requiredUserName = required('VALIDATION.REQ_USERNAME');
const requiredPassword = required('VALIDATION.REQ_PASSWORD');
const requiredIterationCount = required('VALIDATION.REQ_ITRATION_COUNT');

const onSubmit = async (values, dispatch, props) => {};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      linktoken: null,
      currentUrl: null,
      showPrivateKeyDialog: false,
      isError: false,
      appCheck: false,
      userId: '',
      rowData: null,
      userData: {},
      isFormLoading: false
    };
    let urlParams = new URLSearchParams(window.location.search);
    this.currentUrl = window.location.href;
    this.linktoken = urlParams.get('token');
    this.sso = urlParams.get('sso') ? true : false;
    this.redirectURI = urlParams.get('redirect_uri');
    this.portal = urlParams.get('portal');
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    console.log("Search Params: ", searchParams);
    const id = searchParams.get('id');
    if(id)
    {
      console.log("email00000000000000000000",id)
      this.setState({id});
    }
    else
    {
      console.log("hi")
    }
  }

  componentWillMount() {
    this.props.history.state = {};
    // this.props.history.push(ROUTE.LOGIN);
  }

  componentWillUnmount() {
    this.props.history.state = {};
  }
  /////////////
  handleSubmitForm = (userFormData, userData, uploadImage) => {
    const formData = new FormData();
    if (uploadImage) {
      formData.append('avatar', uploadImage, uploadImage.name); // append file
    }

    for (const key in userFormData) {
      if (key) {
        formData.append(key, userFormData[key]); // append remaining user data
      }
    }

    if (!this.state.id) {
      if(this.linktoken)
      {
        formData.append('linktoken',this.linktoken)
        formData.append('link',this.currentUrl);
        console.log(";;;;;;;;;;;;;;;;",formData)
        this.createUser(formData);
      }
      else
      {
        this.createUser(formData);
      }
    } else {
        this.updateUserList(userFormData, userData, formData);
    }
  };
  /*
   * call Function doCreate on action
   */
  createUser = userObj => {
    this.setState({ isFormLoading: true });

    let redirectPlatformData,
      redirectURI = localStorage.getItem('pltfmUrl');
    if (redirectURI) {
      redirectPlatformData = localStorage.getItem('redirectPlatformData')
        ? JSON.parse(localStorage.getItem('redirectPlatformData'))
        : '';
    }

    this.props
      .doSignup(userObj, [], redirectPlatformData)
      .then(res => {
        this.setState({ isFormLoading: false });
        if (res.status) {
          toasterInstance(<Trans>{'API.' + res.data.message}</Trans>, 2, 'successUser');
          this.setState({
            handleAddModal: false
          });
          this.props.history.push('/?id=fromSignup');
          // this.props.getUsersList(); // navigate
        } else {
          if (res.data) {
            if (typeof res.data.response.errors === 'string') {
              toasterInstance(<Trans>{'API.' + res.data.response.errors}</Trans>, 4, 'failedUser');
            } else if(res.data.status == 409)
            {
              toasterInstance(res.data.message, 4, 'failedUser');
            }
            else {
              res.data.response.errors.forEach(data => {
                toasterInstance(<Trans>{'API.' + data.msg}</Trans>, 4, 'failedUser');
              });
            }
          }
        }
      })
      .catch(err => {
        console.log("0000000000000000",err)
        this.setState({ isFormLoading: false });
        toasterInstance(<Trans>TOASTER.USER_C_FAIL</Trans>, 4, 'failedUser');
      });
  };
  /*
   * call Function doUpdateUser on action
   */
  updateUserList = (userObj, userData, formData) => {
      const newArray = [];
      this.setState({ isFormLoading: true });
      this.props.usersList.data.forEach(data => {
          if (data.id === userObj.id) {
              data.email = userObj.email;
              data.lastName = userObj.lastName;
              data.firstName = userObj.firstName;
              data.name = data.firstName + ' ' + data.lastName;
          }
          newArray.push(data);

      });
      this.props.doUpdateUser(userObj, newArray, formData)
          .then(res => {
              this.setState({ isFormLoading: false });
              if (this.state.userId === userData.id)
                  sessionStorage.setItem('displayName', userData.name);

              if (res.status) {
                  toasterInstance(<Trans>{"API." + res.data.message}</Trans>, 2, 'successUser');
                  this.setState({
                      handleAddModal: false,
                      userId: '',
                      userData: {},
                      rowData: null
                  })
                  this.props.getUsersList();
              } else {
                  if (res.data) {
                      if (typeof (res.data.response.errors) === "string") {
                          toasterInstance(<Trans>{"API." + res.data.response.errors}</Trans>, 4, 'failedUser');
                      } else {
                          res.data.response.errors.forEach(data => {
                              toasterInstance(<Trans>{"API." + data.msg}</Trans>, 4, 'failedUser');
                          })
                      }
                  }
              }
          }).catch(err => {
              this.setState({
                  handleAddModal: false,
                  userId: '',
                  userData: {},
                  rowData: null,
                  isFormLoading: false
              })
              toasterInstance(<Trans>TOASTER.USER_E_FAIL</Trans>, 4, 'failedUser');
          });
  };

  /////////////
  submitSignup = event => {
    const { userName, password, iterationCount } = this.props.formValues;

    if (
      CheckIfNotEmpty(userName) &&
      CheckIfNotEmpty(password) &&
      CheckIfNotEmpty(iterationCount) &&
      CheckIfIterationCountIsValid(iterationCount)
    ) {
      EncryptionServices.generateCredential(userName, password, iterationCount).then(
        credentials => {
          doLogin({ email:userName, password}, {
            sso: this.sso,
            redirectURI: this.redirectURI,
            portal: this.portal
          }).then(res => {
            const data = res.data;
            const { token } = data.response;
            if (data && data.status === 200 && data.response && data.response.token) {
              if (this.redirectURI !== null) {
                window.location.href = `${this.redirectURI}?token=${token}`;
                return;
              }

              localStorage.setItem('cred', JSON.stringify(data.response));
              EncryptionServices.decodeJwtToken(data.response.token).then(decVal => {
                sessionStorage.setItem('userRole', decVal.role.roleName);
                // check user login permissions
                                let appType = process.env.REACT_APP_AUDIENCE_TYPE;
                let roleId = decVal.role.id;
                // roleId = 4;
                if (appType && appType.trim() === 'AdminApp') {
                  this.setState({
                    appCheck: true
                  });
                }
                // if ((roleId === 3 ) && appType && appType.trim() === 'AdminApp') {
                //   this.setState({
                //     appCheck: true
                //   });
                // }

                // if (roleId === 4 && appType && appType.trim() === 'ClientApp') {
                //   this.setState({
                //     appCheck: true
                //   });
                // }
                if (!this.state.appCheck) {
                  this.props.history.state = {
                    status: 'error',
                    msg: 'API.INVALID_CREDENTIALS',
                    show: true
                  };
                  this.setState({ isError: !this.state.isError });
                } else {
                  if (decVal.challenged) {
                    if (decVal.defaultAuthMethod === constants.TFA_METHODS.sms2fa) {
                      this.props.history.push('/verify-sms');
                    } else if (decVal.defaultAuthMethod === constants.TFA_METHODS.u2f) {
                      this.props.history.push('/verify-securityKey');
                    } else {
                      this.props.history.push('/otp');
                    }
                  } else {
                    if (data.response.userData) {
                      const sso = data.response.userData.sso;
                      if (sso) {
                        const {
                          error,
                          errorMessage,
                          access_code,
                          redirect_uri
                        } = data.response.userData;
                        if (error) {
                          if (redirect_uri.includes('http')) {
                            window.location.href = `${redirect_uri}?accessCode=''&error=${error}&errorMessage=${errorMessage}`;
                          } else {
                            window.location.href = `https://${redirect_uri}?accessCode=''&error=${error}&errorMessage=${errorMessage}`;
                          }
                        } else {
                          if (redirect_uri.includes('http')) {
                            window.location.href = `${redirect_uri}?accessCode=${access_code}`;
                          } else {
                            window.location.href = `https://${redirect_uri}?accessCode=${access_code}`;
                          }
                        }
                      } else {
                        // localStorage.setItem('cred', JSON.stringify(data.response));
                        AuthService.authenticate(
                          data.response.token,
                          JSON.stringify(data.response)
                        );
                        this.props.getPermission();
                        this.props.history.push('/');
                      }
                    } else {
                      this.props.history.state = {
                        status: 'error',
                        msg: 'TOASTER.LOGIN_FAIL',
                        show: true
                      };
                      this.setState({ isError: !this.state.isError });
                    }
                  }
                }
              });
            } else {
              if (res && res.data) {
                if (typeof res.data.response.errors === 'string') {
                  this.props.history.state = {
                    status: 'error',
                    msg: 'API.' + res.data.response.errors,
                    show: true
                  };
                  this.setState({ isError: !this.state.isError });
                } else {
                  res.data.response.errors.forEach(innerData => {
                    this.props.history.state = {
                      status: 'error',
                      msg: 'API.' + innerData.msg,
                      show: true
                    };
                    this.setState({ isError: !this.state.isError });
                  });
                }
              }
            }
          });
        }
      );
    } else {
      this.props.history.state = { status: 'error', msg: 'TOASTER.LOGIN_FAIL', show: true };
      this.setState({ isError: !this.state.isError });
    }
  };

  render() {
    console.log("component redering")
    const { classes, ...other } = this.props;
    return (
      <div className={`${classes.loginScreen} loginPage`}>
        <div className={classes.login}>
          <div className={classes.loginLeft}>
            <div className={classes.headi1} style={{ display: 'flex' }}>
              <Grid className={classes.divset}>
                <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
                <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo" />
              </Grid>
              <div className={classes.btnset}>
                <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
                  {this.props.theme.mode == 'light' ? (
                    <img width="35" src={lightTheme} alt="theme" />
                  ) : (
                    <img width="35" src={darkTheme} alt="theme" />
                  )}
                </Button>
                {/* <Button className={classes.createbtn} onClick={()=>{this.goTo()}} type="button">
                      Create an Account
                    </Button> */}
              </div>
            </div>

            <div style={{ display: 'flex' }} className={classes.divMain}>
              <div className= {`rightLogo ${classes.rightLogoset}`}>
                <img src={userIcon} alt="" />
                <div>
                  <p>
                  Thera<span>Me</span>
                  </p>
                  <span className={classes.heading}>Create New Account</span>
                </div>
              </div>

              <SignupForm
                id={this.state.userId}
                token={this.linktoken}
                rowData={this.state.rowData}
                userData={this.state.userData}
                handleSubmit={this.handleSubmitForm}
                isLoading={this.state.isFormLoading}
              />
            </div>
            <div className={classes.footi}>
              <span className={classes.copyright}>
                {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>

      // <div className={`${classes.loginScreen} loginPage`}>
      //     <div className={classes.login}>
      //         <div className={classes.loginLeft}>
      //             <img src={webLines} alt=""/>
      //             <div className='rightLogo'>
      //                 <img src={userIcon} alt=""/>
      //                 <div>
      //                     <p>Thera<span>Me</span></p>
      //                     <span>Therame Dashboard</span>
      //                 </div>
      //             </div>
      //         </div>
      //         <SignupForm
      // 			id={this.state.userId}
      // 			rowData={this.state.rowData}
      // 			userData={this.state.userData}
      // 			handleSubmit={this.handleSubmitForm}
      // 			isLoading={this.state.isFormLoading}
      // 		/>

      //     </div>
      // </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  // getUsersList: (object) => dispatch(getUsersList(object)),
  doUpdateUser: (usersInfo, data, formData) => dispatch(doUpdateUser(usersInfo, data, formData)),
  doSignup: (usersInfo, data, redirectObj) => dispatch(doSignup(usersInfo, data, redirectObj))
  // doDeleteUser: (usersInfo, data) => dispatch(doDeleteUser(usersInfo, data)),
});

const mapStateToProps = state => ({
  formValues: getFormValues('login')(state)
});

const stylesheets = reduxForm({
  form: 'login',
  onSubmit
})(withStyles(styles, { withTheme: true })(Signup));
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(stylesheets));
