
import { constants } from '../constant/constant';
import { USER, CLIENT } from '../constant/actionTypes';
import {httpserviceWithMultipart} from "../services/HttpServices";
let apiEndPoint;

export default (usersInfo, object, data, isClient = false) => dispatch => {
    if(!isClient) apiEndPoint = constants.ADMIN_URL;
    else apiEndPoint = constants.CLIENT_URL;
    return httpserviceWithMultipart('put', data, apiEndPoint + constants.API.USER + '/' + usersInfo.id)
        .then(function (response) {
            if (isClient) {
                dispatch({
                    payload: { data: object, count: object.length },
                    type: CLIENT.USER_LIST.SUCCESS
                })
            } else {
                dispatch({
                    payload: { data: object, count: object.length },
                    type: USER.GET.SUCCESS
                })
            }
            return { status: 1, data: response.data };
        })
        .catch(function (error) {
            return { status: 0, data: error.response && error.response.data ? error.response.data : error };
        });
};
