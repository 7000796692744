
import { DASHBOARD } from "../constant/actionTypes";

export default (state, action) => {
    if (typeof state == 'undefined') {
        state = [];
    }
    switch (action.type) {
        case DASHBOARD.USER.INITIATE:
            return {isFetching: false};
        case DASHBOARD.USER.SUCCESS:
            return {isFetching: true, data: action.payload};
        case DASHBOARD.USER.ERROR:
            return action.payload;
        default:
            return state;
    }
};
