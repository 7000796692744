
import tableIcons from '../helper/tableIcon';

export default (function () {
    let menuList = [];

    const getMenuList = value => {
        return menuList;
    };

    const setMenuList = value => {
        menuList = value;
    };

    const getModulesName = menuListItems => {
        let moduleNames = [];
        if (menuListItems && menuListItems.length) {
            menuListItems.forEach(item => {
                const name = item.moduleName;
                moduleNames.push(name);
                if (item.create) { moduleNames.push(name + 'C'); }
                if (item.read) { moduleNames.push(name + 'R'); }
                if (item.update) { moduleNames.push(name + 'U'); }
                if (item.delete) { moduleNames.push(name + 'D'); }
                if (name === 'licenseGenerate') {
                    if (item.copy) {moduleNames.push(name + 'Copy');}
                    if (item.instance) {moduleNames.push(name + 'Instance');}
                    if (item.upload) {moduleNames.push(name + 'Upload');}
                }
                if (name === 'client') {
                    // moduleNames.push(name + 'User');
                    if (item.lock) {moduleNames.push(name + 'Lock');}
                    moduleNames.push(name + 'Verify');
                    moduleNames.push(name + 'Embed');
                }
            });
        }
        setMenuList(moduleNames);
        return moduleNames;
    };

    const getActionsList = (permissions, actions) => {
        if (!(permissions && permissions.permission && Array.isArray(permissions.permission)
            && permissions.permission.length)) {
            permissions = menuList;
        } else {
            permissions = permissions.permission;
        }
        let tempActionList = [];
        if (permissions && Array.isArray(permissions)) {
            if (actions && actions.length) {
                actions.forEach(item => {
                    if (permissions.indexOf(item.action) > -1) {
                        if (item.icon === 'add') {
                            tempActionList.push({
                                icon: tableIcons.Add,
                                tooltip: item.title,
                                onClick: item.handler,

                            });
                        } else if(item.icon === 'lock'){
                            tempActionList.push({
                                icon: tableIcons.LockOpen,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'verifyUser'){
                            tempActionList.push({
                                icon: tableIcons.VerifiedUser,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'embed'){
                            tempActionList.push({
                                icon: tableIcons.EmbedCode,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'clientUser'){
                            tempActionList.push({
                                icon: tableIcons.SupervisedUserCircle,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateCopy'){
                            tempActionList.push({
                                icon: tableIcons.GetAppIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateUpload'){
                            tempActionList.push({
                                icon: tableIcons.PublishIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateInstance'){
                            tempActionList.push({
                                icon: tableIcons.MenuIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        }else {
                            tempActionList.push({
                                icon: item.icon === 'edit' ? tableIcons.Edit : tableIcons.Delete,
                                tooltip: item.title,
                                onClick: item.handler,

                            });
                        }
                    }
                });
            }
        }
        return tempActionList;
    };
    const getActionsListN = (permissions, actions) => {

        if (!(permissions && permissions.permission && Array.isArray(permissions.permission)
            && permissions.permission.length)) {
            permissions = menuList;
        } else {
            permissions = permissions.permission;
        }
        let tempActionList = [];
        if (permissions && Array.isArray(permissions)) {
            if (actions && actions.length) {
                actions.forEach(item => {
                    // if (permissions.indexOf(item.action) > -1) {
                        if (item.icon === 'add') {
                            tempActionList.push({
                                icon: tableIcons.Add,
                                tooltip: item.title,
                                onClick: item.handler,

                            });
                        } else if(item.icon === 'lock'){
                            tempActionList.push({
                                icon: tableIcons.LockOpen,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'verifyUser'){
                            tempActionList.push({
                                icon: tableIcons.VerifiedUser,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'embed'){
                            tempActionList.push({
                                icon: tableIcons.EmbedCode,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'clientUser'){
                            tempActionList.push({
                                icon: tableIcons.SupervisedUserCircle,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateCopy'){
                            tempActionList.push({
                                icon: tableIcons.GetAppIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateUpload'){
                            tempActionList.push({
                                icon: tableIcons.PublishIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        } else if(item.icon === 'licenseGenerateInstance'){
                            tempActionList.push({
                                icon: tableIcons.MenuIcon,
                                tooltip: item.title,
                                onClick: item.handler,
                            });
                        }else {
                            tempActionList.push({
                                icon: item.icon === 'edit' ? tableIcons.Edit : tableIcons.Delete,
                                tooltip: item.title,
                                onClick: item.handler,

                            });
                        }
                    // }
                });
            }
        }
        return tempActionList;
    };

    return {
        getMenuList: getMenuList,
        setMenuList: setMenuList,
        getModulesName: getModulesName,
        getActionsList: getActionsList,
        getActionsListN: getActionsListN,
    };
})();
