

import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";
import { Trans } from 'react-i18next';
export const    renderTextField = ({
    input,
    icon,  
    type,
    multiline,
    disabled,
    name,
    autoComplete,
    label,
    placeholder,
    required,
    meta: { touched, error },
    classes,
}) => (
        <>
    <Grid container alignItems="flex-end" className={classes.marginBottom}>
                <Grid item className={classes.formInput}>

            <TextField
                {...input}
                className={ classes.loginCredentials + ' ' + classes.inputNew}
                type={type}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                // label={label}
                autoComplete={autoComplete || "off"}
                />
                </Grid>
                </Grid>
            <FormHelperText className={classes.error}>{touched && error && <Trans>{touched && error}</Trans>}</FormHelperText>
        </>
    )