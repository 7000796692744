import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { dateFormat } from "../helper/StringUtils";

/**
 * get license templates
 * @returns {return license templates}
 */
export const getLicenseTemplates = (obj) => dispatch => {
    let query;
    if(!obj){
        query = `${constants.API.GET_LICENSE_TEMPLATES}`;
    }else{
        query = `${constants.API.GET_LICENSE_TEMPLATES}?page=${obj.page}&size=${obj.size}`;
    }
    dispatch({
        payload: [],
        type: 'GET_LICENSE_TEMPLATES_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (templateResponse) {
            const rows = templateResponse.data.response.data.templates ? templateResponse.data.response.data.templates : templateResponse.data.response.data.rows;
            const count = templateResponse.data.response.data.totalItems ? templateResponse.data.response.data.totalItems : templateResponse.data.response.data.count;
            const responseData = rows.map(data => {
                data.created_at = dateFormat(data.createdAt);
                return data;
            });
            dispatch({
                payload: {data: responseData , count: count},
                type: 'GET_LICENSE_TEMPLATES_REQUEST_SUCCESS'
            });
            return responseData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_LICENSE_TEMPLATES_REQUEST_ERROR'
            });
        });
}

export const createLicenseTemplate = (postData) => {
    return httpservice()
        .post(constants.API.LICENSE_TEMPLATE, postData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const updateLicenseTemplate = (updateData) => {
    return httpservice()
        .put(`${constants.API.LICENSE_TEMPLATE}`, updateData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const deleteLicenseTemplate = (id) => {
    return httpservice()
        .delete(`${constants.API.LICENSE_TEMPLATE}?id=${id}`)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        })
}