
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { TextField, InputAdornment, IconButton, FormHelperText } from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from '../../assets/jss/FormStyle';
import { CheckIfDateIsValid, CheckIfNotEmpty, CheckIfNumbericValid, CheckIfValidIp } from '../../helper/StringUtils';
import { CheckIfEmailIsValid } from '../../helper/StringUtils';
import { CheckIfPasswordIsValid } from '../../helper/StringUtils';
import { CheckPasswordFormat } from '../../helper/StringUtils';
import { CheckIfNewPasswordIsValid } from '../../helper/StringUtils';
import { CheckIfIterationCountIsValid } from '../../helper/StringUtils';
import { CheckIfphoneNumberIsValid } from '../../helper/StringUtils';
import { Trans } from 'react-i18next';
import { moment } from 'moment';

let password;
let confirmPassword;
let newPassword;
let oldPassword;

class CInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            showPassword: false,
        };
    }

    /*
     * Function will check
     * 1) Field are empty or not
     * 2) Email, Phone number, Iteration count are valid or not
     * 3) Password and confirmPassword are equal or not
     * 4) Date is between 31/12/9999 and 01/01/1900
     */

    dateVal = ""
    validateInput = e => {
        let tempName = this.props.name;
        let dom = e.target;
        if (dom.type === 'date') {
            if(CheckIfDateIsValid(dom.value)){
                this.props.checkError(dom, false)
                this.dateVal = dom.value
            } else{
                const year = new Date(dom.value).getFullYear();
                if(year>9999){
                    dom.value = this.dateVal
                }
                else{
                    this.dateVal = dom.value
                    this.props.checkError(dom, true, "VALIDATION.DATE_INVALID");
                }
                return false;
            }
        }
        if (
            !(
                tempName === 'password' ||
                tempName === 'confirmPassword' ||
                tempName === 'oldPassword' ||
                tempName === 'newPassword' ||
                tempName === 'phoneNumber' ||
                tempName === 'iterationCount' ||
                tempName === 'oldIterationCount'

            )
        ) {
            if (CheckIfNotEmpty(e.target.value)) {
                this.props.checkError(dom, false);
            } else {
                this.props.checkError(dom, true);
                return false;
            }
        }

        switch (this.props.name) {
            case 'email':
                CheckIfEmailIsValid(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.EMAIL_NVALID");
                return false;
            case 'ipaddress':
                CheckIfValidIp(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.VALID_IP");
                return false;
            case 'versionNumber':
                CheckIfNumbericValid(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.NUMERIC_VERSION_NUMBER");
                return false;
            case 'phoneNumber':
                let phoneNumber = e.target.value;
                if (phoneNumber) {
                    CheckIfphoneNumberIsValid(e.target.value)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true);
                }
                return false;
            case 'password':
                password = e.target.value;
                return false;
            case 'oldPassword':
                oldPassword = e.target.value;
                if (oldPassword && oldPassword !== "") {
                    CheckPasswordFormat(oldPassword)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_FIELD");
                }
                return false;
            case 'newPassword':
                newPassword = e.target.value;
                if (newPassword) {
                    if (CheckIfNewPasswordIsValid(oldPassword, newPassword)) {
                        CheckPasswordFormat(newPassword)
                            ? this.props.checkError(dom, false)
                            : this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
                    } else {
                        this.props.checkError(dom, true, <Trans>VALIDATION.NEW_C_PASS</Trans>);
                    }
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_FIELD");
                }
                return false;
            case 'confirmPassword':
                confirmPassword = e.target.value;
                if (confirmPassword) {
                    if (CheckIfPasswordIsValid(newPassword, confirmPassword)) {
                        CheckPasswordFormat(confirmPassword)
                            ? this.props.checkError(dom, false)
                            : this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
                    } else {
                        this.props.checkError(dom, true, <Trans>VALIDATION.CONFIRM_N_PASS</Trans>);
                    }
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_FIELD");
                }
                return false;
            case 'iterationCount':
                if (e.target.value) {
                    CheckIfIterationCountIsValid(e.target.value)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, "Invalid iteration count");
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_FIELD");
                }
                return false;
            case 'oldIterationCount':
                if (e.target.value) {
                    CheckIfIterationCountIsValid(e.target.value)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, "Invalid old iteration count");
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_FIELD");
                }
                return false;
            case 'passCode':
                e.target.value.length === 6
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true);
                return false;
            case 'licenseLimit':
                if (e.target.value) {
                    (e.target.value < 10000 && e.target.value > 0)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, <Trans>VALIDATION.LICENSE_ALLOWED_INSTANCES_FORMAT</Trans>);
                } else {
                    this.props.checkError(dom, true, "VALIDATION.REQ_LICENSE_ALLOWED_INSTANCES");
                }
                return false;
            default:
                this.props.checkError(dom, false);
        }
    };

    isValidFunction = e => {
        if (this.props.validateForm) this.validateInput(e);
    };

    removeBorder = e => {
        if (this.props.validateForm) this.props.checkError(e.target, false);
    };

    checkType = input => {
        if(input === 'password' || input === 'confirmPassword' || input === 'newPassword' || input === 'oldPassword'
            || input === 'MAIL_PASS'){
            return 'password';
        } else if (input === 'expiryDate') {
            return 'date';
        } else if (input === 'licenseLimit') {
            return 'number';
        } else if (input === 'expiryDateTime') {
            return 'datetime-local'
        } else {
            return 'text';
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
        ;
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render() {
        const {classes, randomKey} = this.props;
        return (
            <React.Fragment key={randomKey ? randomKey : 1}>
                <TextField
                    className={classes.formInput}
                    name={this.props.name}
                    type={this.checkType(this.props.name) === 'password' ? (this.state.showPassword ? 'text' : 'password') : this.checkType(this.props.name)}
                    InputProps={{
                        className: this.props.theme.logo ? "darkTheme " : "lightTheme",
                        endAdornment:this.checkType(this.props.name) === 'password' ?
                                <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" style={{
                                    marginRight: (this.props.name === 'MAIL_PASS') ? '-10px' : '15px',
                                    marginBottom: '15px',
                                    // paddingRight: (this.props.name === 'MAIL_PASS') ? '0px' :'12px',
                                    color: this.props.theme.logo ? "white " : "black"
                                }}
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                    >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment> : ''

                    }}
                    label={this.props.label}
                    id={this.props.id}
                    defaultValue={this.props.info}
                    onChange={this.props.onChange ? this.props.onChange : this.isValidFunction}
                    // onChange={this.props.handleChange}
                    disabled={this.props.readonly ? this.props.readonly : false}
                    error={this.props.isError}
                    helperText={this.props.helperText}
                    ref={this.props.refP}
                    format={this.checkType(this.props.name) === 'date' ? 'YYYY/MM/DD' : null}
                />
                <FormHelperText className={classes.error}>{this.props.isError &&
                <Trans>{this.props.errorVal}</Trans>}</FormHelperText>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, {withTheme: true})(CInput);
