import AuthService from "./AuthService";
import {constants} from "../constant/constant";

let connection = null
let retriesCount = 0
let retrySchedule = null
let buildHashInterval = null
let callback = null

const SocketService = {
    close: () => {
        if (connection) {
            if (connection.close) {
                connection.close();
            } else {
                // For IE
                connection.websocket.close();
            }
        }
    },

    connect: (Callback = null) => {
        callback = Callback;
        const socketToken = sessionStorage['socketToken']
        console.log("socketToken", socketToken);
        if (socketToken){
            _connect(socketToken)
        }
    },

    send: (data) => {
        if (connection) {
            if(connection.readyState === 1){
                connection.send(typeof data === 'string' ? data : JSON.stringify(data));
            }
            else{
                retryConnect()
            }
        }
    }
}

const onOpen = (e) => {
    console.log(`WebSocket connection established with ${constants.SOCKET_URL}`)
    retriesCount = 0;
    setInterval(function timeout() {
        SocketService.send('ping')
    }, 60000);
}

const onError = (e) => {
    console.warn(`Failed establishing WebSocket connection with ${constants.SOCKET_URL}`);
    retryConnect();
}

const onClose = (e) => {
    if (AuthService.isLoggedIn()) {
        e.wasClean && console.log('Server Closed Websocket Connection');
    } else {
        console.log('Websocket Connection Closed');
    }
}

const onMessage = (message) => {
    callback && callback(message)
}

const retryConnect = () => {
    clearInterval(buildHashInterval);
    if (retrySchedule){
        console.log('Another retry is scheduled, Aborting Socket Connection!')
        return false
    }
    else if (!AuthService.isLoggedIn()){
        console.log('User is not logged in, Aborting Sochet connection!')
    }
    if (retriesCount < 10) {
        retriesCount += 1;
    }
    connection = null
    const retryAfter = retriesCount * 10;
    console.log('Socket connection will be retried after', retryAfter, 'seconds');

    retrySchedule = setTimeout(() => {
        console.log('Socket connection retrying now');
        clearTimeout(retrySchedule);
        retrySchedule = null;
        SocketService.connect();
    }, retryAfter * 1000);

}

const _connect = (_socketToken) => {
    if(!constants.SOCKET_URL){
        console.error('Please assign a SOCKET_URL in constants.');
        return
    }
    const url = `${constants.SOCKET_URL}/${_socketToken}`;
    connection = new WebSocket(url);
    connection.onopen = onOpen.bind(this);
    connection.onerror = onError.bind(this);
    connection.onclose = onClose.bind(this);
    connection.onmessage = onMessage.bind(this);
}

export default SocketService
