
/* jshint esversion: 6 */

import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/FormStyle';
import {withTranslation, Trans} from "react-i18next";
import {Button, Grid} from "@material-ui/core";

class CButton extends Component {

    render() {
        const {classes, isFull, disabled, isMarginBottom, smallBtn,equalSpace,isReset} = this.props;
        return (
            <Grid item xs={12}>
                <div className={`${classes.actionBtn} ${classes[isFull ? 'removePosition' : '']} ${classes[isReset ? 'flexEnd' : '']} ${classes[equalSpace ? 'templateBtn' : '']}`}>
                    <Button disabled={disabled} type="submit" className={`${classes.activeButton} ${classes[isMarginBottom ? 'marginBottomBtnTF' : '']} ${classes[smallBtn ? 'smallBtn' : '']}`}>
                        <Trans i18nKey={this.props.type}/>
                    </Button>
                    <Button type="button" onClick={this.props.onCancel}
                            className={`${classes.defaultButton} ${classes[isFull ? 'changeBg' : '']} ${classes[isMarginBottom ? 'marginBottomBtnTF' : '']} ${classes[smallBtn ? 'smallBtn' : '']}`}>
                        <Trans>BUTTON.ABORT</Trans>
                    </Button>
                    {isReset ?
                        <Button disabled={disabled} type="button" onClick={this.props.onReset}
                                className={`${classes.activeButton} ${classes[isMarginBottom ? 'changeBg' : '']} ${classes[this.props.resetAlign + 'Align']} ${classes[isFull ? 'marginBottomBtnTF' : '']} ${classes[smallBtn ? 'smallBtn' : '']}`}>
                            <Trans>BUTTON.RESET_SETTING</Trans>
                        </Button> : null}
                </div>
            </Grid>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(CButton));