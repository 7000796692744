
/* jshint esversion: 6 */

import buttonsStyle from "./buttonsStyle";

const FormStyle = theme => ({
    parentSetting: {
        position: 'relative',
        paddingTop: '80px !important',
        overflow: 'visible',
        marginBottom: 0,
        boxShadow: 'none'
    },
    loginRightTop: {
        paddingTop: '100px !important'
      },
    dateIcon:{
      position:'absolute',
      right:0,
      top: 25,
      zIndex: 999,
      pointerEvents:'none',
      background:theme.palette.modalsContentBg.color,
      '& svg':{
          width:20,
          height:20,
          fill:theme.palette.modalTextColor.color
      }
    },
    formControl: {
        width: "100% !important",
        display: 'flex',
    },
    inputHide: {
        display: "none"
    },
    editFileContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid",
        padding: "10px"
    },
    changePathLabel: {
        width: '100%',
        marginBottom: 20,
        display: 'flex',
        color: theme.palette.secondary.contrastText,
        justifyContent: 'space-between',
        padding: 0,
        fontSize: "0.8rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em"
    },
    headerBar: {
        background: theme.palette.primary.dark,
        padding: '0 0 30px',
        position: 'relative',
        marginTop: 0,
        minHeight: 'calc(100vh - 255px)',
        '@media (min-width: 1399px)': {
            padding: '30px 100px 80px 100px',
            minHeight: 'calc(100vh - 305px)'
        }
    },
    createHeaderBar: {
        background: theme.palette.primary.dark,
        padding: '50px 0',
        position: 'relative',
        minHeight: 'calc(100vh - 345px)',
        '@media (min-width: 1399px)': {
            padding: '70px 50px 80px 50px',
            minHeight: 'calc(100vh - 395px)'
        }
    },
    headerBarSetting: {
        background: theme.palette.primary.dark,
        padding: '10px 0',
        position: 'relative',
        marginTop: 40,
        minHeight: 'calc(100vh - 310px)',
        width: '100%',
        boxSizing: 'border-box'
    },
    formLabel: {
        color: theme.palette.secondary.contrastText,
        width: 220,
        textAlign: 'right',
        fontSize: 16,
        float: 'left',
        marginRight: 15,
        lineHeight: '32px',
        marginBottom: 0
    },
    formLabelSetting: {
        color: theme.palette.secondary.contrastText,
        width: '100%',
        fontSize: 14,
        float: 'left',
        lineHeight: '38px',
        marginLeft: 0,
        /*whiteSpace: 'nowrap',
         textOverflow: 'ellipsis',
         overflow: 'hidden',*/
        wordBreak: 'break-word',
        '@media (max-width: 1366px)': {
            lineHeight: '15px',
            fontSize: 12
        }
    },
    formLabelSettingSwitch: {
        color: theme.palette.secondary.contrastText,
        width: '100%',
        fontSize: 14,
        float: 'left',
        lineHeight: '38px',
        '@media (max-width: 1366px)': {
            fontSize: 12
        }
    },
    formHeadingSetting: {
        color: theme.palette.secondary.contrastText,
        width: '100%',
        fontSize: 18,
        float: 'left',
        lineHeight: '38px'
    },
    formInput: {
        width: '100%',
        minWidth: 65,
        float: 'left',
        fontSize: '16px',
        border: 0,
        background: 'none',
        color: theme.palette.secondary.contrastText,
        padding: '7px 0',
        resize: 'none',
        fontFamily: 'Roboto, sans-serif !important',
        maxHeight: 88,
        '&.warning': {
            borderBottom: 'solid 1px red'
        },
        '@media (min-width: 1365px)': {
            minWidth: 125
        }
    },
    fullWidthTextFeild: {
        maxHeight: 'inherit',
        '& textarea': {
            minHeight: 44,
        }
    },
    formInputSelect: {
        width: '100%',
        minWidth: 65,
        float: 'left',
        fontSize: '16px',
        border: 0,
        background: 'none',
        color: theme.palette.secondary.contrastText,
        padding: '7px 0',
        resize: 'none',
        fontFamily: 'Roboto, sans-serif !important',
        borderBottom: '1px solid #a9abb9',
        maxHeight: 88,
        '&.warning': {
            borderBottom: 'solid 1px red'
        },
        '@media (min-width: 1365px)': {
            minWidth: 125
        }

    },
    formTextarea: {
        width: '100%',
        float: 'left',
        fontSize: '16px',
        border: 0,
        background: theme.palette.primary.light,
        color: theme.palette.secondary.contrastText,
        padding: '7px',
        resize: 'inherit',
        margin: '2px 0',
        fontFamily: 'Roboto, sans-serif !important'
    },
    fullWidth: {
        width: '100%',
        float: 'left'
    },
    formSelect: {
        width: '100%',
        float: 'left',
        fontSize: '16px',
        border: 0,
        /*borderBottom: theme.palette.secondary.contrastText + ' solid 1px',*/
        background: 'none',
        color: theme.palette.secondary.contrastText,
        padding: 0,
        fontFamily: 'Roboto, sans-serif !important'
    },
    formSelectSummary: {
        width: '245px',
        float: 'left',
        fontSize: '16px',
        border: 0,
        borderBottom: theme.palette.secondary.contrastText + ' solid 1px',
        background: 'none',
        color: theme.palette.secondary.contrastText,
        padding: 0,
        fontFamily: 'Roboto, sans-serif !important'
    },
    fileUploadSection: {
        position: 'absolute',
        right: 0,
        top: -50,
        width: 100,
        height: 100,
        borderRadius: 50,
        background: theme.palette.primary.light,
        '@media (min-width: 1399px)': {
            right: 25
        }
    },
    fileUploadSectionSetting: {
        position: 'absolute',
        right: 10,
        top: -30,
        width: 100,
        height: 100,
        borderRadius: 50,
        background: '#fff',
        '@media (max-width: 1365px)': {
            top: -20
        }
    },
    avatar: {
        width: 100,
        height: 100,
        '@media (max-width: 580px)': {
            width:70,
            height:70
                }
    },
    setUploadAvatar: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 30,
        height: 30,
        borderRadius: 15,
        background: theme.palette.avatarBg.color,
      
    },
    fileUploadSectionInput: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 30,
        height: 30,
        opacity: 0,
        zIndex: 10,
        cursor: 'pointer'
    },
    dropdown: {
        width: '100%', // Adjust the width as needed
        padding: theme.spacing(1),
        // border: '1px solid #000',
        // borderRadius: '4px',
        backgroundColor: 'transparent',
      },
    changeAvatar: {
        fill: theme.palette.modalTextColor.color,
        margin: '5px',
        fontSize: 20
    },
    actionBtn: {
        width: '100%',
        float: 'left',
        position: 'absolute',
        right: 25,
        bottom: 25,
        '@media (min-width: 1399px)': {
            right: 75
        }
    },
    activeButton: {
        whiteSpace:"nowrap",

        '&:hover':{
            background: theme.palette.primary.color,
            opacity:0.9
        },
        '&.Mui-disabled':{
          color:'#fff',
          cursor:'not-allowed',
          pointerEvents:'inherit',
          background: theme.palette.primary.color,
          '&:hover':{
            background: theme.palette.primary.color,
            cursor:'not-allowed',
        },
        },
        ...buttonsStyle.activeButton,
        color: '#fff',
        background: theme.palette.primary.color
    },
    defaultButton: {
        ...buttonsStyle.defaultButton,
            color: '#1e1c1c',
            background: '#ebebeb',
            lineHeight:1,
        '&:hover':{
            background: '#ebebeb',
            color:'#1e1c1c',
            boxShadow:theme.palette.defaultBtnShadow.boxShadow
        },
    },
    pageLoader: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: '0',
        top: '0',
        background: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
    },
    svg: {
        color: theme.palette.primary.contrastText
    },
    tabsHead: {
        background: 'none',
        boxShadow: 'none',
        maxHeight: 57
    },
    flexDirection: {
        flexDirection: 'column'
    },
    flexDiv: {
        width: '100%',
        marginBottom: 20,
        display: 'flex',
        color: theme.palette.secondary.contrastText,
        justifyContent: 'space-between'
    },
    error: {
        // borderBottom: '1px solid #e01100 !important',
        color: '#f44336',
        clear: "both"
        // '&:before': {
        //     borderBottom: 0
        // },
        // '&:hover:before': {
        //     borderBottom: '1px solid #e01100 !important'
        // }
    },
    positionRelative: {
        position: 'relative'
    },
    errorFA: {
        color: 'red',
        fontSize: '12px',
        marginLeft: 0,
        position: 'absolute',
        left: 0,
        top: '50px'
    },
    overflowVisible: {
        overflow: 'visible',
        boxShadow: 'none'
    },
    reportInfo: {
        width: 'auto',
        float: 'left',
        marginRight: 15
    },
    paddingTwoFive: {
        padding: 10,
        background: theme.palette.primary.dark,
        '@media (min-width: 1399px)': {
            padding: 25
        }
    },
    paddingNine: {
        padding: '25px 10px',
        '@media (min-width: 1399px)': {
            padding: '25px 9px'
        }
    },
    marginBottomSpacing: {
        marginBottom: 15,
        position: 'relative',
        width: '100%',
        float: 'left',
        minHeight: 62,
        '& span.Mui-checked':{
            color:'inherit'
        },
        '@media (max-width: 580px)': {
            marginBottom: 10,
            
                }
    },
    mailSettingContent: {
        width: 'calc(100% - 300px)',
        float: 'left',
        margin: 100,
        textAlign: 'center',
        lineHeight: '35px',
        padding: 50,
        background: theme.palette.primary.light,
        '@media (max-width: 768px)': {
            padding: 10,
            margin: 10,
            width: 'calc(100% - 40px)'
        }
    },
    removeBoxShadow: {
        boxShadow: 'none'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        float: 'left'
    },
    chip: {
        margin: 2
    },
    floatLeft: {
        float: 'left'
    },
    /*maxWidth: {
        maxWidth: 320
    },*/
    ckEditor: {
        background: theme.palette.secondary.light
    },
    customFileUpload: {
        float: 'left',
        position: 'relative',
        marginLeft: '-15px'
    },
    uploadFileInput: {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        width: '100%',
        height: '100%'
    },
    fileDropDragUpload: {
        minHeight: '88px',
        borderStyle: 'dashed',
        minWidth: 65,
        cursor:"pointer",
        padding: "30px 8px",
        color: theme.palette.secondary.contrastText,
        // paddingTop: 30,
        alignItems: 'flex-start',
        '@media (max-width: 565px)': {
            fontSize:"2.5vw"
        }

    },
    QRCode: {
        background: '#fff',
        padding: 10
    },
    Low: {
        background: '#777777',
        padding: '5px',
        color: '#f5f5f5'
    },
    Medium: {
        background: '#cfb70b',
        padding: '5px',
        color: '#f5f5f5'
    },
    High: {
        background: '#EF5350',
        padding: '5px',
        color: '#f5f5f5'
    },
    Critical: {
        background: '#F76397',
        padding: '5px',
        color: '#f5f5f5'
    },
    Notes: {
        background: '#7266BA',
        padding: '5px',
        color: '#f5f5f5'
    },
    externalSearch: {
        position: 'absolute',
        left: 10,
        top: 25,
        zIndex: 9,
        '@media (min-width: 1399px)': {
            left: 25
        }
    },
    editor: {
        border: '3px solid ' + theme.palette.secondary.main,
        position: 'relative'
    },
    removeTopBottomPadding: {
        paddingBottom: 0 + '!important'
    },
    floatLeftButton: {
        float: 'left',
        marginRight: 15,
        '@media (max-width: 1365px)': {
            marginRight: 5
        }
    },
    bottomExtraMargin: {
        marginBottom: '-54px',
        float: 'left',
        marginTop: 30
    },
    extraPosition: {
        position: 'relative',
        zIndex: 1
    },
    extraTopMargin: {
        marginTop: 10,
        float: 'left'
    },
    queLabelSetting: {
        color: theme.palette.secondary.contrastText,
        marginTop: 5
    },
    lessMarginLeft: {
        marginLeft: 0
    },
    floatRight: {
        float: 'right'
    },
    Initiated: {
        background: '#f2c572',
        padding: '5px',
        color: '#f5f5f5'
    },
    InProgress: {
        background: '#a01497',
        padding: '5px',
        color: '#f5f5f5'
    },
    Completed: {
        background: '#3597dc',
        padding: '5px',
        color: '#f5f5f5'
    },
    Cancelled: {
        background: '#cc66ff',
        padding: '5px',
        color: '#f5f5f5'
    },
    createHeaderBarModal: {
        padding: '5px 0 0',
        minHeight: 'inherit',
        background: 'none',
        '& > div > div + div':{
           backgroundColor:'inherit'
        },
        '& table thead th':{
             backgroundColor:'transparent'
        },
        '@media (min-width: 1399px)': {
            minHeight: 'inherit',
            padding: '5px 0 0',
        }
    },
    removePosition: {
        position: 'static',
        marginTop: 25,
        marginBottom: 25,
        display:'flex',
        flexDirection:'row-reverse',
        justifyContent:'space-between'
    },
    fileUploadSectionModal: {
        '@media (min-width: 1399px)': {
            right: 25
        }
    },

    openAddModal: {
        right: 0,
        top: 30,
        left: 'inherit',
        backgroundColor: theme.palette.primary.light + '!important',
        borderRadius: '50%',
        textAlign: 'center',
        padding: '0',
        color: theme.palette.primary.contrastText,
        fill: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        border: 0,
        '&:hover': {
            backgroundColor: theme.palette.primary.light + '!important'
        },
        '&:focus': {
            outline: 'none'
        },
        '@media (max-width:767px)':{
            top: 0,
        }
    },
    insertDriveFile: {
        right: 178,
        '@media (min-width: 1399px)': {
            right: 193
        }
    },
    pictureAsPdf: {
        right: 136,
        '@media (min-width: 1399px)': {
            right: 151
        }
    },
    Fullscreen: {
        right: 94,
        '@media (min-width: 1399px)': {
            right: 109
        }
    },
    RateReview: {
        right: 52,
        '@media (min-width: 1399px)': {
            right: 67
        }
    },
    companyList: {
        position: 'relative'
    },
    extraTopMarginAvtar: {
        marginTop: 65,
        top: -77,
        right:16,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    hiddenScroll: {
        overflowY: 'visible'
    },
    marginFive: {
        margin: '8px 0'
    },
    selectMarginTop: {
        marginTop: 15
    },
    linkColorTd: {
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            color: theme.palette.secondary.main,
        }
    },
    'path': {
        fill: theme.palette.secondary.contrastText
    },
    alignLeft: {
        textAlign: 'left',
        paddingLeft: 15
    },
    extraPadding: {
        padding: '0 12px'
    },
    fontManage: {
        transform: 'scale(0.75)'
    },
    freezeFooter: {
        maxHeight: 'calc(100vh - 340px)',
        overflowY: 'auto',
        width: '100%',
        padding: '0 20px',
        boxSizing:'border-box',
        '@media (max-width:767px)':{
            margin: '0',
            padding:'0 10px'
        }
    },
    buttonSize: {
        width: 32,
        height: 32,
        padding: 0,
        '@media (max-width:340px)':{
            width: 26,
            height: 26,
        }
    },
    marginTop0: {
        marginTop: 0,
        color: theme.palette.secondary.dark,
        fontWeight: 'normal',
        fontSize: 16
    },
    fontSize14: {
        fontSize: 14,
        lineHeight: '20px'
    },
    leftAlign: {
        float: 'left',
        marginRight: 15
    },
    rightAlign: {
        float: 'right',
        marginRight: '13px'
    },
    extraMarginTop50: {
        marginTop: 50
    },
    modalBodyScroll: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 190px)',
        overflowX: 'hidden',
        paddingBottom: 20
    },
    marginBottomBtnTF: {
        marginBottom: 25
    },
    selectBoxDiv:{
            '& label + div':{
                '&::before':{
                   borderBottom:'1px solid ' + theme.palette.modalTextColor.color
                },
                '&::after':{
                    borderBottom:'1px solid ' + theme.palette.modalTextColor.color
                 },
                '&.Mui-focused > div':{
                    backgroundColor:'transparent !important'
                },
            }
    },
    helpIcon:{
        float:'right',
        position:'absolute',
        top:'20px',
        right:'0px',
            '& .versionNumberHelpIcon': {
                position: 'absolute',
                background: '#000',
                color: '#fff',
                right: 6,
                padding:10,
                width:380,
                fontSize:13,
                opacity:0,
                top:36,
                '& span':{
                    borderLeft:'5px solid transparent',
                    borderRight:'5px solid transparent',
                    borderBottom:'5px solid #000',
                    position: 'absolute',
                    zIndex: 999,
                    top: -5,
                    right: 5
                }
            },
        '&:hover':{
            '& .versionNumberHelpIcon':{
                opacity:1
            }
        }
    },
    checkboxBlock:{
      '& label > span':{
          '&.Mui-checked':{
              color:'inherit'
          }
      }
    },
    nested: {
        display: 'none',
        '&.active':{
            display:'block'
        }
    },
    submodules: {
        marginLeft: 50,
        '& p':{
            margin:0,
            '& span':{
                color:'inherit !important'
            }
        },
        '&.active':{
            display:'block'
        }
    },
	tooltipClass: {
		marginRight: 25,
		fontSize: 14
    },
    iconLabel:{
        display:'flex',
        alignItems:'center',
        background:theme.palette.iconLabelBg.color,
        marginBottom:10,
        justifyContent:'space-around',
        borderRadius:4,
        width:300,
        position:'relative',
        '@media (max-width:1128px)': {
            width:"auto"
          },
        '& > div':{
            width:'100%',
             '& + div':{
               width:'auto',
               position:'absolute',
               right:0,
             },
           '& label':{
               marginRight:0
           }
         },
        '& button':{
            marginBottom:0,
            fontSize:12,
            background:theme.palette.iconLabelBgDisabled.color,
            display:'flex',
            justifyContent:'center',
            minWidth:'100%',
            float:'none',
            textTransform:'Inherit',
            border:'1px solid #0000001A',
            '& .div': {
                cursor: 'pointer'
              },
            minHeight:48,
            '& span > span':{
              position:'absolute',
              left:10,
            },
            '& .fullText':{
              position:'static',
              color:theme.palette.iconLabelTextDisabled.color,
              opacity:0.5,
              fontWeight:500
            },
            '&:hover':{
                backgroundColor:theme.palette.iconLabelBgDisabled.color
            },
            '& svg':{
                width:24,
                height:24,
                fill:theme.palette.modalTextColor.color,
                cursor:'pointer',
                position: 'absolute',
                top: -12
            }
        }
     },
     buttonBorder:{
         '& .MuiCheckbox-colorSecondary.Mui-checked':{
             color:theme.palette.modalTextColor.color
         },
         '& button':{
             background:theme.palette.iconLabelBgDisabled.color,
             border:'1px solid '+theme.palette.primary.color,
             opacity:1,
             '&:hover':{
                backgroundColor:theme.palette.iconLabelBg.color,
             },
             '& .fullText':{
                position:'static',
                color:theme.palette.modalTextColor.color,
                opacity:1,
                fontWeight:'bold'
             },
             '& svg':{
                width:24,
                height:24,
                fill:theme.palette.modalTextColor.color,
                opacity:1
             }
         }
     },
      checkbox: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width:420px)': {
          flexDirection: 'column'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
          color: 'inherit'
        },
        '& label': {
          '& span': {
            '&:last-child': {
              fontSize: 14
            },
            '&.Mui-checked':{
                color:'inherit'
            }
          }
        }
      },
      checkboxColumnWise: {
        flexDirection: 'column',
        paddingLeft: '30px',
        paddingRight: '1px'
      },
      root: {
        width: '100%',
      },
      radioLabel: {
        paddingLeft: '23px'
      },
      checkFormLabel: {
        margin: '20px 0 10px',
        '&.Mui-focused': {
          color: theme.palette.modalTextColor.color
        }
      },
      fileInput: {
        width: '100%',
        border: 'none',
        margin: 0,
        bottom: 0,
        background: '#355b7c',
        position: 'absolute',
        top: 0,
        padding: 0,
        opacity: 0,
        borderRadius: '5px',
        cursor: 'pointer',
        '& > div': {
          opacity: 0,
          padding: 0,
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 0,
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          }
        },
        '& label': {
          display: 'none'
        },
        '& input': {
          opacity: 0,
          height: '100%'
        }
      },
      browseBtn: {
        color: '#fff',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 'bold',
        position: 'static',
        pointerEvents: 'none',
        display: 'flex',
        '& svg': {},
        '& img': {
          width: '26px',
          display: 'inline-block',
          verticalAlign: 'text-bottom',
          pointerEvents: 'none',
          paddingRight: '5px'
        },
        '@media (max-width:767px)': {
          fontSize: 12,
          '& img': {
            width: '14px'
          }
        }
      },
      fileName: {
        position: 'absolute',
        left: '160px',
        fontSize: '18px',
        fontWeight: 'bold',
        width: '100%'
      },
      radioGroup: {
        position: 'absolute',
        top: -9,
        left: 150,
        flexDirection: 'row',
        '@media (max-width:767px)': {
          '& span': {
            fontSize: 13
          }
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
          color: 'inherit'
        },
        '& .helpIcon': {
          position: 'relative',
          bottom: 0,
          right: 0,
          display: 'inline-block',
          verticalAlign: 'middle',
          color: '#b7b7b7',
          '& svg': {
            width: 18,
            height: 18
          },
          '& .helpIconToolTip': {
            top: 31,
            color: '#fff',
            left: 0,
            padding: 10,
            position: 'absolute',
            fontSize: 12,
            width: '320px',
            display: 'none',
            zIndex: 1,
            background: '#454545',
            '& span': {
              top: -5,
              left: 5,
              display: 'block',
              position: 'absolute',
              borderTop: '11px solid transparent',
              borderRight: '11px solid #454545',
              transform: 'rotate(225deg)'
            }
          },
          '&:hover': {
            '& .helpIconToolTip': {
              display: 'inline-block'
            }
          }
        }
      },
      inputPosition: {
        position: 'relative',
        '&.inputMargin': {
          '& > div': {
            margin: 0,
            width: '100%'
          }
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          bottom: -13,
          marginTop: 0,
          marginLeft: 0
        }
    },
    registerInputPosition: {
        position: 'relative',
        '& .MuiFormHelperText-root': {
          marginLeft: 25
        }
    },
    filterSelect: {
        position: 'static',
        zIndex: 9,
        left: 150,
        top: 21,
        '& .filterCount': {
          display: 'none'
        },
        '& label': {
          top: -5,
          position: 'static',
          '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.modalTextColor.color
          }
        },
        // '& .MuiInput-underline:before': {
        //   borderBottom: '1px solid ' + theme.palette.modalTextColor.color
        // },
        '& .MuiInput-underline.Mui-focused:after': {
          transform: 'scaleX(0)'
        }
    },
    filterSelectInput: {
        margin: '8px 0 0 !important',
        '& .MuiSelect-select': {
          color: theme.palette.modalTextColor.color,
          padding: 0,
          backgroundColor: 'transparent !important'
        },
        '@media (min-width: 768px) and (max-width:1200px)': {
          minWidth: 120
        }
    },
    spellCheckInput: {
        position: 'relative',
        minWidth: '300px'
    },
    filterCount: {
        position: 'relative'
    },
    filterBtnLeft: {
        left: 11,
        top: 25,
        width: 260,
        '@media (min-width:1367px)': {
          left: 25
        }
    },
    countrySelector: {
        position: 'relative'
    },
    verificationModal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        margin: '0',
        marginTop: 0,
        padding: '30px 30px',
        background:theme.palette.profileSectionSidebarContent.color,
        '@media (max-width:750px)': {
            display:"block",
                      },
    },
    verificationModalLeft: {
        width: '57%',
          '@media (max-width:1090px)': {
            width:"62%",
           },
           '@media (max-width:750px)': {
            width:"82%",
            },
           '@media (max-width:750px)': {
            width:"100%",
            },
        '& .removeBoxShadow':{
            background:'none',
            boxShadow:'none',
            '& p':{
                color:'#888',
                '@media (max-width:1090px)': {
                    fontSize:"12px",
                  },
            },
            '& h4':{
                marginTop:0
            }
        },
        '& h3': {
          fontSize: 16,
          color: theme.palette.modalTextColor.color,
          margin: 0
        },
        '& > label': {
          fontSize: 14,
          textAlign: 'justify',
          display: 'block',
          color: '#888',
          margin: '20px 0'
        }
    },
    verificationBtns: {
        // width: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: 24
    },
    verificationBtnsGroup: {
        margin: '5px 0',
        width: '100%',
        justifyContent: 'flex-start',
        display: 'flex',
        '& button': {
          width: '100%',
          fontSize: '0.875rem',
          marginLeft: 0,
          marginRight: 8
        }
    },
      topPaddingRemove: {
        padding: 0
    },
      addExtraPadding: {
        padding: 24
    },
    profileUploadSection: {
        right: 0,
        top: -50,
        width: 100,
        height: 100,
        borderRadius: 50,
        background:theme.palette.profileSectionSidebar.color,
        '& svg':{
            filter:theme.palette.invert.invert
        },
        '@media (min-width: 1399px)': {
            right: 25
        },
        
        '@media (max-width: 580px)': {
            width:70,
            height:70
                }


    },
    
    setProfileUploadAvatar: {
        position: 'absolute',
        left: 70,
        bottom: 0,
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: theme.palette.profileSectionSidebar.color,
        '& svg':{
            filter:'unset',
            '@media (max-width: 580px)': {
                fontSize:"13px"
                    }            

        },
        '@media (max-width: 580px)': {
            width:22,
            height:22,
            left:"53px"
                }
    },
    phoneNumber: {
        '& .PhoneInput': {
            padding: '10px 0px',
            color: theme.palette.modalTextColor.color,
            '& input.PhoneInputInput': {
         
            border: 'none',
            borderBottom: '1px solid ' + theme.palette.modalTextColor.color,
            background: 'transparent',
            color: theme.palette.modalTextColor.color + '!important',
            fontSize: 16,
            '@media (max-width: 580px)': {
               fontSize:"13px!important"
                    },
            },
            '& .PhoneInputCountryIcon--border img': {
            border: '1px solid ' + theme.palette.modalTextColor.color
            }
        }
    },
    phoneSpan: {
        top: 18,
        color: theme.palette.modalTextColor.color,
        textAlign: 'center',
        margin: 2,
        padding: 1,
        position: 'absolute',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 'none',
          opacity: 0.8
        },
        '@media (max-width: 580px)': {
            top:"22px"
     }
    },
    changeRightPosition: {
        right: 55,
        '@media (max-width: 580px)': {
            fontSize:"12px",
        right:"none"
        }
    },
    selectedIcon: {
        borderRadius: '50%',
        background: '#3d8117',
        color: '#fff',
        width: '25px',
        height: '25px',
        textAlign: 'center',
        margin: 2,
        padding: 1,
        position: 'absolute',
        top: 15,
        right: 3,
        '& svg': {
          fontSize: 25
        }
    },
    verifyRightPosition: {
        right: 3,
        '@media (max-width: 580px)': {
            fontSize:"12px",
            right: '14px',
     }
    },
    removeMargin: {
        height: 'auto',
        margin: 0
    },
    profilePicture: {
        color: theme.palette.modalTextColor.color,
        marginTop:0
    },
    personalDetailsContainer:{
        backgroundColor: theme.palette.profileSectionSidebarContent.color,
        boxShadow:'none',
        '& .PhoneInputCountryIcon':{
            color: theme.palette.modalTextColor.color,
        }
    },
    personalDetails:{
        padding:'25px 30px',
        '@media (max-width: 580px)': {
            padding:'20px 10px !important',
     }
    },
    addPositionStatic:{
        position:'static',
        '@media (max-width: 690px)': {
         display:"flex",
         justifyContent:"space-between",
         flexDirection: 'row-reverse',
                }

        
    },
    removeBg:{
        backgroundColor:'transparent',
        background:'none',
        '& > div':{
            padding:0,
            '& h4':{
                margin:0
            }
        }
    },
    commentIconSvg: {
        '& svg': {
          margin: '0px 5px'
        },
        '& .timer': {
          margin: '0px 5px'
        }
    },
    mailSettingActionBtn: {
        bottom: 200,
        '@media (min-width: 1399px)': {
            bottom: 425
        }
    },
    add_margin_35: {
        margin: '35px 0px',
        display:"flex",
        justifyContent:"end",
        '@media (max-width: 550px)': {
            flexDirection:"column",
            margin: '8px 0px',
        "& button":{
            width:"100%!important",
            marginLeft:"0px",
         },
       },
      },
    marginLeft_15: {
        marginLeft: 15
    },
    minHeight60: {
        minHeight: 60,
        cursor: 'default'
    },
    paddingtwentyFive: {
        padding: '25px 25px 0px 25px',
        background: theme.palette.mainTableLayout.color,
        '& > div': {
            background: theme.palette.mainTableLayout.color
        },
    },
    paddingAllOver: {
        padding: '15px',
        '@media (max-width: 630px)': {
           padding:"12px",
        },
        '@media (max-width: 690px)': {
           padding:"12px",
        },

    },
    noteLabel:{
        color:theme.palette.modalTextColor.color,
        fontSize:12,
    },
    enableU2F: {
        width: '100%',
        float: 'left',
        '& + button':{
          minWidth:'fit-content',
          color:'#fff',
          backgroundColor:theme.palette.primary.color,
          '&:hover':{
              opacity:0.9,
              backgroundColor:theme.palette.primary.color,
              color:'#fff',
          }
        }
    },
    listItemName:{
        paddingLeft:0,
        textAlign:'center'
    },
    insertDriveModal:{
        marginTop:0,
        '& h4':{
            margin:'25px 0',
            display:'flex',
            alignItems:'center',
            '& span':{
            display:'flex',
            }
        }
    },
    fixedLabel:{
        '& label':{
            top:'-12px !important',
            fontSize:12,
            transform: 'translate(0, 24px) scale(1) !important'
        }
    },
    licenseCheckBox: {
        minHeight: 'inherit',
        marginBottom: 10,
        marginLeft: -10,
        marginTop: -15,
    },
    switchButton: {
        '& label': {
            '& span.Mui-checked': {
                '& > span span': {
                    backgroundColor: theme.palette.primary.color
                },
                '& +span':{
                    backgroundColor: '#1e1e1e !important',
                    opacity: '1 !important'
                }
            },
            marginTop: '20px',
            marginLeft: '0px !important'
        }
    },
    downloadButton: {
        width: 'auto',
        minWidth: 120,
        minHeight: 42,
        lineHeight: '42px',
        marginLeft:0,
        textAlign: 'center',
        padding: '0 12px',
        border: 0,
        color: '#fff',
        background: theme.palette.secondary.main,
        float: 'right',
        marginBottom: 10,
        borderRadius: '0 !important',
        '&:hover': {
            background: theme.palette.secondary.main,
            opacity:0.9,
        }
    },
    maxHeightRemove:{
        maxHeight:'inherit'
    },
    smallBtn: {
        width: 'auto'
    },
    templateBtn:{
      display:'flex',
      flexDirection:'row-reverse',
      padding:'0 20px',
      boxSizing:'border-box',
      justifyContent:'space-between',
      '& button':{
         '&:first-child':{
             marginLeft:0,
             marginRight:'0 !important'
         }
      }
    },
    flexEnd:{
        justifyContent:'flex-start'
    },
    removeRadius:{
        borderRadius:"unset"
    }
});

export default FormStyle;
