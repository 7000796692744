import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/styles';

import { Grid, Link, Button } from '@material-ui/core';

import Mail from '@material-ui/icons/MailOutline';
import Lock from '@material-ui/icons/LockOpen';
import { VisibilityOutlined as LockIcon, VisibilityOffOutlined as LockOpenIcon } from '@material-ui/icons';
import Autorenew from '@material-ui/icons/Autorenew';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
// import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import CustomMessages from './CustomMessages';

import { renderTextField } from '../Form/Fields';
import PasswordField from '../Form/PasswordField';
import { required, email } from '../Form/validate';

import { ROUTE } from '../../constant';
import { constants } from '../../constant/constant';
import { doLogin, getPlatform } from '../../actions';
import styles from '../../assets/jss/LoginScreenStyles';
import darkTheme from '../../assets/images/theme-dark.png';
import lightTheme from '../../assets/images/theme-light.png';
import { AuthService, EncryptionServices } from '../../services';
import loginMainLogo from '../../assets/images/TEA-logo.png'; //TEA-logo.png;
import { CheckIfNotEmpty } from '../../helper/StringUtils';
import headerLogo from '../../assets/images/TEA-logo.png';
import LoginBackButton from './../../assets/images/login-button.svg';
import MobileLogo from './../../assets/images/software_light_mobile.svg';
import userIcon from './../../assets/images/login-update-icon.png';

const requiredUserName = required('VALIDATION.REQ_USERNAME');
const requiredPassword = required('VALIDATION.REQ_PASSWORD');

const onSubmit = async (values, dispatch, props) => { };

class LoginNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrivateKeyDialog: false,
      isError: false,
      isRedirect: true,
      isSignUp: false,
      showPassword: false,
      userInfo: [],
      appCheck: false
    };
    let urlParams = new URLSearchParams(window.location.search);
    this.sso = urlParams.get('sso') ? true : false;
    this.fromSignup = urlParams.get('id') == 'fromSignup' ? true : false;
    this.redirectURI = urlParams.get('redirect_uri');
    this.portal = urlParams.get('portal');
  }

  componentDidMount() {
    console.log(this.redirectURI);
    document.title = 'Therame Dashboard - Login';
    if (this.redirectURI) {
      localStorage.setItem('pltfmUrl', `${this.redirectURI}`);
      localStorage.setItem(
        'redirectPlatformData',
        JSON.stringify({
          sso: this.sso,
          redirectURI:
            this.redirectURI && this.redirectURI != 'null'
              ? this.redirectURI
              : 'https://auth.portal.net/login',
          portal: this.portal
        })
      );

      this.checkPlatform();
      //   this.checkRedirectLoginOrNot();
      // getRedirectToken(userToken,{
      //     sso: this.sso,
      //     redirectURI: this.redirectURI,
      //     portal: this.portal
      // }).then(res => {
      //     const data = res.data;
      //     const {token} = data.response;
      //     if (data && data.status === 200 && data.response && data.response.token) {
      //         window.location.href = `${this.redirectURI}?token=${token}`;
      //         return;
      //     }

      // });
    } else {
      if (this.fromSignup) {
        this.redirectURI = localStorage.getItem('pltfmUrl');
      }
      if (this.redirectURI) {
        this.checkPlatform();
        // this.checkRedirectLoginOrNot();
      } else {
        this.setState({ isRedirect: false });
        this.setUserInfoEmpty();
      }
    }
  }
  checkPlatform = () => {
    getPlatform({
      sso: this.sso,
      redirectURI: this.redirectURI,
      portal: this.portal
    }).then(res => {
      const data = res.data;
      if (data && data.status === 200 && data.response) {
        let isArray = Array.isArray(data.response);
        if (isArray && data.response.length > 0) {
          const { isSignup, redirectUrl } = data.response;
          this.setState({ isSignUp: isSignup });
          this.checkToken();
        } else if (data.response.redirectUrl) {
          const { isSignup, redirectUrl } = data.response;
          this.setState({ isSignUp: isSignup });
          this.checkToken();
        } else {
          //not found
          this.setState({ isSignUp: false });
          this.props.history.push(ROUTE.NOT_FOUND);
        }
        console.log(data);
      } else {
        if (res && res.data) {
          if (typeof res.data.response.errors === 'string') {
            this.props.history.state = {
              status: 'error',
              msg: 'API.' + res.data.response.errors,
              show: true
            };
            this.setState({ isError: !this.state.isError });
          } else {
            // res.data.response.errors.forEach(innerData => {
            //   this.props.history.state = {
            //     status: 'error',
            //     msg: 'API.' + innerData.msg,
            //     show: true
            //   };
            //   this.setState({ isError: !this.state.isError });
            // });
          }
        }
      }
    });
    // this.checkToken();
  };
  checkToken = async () => {
    console.log('checkToken called');
    const usersTokenData = localStorage.getItem('authTokenData')
      ? JSON.parse(localStorage.getItem('authTokenData'))
      : [];
    // let tokenDataList = JSON.parse(JSON.stringify(usersTokenData));
    let tokenDataListNew = [];
    if (usersTokenData.length > 0) {
      for (let index = 0; index < usersTokenData.length; index++) {
        console.log('length', usersTokenData.length, index);
        const element = usersTokenData[index];

        let tokenData = await EncryptionServices.decodeJwtToken(element.token);

        var now = Date.now();
        console.log('token date expires', tokenData.expires);
        // var expires = tokenData.iat * 1000;
        var expires = tokenData.expires * 1000;

        if (!(now > expires)) {
          console.log('not expire', index);
          tokenDataListNew.push(element);
        }
        if (index === usersTokenData.length - 1) {
          console.log('values set : ', tokenDataListNew);
          this.setState({ isRedirect: true });
          this.setState({ userInfo: tokenDataListNew });
          localStorage.setItem('authTokenData', JSON.stringify(tokenDataListNew));
        }
      }

      // usersTokenData.forEach((x , i )=>{
      //   EncryptionServices.decodeJwtToken(x).then(data => {
      //     x['detail'] = data;
      //     if(i === usersTokenData.length - 1 ){
      //       console.log("im on last ",i , usersTokenData.length - 1 )
      //       this.setState({ isRedirect: true });
      //       this.setState({ userInfo: usersTokenData });
      //     }

      //   });
      // })
    } else {
      this.setState({ isRedirect: false });
      this.setUserInfoEmpty();
    }
  };
  checkRedirectLoginOrNot(data = '', index = 'index') {
    let userToken;
    let cred;
    if (index !== 'index') {
      userToken = this.state.userInfo[index].token;
      cred = this.state.userInfo[index].cred;

      // const userToken = localStorage.getItem('authToken')
      //   ? localStorage.getItem('authToken')
      //   : '';
      if (userToken) {
        EncryptionServices.decodeJwtToken(userToken).then(data => {
          var now = Date.now();
          console.log('token date expires', data.expires);
          // var expires = data.iat * 1000;
          var expires = data.expires * 1000;

          if (!(now > expires)) {
            localStorage.setItem('authToken', userToken);
            localStorage.setItem('cred', JSON.stringify(cred));
            // sessionStorage.removeItem('cred'); // teste   ,  issue it's not found on when user try to login in auth after redirect up
            localStorage.setItem('pltfmUrl', '');
            localStorage.setItem('redirectPlatformData', '');
            window.location.href = `${this.redirectURI}?token=${userToken}`;
            return;
          } else {
            if (AuthService.isLoggedIn() && now > expires) {
              AuthService.signOut();
              // window.location.href = ROUTE.ROOT;
            }
          }
        });
      } else {
        this.setUserInfoEmpty();
      }
    } else {
      console.log('index not user found!');
    }
  }
  componentWillMount() {
    this.props.history.state = {};
    this.props.history.push(ROUTE.LOGIN);
  }

  componentWillUnmount() {
    this.props.history.state = {};
  }

  submitLogin = event => {
    const { userName, password } = this.props.formValues;
    console.log("responseLogin 1 data1",this.props.formValues)

    if (
      CheckIfNotEmpty(userName) &&
      CheckIfNotEmpty(password)
    ) {
      EncryptionServices.generateCredential(userName, password).then(
        credentials => {
          doLogin({ email:userName, password}, {
            sso: this.sso,
            redirectURI: this.redirectURI,
            portal: this.portal
          }).then(res => {

            if (!res.status) {
              this.props.history.state = {
                status: 'error',
                msg: 'API.INVALID_CREDENTIALS',
                show: true
              };
              this.setState({ isError: !this.state.isError });
              return;
            }
            const data = res.data;
            const { token } = data.response;

            if (data && data.status === 200 && data.response && data.response.token) {
              localStorage.setItem('cred', JSON.stringify(data.response));
              EncryptionServices.decodeJwtToken(data.response.token).then(decVal => {
                sessionStorage.setItem('userRole', decVal.role.roleName);
                // check user login permissions
                let appType = process.env.REACT_APP_AUDIENCE_TYPE;
                let roleId = decVal.role.id;
                if ((roleId === 1 || roleId === 2 || roleId === 3) && appType && appType.trim() === 'AdminApp') {
                  this.setState({
                    appCheck: true
                  });
                }else{
                  this.setState({
                    appCheck: false
                  });
                  this.props.history.state = {
                    status: 'error',
                    msg: 'API.USER_NOT_ALLOWED',
                    show: true
                  };
                  this.setState({ isError: !this.state.isError });
                  return;
                }

                // if (roleId === 3 && appType && appType.trim() === 'ClientApp') {
                //   this.setState({
                //     appCheck: true
                //   });
                // }
                console.log("responseLogin 2 appcheck",!this.state.appCheck)

                if (!this.state.appCheck) {
                  this.props.history.state = {
                    status: 'error',
                    msg: 'API.INVALID_CREDENTIALS',
                    show: true
                  };
                  this.setState({ isError: !this.state.isError });
                } else {
                  if (decVal.challenged) {
                    if (decVal.defaultAuthMethod === constants.TFA_METHODS.sms2fa) {
                      this.props.history.push('/verify-sms');
                    } else if (decVal.defaultAuthMethod === constants.TFA_METHODS.u2f) {
                      this.props.history.push('/verify-securityKey');
                    } else {
                      this.props.history.push('/otp');
                    }
                  } else {
                    if (data.response.userData) {
                      const sso = data.response.userData.sso;
                      if (sso && sso != 'false') {
                        const {
                          error,
                          errorMessage,
                          access_code,
                          redirect_uri
                        } = data.response.userData;
                        if (error) {
                          if (redirect_uri.includes('http')) {
                            window.location.href = `${redirect_uri}?accessCode=''&error=${error}&errorMessage=${errorMessage}`;
                          } else {
                            window.location.href = `https://${redirect_uri}?accessCode=''&error=${error}&errorMessage=${errorMessage}`;
                          }
                        } else {
                          if (redirect_uri.includes('http')) {
                            window.location.href = `${redirect_uri}?accessCode=${access_code}`;
                          } else {
                            window.location.href = `https://${redirect_uri}?accessCode=${access_code}`;
                          }
                        }
                      } else {
                        // localStorage.setItem('cred', JSON.stringify(data.response));
                        AuthService.authenticate(
                          data.response.token,
                          JSON.stringify(data.response)
                        );
                        this.props.getPermission();
                        if (this.redirectURI) {
                          window.location.href = `${this.redirectURI}?token=${token}`;
                          localStorage.setItem('pltfmUrl', '');
                          localStorage.setItem('redirectPlatformData', '');
                          return;
                        } else {
                          this.props.history.push('/');
                        }
                      }
                    } else {
                      this.props.history.state = {
                        status: 'error',
                        msg: 'TOASTER.LOGIN_FAIL',
                        show: true
                      };
                      this.setState({ isError: !this.state.isError });
                    }
                  }
                }
              });
            } else {
              if (res && res.data) {
                if (typeof res.data.response.errors === 'string') {
                  this.props.history.state = {
                    status: 'error',
                    msg: 'API.' + res.data.response.errors,
                    show: true
                  };
                  this.setState({ isError: !this.state.isError });
                } else {
                  res.data.response.errors.forEach(innerData => {
                    this.props.history.state = {
                      status: 'error',
                      msg: 'API.' + innerData.msg,
                      show: true
                    };
                    this.setState({ isError: !this.state.isError });
                  });
                }
              }
            }
          }).catch(
            err => {
              console.log("something went wrong",err)
            }
          );

        }
      );
    } else {
      this.props.history.state = { status: 'error', msg: 'TOASTER.LOGIN_FAIL', show: true };
      this.setState({ isError: !this.state.isError });
    }
  };
  addNewUser = () => {
    AuthService.signOut();
    setTimeout(() => {
      // this.props.history.push('/');
      this.setState({ isRedirect: false });
    }, 1000);
  };
  signInWithSelectedUser = (data, index) => {
    this.checkRedirectLoginOrNot(data, index);
  };
  signOutThisUser = (data, index) => {
    AuthService.signOut();
    this.setUserInfoEmpty(index);
  };
  setUserInfoEmpty = (index = 'index') => {
    if (index !== 'index') {
      let userInfo = this.state.userInfo;
      userInfo.splice(index, 1);
      localStorage.setItem('authTokenData', JSON.stringify(userInfo));
      this.setState({
        userInfo: userInfo
      });
    } else {
      this.setState({
        userInfo: []
      });
    }
  };
  goTo = () => {
    this.props.history.push(ROUTE.SIGNUP);
  };
  render() {
    const { classes, ...other } = this.props;
    return (
      <div className={`${classes.loginScreen} loginPage`}>
        <div className={classes.login}>
          <div className={classes.loginLeft}>

            <div className={classes.headi} style={{ display: 'flex' }}>
              <Grid className={classes.divset}>
                <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
                <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo" />
              </Grid>
              <div className={classes.btnset}>
                <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
                  {this.props.theme.mode == 'light' ? (
                    <img width="35" src={lightTheme} alt="theme" />
                  ) : (
                    <img width="35" src={darkTheme} alt="theme" />
                  )}
                </Button>
                  <Button
                    className={classes.createbtn}
                    onClick={() => {
                      this.goTo();
                    }}
                    type="button"
                  >
                    Create an Account
                  </Button>
                {/* {this.state.isSignUp ? (""
                ) : (
                  ''
                )} */}
              </div>
            </div>

            <div style={{ display: 'flex' }} className={classes.divMain + ' ' + classes.dvmain}>
              <div className="rightLogo">
                <img src={userIcon} alt="" />
                <div>
                  <p>
                    Thera<span>Me</span>
                  </p>
                  <span className={classes.heading}>Therame Dashboard</span>
                  {/* <span className={classes.heading}>Login To Your Account</span> */}
                </div>
              </div>

              {this.state.isRedirect ? (
                <form
                  name="loginForm"
                  className={classes.loginRight}
                  // onSubmit={this.props.handleSubmit(this.submitLogin)}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.mobileBackgroundImage} />

                  {this.state.userInfo.length > 0 ? (
                    this.state.userInfo.map((k, i) => (
                      <>
                        <Grid container alignItems="flex-end" style={{ paddingBottom: "10px" }}>
                          <Grid
                            item
                            onClick={() => {
                              this.signInWithSelectedUser(k, i);
                            }}
                            alignItems="center"
                            className={classes.loggedInUserItem}

                          >
                            <AccountCircleRoundedIcon
                              style={{
                                marginRight: 10
                              }}
                            />{' '}
                            {k['cred']['userData']['firstName']} {k['cred']['userData']['lastName']}
                            <br />
                            <div style={{ fontSize: 12, display: 'contents' }}>
                              {k['cred']['userData']['email']}
                            </div>
                            <Grid
                              className={classes.crossIcon}
                              onClick={() => {
                                this.signOutThisUser(k, i);
                              }}
                            >
                              <HighlightOffOutlinedIcon />
                            </Grid>
                          </Grid>

                          <Grid item></Grid>
                        </Grid>
                      </>
                    ))
                  ) : (
                    <div className={classes.loggedInUserErrorNote} >No one login</div>
                  )}
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={
                      classes.linkCss + ' ' + classes.registerUser + ' ' + classes.loginUser
                    }
                  >
                    <Button
                      type="button"
                      onClick={() => {
                        this.addNewUser();
                      }}
                      className={classes.activeButton}
                    >
                      <img src={LoginBackButton} alt="" />
                      Add an other user
                    </Button>
                  </Grid>

                </form>
              ) : (
                <form
                  name="loginForm"
                  className={classes.loginRight}
                  onSubmit={this.props.handleSubmit(this.submitLogin)}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.mobileBackgroundImage} />
                  <CustomMessages {...other} />
                  <div className={classes.forEachQ}>
                    <Grid item className={classes.inputIcon}>
                      <Mail />
                    </Grid>
                    <Field
                      validate={[requiredUserName, email]}
                      component={renderTextField}
                      id="name"
                      name="userName"
                      autoComplete="on"
                      type="email"
                      label="Email"
                      classes={classes}
                      placeholder="Email"
                    />
                  </div>
                  <div className={classes.forEachQ}>
                    <Grid item className={classes.inputIcon}>
                      {this.state.showPassword ? <LockIcon onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> : <LockOpenIcon onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}
                    </Grid>
                    <Field
                      validate={[requiredPassword]}
                      // component={renderTextField}
                      component={PasswordField}
                      id="password"
                      name="password"
                      type="password"
                      design='new'
                      showPassword={this.state.showPassword}
                      label="Password"
                      placeholder="Password"
                      classes={classes}
                    />
                  </div>


                  <Grid
                    container
                    justifyContent="flex-end"
                    className={
                      classes.linkCss + ' ' + classes.registerUser + ' ' + classes.loginUser
                    }
                  >
                    <Button
                      type="submit"
                      disabled={this.props.pristine || !this.props.valid || this.props.submitting}
                      className={classes.activeButton}
                    >
                      <Trans>LOGIN.BTN</Trans>
                      <img src={LoginBackButton} alt="" />
                    </Button>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.linkCss + ' ' + classes.forgotPassword}
                  >
                    <Link component={RouterLink} to="/forgot-password">
                    <span className={classes.linkText}> {<Trans>FORGOT_YOUR_PASSWORD</Trans>}</span>
                    </Link>
                    {/* <Link component={RouterLink} to="/groupChat">
                    <span className={classes.linkText}> {<Trans>FORGOT_YOUR_PASSWORD</Trans>}</span>
                    </Link> */}
                    {/* <Grid item xs={12}>
                      <img src={headerLogo} className={classes.footerLogo} alt="header logo" />

                    </Grid> */}
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.linkCss + ' ' + classes.forgotPassword}
                  >
                    {/* <Link component={RouterLink} to="/signup">
                      {<Trans>Don't have account? </Trans>}
                      <span className={classes.linkText}>SignUp</span>
                    </Link> */}
                    <Grid item xs={12}>
                      <img src={headerLogo} className={classes.footerLogo} alt="header logo" />

                    </Grid>
                  </Grid>
                  {/* {this.state.isSignUp ? (
                    <Grid
                      container
                      justifyContent="flex-end"
                      className={classes.linkCss + ' ' + classes.forgotPassword}
                    >
                      <Link component={RouterLink} to="/signup">
                        Signup
                      </Link>
                    </Grid>
                  ) : (
                    ''
                  )} */}


                </form>
              )}
            </div>
            <div className={classes.footi}>
              <span className={classes.copyright}>
                {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('login')(state)
});

const stylesheets = reduxForm({
  form: 'login',
  onSubmit
})(withStyles(styles, { withTheme: true })(LoginNew));
export default withTranslation()(connect(mapStateToProps)(stylesheets));
