import React from "react";

const SettingsIcon = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       width="496px" height="496px" viewBox="0 0 496 496">
    <g>
      <g>
        <g>
          <path d="M464.75,190.874h-34.499c-3.37-10.761-7.65-21.114-12.788-30.945l24.427-24.426c4.813-4.813,7.464-11.208,7.464-18.004
				c0-6.798-2.649-13.192-7.464-18.004l-45.134-45.134c-4.813-4.813-11.208-7.464-18.006-7.464c-6.797,0-13.191,2.651-18.004,7.464
				l-24.428,24.427c-9.832-5.139-20.188-9.417-30.944-12.789V31.5c0-14.061-11.438-25.5-25.5-25.5h-63.75
				c-14.061,0-25.5,11.439-25.5,25.5v34.499c-10.761,3.37-21.114,7.65-30.945,12.788l-24.426-24.426
				c-4.813-4.814-11.208-7.464-18.004-7.464c-6.798,0-13.192,2.652-18.004,7.464L54.111,99.495
				c-4.813,4.813-7.464,11.207-7.464,18.005c0,6.797,2.651,13.191,7.464,18.004l24.427,24.426
				c-5.139,9.832-9.417,20.187-12.789,30.946H31.25c-14.061,0-25.5,11.439-25.5,25.5v63.75c0,14.061,11.439,25.5,25.5,25.5h34.498
				c3.37,10.762,7.65,21.114,12.789,30.946L54.11,360.998c-4.814,4.813-7.464,11.207-7.464,18.005s2.652,13.192,7.464,18.004
				l45.134,45.134c4.813,4.813,11.207,7.464,18.005,7.464c6.797,0,13.191-2.647,18.004-7.464l24.427-24.427
				c9.832,5.139,20.186,9.417,30.945,12.788V465c0,14.061,11.439,25.5,25.5,25.5h63.75c14.061,0,25.5-11.439,25.501-25.5v-34.499
				c10.763-3.37,21.114-7.65,30.946-12.788l24.426,24.427c4.813,4.814,11.207,7.464,18.005,7.464s13.192-2.649,18.004-7.464
				l45.134-45.134c4.813-4.813,7.467-11.208,7.467-18.006c0-6.797-2.651-13.191-7.467-18.004l-24.427-24.427
				c5.142-9.832,9.417-20.188,12.788-30.945h34.498c14.061,0,25.5-11.439,25.5-25.5v-63.75
				C490.25,202.313,478.811,190.874,464.75,190.874z M464.75,280.122h-44.112c-5.813,0-10.894,3.935-12.348,9.566
				c-3.912,15.174-9.838,29.492-17.611,42.565c-2.979,5.013-2.18,11.409,1.943,15.532l31.237,31.183l-45.08,45.138l-31.237-31.237
				c-4.126-4.124-10.522-4.921-15.532-1.942c-13.074,7.773-27.396,13.699-42.566,17.611c-5.633,1.453-9.565,6.53-9.565,12.347V465
				h-63.75v-44.113c0-5.814-3.935-10.895-9.566-12.348c-15.174-3.913-29.495-9.838-42.568-17.611
				c-2.023-1.204-4.275-1.79-6.511-1.79c-3.297,0-6.563,1.277-9.018,3.737l-31.183,31.237l-45.135-45.08l31.237-31.238
				c4.124-4.124,4.923-10.52,1.943-15.531c-7.774-13.073-13.7-27.396-17.612-42.565c-1.453-5.632-6.53-9.566-12.347-9.566H31.25
				v-63.75h44.11c5.816,0,10.894-3.935,12.347-9.565c3.913-15.175,9.838-29.495,17.611-42.569c2.98-5.012,2.181-11.409-1.943-15.532
				l-31.237-31.183l45.08-45.135l31.237,31.238c4.126,4.125,10.522,4.922,15.532,1.943c13.074-7.774,27.396-13.7,42.568-17.612
				c5.632-1.453,9.566-6.53,9.566-12.347V31.5h63.75v44.11c0,5.816,3.936,10.894,9.566,12.347
				c15.174,3.913,29.492,9.838,42.565,17.611c5.011,2.98,11.408,2.179,15.532-1.943l31.183-31.237l45.138,45.08l-31.237,31.237
				c-4.123,4.125-4.923,10.52-1.942,15.532c7.773,13.073,13.699,27.396,17.611,42.567c1.453,5.633,6.53,9.566,12.347,9.566h44.115
				L464.75,280.122L464.75,280.122z"/>
        </g>
      </g>
    </g>
  </svg>

);

export default SettingsIcon;