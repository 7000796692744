
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import styles from '../../assets/jss/CropImageStyle';
import CPopup from "../Utils/CPopup"
import {withTranslation, Trans} from 'react-i18next';
import React, {Component} from 'react';

class CModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openStepModal: false,
            modalBody: ''
        };
        this.data = '';
        this.index = 0;
    }

    viewSteps = (data) => {
        this.data = data
        this.setState({
            openStepModal: true
        })
    }

    closeDialogue = e => {
        this.setState({
            openStepModal: false,
        })
    }

    render() {
        const {classes, increaseWidth, adjustModalSize,icon,icons} = this.props;
        return (
            <div>
                <CPopup
                    openDialouge={this.state.openStepModal}
                    modalDismiss={this.closeDialogue}
                    modalContent={this.state.modalBody}
                />
                <Dialog
                    disableEnforceFocus
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.props.open}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    onEntered={this.props.onEntered}
                    className={classes.modalContainer + ' ' + (increaseWidth ? classes.historyModal : null) + ' ' + (adjustModalSize ? classes.adjustModalSize : null) + ' ' + classes.customModalWidth}
                >
                    <MuiDialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                        <span className={classes.moduleImage}>{this.props.icon ? this.props.icon : this.props.icons}</span>
                        <Trans i18nKey={this.props.title}></Trans>

                        
                                <IconButton aria-label="Close" className={classes.cancelCrossIcon}
                                            onClick={this.props.handleClose}>
                                    <Clear/>
                                </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent
                        className={`${classes.dialogContent} ${classes[this.props.class ? this.props.class : '']}`}>
                        {this.props.children}
                    </MuiDialogContent>
                </Dialog>

            </div>
        );
    }
}

CModal.defaultProps = {
    crossIcon: true,
	adjustModalSize: false
};
const styleSheet = withStyles(styles, {withTheme: true})(CModal);
export default withTranslation()(styleSheet);
