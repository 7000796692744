
/* jshint esversion: 6 */

import mobileLogoLines from '../../assets/images/mobile-lines.png';

const LoginScreenStyles = theme => ({
  loginScreen: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  login: {
    width: '100%',
    maxWidth: '100%',
    '@media (min-width: 1600px)': {
      width: '100%'
    }
  },
  loginLeft: {
    // width: 'calc(100% - 600px)',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'block',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.login.background.backgroundColor,
    // backgroundImage: theme.palette.login.background.backgroundImage,
    backgroundSize: 'cover',
    flexDirection: 'row',
    boxSizing: 'border-box',

    '@media (max-width:990px)': {
      // marginTop: "79px",
      position: 'relative'
    },
    '& img': {
      '@media (min-width:1500px) and (max-width:2500px)': {
        objectFit: 'cover'
      }
    },
    '& .rightLogo': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // height:'97vh',
      width:"50%",
      '@media (max-width:990px)': {
        marginBottom: '50px',
        flexDirection: 'row'
      },

      '& img': {
        width: 180,
        height: 'auto',
        // marginTop: -55,
        '@media (max-width:990px)': {
          marginTop: '0'
        }
      },
      '& div': {
        '& > span': {
          color: theme.palette.login.rightLogo.textColor,
          fontSize: 50,
          '@media (max-width:1190px)': {
            fontSize: "3.6vw",
            marginTop: '0'
          },

          marginRight: '10px',
          fontWeight: '600',
          transform: 'scaleY(0.9)',
          display: 'inline-block',
          lineHeight: 1
        }
      },
      '& p': {
        fontWeight: 700,
        margin: 0,
        fontSize: 65,
        color: theme.palette.login.rightLogo.textColor,
        '& span': {
          fontWeight: 'normal',
          fontSize: 52,
          display: 'inline-block',
          marginLeft: -6
        }
      },
      '@media  (max-width:992px)': {
        width: '100%',
        '& img': {
          width: 110,
          height: 'auto',
          paddingTop: 0
        },
        '& p': {
          fontSize: 37,
          '& span': {
            fontSize: '40px !important'
          }
        },
        '& div': {
          '& > span': {
            fontSize: '35px !important'
          }
        }
      },
      '@media  (max-width:700px)': {
        '& p': {
          fontSize: 30,
          '& span': {
            fontSize: '33px !important'
          }
        },
        '& div': {
          '& > span': {
            fontSize: '23px !important'
          }
        }
      },
      '@media (min-width:1500px) and (max-width:2500px)': {
        '& img': {
          width: 150,
          marginTop: -5
        },
        '& p': {
          fontSize: 70,
          '& span': {
            fontSize: '70px !important',
            marginLeft: '-8px !important'
          }
        },
        '& div': {
          '& > span': {
            fontSize: '60px !important'
          }
        }
      },
      '@media (min-width:2501px) and (max-width:3800px)': {
        marginLeft: 40,
        '& img': {
          width: 400
        },
        '& p': {
          fontSize: 124,
          '& span': {
            fontSize: '114px !important',
            marginLeft: '-8px !important'
          }
        },
        '& div': {
          paddingLeft: '40px !important',
          '& > span': {
            fontSize: '104px !important'
          }
        }
      }
    },
    '@media (max-width: 1920px)': {
      marginTop: '0'
    },
    '@media (min-width: 2500px)': {
      minHeight: '100vh'
    },
    '@media (min-width: 3800px)': {
      minHeight: '100vh'
    },
    '@media (max-width:767px)': {
      // display:'none'
    },
    '@media (min-width:768px) and (max-width:1200px)': {
      width: '100%'
    },
    '@media (min-width: 1301px) and (max-width:1400px)': {
      // width: 'calc(100% - 600px)',
    }
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    objectFit: 'cover'
  },
  h3: {
    color: 'currentColor',
    fontSize: 32,
    fontWeight: 400
  },
  loginRight: {
    marginLeft: 'auto',
    paddingTop: '34px',
    paddingBottom: "20%",
    width: '600px',
    position: 'relative',
    float: 'right',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    // minHeight: '100vh',
    // background: "#252935",
    flexDirection: 'column',
    // padding: '35px 50px',
    boxSizing: 'border-box',
    // // boxShadow:
    //     '0px 2px 8px -14px rgba(0,0,0,0.1), 0px 7px 8px 3px rgba(0,0,0,0.1), 0px 9px 46px 8px rgba(0,0,0,0.1)',
    '@media (max-width: 990px)': {
      // minHeight: '100vh',
      width: '100%',
      margin: 'auto!important',
      float: 'inherit'
    },
    '@media (min-width: 2500px)': {
      // minHeight: '100vh',
    },
    '@media (min-width: 3800px)': {
      // minHeight: '100vh',
    },
    // '@media (max-width:420px)':{
    //     width:'100%',
    //     padding: '30px 30px 0 30px !important',
    //     justifyContent:'space-around',
    //     overflow:'auto',
    //     display:'block',
    //     // height:'100vh'
    // },
    '@media (min-width:421px) and (max-width:767px)': {
      // width:'100%',
      // padding: '30px 30px 50px 30px !important',
      // justifyContent:'space-around',
      // overflow:'auto',
      // display:'block',
      // height:'100vh'
    },
    '@media (min-width:768px) and (max-width:1200px)': {}
  },
  registerRight: {
    overflow: 'auto',
    padding: '50px 50px 0',
    justifyContent: 'flex-start'
    // height: '100vh',
    // minHeight: '100vh',
  },
  formLabel: {
    color: theme.palette.primary.contrastText,
    width: 200,
    textAlign: 'right',
    fontSize: 16,
    float: 'left',
    marginRight: 20,
    lineHeight: '32px',
    marginBottom: 25
  },

  // formInput: {
  //     width: 'calc(100% - 0px)',
  //     float: 'left',
  //     '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
  //         borderBottom: '2px solid #888 !important'
  //     },
  //     '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
  //         borderBottom: '2px solid #888 !important'
  //     },

  //     '& label': {
  //         color: '#000 !important',
  //         fontWeight: 'bold',
  //         fontSize: 16,
  //         paddingBottom: 10,
  //         background: 'none !important',
  //         paddingLeft: '30px !important',
  //         paddingRight: '30px !important',
  //         top: '7px !important',
  //         '&.Mui-focused': {
  //             color: '#888 !important',
  //             background: 'none',
  //             top: '0px !important',
  //             fontSize: 12,
  //         },
  //         '& @media (min-width:1600px)': {
  //             fontSize: 16,
  //         },
  //     },
  //     '& div': {
  //         '& input': {
  //             marginLeft: 0,
  //             paddingLeft: 30,
  //             paddingRight: 30,
  //             color: '#000',
  //             fontSize: '14px',
  //             fontWeight: 'bold',
  //             '& @media (min-width:1600px)': {
  //                 fontSize: 16,
  //             },
  //             '&:-webkit-autofill': {
  //                 "-webkitBoxShadow": "0 0 0 30px transparent inset !important",
  //                 caretColor: "#000000 !important",
  //                 "-webkitTextFillColor": "#000000 !important"

  //             },
  //             '&:-webkit-autofill:hover':{
  //                 "-webkitBoxShadow": "0 0 0 30px transparent inset !important",
  //             }
  //         },
  //     },
  //     '& .MuiInput-underline:before': {
  //         borderBottom: '1px solid #000 !important',
  //         left: 0,
  //         '&.Mui-focused:before': {
  //             borderBottom: '1px solid #888  ',
  //         }
  //     },
  //     '& .MuiInput-underline:after': {
  //         borderBottom: '1px solid #000 !important',
  //         left: 25,
  //         '&.Mui-focused:after': {
  //             borderBottom: '1px solid #888 !important',
  //         }
  //     },
  // },
  changeAvatar: {
    fill: theme.palette.primary.contrastText,
    margin: '5px',
    fontSize: 20
  },
  activeButton: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 46,
    lineHeight: '46px',
    textAlign: 'center',
    transition: 'box-shadow .45s',
    border: 0,
    // background: theme.palette.primary.color,
    marginBottom: 5,
    // borderRadius: '2px',
    textTransform: 'capitalize',
    minWidth: 'inherit',

    margin: 0,
    padding: '20px 45px',
    backgroundColor: theme.palette.login.activeButton.backgroundColor,
    borderRadius: '8px',
    fontSize: '27px',
    color: theme.palette.login.activeButton.color,
    fontWeight: '500',
    width: '100%',
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 1)',
      '& img': {
        opacity: 1
      }
    },
    '& img': {
      width: 25,
      marginRight: 8
    },
    '&:hover': {
      transition: 'box-shadow .45s',
      background: theme.palette.primary.color,
      boxShadow: '0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19)'
    },
    '@media (max-width:767px)': {
      marginTop: 15
    }
  },
  marginBottom: {
    marginBottom: 2,
    position: 'relative',
    '@media (min-width: 1919px)': {
      marginBottom: 2
    }
  },
  inputIcon: {
    position: 'absolute',
    right: '24px',
    paddingTop: 18,
    // transform: "translate(-1px,20px)",
    color: theme.palette.login.inputIcon.color,
    // position: 'absolute',
    // top: '1px',
    // left: '-38px',
    // borderBottom: 'solid 1px #fff',
    lineHeight: '13px',
    '& svg': {
      color: theme.palette.login.inputIcon.svgColor,
      // marginTop: 22,
      // marginLeft: 39,
      position: 'relative',
      zIndex: 99,
      width: 35,
      height: 35,
      paddingTop: 5
    }
  },
  inputIcon2: {
    color: '#000',
    position: 'absolute',
    top: '1px',
    left: '-38px',
    // borderBottom: 'solid 1px #fff',
    lineHeight: '13px',
    '& svg': {
      color: '#000',
      marginTop: 28,
      marginLeft: 39,
      position: 'relative',
      zIndex: 99,
      width: 20,
      height: 20
    }
  },
  correctStatus: {
    position: 'absolute',
    right: 0,
    top: 35,
    display: 'none',
    '& svg': {
      color: '#0065f7'
    }
  },
  linkCss: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'flex-end',
    '& a': {
      color: '#888',
      fontSize: 23,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  footerLogo: {
    height: '30px',
    margin: '15px auto',
    display: 'none',
    '@media (min-width:320px) and (max-width:480px)' : {
        display: 'block'
    },
},
  forgotPassword: {
    textDecoration: ' none',
    fontSize: '22px!important',
    fontWeight: 500,
    letterSpacing: ' -0.6px',

    marginBottom: 40,
    '@media (max-width:990px)': {
      justifyContent: 'center'
    },
    '& a': {
      color: theme.palette.login.forgotPassword.color,
      fontWeight: 'bold'
    },
    '@media (max-width:767px)': {
      justifyContent: 'center',
      marginBottom: 20
    }
  },
  registerUser: {
    marginBottom: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& a': {
      marginLeft: 5,
      '& span': {
        color: theme.palette.primary.color,
        fontWeight: 'bold'
      }
    },
    '@media (max-width:767px)': {
      flexDirection: 'column'
    },
    '@media (min-width:768px) and (max-width:1024px)': {
      justifyContent: 'center'
    }
  },
  loginUser: {
    justifyContent: 'center',
    '& button': {
      width: '100%'
    }
  },
  forgetPassword: {
    marginTop: 10,
    flexDirection: 'column',
    '& button': {
      width: '100%',
      marginBottom: 30
    }
  },
  registerBtn: {
    marginTop: 0,
    marginBottom: 50
  },
  loginLogo: {
    boxSizing: 'border-box',
    maxWidth: 105
  },
  divset: {
    '@media (max-width:450px)': {
      textAlign: 'center'
    }
  },
  mobileLogo: {
    boxSizing: 'border-box',
    width: 160,
    margin: '0 auto',
    display: 'none',
    '@media (max-width:767px)': {
      display: 'none',
      // width:'100%',
      marginBottom: 20
    }
  },
  loginCredentials: {
    // '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    //     borderBottom: '2px solid #888 !important'
    // },
    // '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
    //     borderBottom: '2px solid #888 !important'
    // },
    '& div': {
        width: '100% !important',
            /* Change the white to any color */
            '& input': {
               
                            '&:-webkit-autofill': {
                                "-webkitBoxShadow": "0 0 0 30px transparent inset !important",
                                caretColor: "#000000 !important",
                                "-webkitTextFillColor": "#000000 !important"
              
                            },
                            '&:-webkit-autofill:hover':{
                                "-webkitBoxShadow": "0 0 0 30px transparent inset !important",
                            }
                        }
    },
    '& label': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 16,
      paddingBottom: 3,
      background: 'none !important',
      // paddingLeft: '25px !important',
      paddingRight: '0 !important',
      top: '0px !important',
      '&.Mui-focused': {
        // color: '#888 !important',
        // background: 'none',
        // top: '0px !important',
        // fontSize: 12,
        display: 'none'
      },
      '& @media (min-width:1600px)': {
        fontSize: 16
      }
    }

    // '& div': {
    //     '& input': {
    //         marginLeft: 0,
    //         paddingLeft: 30,
    //         paddingRight: 30,
    //         color: '#000',
    //         fontSize: '14px',
    //         fontWeight: 'bold',
    //         '& @media (min-width:1600px)': {
    //             fontSize: 16,
    //         },
    //     },
    // },
    // '& .MuiInput-underline:before': {
    //     borderBottom: '1px solid #000 !important',
    //     left: 0,
    //     '&.Mui-focused:before': {
    //         borderBottom: '1px solid #888  ',
    //     }
    // },
    // '& .MuiInput-underline:after': {
    //     borderBottom: '1px solid #000 !important',
    //     left: 25,
    //     '&.Mui-focused:after': {
    //         borderBottom: '1px solid #888 !important',
    //     }
    // },
  },
  registerFields: {
    '& div': {
      '& input': {
        paddingRight: 0
      }
    }
  },
  copyright: {
    lineHeight: 1.8,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    display: 'block',
    color: theme.palette.login.copyright.color,
    fontSize: 12,
    fontWeight: 'normal',
    padding: '10px 0',
    borderTop: theme.palette.login.copyright.borderTop,
    padding: '25px 0px',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '-0.3px',
    background: theme.palette.login.background.backgroundColor,
    zIndex: 100,
    '@media (max-width:690px)': {
      fontSize: 16
    },
    '@media (max-width:690px)': {
      
      fontSize: 13
    },
    '@media (max-width:400px)': {
      
      fontSize: 11
    }
  },
  registerFooter: {
    position: 'static'
  },
  registerButton: {
    color: '#888',
    fontSize: 14,
    '@media (max-width:767px)': {
      fontSize: 12,
      marginBottom: 10
    },
    '@media (min-width:768px) and (max-width:1024px)': {
      marginBottom: 10
    }
  },
  registerCredential: {
    width: '100%'
  },
  forgotPasswordLabel: {
    color: '#757575',
    width: 'auto',
    float: 'left',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '40px',
    marginTop: 0,
    '& span': {
      color: theme.palette.primary.color
    },
    '@media (max-width:767px)': {
      marginTop: 30,
      textAlign: 'center',
      lineHeight: 'inherit',
      marginBottom: 20
    }
  },
  phoneNumberType: {
    '& .react-tel-input': {
      width: 'auto',
      marginLeft: 25,
      marginTop: 30,
      borderBottom: '1px solid #000',
      '& input': {
        border: 'none',
        width: '100%'
      },
      '& .flag-dropdown': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    }
  },
  helpIconOnLogin: {
    float: 'right',
    position: 'absolute',
    top: '20px',
    right: '0px',
    '& .loginPasswordHelpIcon': {
      position: 'absolute',
      background: '#000',
      color: '#fff',
      right: 6,
      padding: 10,
      width: 380,
      fontSize: 13,
      opacity: 0,
      top: 36,
      '& span': {
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid #000',
        position: 'absolute',
        zIndex: 999,
        top: -5,
        right: 5
      }
    },
    '&:hover': {
      '& .loginPasswordHelpIcon': {
        opacity: 1
      }
    }
  },
  tooltipClass: {
    marginRight: 25,
    font: 16
  },
  phoneLabel: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    position: 'absolute',
    top: 15,
    left: 25,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000'
  },
  errorMessage: {
    fontSize: 12,
    color: '#f00',
    marginLeft: 28,
    left: '0 !important'
  },
  mobileBackgroundImage: {
    '@media (max-width:767px)': {
      background: 'url(' + mobileLogoLines + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      left: 10,
      top: 20,
      height: '100vh',
      position: 'fixed',
      width: 52,
      display: 'none',
      paddingTop: 140,
      '& img': {
        width: '100%',
        height: '100%'
      }
    },
    '@media (min-width:421px) and (max-width:767px)': {
      left: 10,
      paddingTop: 10,
      top: 20,
      height: '100vh',
      position: 'fixed',
      background: 'url(' + mobileLogoLines + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      width: 52,
      display: 'none',
      '& img': {
        width: '100%',
        height: '100%'
      }
    }
  },
  fullWidth: {
    width: '100%'
  },
  alignLeft: {
    '& label': {
      left: 0
    },
    '& input': {
      marginLeft: '0 !important'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0 !important'
    }
  },
  titleAlign: {
    paddingLeft: 6
  },
  methodsText: {
    margin: '10px 0',
    color: theme.palette.primary.color,
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  mailButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.modalHeaderBg.color,
    '&:hover': {
      background: theme.palette.modalHeaderBg.color,
      boxShadow: 'none',
      opacity: 0.8
    }
  },
  commentIconSvg: {
    '& svg': {
      margin: '0px 5px'
    },
    '& .timer': {
      margin: '0px 5px'
    }
  },
  methodsBorder: {
    border: '1px solid '+theme.palette.primary.color,
    margin: '20px 0px 0px',
    color: '#000',
    width: '100%',
    padding: 14,
    fontSize: 16,
    fontWeight: 600,
    '& a': {
      color: '#000'
    },
    '&:hover': {   
      opacity: 0.8
    }
  },
  alignResendButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& button': {
      marginLeft: 10
    }
  }, 
  OTPScreen: {
    '& h2': {
      margin: 0,
      padding: '20px 0',
      width: '100%',
      textAlign: 'center'
    }
  },
  otpFormInput: {
    '& label': {
      left: 0
    },
    '& div input': {
      paddingLeft: 0
    }     
  },
  inputPadLeft: {
    '& div input': {
      paddingLeft: 0
    }
  },
  fileUploadSection: {
    position: 'relative',
    right: 0,
    top: -50,
    width: 100,
    height: 100,
    borderRadius: 50,
    background: theme.palette.primary.light,
    '@media (min-width: 1399px)': {
      right: 25
    }
  },
  fileUploadSectionSetting: {
    position: 'absolute',
    right: 10,    
    top: -30,
    width: 100,
    height: 100,
    borderRadius: 50,
    background: '#fff',
    '@media (max-width: 1365px)': {
      top: -20
    }
  },
  avatar2: {
    width: 100,
    height: 100
  },
  setUploadAvatar: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 30,
    height: 30,
    borderRadius: 15,
    background: theme.palette.avatarBg.color
  },
  fileUploadSectionInput: {
    position: 'absolute',   
    left: 0,
    top: 0,
    width: 30,
    height: 30,
    opacity: 0,
    zIndex: 10,
    cursor: 'pointer'
  },
  changeAvatar2: {
    fill: theme.palette.modalTextColor.color,
    margin: '5px',
    fontSize: 20
  },
  extraTopMarginAvtar: {
    top: '0px',    
    left: '50%',
    transform: 'translateX(-50%)'
  },
  phoneNumber: {
    '& .PhoneInput': {
      padding: '10px 0px',
      color: theme.palette.modalTextColor.color,
      '& input.PhoneInputInput': {
        border: 'none',
        // borderBottom: '1px solid ' + theme.palette.modalTextColor.color,
        background: 'transparent',
        color: theme.palette.modalTextColor.color + '!important',
        fontSize: 16
        
      },
      '& .PhoneInputCountryIcon--border img': {
        border: '1px solid ' + theme.palette.modalTextColor.color
      }
    }
  },
  phoneSpan: {
    top: 18,
    color: theme.palette.modalTextColor.color,
    textAlign: 'center',
    margin: 2,
    padding: 1,
    position: 'absolute',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none',
      opacity: 0.8
    }
  },
  changeRightPosition: {
    right: 55
  },
  selectedIcon: {
    borderRadius: '50%',
    background: '#3d8117',   
    color: '#fff',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    margin: 2,
    padding: 1,
    position: 'absolute',
    top: 15,
    right: 3,
    '& svg': {
      fontSize: 50
    }
  },   
  verifyRightPosition: {
    right: 3
  },
  removeMargin: {
    height: 'auto',
    margin: 0
  },
  profilePicture: {
    color: theme.palette.modalTextColor.color,
    marginTop: 0
  },
  personalDetailsContainer: {
    backgroundColor: theme.palette.profileSectionSidebarContent.color,
    boxShadow: 'none',
    '& .PhoneInputCountryIcon': {
      color: theme.palette.modalTextColor.color
    }
  },
  personalDetails: {
    padding: '25px 30px'  
  },
  addPositionStatic: {
    position: 'static'
  },
  removeBg: {
    backgroundColor: 'transparent',
    background: 'none',
    '& > div': {
      padding: 0,
      '& h4': {
        margin: 0
      }
    }
  },
  commentIconSvg: {
    '& svg': {
      margin: '0px 5px'
    },
    '& .timer': {
      margin: '0px 5px'
    }
  },
  mailSettingActionBtn: {
    bottom: 200,
    '@media (min-width: 1399px)': {
      bottom: 425
    }
  },
  add_margin_35: {
    margin: '35px 0px'
  },
  marginLeft_15: {
    marginLeft: 15
  },
  minHeight60: {
    minHeight: 60,
    cursor: 'default'
  },
  paddingtwentyFive: {
    padding: '25px 25px 0px 25px',
    background: theme.palette.mainTableLayout.color,
    '& > div': {
      background: theme.palette.mainTableLayout.color
    }
  },
  paddingAllOver: {
    padding: '15px'
    
  },
  noteLabel: {
    color: theme.palette.modalTextColor.color,
    fontSize: 12
  },
  enableU2F: {
    width: '100%',
    float: 'left',
    '& + button': {
      minWidth: 'fit-content',
      color: '#fff',
      backgroundColor: theme.palette.primary.color,
      '&:hover': {
        opacity: 0.9,
        backgroundColor: theme.palette.primary.color,
        color: '#fff'
      }
    }
  },
  listItemName: {
    paddingLeft: 0,
    textAlign: 'center'
  },
  insertDriveModal: {
    marginTop: 0,
    '& h4': {
      margin: '25px 0',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        display: 'flex'
      }
    }
  },
  fixedLabel: {
    '& label': {
      top: '-12px !important',
      fontSize: 12,
      transform: 'translate(0, 24px) scale(1) !important'
    }
  },
  licenseCheckBox: {
    minHeight: 'inherit',
    marginBottom: 10,
    marginLeft: -10,
    marginTop: -15
  },
  switchButton: {
    '& label': {
      '& span.Mui-checked': {
        '& > span span': {
          backgroundColor: theme.palette.primary.color
        },
        '& +span': {
          backgroundColor: '#1e1e1e !important',
          opacity: '1 !important'
        }
      },
      marginTop: '20px',
      marginLeft: '0px !important'
    }
  },
  downloadButton: {
    width: 'auto',
    minWidth: 120,
    minHeight: 42,
    lineHeight: '42px',
    marginLeft: 0,
    textAlign: 'center',
    padding: '0 12px',
    border: 0,
    color: '#fff',
    background: theme.palette.secondary.main,
    float: 'right',
    marginBottom: 10,
    borderRadius: '0 !important',
    '&:hover': {
      background: theme.palette.secondary.main,
      opacity: 0.9
    }
  },
  maxHeightRemove: {
    maxHeight: 'inherit'
  },
  smallBtn: {
    width: 'auto'
  },
  templateBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '0 20px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '& button': {
      '&:first-child': {
        marginLeft: 0,
        marginRight: '0 !important'
      }
    }
  },
  flexEnd: {
    justifyContent: 'flex-start'
  },

  fullWidthTextFeild: {
    maxHeight: 'inherit',
    '& textarea': {
      minHeight: 44
    }
  },
  formInputSelect: {
    width: '100%',
    minWidth: 65,
    float: 'left',
    fontSize: '16px',
    border: 0,
    background: 'none',
    color: 'black !important',
    padding: '7px 0',
    resize: 'none',
    fontFamily: 'Roboto, sans-serif !important',
    // borderBottom: '1px solid #a9abb9 !important',
    maxHeight: 88,
    '&.warning': {
      // borderBottom: 'solid 1px red'
    },
    '@media (min-width: 1365px)': {
      minWidth: 125
    }
  },
  formTextarea: {
    width: '100%',
    float: 'left',
    fontSize: '16px',
    border: 0,
    background: theme.palette.primary.light,
    color: theme.palette.secondary.contrastText,
    padding: '7px',
    resize: 'inherit',
    margin: '2px 0',
    fontFamily: 'Roboto, sans-serif !important'
  },
  formSelect: {
    width: '100%',
    float: 'left',
    fontSize: '16px',
    border: 0,
    borderBottom: '1px solid #a9abb9',
    background: 'none',
    color: 'black',
    padding: 0,
    fontFamily: 'Roboto, sans-serif !important'
  },
  formSelectSummary: {
    width: '245px',
    float: 'left',
    fontSize: '16px',
    border: 0,
    borderBottom: theme.palette.secondary.contrastText + ' solid 1px',
    background: 'none',
    color: theme.palette.secondary.contrastText,
    padding: 0,
    fontFamily: 'Roboto, sans-serif !important'
  },
  crossIcon: {
    position: 'absolute',
    right: 0,
    zIndex: 100,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  marginBottom: {
    display: 'flex',
    alignItems: 'center',
    // padding: "25px 20px",
    // fontSize: "18px",
    // backgroundSize: "27px",
    // backgroundPosition:" center right 22px",
    fontFamily: ' "Poppins",sans-serif',
    padding: ' 22px 45px',
    borderRadius: '8px',
    border: 0,
    background: theme.palette.login.marginBottom.background,
    lineHeight: 1.2,
    fontSize: ' 25px',
    color: theme.palette.login.marginBottom.color,
    fontWeight: 400,
    width: '100%'
    // marginBottom: "9px",
  },
  formInput: {
    width: '99%',
    backgroundColor: theme.palette.login.formInput.backgroundColor,
    display: 'inline-block',
    borderBottom: 'none !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    // fontSize:"3rem",
    '@media (max-width:1040px)': {
      // width:"80%",
    }
  },

  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none !important'
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
    borderBottom: 'none !important'
  },
  inputNew: {
    border: 'none!important',
    display: 'block',
    '& div': {
      // backgroundColor:"black",
      fontSize: '13px!important',
      width:"100%",
    },
    boxSizing:"border-box",
    // '& label': {
    //   top: "7px",
    //   left: "23px",
    // },
    fontFamily: ' "Poppins",sans-serif',
    padding: ' 12px 18px',
    borderRadius: '8px',
    border: 0,
    background: theme.palette.login.marginBottom.background,
    lineHeight: 1.2,
    fontSize: ' 25px',
    color: theme.palette.login.marginBottom.color,
    fontWeight: 400,
    width: '100%',
    
    
  },
  formi:{
    width:"100%",
    '@media (max-width:850px)': {
      marginTop: "0px",
    },
    '@media (max-width:600px)': {
      marginTop: "15px",
    },
    '@media (max-width:600px)': {
      marginTop: "30px",
    },
'& h1 ':{
  fontSize:"40px",
  
  '@media (max-width:550px)': {
  fontSize: "27px",
  },
}
  },
  headi: {
    padding: '25px 50px',
    paddingLeft: "53px",
    borderBottom: '0.25px solid '+theme.palette.login.background.borderColor,
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width:450px)': {
      
      paddingLeft: '25px',
      paddingRight: '25px',
    }
  },
  footi: {
    display: 'block'
  },
  createbtn: {
    background: theme.palette.primary.color,
    height: '30px',
    padding: '26px',
    fontSize: '16px',
    color: theme.palette.login.activeButton.color,
    marginRight:"12px",
    '&.Mui-disabled': {
      color: theme.palette.login.activeButton.color,
      
    },
    '@media  (max-width:630px)': {
      padding: '22px',
      fontSize: '13px'
    },
    '@media (min-width:850px) and (max-width:990px)': {
      padding: '22px',
      fontSize: '15px'
    }
  },
  heading: {
    fontWeight: '400!important'
  },
  removePaddingTB: {
    marginRight: '10px',
    '& svg': {
      transform: 'rotate(150deg )',
      fontSize: '2.5rem'
    }
  },
  '& #name': {
    position: 'relative'
  },
  '& #password': {
    position: 'relative'
  },
  '& #iterationCount': {
    position: 'relative'
  },
  forEachQ: {
    width: '100%',
    marginBottom: '22px'
  },
  error: {
    color: 'rgb(255, 0, 0)'
  },
  divMain: {
    justifyContent:"space-between",
    padding: '6px 50px',
    height:"74vh",
    '@media (max-width:850px)': {
      height:"74vh",
      display: 'block!important',
      padding: '6px 100px'
    },
    '@media (max-width:850px)': {
      display: 'block!important',
      padding: '6px 50px'
    },
    '@media (max-width:500px)': {
      display: 'block!important',
      padding: '6px 25px'
    },
  },
  loginScreen: {
    height: '100%!important'
  },
  btnset: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  btns:{
    display:"flex",
    justifyContent:"flex-end",
  },
  message:{
    "& h5":{
      color:"red"
    }
  },
  rightdiv:{
    display:"flex",
    alignItems:"center",
    color:theme.palette.login.forgotPassword.color,
    width:"40%",
    '@media (max-width:920px)': {
      width:"44%",
      margin:"right"
        },
    '@media (max-width:850px)': {
      width:"100%",
      margin:"right"
        },


  },
  para:{
  '@media (max-width:600px)': {
  fontSize:"14px"
  },
  '@media (max-width:400px)': {
  fontSize:"12px"
  },

}
});

export default LoginScreenStyles;
