
/* jshint esversion: 6 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/LoginScreenStyles';
import { Button, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { constants } from '../../constant/constant';
import { EncryptionServices } from '../../services';
import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';

import loginMainLogo from '../../assets/images/TEA-logo.png';
import MobileLogo from './../../assets/images/software_light_mobile.svg';

import headerLogo from '../../assets/images/TEA-logo.png';

import userIcon from './../../assets/images/login-update-icon.png';

class AuthMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAuth: {},
      previousType: ''
    };
  }

  componentDidMount() {
    const state = this.props.history.state;
    let previousType = state && state.method ? constants.URL_AUTH[state.method] : '';
    const cred = JSON.parse(localStorage.getItem('cred'));
    if (cred.token)
      EncryptionServices.decodeJwtToken(cred.token).then(decryptToken => {
        if (previousType === '') {
          previousType = decryptToken.defaultAuth;
        }

        const authMethods = {};
        authMethods.token2fa = decryptToken.activeAuth?.otp;
        authMethods.sms2fa = decryptToken.activeAuth?.sms;
        authMethods.u2f = decryptToken.activeAuth?.u2f;

        this.setState({ activeAuth: authMethods, previousType: previousType });
      });
  }

  createMethods = classes => {
    let methods = [];
    const { activeAuth, previousType } = this.state;
    if (Object.keys(activeAuth).length) {
      Object.keys(activeAuth).map(res => {
        if (activeAuth[res] && +previousType !== +constants.TFA_METHODS[res]) {
          methods.push(
            <Grid key={constants.TFA_METHODS[res]} item className={` ${classes.alignCenter}`}>
              <a href={`/${constants.LOGIN_METHODS_TYPE[constants.TFA_METHODS[res]]}`}>
                <Button className={classes.activeButton + ' ' + classes.activeButtonOnAuth}>
                  Verify through {constants.LOGIN_METHODS_TEXT[res]}
                </Button>
              </a>
            </Grid>
          );
        }
        return methods;
      });

      if (+previousType !== 4 && (activeAuth.sms2fa || activeAuth.token2fa || activeAuth.u2f)) {
        methods.push(
          <Grid key={3} item className={`${classes.alignCenter}`}>
            <a href={'/verify-recoveryCode'}>
              <Button className={classes.activeButton + ' ' + classes.activeButtonOnAuth}>
                Verify through {constants.LOGIN_METHODS_TEXT.recoveryCode}
              </Button>
            </a>
          </Grid>
        );
      }
    }

    return methods;
  };

  render() {
    const { classes } = this.props;
    const methods = this.createMethods(classes);
    return (
      <div className={`${classes.loginScreen} loginPage`}>
        <div className={classes.login}>
          <div className={classes.loginLeft}>
            <div className={classes.headi} style={{ display: 'flex' }}>
              <Grid className={classes.divset}>
                <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
                <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo" />
              </Grid>
              <div className={classes.btnset}>
                <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
                  {this.props.theme.mode == 'light' ? (
                    <img width="35" src={lightTheme} alt="theme" />
                  ) : (
                    <img width="35" src={darkTheme} alt="theme" />
                  )}
                </Button>
                {this.state.isSignUp ? (
                  <Button
                    className={classes.createbtn}
                    onClick={() => {
                      this.goTo();
                    }}
                    type="button"
                  >
                    Create an Account
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div style={{ display: 'flex' }} className={classes.divMain}>
              <div className="rightLogo rightLogoAuth">
                <img src={userIcon} alt="" />
                <div>
                  <p>
                  Thera<span>Me</span>
                  </p>
                  <span className={classes.heading}>Therame Dashboard</span>
                  {/* <span className={classes.heading}>Login To Your Account</span> */}
                </div>
              </div>
              <div className={classes.rightdiv}>
                <div className={classes.formi} noValidate name="otpForm" autoComplete="off">
                  <h1>Choose Verification Methods</h1>
                  {/* <p style={{opacity:"0.9"}}>Send a verification code to your phone number</p> */}
                  <div className={classes.forEachQ}>{methods}</div>
                  <div className={classes.btns}>
                    {/* <Button className={classes.createbtn}>Verify</Button>
   <Button className={classes.createbtn} style={{backgroundColor:"#3d4c3d"}}>Resend SMS</Button> */}
                  </div>
                </div>
              </div>
              {/* <form
                        name="loginForm"
                        className={classes.loginRight}
                        noValidate
                        autoComplete="off">
                        <div className={classes.mobileBackgroundImage} />
                        <Grid container spacing={1} alignItems="flex-end">
                            <img className={classes.loginLogo} src={loginMainLogo} alt="login logo"/>
                            <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo"/>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end" className={classes.marginBottom}>
                            <Grid container spacing={1} alignItems="flex-end"
                               className={classes.alignCenter + ' ' + classes.OTPScreen}>
                                <h2>Choose Verification Methods</h2>
                            </Grid>
                            {methods}
                        </Grid>
                        <span className={classes.copyright}>&copy; Ngxoft Solutions {new Date().getFullYear()}. All Rights Reserved.</span>
                    </form> */}

              <Grid
                container
                justifyContent="flex-end"
                className={classes.linkCss + ' ' + classes.forgotPassword}
              >
                <Grid item xs={12}>
                  <img src={headerLogo} className={classes.footerLogo} alt="header logo" />
                </Grid>
              </Grid>
            </div>
            <div className={classes.footi}>
              <span className={classes.copyright}>
                {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>

      // <div className={`${classes.loginScreen} loginPage`}>
      //     <div className={classes.login}>
      //         <div className={classes.loginLeft}>
      //             <img src={webLines} alt=""/>
      //             <div className='rightLogo'>
      //                 <img src={userIcon} alt="" />
      //                 <div>
      //                     <p>Thera<span>Me</span></p>
      //                     <span>Therame Dashboard</span>
      //                 </div>
      //             </div>
      //         </div>

      //     </div>
      // </div>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(AuthMethods));
