
import buttonsStyle from './buttonsStyle';

const CropImageStyle = theme => ({
    crop: {
        positoon: 'relative',
        overflow: 'auto'
    },
    moduleImage:{
      background:'#189b5430',
      marginRight:10,
      width:40,
      height:40,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:'50%',
      '& svg':{
          fill:theme.palette.primary.color,
          width:22,
          height:22
      }
    },
    cropButton: {
        background: '#fff',
        color: '#000',
        border: 0,
        padding: '5px 35px',
        fontSize: '18px',
        cursor: 'pointer',
        margin: '10px 0'
    },
    cropImage: {
        borderRadius: '5px',
        maxWidth: '100%',
        margin: 8
    },
    dialogTitle: {
        color: theme.palette.modalTextColor.color,
        background: theme.palette.modalsContentBg.color,
        width: '100%',
        boxSizing:'border-box',
        '& h2':{
            display: 'flex',
            alignItems: 'center',
        },
        '@media (max-width: 767px)': {
            minWidth: 'inherit',
            padding: '16px 10px'
        }
    },
    dialogHeader: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: '5px 15px'
    },
    dialogAction: {
        background: theme.palette.modalsContentBg.color,
        justifyContent:'space-between',
        padding:'0 20px 10px'
    },
    dialogContent: {
        // paddingTop:50,
        color: theme.palette.secondary.contrastText,
        background: theme.palette.modalsContentBg.color,
        minHeight: 'auto',
        padding: 24,
        '@media (max-width: 767px)': {
            minWidth: 'inherit',
            padding: '16px 16px'
        }
    },
    cancelCrossIcon: {
        color: theme.palette.primary.contrastText,
        minWidth: 'inherit',
        position: 'absolute',
        top: -10,
        right: -10,
        padding: 0,
        height:24,
        width:24,
        borderRadius:'50%',
        border:'1px solid ' + theme.palette.borderDark.color,
        background:theme.palette.modalsContentBg.color,
        '& svg':{
           width:14,
           height:14,
           fill:theme.palette.modalTextColor.color
        },
        '&:hover':{
            backgroundColor:theme.palette.modalsContentBg.color,
        }
    },
    extraRighSpace: {
        right: 40,
        cursor: 'pointer'
    },
    defaultButton: {
        ...buttonsStyle.defaultButton,
            color: '#1e1c1c',
            background: '#ebebeb',
        '&:hover':{
            background: '#ebebeb',
            color:'#1e1c1c',
            boxShadow:theme.palette.defaultBtnShadow.boxShadow
        },
    },
    dialogMinWidth: {
        width: '600px'
    },
    textColor: {
        color: theme.palette.secondary.main
    },
    activeButton: {
        width:'auto',
        '&:hover':{
            background: theme.palette.primary.color,
            opacity:0.9
        },
        '&.Mui-disabled':{
          color:'#fff',
          cursor:'not-allowed',
          pointerEvents:'inherit',
          background: theme.palette.primary.color,
          '&:hover':{
            background: theme.palette.primary.color,
            cursor:'not-allowed',
        },
        },
        ...buttonsStyle.activeButton,
        color: '#fff',
        background: theme.palette.primary.color
    },
    h4Styles: {
        color: theme.palette.primary.main,
        margin: 0
    },
    chipsBox: {
        float: 'left',
        margin: '10px 10px 0 0'
    },
    linkColor: {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer'
    },
    hiddenScroll: {
        overflowY: 'visible'
    },
    marginFive: {
        margin: '8px 0'
    },
    marginBottomSpacing: {
        marginBottom: 15,
        position: 'relative',
        width: '100%',
        float: 'left'
    },
    modalBodyScroll: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 190px)',
        overflowX: 'hidden',
        paddingBottom: 20
    },
    modalContainer:{
       '& > div:nth-child(3) > div':{
           maxWidth:'inherit',
           width:600,
           overflow:'inherit'
       }
    },
    historyModal:{
        '& form button':{
          '&:first-child':{
              marginRight:10
          }
        },
        '& > div:nth-child(3) > div':{
           width:700,
           background: theme.palette.modalsContentBg.color,
            '& > div + div':{
                padding:'0',
                background:'none',
                '& > div > div':{
                    padding:'0 25px 30px',
                }
            },
            '@media (max-width:767px)':{
                width:'90%',
                margin:'32px 10px',
                maxWidth:'100%',
                '& > div + div':{
                    padding:'0',
                    '& > div > div':{
                        padding:'0 15px 30px',
                    }
                }
            }
        }
    },
	adjustModalSize:{
			'& .MuiDialog-paperWidthSm': {
				maxWidth: '600px !important',
				'& label': {
					color: theme.palette.secondary.contrastText + ' !important '
				},
				'& input': {
					color: theme.palette.secondary.contrastText + ' !important ',
					borderBottom: '1px solid ' + theme.palette.secondary.contrastText + ' !important'
				}
			}			
    },
    cropModal: {
        '& > div:nth-child(3)':{
            overflow: 'inherit',
            '& > div':{
                overflow: 'inherit',
            }
        }
    }
});
export default CropImageStyle;
