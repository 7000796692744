import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/images/therame.svg';
import logoBlack from '../../assets/images/therame.svg';
import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';
import { withStyles } from '@material-ui/styles';
import userIcon from '../../assets/images/1.jpg';
import styles from '../../assets/jss/headerStyle';
import { AppBar, Toolbar, Button, Menu, MenuItem, Avatar, Tooltip, Badge } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next';

import EditIcon from '@material-ui/icons/Edit';
import NotificationIcon from '@material-ui/icons/Notifications';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import UserChangePasswordForm from './UserChangePasswordForm';

import CModal from '../Utils/CModal';

import { ROUTE } from '../../constant';
import { userChangePassword } from '../../actions';
import { toasterInstance, AuthService } from '../../services';

import SocketService from '../../services/SocketService';

import ukMap from '../../assets/images/uk.svg';
import russiaMap from '../../assets/images/russia.svg';
import Person from '../../assets/images/svgIcons/personalSettingsIcon';
import Notification, { notify } from '../Notifications/notifications';
import BellNotification from '../Notifications/bellNotifications';
class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownMenu: null,
      languageDropDown: null,
      headerViewVisibleModel: false,
      showHeaderViewFormLoader: false,
      showNotifications: null,
      notifications: [
        { message: 'Notification 1' },
        { message: 'Notification 2' }
        // Add more notifications as needed
      ]
    };
    this.baseImagePath = `${process.env.REACT_APP_ADMIN_ENDPOINT}${process.env.REACT_APP_USER_IMAGE_PATH}`;
  }

  componentDidMount() {
    // const loginUser = sessionStorage.getItem('cred') ? JSON.parse(sessionStorage.getItem('cred')).userData : false;
    // this.clientId = loginUser.clientId;

    // if (this.clientId) {

    // }
    SocketService.connect(this.onSocketNotification);
    // this.updateHeaderNotification()
  }
//   toggleNotifications = () => {
//     this.setState(prevState => ({
//       showNotifications: !prevState.showNotifications
//     }));
//   };
  onSocketNotification = message => {
    let messageType;
    messageType = JSON.parse(message.data);
    console.log('message---------------->', message.data);
    console.log('message---------------->', messageType);
    let credData = localStorage.getItem('cred');
    credData = JSON.parse(credData || '');
    if (messageType.message == 'UPDATE_CHAT') {
      // console.log("message.data---------------->",message.data.data);
      if (messageType.data.chatInfo.senderId !== credData.userData.id) {
        notify(messageType.data);
      } else {
        console.log('hi');
      }
    }
  };
  // updateHeaderNotification = (updateList = true) => {
  //     getNotificationCount()
  //         .then(res => {
  //             this.setState({notificationCount: res.count})
  //             if (updateList) {
  //                 this.setState({notificationList: false}, () => this.setState({notificationList: true}))
  //             }
  //         })
  //         .catch(error => this.setState({notificationCount: 0}))
  // }

  /**
   * handleClose close the dropdown menu
   */

  handleClose = event => {
    this.setState({
      dropdownMenu: null
    });
  };

  /**
   * handleOpen open the dropdown menu
   */
  handleOpen = event => {
    this.setState({
      dropdownMenu: event.currentTarget
    });
  };
  handleCloseNotifications = event => {
    this.setState({
        showNotifications: null
    });
  };

  /**
   * handleOpen open the dropdown menu
   */
  handleOpenNotifications = event => {
    this.setState({
        showNotifications: event.currentTarget
    });
  };

  /**
   * function to logout user from application
   */
  headerViewDoLogoutUser = () => {
    AuthService.signOut();
    window.location.href = ROUTE.ROOT;
  };

  /**
   * function to change user password
   */
  changePasswordHandler = e => {
    e.preventDefault();
    this.setState({
      dropDownMenu: null,
      showNotifications: null,
      headerViewVisibleModel: true
    });
  };

  userProfile = () => {
    return window.open('/personal-setting', '_self');
  };

  /**
   * Change Password Creation
   */
  headerViewChangePassword = pwdInfo => {
    this.setState({ showHeaderViewFormLoader: true });
    userChangePassword(pwdInfo)
      .then(res => {
        this.setState({ showHeaderViewFormLoader: false });
        const headerViewData = res;
        if (headerViewData && headerViewData.data && headerViewData.data.status === 200) {
          toasterInstance(
            <Trans>{'API.' + headerViewData.data.message}</Trans>,
            2,
            'successChangePassword'
          );
          this.setState({
            headerViewVisibleModel: false
          });
          setTimeout(() => {
            this.headerViewDoLogoutUser();
          }, 2000);
        } else {
          if (headerViewData && headerViewData.response) {
            if (typeof headerViewData.response.errors === 'string') {
              toasterInstance(
                <Trans>{'API.' + headerViewData.response.errors}</Trans>,
                4,
                'failedChangePassword'
              );
            } else {
              headerViewData.data.response.errors.forEach(innerData => {
                toasterInstance(<Trans>{'API.' + innerData.msg}</Trans>, 4, 'failedChangePassword');
              });
            }
          }
        }
      })
      .catch(err => {
        this.setState({ showLoader: false });
        toasterInstance(<Trans>TOASTER.SOFTWARE_C_FAIL</Trans>, 4, 'failedChangePassword');
      });
  };

  handleSubmitForm = formData => {
    this.headerViewChangePassword(formData);
  };

  /**
   * handle language close dropdown
   */
  handleLanguageClose = (event, value) => {
    this.setState({
      languageDropDown: null
    });
    this.props.changeLanguage(event.target.innerText);
  };
  /**
   * handle language open dropdown
   */
  handleLanguageOpen = event => {
    this.setState({
      languageDropDown: event.currentTarget
    });
  };

  render() {
    const { classes } = this.props;
    const { showNotifications, notifications } = this.state;
    return (
      <div>
        <AppBar className={classes.headerBar}>
          <Toolbar className={classes.rootToolbar}>
            {this.props.logo === 1 ? (
              <span className={classes.logoImg}>
                <img width="250" src={logoBlack} alt="logo" />
              </span>
            ) : (
              <span className={classes.logoImg}>
                <img width="250" src={logo} alt="logo" />
              </span>
            )}
            <div className={classes.headerRightBlock}>
              {/* { sessionStorage['userRole'] === 'client'?
                                <CipDashboard
                                    logo={this.props.logo}
                                /> : ''
                            } */}

              <Tooltip title="Notifications">
                <Button onClick={this.handleOpenNotifications.bind(this)} className={classes.removePaddingTB}>
                  {/* <Brightness2Icon /> */}
                  {/* {this.props.theme.mode == 'light' ? <img width="35" src={lightTheme} alt="theme" /> : <img width="35" src={darkTheme} alt="theme" />} */}
                  <NotificationIcon />
                </Button>
              </Tooltip>
              <BellNotification
                notifications={notifications}
                showNotification={showNotifications}
                onCloseNotify={this.handleCloseNotifications}
              />

              <Tooltip title={this.props.logo === 1 ? 'Light Mode' : 'Dark Mode'}>
                <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
                  {/* <Brightness2Icon /> */}
                  {this.props.theme.mode == 'light' ? (
                    <img width="35" src={lightTheme} alt="theme" />
                  ) : (
                    <img width="35" src={darkTheme} alt="theme" />
                  )}
                </Button>
              </Tooltip>

              {/* <Tooltip title="Change Language">
                            <Button onClick={this.handleLanguageOpen.bind(this)}>
                                {this.props.currentLanguage === 'en-US' ? <img src={ukMap} alt=""/> :
                                    <img src={russiaMap} alt=""/>}
                            </Button>
                            </Tooltip> */}
              <Menu
                name={'languageChange'}
                keepMounted
                open={Boolean(this.state.languageDropDown)}
                onClick={this.handleLanguageClose}
                anchorEl="null"
                className={`${classes.menuDropdown} ${classes.flagDropdown}`}
              >
                <MenuItem name={'eng'} value={'Eng'}>
                  <img className={classes.flagIcon} src={ukMap} alt="" />
                  English
                </MenuItem>
                <MenuItem name={'ru'} value={'Ru'}>
                  <img className={classes.flagIcon} src={russiaMap} alt="" />
                  Russia
                </MenuItem>
              </Menu> 
              {/* <NotificationIcon onClick={this.toggleNotifications} />  
                            {showNotifications && <BellNotification notifications={notifications} />} */}
              <Tooltip title="Settings">
                <Button className={classes.profileBtn} onClick={this.handleOpen.bind(this)}>
                  {' '}
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      sessionStorage['displayImg']
                        ? `${this.baseImagePath}${sessionStorage['displayImg']}?id=${Math.random() *
                            100}`
                        : // src={sessionStorage['displayImg'] ?
                          //     `${constants.API.IMAGE}/user/${sessionStorage['displayImg']}?id=${Math.random() * 100}`
                          userIcon
                    }
                  />
                  {/* <h4 className={classes.userName}>{sessionStorage['displayName']}</h4> */}
                </Button>
              </Tooltip>

              <Menu
                className={classes.menuDropdown}
                anchorEl={this.state.dropdownMenu}
                keepMounted
                open={Boolean(this.state.dropdownMenu)}
                onClick={this.handleClose}
              >
                <MenuItem onClick={this.userProfile}>
                  <Person />
                  {<Trans>SETTINGS.PERSONAL_SETTING</Trans>}
                </MenuItem>
                <MenuItem onClick={this.headerViewDoLogoutUser}>
                  <LogoutIcon />
                  <Trans>MENU.LOGOUT</Trans>
                </MenuItem>
              </Menu>
              <CModal
                title={'LOGIN.CHANGE_PASSWORD'}
                open={this.state.headerViewVisibleModel}
                crossIcon={true}
                icon={<EditIcon />}
                handleClose={() => this.setState({ headerViewVisibleModel: false })}
                adjustModalSize={true}
              >
                <UserChangePasswordForm
                  handleSubmit={this.handleSubmitForm}
                  handleClose={() => this.setState({ headerViewVisibleModel: false })}
                  isLoading={this.state.showHeaderViewFormLoader}
                />
              </CModal>
              {/* <NotificationIcon   onClick={this.toggleNotifications} />
                            {showNotifications && <BellNotification notifications={notifications} />} */}
            </div>
            {/* <Notification /> */}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    serverDetail:
      state.getServerDetail && state.getServerDetail.data ? state.getServerDetail.data : []
  };
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(HeaderView))
);
