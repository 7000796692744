
import {httpservice} from '../services';
import {dateFormat} from "../helper/StringUtils";
import {constants} from "../constant/constant";
import {SOFTWARE} from '../constant/actionTypes';

export default (object) => dispatch => {
    const query = `${constants.API.CLIENT_MODULE_SOFTWARE}/${object.id}`;
    dispatch({
        payload: [],
        type: SOFTWARE.GET.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const softwareData = response.data.response;
            const finalData = softwareData.map(data => {
                if(data.expiryDate !== '-') data.expiryDate = dateFormat(data.expiryDate);
                return data;
            });
            dispatch({
                payload: {data: finalData, count: finalData.length},
                type: SOFTWARE.GET.SUCCESS
            });
            return {data: finalData, count: finalData.length};
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: SOFTWARE.GET.ERROR
            });
        })
};