
import { constants } from "../constant/constant";
import { httpservice } from '../services';
import { SYSLOG_SETTING } from '../constant/actionTypes';
import { dateFormat } from "../helper/StringUtils";

export default () => dispatch => {
    const query = `${constants.API.SYSLOG_SETTINGS_LIST}`;
    dispatch({
        payload: [],
        type: SYSLOG_SETTING.DATA.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const { rows = [], count = 0} = response.data.response;
            const responseData = rows.map(data => {
                data.protocol = data.protocol.toUpperCase();
                data.facility = data.facility.charAt(0).toUpperCase() + data.facility.slice(1);
                data.appName = data.appName.charAt(0).toUpperCase() + data.appName.slice(1);
                data.createdAt = dateFormat(data.createdAt);
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'admin';
                return data;
            });
            dispatch({
                payload: { data: responseData, count: count },
                type: SYSLOG_SETTING.DATA.SUCCESS
            });
            return responseData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: SYSLOG_SETTING.DATA.ERROR
            });
        })
};