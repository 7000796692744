
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {withTranslation, Trans} from 'react-i18next';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/confirmPopupStyles';

class CPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {classes, modalContent} = this.props;

        return (
            <Dialog
                open={this.props.openDialouge}
                onClose={this.props.modalDismiss}
                fullWidth={true}
                maxWidth="sm"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {this.props.icon ? <span className={classes.moduleImage}>{this.props.icon ? this.props.icon : this.props.icons}</span> : null}
                    <Trans i18nKey={modalContent.title}></Trans>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <h4>
                        <Trans i18nKey={modalContent.body}></Trans>{' '}
                        {modalContent.name ? `'${modalContent.name}' ?` : '?'}
                    </h4>
                    {modalContent.content ?
                        <h4>
                            {modalContent.content}
                        </h4> : null
                    }
                </DialogContent>

                <DialogActions className={`${classes.dialogAction} ${classes.removePosition}`}>
                    <Button className={`${classes.defaultButton} ${classes.changeBg}`}
                            onClick={this.props.modalDismiss}>
                        <Trans>BUTTON.ABORT</Trans>
                    </Button>
                    {modalContent.title !== "VULNERABILITY.STR" &&
                    <Button className={classes.activeButton} onClick={this.props.modalSubmit}>
                        <Trans>BUTTON.OK</Trans>
                    </Button>
                    }
                </DialogActions>

            </Dialog>
        );
    }
}

CPopup.defaultProps = {
    confirmModal: true
};
export default withTranslation()(withStyles(styles, {withTheme: true})(CPopup));
