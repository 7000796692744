

import { httpservice} from '../services';
import {constants} from '../constant/constant';
import { CLIENT } from '../constant/actionTypes';

export default (clientInfo,object) => dispatch=> {
    return httpservice()
        .put(`${constants.API.CLIENT}/${clientInfo.id}`, clientInfo)
        .then(function (response) {
            dispatch({
                payload: { data: object, count: object.length },
                type: CLIENT.GET.SUCCESS
            });
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};
