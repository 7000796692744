
/* jshint esversion: 6 */

import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/FooterLayoutStyle';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showExpendedMenu: true
        };
    }
    render() {
        const {classes} = this.props;
        return <div className={classes.footer + ' ' + (this.state.showExpendedMenu ? classes.shrinkFooter : '')}>&copy; {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.</div>;
    }
}

export default withStyles(styles, {withTheme: true})(Footer);
