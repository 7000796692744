import React from "react";

export default () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       width="200px" height="200px" viewBox="0 0 200 200">
    <g>
      <g>
        <path d="M184.032,57.233L135.188,6.478c-0.695-0.722-1.655-1.13-2.656-1.13H59.73c-8.147,0-14.776,6.626-14.776,14.77v51.597
			H15.451c-6.095,0-11.055,4.958-11.055,11.052v55.325c0,6.093,4.959,11.051,11.055,11.051h29.503v33.189
			c0,8.128,6.629,14.743,14.776,14.743h110.557c8.148,0,14.776-6.61,14.776-14.736V59.789
			C185.063,58.836,184.694,57.92,184.032,57.233z M133.443,15.301l40.099,41.666h-40.099V15.301z M15.451,141.769
			c-2.03,0-3.68-1.649-3.68-3.677V82.767c0-2.028,1.651-3.678,3.68-3.678h99.564c2.03,0,3.681,1.65,3.681,3.678v55.325
			c0,2.027-1.651,3.677-3.681,3.677H15.451z M177.689,182.339c0,4.06-3.322,7.361-7.402,7.361H59.731
			c-4.082,0-7.402-3.306-7.402-7.368v-33.189h62.688c6.095,0,11.054-4.958,11.054-11.051V82.767c0-6.094-4.959-11.052-11.054-11.052
			H52.329V20.117c0-4.078,3.321-7.396,7.402-7.396h66.339v47.932c0,2.036,1.65,3.687,3.687,3.687h47.933V182.339L177.689,182.339z"
        />
      </g>
    </g>
    <g>
      <path d="M49.184,116.698c0,2.034-0.524,3.862-1.572,5.485c-1.048,1.621-2.582,2.892-4.601,3.808
		c-2.02,0.917-4.414,1.376-7.182,1.376c-3.32,0-6.057-0.625-8.214-1.882c-1.53-0.9-2.773-2.106-3.729-3.611
		c-0.957-1.509-1.434-2.974-1.434-4.396c0-0.826,0.287-1.532,0.86-2.122c0.574-0.589,1.304-0.882,2.191-0.882
		c0.719,0,1.327,0.229,1.824,0.687c0.497,0.46,0.921,1.141,1.273,2.044c0.428,1.069,0.891,1.965,1.389,2.684
		c0.497,0.72,1.196,1.313,2.099,1.779c0.903,0.467,2.088,0.698,3.557,0.698c2.02,0,3.66-0.47,4.922-1.411
		c1.262-0.94,1.893-2.113,1.893-3.522c0-1.115-0.34-2.022-1.021-2.719c-0.68-0.695-1.56-1.228-2.639-1.595
		c-1.079-0.368-2.521-0.757-4.325-1.17c-2.417-0.565-4.44-1.228-6.069-1.984c-1.629-0.759-2.922-1.789-3.878-3.099
		c-0.956-1.308-1.434-2.931-1.434-4.876c0-1.85,0.504-3.494,1.514-4.932s2.47-2.543,4.383-3.316c1.912-0.772,4.16-1.159,6.746-1.159
		c2.065,0,3.851,0.256,5.357,0.769s2.757,1.193,3.751,2.042c0.994,0.849,1.721,1.741,2.18,2.673c0.459,0.933,0.688,1.843,0.688,2.73
		c0,0.811-0.287,1.541-0.86,2.191c-0.574,0.65-1.289,0.976-2.146,0.976c-0.78,0-1.373-0.195-1.778-0.586
		c-0.405-0.39-0.845-1.028-1.319-1.916c-0.612-1.269-1.346-2.26-2.203-2.971c-0.856-0.712-2.233-1.067-4.13-1.067
		c-1.759,0-3.177,0.386-4.257,1.159c-1.078,0.773-1.617,1.702-1.617,2.788c0,0.674,0.184,1.254,0.551,1.743
		c0.366,0.49,0.871,0.91,1.514,1.262c0.643,0.353,1.292,0.628,1.95,0.827c0.658,0.198,1.744,0.488,3.258,0.87
		c1.897,0.445,3.614,0.934,5.152,1.469c1.537,0.536,2.845,1.188,3.923,1.952s1.919,1.731,2.524,2.903
		C48.881,113.565,49.184,114.999,49.184,116.698z"/>
      <path d="M62.469,123.215v-10.67L54.254,99.81c-0.719-1.147-1.228-2.042-1.525-2.684c-0.299-0.643-0.448-1.201-0.448-1.675
		c0-0.78,0.302-1.453,0.906-2.019c0.604-0.566,1.35-0.849,2.237-0.849c0.933,0,1.641,0.264,2.123,0.792
		c0.481,0.527,1.205,1.58,2.168,3.155l6.287,10.21l6.355-10.21c0.382-0.628,0.704-1.155,0.964-1.583
		c0.26-0.429,0.547-0.83,0.86-1.205c0.314-0.375,0.662-0.662,1.044-0.861c0.382-0.199,0.849-0.298,1.4-0.298
		c0.856,0,1.572,0.28,2.146,0.838c0.573,0.559,0.86,1.205,0.86,1.938c0,0.597-0.146,1.193-0.436,1.791
		c-0.291,0.596-0.788,1.422-1.492,2.478l-8.421,12.918v10.67c0,1.392-0.321,2.432-0.963,3.12c-0.643,0.688-1.46,1.032-2.456,1.032
		c-1.009,0-1.828-0.34-2.455-1.02C62.782,125.667,62.469,124.621,62.469,123.215z"/>
      <path d="M108.727,116.698c0,2.034-0.523,3.862-1.571,5.485c-1.048,1.621-2.581,2.892-4.602,3.808
		c-2.019,0.918-4.414,1.376-7.182,1.376c-3.319,0-6.058-0.625-8.214-1.882c-1.529-0.9-2.773-2.106-3.729-3.611
		c-0.956-1.509-1.434-2.974-1.434-4.396c0-0.826,0.288-1.532,0.86-2.122c0.574-0.589,1.304-0.882,2.192-0.882
		c0.718,0,1.327,0.229,1.824,0.687c0.496,0.46,0.921,1.141,1.273,2.044c0.428,1.069,0.891,1.965,1.388,2.684
		c0.497,0.72,1.196,1.313,2.1,1.779c0.902,0.467,2.088,0.698,3.556,0.698c2.019,0,3.66-0.47,4.921-1.411
		c1.263-0.94,1.894-2.113,1.894-3.522c0-1.115-0.341-2.022-1.021-2.719c-0.681-0.695-1.561-1.228-2.638-1.595
		c-1.079-0.368-2.521-0.757-4.326-1.17c-2.418-0.565-4.44-1.228-6.07-1.984c-1.628-0.759-2.921-1.789-3.877-3.099
		c-0.956-1.308-1.434-2.931-1.434-4.876c0-1.85,0.505-3.494,1.515-4.932c1.009-1.438,2.47-2.543,4.382-3.316
		c1.912-0.772,4.161-1.159,6.745-1.159c2.065,0,3.852,0.256,5.359,0.769c1.506,0.513,2.757,1.193,3.751,2.042
		c0.994,0.849,1.721,1.741,2.18,2.673c0.459,0.933,0.688,1.843,0.688,2.73c0,0.811-0.285,1.541-0.859,2.191
		c-0.573,0.65-1.289,0.976-2.146,0.976c-0.78,0-1.373-0.195-1.778-0.586c-0.405-0.39-0.846-1.028-1.32-1.916
		c-0.611-1.269-1.346-2.26-2.202-2.971c-0.856-0.712-2.234-1.067-4.131-1.067c-1.758,0-3.178,0.386-4.256,1.159
		c-1.078,0.773-1.618,1.702-1.618,2.788c0,0.674,0.184,1.254,0.551,1.743c0.368,0.49,0.872,0.91,1.514,1.262
		c0.642,0.353,1.293,0.628,1.951,0.827c0.657,0.198,1.744,0.488,3.258,0.87c1.897,0.445,3.614,0.934,5.151,1.469
		c1.537,0.536,2.844,1.188,3.923,1.952c1.078,0.765,1.92,1.731,2.525,2.903C108.424,113.565,108.727,114.999,108.727,116.698z"/>
    </g>
  </svg>
);