
import React from 'react';
import DashboardIcon from './../assets/images/svgIcons/dashboard';
import DomainIcon from '@material-ui/icons/Domain';
import PersonIcon from './../assets/images/svgIcons/userIcon';
import AssignmentIcon from './../assets/images/svgIcons/clients';
import SyslogSettingsIcon from './../assets/images/svgIcons/syslogSettings';
import MlScanIcon from './../assets/images/svgIcons/mlScanIcon';
import PlatformIcon from './../assets/images/svgIcons/platformIcon';
import EventLogIcon from './../assets/images/svgIcons/eventLogIcon';
import IPFirewallIcon from './../assets/images/svgIcons/ipFirewallIcon';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import InsertDriveFileIcon from './../assets/images/svgIcons/software';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SecurityIcon from '@material-ui/icons/Security';
import TransformIcon from '@material-ui/icons/Transform';
import Edit from '@material-ui/icons/Edit';
import EventNoteIcon from '@material-ui/icons/EventNote';
import UserManagementIcon from './../assets/images/svgIcons/userManagementIcon'
import ManageCompanyIcon from './subMneuIcon/ManageCompanyIcon';
import ManageProjectIcon from './subMneuIcon/ManageProjectIcon';
import CreateCompanyIcon from './subMneuIcon/CreateCompanyIcon';
import ManageUserIcon from './subMneuIcon/ManageUserIcon';
import CreateUserIcon from './subMneuIcon/CreateUserIcon';
import CreateProjectIcon from './subMneuIcon/CreateProjectIcon';
import ManageTemplateIcon from './subMneuIcon/ManageTemplateIcon';
import SecuritySettingIcon from './subMneuIcon/SecuritySettingIcon';
import MailSettingIcon from './subMneuIcon/MailSettingIcon';
import RemedyChildIcon from './subMneuIcon/RemedyChildIcon';
import LicenseManagementIcon from './subMneuIcon/licenseManagementIcon';
import EmotionIcon from './subMneuIcon/EmotionIcon';
import WhatCanIdoIcon from './subMneuIcon/WhatCanIdoIcon';
import DataIcon from './subMneuIcon/DataIcon';
import VideosIcon from './subMneuIcon/VideosIcon';
import ImagesIcon from './subMneuIcon/ImagesIcon';
import ChatIcon from './subMneuIcon/ChatIcon';
import DashboardNewIcon from './subMneuIcon/DashboardNewIcon';

/*
 *  function to map icon in left menu
 * */
export const manageIcon = type => {
    switch (type) {
        case 'DataIcon':
            return <DataIcon/>;
        case 'VideosIcon':
            return <VideosIcon/>;
        case 'ImagesIcon':
            return <ImagesIcon/>;
        case 'ChatIcon':
            return <ChatIcon/>;
        case 'DashboardNewIcon':
            return <DashboardNewIcon/>;
        case 'RemedyChildIcon':
            return <RemedyChildIcon/>;
        case 'MailSettingIcon':
            return <MailSettingIcon/>;
        case 'UserSettingIcon':
            return <SecuritySettingIcon/>;
        case 'ManageVulnerabilityIcon':
            return <ManageTemplateIcon/>;
        case 'CreateProjectIcon':
            return <CreateProjectIcon/>;
        case 'ManageProjectIcon':
            return <ManageProjectIcon/>;
        case 'EmotionIcon':
            return <EmotionIcon/>;
        case 'WhatCanIdoIcon':
            return <WhatCanIdoIcon/>;
        case 'CreateUserIcon':
            return <CreateUserIcon/>;
        case 'ManageUserIcon':
            return <ManageUserIcon/>;
        case 'ManageCompanyIcon':
            return <ManageCompanyIcon/>;
        case 'CreateCompanyIcon':
            return <CreateCompanyIcon/>;
        case 'DashboardIcon':
            return <DashboardIcon/>;
        case 'DomainIcon':
            return <DomainIcon/>;
        case 'PersonIcon':
            return <PersonIcon/>;
        case 'AssignmentIcon':
            return <AssignmentIcon/>;
        case 'TransformIcon':
            return <TransformIcon/>;
        case 'HistoryIcon':
            return <HistoryIcon/>;
        case 'SettingsIcon':
            return <SettingsIcon/>;
        case 'InsertDriveFileIcon':
            return <InsertDriveFileIcon/>;
        case 'VpnKeyIcon':
            return <VpnKeyIcon/>;
        case 'SecurityIcon':
            return <SecurityIcon/>;
        case 'Edit':
            return <Edit/>;
        case 'VerifiedUserIcon':
            return <VerifiedUserIcon/>;
        case 'EventNoteIcon':
            return <EventNoteIcon/>;
        case 'SyslogSettingsIcon':
            return <SyslogSettingsIcon/>;
        case 'MlScanIcon':
            return <MlScanIcon/>;
        case 'EventLogIcon':
            return <EventLogIcon/>;
        case 'PlatformIcon':
            return <PlatformIcon/>;
        case 'LicenseManagementIcon':
            return <LicenseManagementIcon />;
        case 'IPFirewallIcon':
            return <IPFirewallIcon />;
            case 'UserManagementIcon':
            return <UserManagementIcon/>;
        default:
            return '';
    }
};

/*
 *  function to get current expanded or collapsed menu item state
 * */
export const hideOtherSubMenu = (item, currentState) => {
    let temp = {
        CLIENT: false,
        SOFTWARE: false,
        PLATFORM: false,
        SYSTEM_EVENT: false
    };
    temp[item] = !currentState;
    return temp;
};
