
import { httpservice } from '../services';
import { constants } from '../constant/constant';
let apiEndPoint = constants.ADMIN_URL;

export const doSyslogSettingCreate = data => {
    return httpservice()
        .post(`${apiEndPoint}${constants.API.SYSLOG_SETTING}`, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doSyslogSettingDelete = Id => {
    const endPointUri = constants.API.SYSLOG_SETTING+`?id=${Id}`;
    return httpservice()
        .delete(endPointUri)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doSyslogSettingUpdate = (Id, data) => {
    const endPointUri = constants.API.SYSLOG_SETTING;
    data["id"] = Id;
    return httpservice()
        .put(endPointUri, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};