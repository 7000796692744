
import {httpservice} from '../services';
import {constants} from "../constant/constant";
import { DASHBOARD } from "../constant/actionTypes";
let apiEndPoint = constants.ADMIN_URL;

export default object => dispatch => {
    return httpservice()
        .get(`${apiEndPoint}${constants.API.DASHBOARD}`)
        .then(response => {
            dispatch({
                payload: response.data,
                type: DASHBOARD.USER.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: DASHBOARD.USER.ERROR
            });
        })
};
