import React from "react";

const CreateUserIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 512 512" height="512px" id="Layer_1" version="1.1"
         viewBox="0 0 512 512" width="512px">
        <path
            d="M330.503,144.608c0,59.285-48.059,107.35-107.349,107.35c-59.291,0-107.35-48.065-107.35-107.35  c0-59.29,48.06-107.355,107.35-107.355C282.444,37.253,330.503,85.318,330.503,144.608z"/>
        <path d="M41.583,446.787c0-97.352,78.915-176.277,176.267-176.277s176.272,78.926,176.272,176.277H41.583z"/>
        <path
            d="M481.013,375.996c0,50.689-41.093,91.781-91.781,91.781  c-50.683,0-91.782-41.092-91.782-91.781c0-50.693,41.1-91.775,91.782-91.775C439.92,284.221,481.013,325.303,481.013,375.996z"
            stroke="#00001a" strokeWidth="20"/>
        <line stroke="#00001a" strokeWidth="20" x1="389.231" x2="389.231" y1="336.234" y2="425.039"/>
        <line stroke="#00001a" strokeWidth="20" x1="344.829" x2="433.633" y1="380.639" y2="380.639"/>
    </svg>
);

export default CreateUserIcon;
