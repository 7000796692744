
/* jshint esversion: 6 */

const buttonsStyle = {

    defaultButton: {
        width: 'auto',
        minWidth: 120,
        minHeight: 42,
        lineHeight: '42px',
        textAlign: 'center',
        padding: '0 12px',
        border: 0,
        float: 'right',
        marginBottom: 5,
        // fontSize:"2vw",
        borderRadius: 4,
        // width:'30%',
        cursor: 'pointer',
        '@media (max-width: 1365px)': {
            padding: '0 5px',
            
            fontSize: 13
        },
        '@media (max-width: 580px)': {
          height:"34px",
        //   lineHeight:"0"
        fontSize: 12

     },
        '@media (max-width: 480px)': {
           width:'40%',

     }
    },
    activeButton: {
        width: 'auto',
        minWidth: 120,
        minHeight: 42,
        lineHeight: '42px',
        textAlign: 'center',
        padding: '0 12px',
        border: 0,
        float: 'right',
        marginLeft: 15,
        borderRadius: 4,
        marginBottom: 5,
        // fontSize:"2vw",
        cursor: 'pointer',
        
     
        '@media (max-width: 580px)': {
            width:'30%',
           height:"34px",
                },
        '& span':{
            color: '#fff !important',
        },
        '@media (max-width: 1365px)': {
            padding: '0 5px',
            
            fontSize: 12
        },
        '@media (max-width: 480px)': {
            width:'40%',
            // fontSize: 10
      },
        '@media (max-width: 410px)': {
            width:'40%',
            // fontSize: 8
      }
    }
};

export default buttonsStyle;
