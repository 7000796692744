
import React, {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import SupervisedUserCircle from './../assets/images/svgIcons/manageClient';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Delete from './../assets/images/svgIcons/delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import LockOpen from '@material-ui/icons/LockOpen';
import VerifiedUser from './../assets/images/svgIcons/whiteListedIps';
import EmbedCode from '@material-ui/icons/Code';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import GetAppIcon from '@material-ui/icons/GetApp';
import PartyModeIcon from '@material-ui/icons/PartyMode';
import PublishIcon from '@material-ui/icons/Publish';
import MenuIcon from '@material-ui/icons/Menu';
import VisibilityIcon from '@material-ui/icons/Visibility';

const tableIcon = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    SupervisedUserCircle: forwardRef((props, ref) => <SupervisedUserCircle {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref}
        className="hoverEffect"
        style={{borderRadius: '50%', padding: '4px'}}
    />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
    RemoveRedEye: forwardRef((props, ref) => <RemoveRedEye {...props} ref={ref}/>),
    LockOpen: forwardRef((props, ref) => <LockOpen {...props} ref={ref}/>),
    VerifiedUser: forwardRef((props, ref) => <VerifiedUser {...props} ref={ref}/>),
    EmbedCode: forwardRef((props, ref) => <EmbedCode {...props} ref={ref}/>),
    FormatListBulleted: forwardRef((props, ref) => <FormatListBulleted {...props} ref={ref}/>),
    ViewModuleIcon: forwardRef((props, ref) => <ViewModuleIcon {...props} ref={ref}/>),
    GetAppIcon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref}/>),
    PartyModeIcon: forwardRef((props, ref) => <PartyModeIcon {...props} ref={ref}/>),
    PublishIcon: forwardRef((props, ref) => <PublishIcon {...props} ref={ref}/>),
    MenuIcon: forwardRef((props, ref) => <MenuIcon {...props} ref={ref}/>),
    VisibilityIcon: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref}/>)
};

export default tableIcon;