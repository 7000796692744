import React from "react";

const CreateCompanyIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 47 47" height="47px" id="Layer_1" version="1.1"
         viewBox="0 0 47 47" width="47px">
        <path
            d="M16.704,19.512c0.397-0.676,0.641-1.452,0.641-2.291c0-2.511-2.043-4.555-4.554-4.555c-2.511,0-4.554,2.043-4.554,4.555  c0,0.838,0.244,1.615,0.641,2.29c-2.293,0.938-3.809,2.684-3.809,4.73v2.883c0,0.828,0.671,1.5,1.5,1.5h7.537  c-0.08,0.351-0.132,0.709-0.132,1.077v3.719c0,0.828,0.671,1.5,1.5,1.5h16.053c0.828,0,1.5-0.672,1.5-1.5v-3.719  c0-0.368-0.053-0.727-0.132-1.077h7.536c0.828,0,1.5-0.672,1.5-1.5v-2.883c0-2.046-1.516-3.792-3.809-4.73  c0.396-0.675,0.641-1.452,0.641-2.291c0-2.511-2.043-4.555-4.555-4.555c-2.511,0-4.553,2.043-4.553,4.555  c0,0.838,0.244,1.615,0.641,2.29c-0.499,0.205-0.961,0.447-1.378,0.723c-0.212-2.808-2.559-5.028-5.419-5.028  c-2.861,0-5.207,2.22-5.419,5.027C17.663,19.958,17.203,19.716,16.704,19.512z M30.027,29.702v2.219H16.974v-2.219  c0-1.711,2.68-3.617,6.526-3.617C27.347,26.085,30.027,27.991,30.027,29.702z M11.237,17.222c0-0.857,0.697-1.555,1.554-1.555  s1.554,0.697,1.554,1.555c0,0.856-0.697,1.553-1.554,1.553S11.237,18.078,11.237,17.222z M12.791,21.775  c2.703,0,4.722,1.303,4.722,2.467v0.281c-0.581,0.322-1.101,0.692-1.556,1.102H8.069v-1.383  C8.069,23.078,10.088,21.775,12.791,21.775z M32.656,17.222c0-0.857,0.696-1.555,1.553-1.555c0.857,0,1.555,0.697,1.555,1.555  c0,0.856-0.697,1.553-1.555,1.553C33.353,18.775,32.656,18.078,32.656,17.222z M34.209,21.775c2.703,0,4.723,1.303,4.723,2.467  v1.383h-7.888c-0.455-0.409-0.975-0.779-1.556-1.102v-0.281C29.488,23.078,31.507,21.775,34.209,21.775z M23.5,18.206  c1.345,0,2.439,1.095,2.439,2.44c0,1.345-1.095,2.438-2.439,2.438c-1.346,0-2.44-1.094-2.44-2.438  C21.06,19.301,22.154,18.206,23.5,18.206z"/>
        <path
            d="M2.652,19.519c0.128,0.034,0.258,0.05,0.385,0.05c0.664,0,1.271-0.444,1.449-1.117C6.29,11.64,11.642,6.289,18.453,4.486  c0.801-0.212,1.278-1.033,1.066-1.834s-1.034-1.274-1.834-1.066C9.834,3.664,3.665,9.833,1.586,17.685  C1.374,18.486,1.852,19.307,2.652,19.519z"/>
        <path
            d="M18.459,42.515c-6.814-1.801-12.167-7.151-13.972-13.964c-0.212-0.801-1.031-1.278-1.834-1.066  c-0.801,0.212-1.278,1.033-1.066,1.834c2.08,7.854,8.251,14.021,16.105,16.097c0.128,0.034,0.257,0.05,0.384,0.05  c0.664,0,1.271-0.444,1.449-1.117C19.738,43.547,19.26,42.727,18.459,42.515z"/>
        <path
            d="M44.367,27.375c-0.801-0.205-1.621,0.273-1.828,1.076c-1.778,6.86-7.14,12.249-13.992,14.063  c-0.801,0.212-1.278,1.033-1.066,1.834c0.178,0.672,0.785,1.116,1.449,1.116c0.127,0,0.256-0.016,0.385-0.05  c7.899-2.091,14.079-8.302,16.129-16.211C45.651,28.401,45.17,27.583,44.367,27.375z"/>
        <path
            d="M28.549,4.486c6.811,1.803,12.162,7.154,13.965,13.964c0.178,0.672,0.785,1.117,1.449,1.117c0.127,0,0.256-0.017,0.385-0.05  c0.801-0.212,1.278-1.033,1.066-1.834c-2.079-7.85-8.247-14.018-16.098-16.097c-0.803-0.208-1.622,0.266-1.834,1.066  S27.748,4.274,28.549,4.486z"/>
    </svg>
);

export default CreateCompanyIcon;
