
import {SOFTWARE} from '../constant/actionTypes';

export default (state, action) => {
    switch (action.type) {
        case SOFTWARE.SUBMODULE_LIST.INITIATE:
            return {isFetching: true, data: action.payload};
        case SOFTWARE.SUBMODULE_LIST.SUCCESS:
            return {isFetching: false, data: action.payload};
        case SOFTWARE.SUBMODULE_LIST.ERROR:
            return {isFetching: false, data: action.payload};
        default:
            return state ? state : [];
    }
};
