
/* jshint esversion: 6 */

import React, {Component, Suspense} from 'react';
import './App.css';
import Footer from './components/Footer/Footer';
import HeaderView from './components/Header/HeaderView';
import SideNavigationView from './components/NavigationMenu/SideNavigationView';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import themeObj from './assets/jss/theme';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/styles';
import styles from './assets/jss/layoutStyle';
import {connect} from 'react-redux';
import {ROUTE} from './constant';
import {getUserRoles, getTokenData, getClientList,getServerDetail} from './actions';
import Login from './components/Login/Login';
import GroupChat from './components/Groupchat/GroupChats'
import Signup from './components/Signup/Signup';
import OTPScreen from './components/Login/OTPScreen';
import AuthMethods from './components/Login/AuthMethods';
import Toaster from './components/Utils/Toaster';
import NotFound from './components/NotFound';
import ForgotPassword from './components/Login/ForgetPassword';
import i18n from './i18n';
import {withTranslation} from 'react-i18next';
import {MenuService, EncryptionServices} from './services';
import ChangePassword from './components/Login/ChangePassword';
import AutoLogout from "./AutoLogout";

const AdminMainContentView = React.lazy(() => import('./components/Router/AdminContentView'));
const ClientMainContentView = React.lazy(() => import('./components/Router/ClientContentView'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: themeObj[sessionStorage['_theme']] || themeObj.dark,
            selectLanguage: sessionStorage['_lg'] || 'en-US',
            isExpired: false,
            type: '',
            isWhitelistIP: null
        };
        let urlParams = new URLSearchParams(window.location.search);

        this.token = urlParams.get('token');
    }

    /**
     *  function to check current theme and update accordingly
     */
    changeTheme = themeType => {
        /**************** for header toggle start **************/
        if (typeof themeType === 'object')
            themeType = this.state.theme.palette.type === 'light' ? 'dark' : 'light';
        /**************** for header toggle end ****************/
        sessionStorage['_theme'] = themeType;

        let newPaletteType = themeObj[themeType];
        newPaletteType.menu = this.state.theme.menu;
        this.setState({
            theme: newPaletteType
        });
       
    };

    changeLanguage = language => {
        let lang;
        if (language === 'English') {
            lang = 'en-US';
        } else if (language === 'Russia') {
            lang = 'ru';
        }
        if (lang) {
            sessionStorage['_lg'] = lang;
            this.setState({
                selectLanguage: lang
            });
            i18n.changeLanguage(lang);
        }

    };

    /**
     * function to change side menus style when click on switch menu
     */
    onChangeMenuType = () => {
        let newPaletteType = this.state.theme;
        newPaletteType.menu = newPaletteType.menu > 60 ? 60 : 250;
        this.setState({
            theme: newPaletteType
        });
    };

    UNSAFE_componentWillMount() {
        //if browser language is not Russian then default languahe is english
        if (sessionStorage['_lg']) {
            i18n.changeLanguage(sessionStorage['_lg']).then(data => {
            });
        } else {
            i18n.changeLanguage(navigator.language === 'ru' ? navigator.language : 'en-US').then(data => {
            });
        }
        const loginUser = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData : false;
        const clientId = loginUser.clientId;
        if (this.token) {
            this.props.getTokenData(this.token).then(res => {
                EncryptionServices.decodeJwtToken(this.token).then(decVal => {
                    this.setState({
                        type: decVal.type
                    })
                    if (new Date().getTime() > decVal.expires || res && res.used) {
                        this.setState({
                            isExpired:true
                        })
                    }
                });
            });
        }
        if(clientId){
            this.props.getClientList({id: clientId});
        }
        this.props.getUserRoles();
    }

    checkForClientApp = () => {
        let appType = process.env.REACT_APP_AUDIENCE_TYPE;
        return (appType && (appType.toUpperCase()).trim() === "CLIENTAPP");
    }

    /**
     * get user's permission for module
     */
    getUserRoles = () => {
        this.props.getUserRoles();
    }

    // function to hide/show whitelist IP module
    handleWhitelistIPModule = (value) => {
        this.setState({
            isWhitelistIP : value
        })
    }

    render() {
        // create multiple theme object
        const muiTheme = createTheme(this.state.theme);
        const {classes,logo} = this.props;
        let userRoles = this.props.userRoles;
        let roles = [];
        if (userRoles && userRoles.length) {
            roles = MenuService.getModulesName(userRoles);
        }
        const isLoggedIn = localStorage.getItem('authToken') ? true : false;
        const loginUser = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData : false;
        return (
            <div className={classes.mainApp}>
                {/* <AutoLogout /> */}
                <MuiThemeProvider theme={muiTheme}>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path={ROUTE.LOGIN}
                                render={(props) => <Login logo={this.state.theme.logo}
                                theme={this.state.theme}
                                changeTheme={this.changeTheme}
                                currentLanguage={this.state.selectLanguage} {...props} getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path={ROUTE.SIGNUP}
                                render={(props) => <Signup {...props} changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} getPermission={this.getUserRoles}/>}
                            />
                             <Route
                                path={ROUTE.GROUP_CHAT}
                                render={(props) => <GroupChat logo={this.state.theme.logo}
                                theme={this.state.theme}
                                changeTheme={this.changeTheme}
                                currentLanguage={this.state.selectLanguage} {...props} getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path={ROUTE.FORGET_PASSWD}
                                component={(props) => <ForgotPassword changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} {...props}  />}
                            />
                            <Route
                                path={ROUTE.CHANGE_PASSWORD}
                                render={(props) => <ChangePassword {...props} changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage}  expired={this.state.isExpired} type={this.state.type}/>}
                            />
                            <Route
                                path="/otp"
                                render={props => <OTPScreen changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} {...props}  getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path="/verify-recoveryCode"
                                render={props => <OTPScreen {...props} changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path="/login-methods"
                                render={props => <AuthMethods {...props} changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path="/verify-sms"
                                render={props => <OTPScreen changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} {...props}  getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path="/verify-securityKey"
                                render={props => <OTPScreen {...props} changeTheme={this.changeTheme} theme={this.state.theme}
                                currentLanguage={this.state.selectLanguage} getPermission={this.getUserRoles}/>}
                            />
                            <Route
                                path="/not-found"
                                render={props => <NotFound  {...props}   getPermission={this.getUserRoles} />}
                            />
                            {isLoggedIn ?
                                <React.Fragment>
                                    <HeaderView
                                        logo={this.state.theme.logo}
                                        theme={this.state.theme}
                                        changeTheme={this.changeTheme}
                                        currentLanguage={this.state.selectLanguage}
                                        changeLanguage={this.changeLanguage}
                                    />
                                    <div className={this.state.theme.logo ? "darkThemes" : "lightThemes"}>
                                        <SideNavigationView loginUser={loginUser} userRoles={roles} isWhitelistIP={this.state.isWhitelistIP} 
                                            clientData={this.props.clientList} onChangeMenuType={this.onChangeMenuType} />
                                        <MuiThemeProvider theme={muiTheme}>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                {this.checkForClientApp() ? 
                                                    <ClientMainContentView
                                                        userRoles={roles}
                                                        handleWhitelistIPModule={this.handleWhitelistIPModule}
                                                        changeTheme={{method: this.changeTheme, value: this.state.theme}}
                                                        loginUser={loginUser}
                                                    />
                                                    :
                                                    <AdminMainContentView
                                                        userRoles={roles}
                                                        changeTheme={{method: this.changeTheme, value: this.state.theme}}
                                                        loginUser={loginUser}
                                                    />
                                                }
                                            </Suspense>
                                        </MuiThemeProvider>
                                    </div>
                                    <Footer/>
                                </React.Fragment> :
                                <Route
                                    path={ROUTE.ALL}
                                    render={
                                        (props) => <Login {...props} logo={this.state.theme.logo}
                                        changeTheme={this.changeTheme}
                                        currentLanguage={this.state.selectLanguage} getPermission={this.getUserRoles}/>
                                    }
                                />
                                // <Route
                                //     path={ROUTE.ALL}
                                //     render={
                                //         (props) => <Login {...props} getPermission={this.getUserRoles}/>
                                //     }
                                // />
                            }
                        </Switch>
                    </BrowserRouter>
                </MuiThemeProvider>
                <Toaster/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getUserRoles: () => dispatch(getUserRoles()),
    getTokenData: (object) => dispatch(getTokenData(object)),
    getClientList: (object) => dispatch(getClientList(object))
});

const mapStateToProps = state => ({
    serverDetail:state.getServerDetail && state.getServerDetail.data ? state.getServerDetail.data : [],
    userRoles: state.userRoles,
    usersList: state.usersList && state.usersList.data ? state.usersList.data : [],
    softwareList: state.softwareList && state.softwareList.data ? state.softwareList.data : [],
    clientList: state.clientList && state.clientList.data ? state.clientList.data : [],
    dashboardData: state.dashboardData && state.dashboardData.data ? state.dashboardData.data : [],
    platformData: state.platformData && state.platformData.data ? state.platformData.data : [],
    syslogSettingData: state.platformData && state.syslogSettingData.data ? state.syslogSettingData.data : [],
    systemEventList: state.systemEventList && state.systemEventList.data ? state.systemEventList.data : [],
    getMLScanList: state.getMLScanList && state.getMLScanList.data ? state.getMLScanList.data : []
});

const stylesheets = withStyles(styles, {withTheme: true})(App);
export default withTranslation()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(stylesheets)
);