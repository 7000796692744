
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { USER } from "../constant/actionTypes";

export default token => dispatch => {
    dispatch({
        payload: [],
        type: USER.USER_TOKEN.INITIATE
    });

    return httpservice()
        .get(`${constants.API.TOKEN}?token=${token}`)
        .then(response => {
            dispatch({
                payload: response.data.response,
                type: USER.USER_TOKEN.SUCCESS
            });
            return response.data.response;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: USER.USER_TOKEN.ERROR
            });
        });
};