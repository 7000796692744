import { httpservice } from '../services';
import { constants } from '../constant/constant';
import AuthService from "../services/AuthService";

export default async (credential, ssoObject) => {
  let loginData = await httpservice()
    .post(
      `${constants.API.LOGIN}?sso=${ssoObject.sso}&redirect_uri=${ssoObject.redirectURI}&portal=${ssoObject.portal}`,
      credential
    )
    .then(function(response) {
      return { status: 1, data: response.data };
    })
    .catch(function(error) {
      return {
        status: 0,
        data: error.response && error.response.data ? error.response.data : error
      };
    });
  if (loginData.data.response && loginData.data.response.token) {
    AuthService.authenticate(loginData.data.response.token, JSON.stringify(loginData.data.response));
    await httpservice()
      .get(constants.API.SOCKET_TOKEN)
      .then(function(response) {
        if (response.data && response.data.response && response.data.response.socketToken) {
          sessionStorage['socketToken'] = response.data.response.socketToken;
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  return loginData;
};
