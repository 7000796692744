
import {httpservice} from '../services';
import { CLIENT } from '../constant/actionTypes';
import {constants} from '../constant/constant';
import useMoment from 'moment';

export default (id,comingData) => dispatch=> {

    return httpservice()
        .get(`${constants.API.SET_PREVIOUS_API_KEY}/${id}`, {})
        .then(function (res) {
            const dataArray = [];
            comingData.forEach(data => {
                if (data.id === id) {
                    data.apiKey =res.data.response.apiKey;
                    data.apiKeyValidity = useMoment(res.data.response.apiKeyValidity);
                }
                dataArray.push(data);
            });
            dispatch({
                payload: { data: dataArray, count: dataArray.length },
                type: CLIENT.GET.SUCCESS
            })
            return res.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};
