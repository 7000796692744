
import { constants } from "../constant/constant";
import { httpservice } from '../services';
import { SYSTEM_EVENT } from '../constant/actionTypes';
import { dateFormat } from "../helper/StringUtils";

export default (obj) => dispatch => {
    const query = `${constants.API.GET_SYSTEM_EVENTS}?page=${obj.page}&size=${obj.size}`
    dispatch({
        payload: [],
        type: SYSTEM_EVENT.DATA.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const rows = response.data.response.data.events;
            const count = response.data.response.data.totalItems;
            const responseData = rows.map(data => {
                data.created_at = dateFormat(data.createdAt);
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'N/A';
                return data;
            });
            dispatch({
                payload: { data: responseData , count: count},
                type: SYSTEM_EVENT.DATA.SUCCESS
            });
            return responseData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: SYSTEM_EVENT.DATA.ERROR
            });
        })
};