
import {httpservice} from '../services';
import {dateFormat} from '../helper/StringUtils';
import { USER } from "../constant/actionTypes";
import {constants} from "../constant/constant";


export default object => dispatch => {
    const query = `${constants.API.USER}`;
    dispatch({
        payload: {data: [], count: 0},
        type: USER.GET.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const count = response.data.response.count;
            const responseData = response.data.response.rows.map(data => {
                data.name = data.firstName + ' ' + data.lastName;
                data.createdAt = dateFormat(data.createdAt);
                data.company = data.company && data.company.name ? data.company.name : '';
                data.role = data.role ? data.role.roleName : '';
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : '';
                if (data.active) {
                    data.active = 'Active';
                } else {
                    data.active = 'Pending';
                }
                if (data.isBlocked) data.active = 'Blocked';

                return data;
            });
            dispatch({
                payload: {data: responseData, count: count},
                type: USER.GET.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: {data: [], count: 0, error: error},
                type: USER.GET.ERROR
            });
        });
};
