

import {httpservice} from '../services';
import {httpserviceWithMultipart} from '../services';
import {constants} from '../constant/constant';
const FileSaver = require('file-saver');
let apiEndPoint = constants.ADMIN_URL;
const loginUser =localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData  :false;
if(loginUser){
    if(loginUser.role.roleName==="client"){
        apiEndPoint=constants.CLIENT_URL
    }
}
export const doSoftwareVersionCreate = data => {
    return httpserviceWithMultipart('post', data, apiEndPoint + constants.API.VERSION)
};

export const doSoftwareVersionUpdate = (data,id) => {
    return httpserviceWithMultipart('put', data, `${apiEndPoint}${constants.API.VERSION}/${id}`);
};

export const doSoftwareVersionDelete = info => {
    return httpservice()
        .delete(`${constants.API.VERSION}/${info}`)
        .then(function (response) {
            return {data:response.data,status:1};
        })
        .catch(function (error) {
            return {data:error.response.data,status:0};
        });
};

export const doSoftwareVersionDownload= rowData => {
    return httpservice()
        .get(`${constants.API.DOWNLOAD_VERSION}/${rowData.id}`,
        {
            responseType: 'blob'
        })
        .then(function (response) {
            const blob = new Blob([response.data]);
            FileSaver.saveAs(blob, rowData.releasePath)
            return {};
        })
        .catch(function (error) {
            return {data:error.response, status:0};
        });
};