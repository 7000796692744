
/* jshint esversion: 6 */

const FooterLayoutStyle = theme => ({
    footer: {
        width: '100%',
        float: 'left',
        textAlign: 'center',
        padding: '12px 0',
        color: theme.palette.secondary.contrastText,
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 9,
        background: theme.palette.sidebarBackground.color,
        borderTop: 'solid 1px '+ theme.palette.primary.dark,
        '@media (max-width:500px)':{
            fontSize:"3vw",
        }
    },
    shrinkFooter:{
        
    }
});

export default FooterLayoutStyle;
