
import { httpservice } from '../services';
import { constants } from '../constant/constant';
import { SOFTWARE } from '../constant/actionTypes';
import moment from 'moment';

export const doSoftwareCreate = (data, newData) => dispatch => {
    return httpservice()
        .post(`${constants.API.SOFTWARE}`, data)
        .then(function (response) {
            const val = response.data.response;
            const newArray = [...newData];
            const newdata = { ...data }
            newdata.id = val.id.id;
            newdata.guid = val.id.guid;
            newdata.created_at = moment().format("DD/MM/YYYY");
            const loginUser = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData : false;
            newdata.createdBy = loginUser ? loginUser.firstName + ' ' + loginUser.lastName : '';
            newArray.push(newdata);

            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: SOFTWARE.GET.SUCCESS
            })
            return { data: response.data, status: 1 };
        })
        .catch(function (error) {
            return { data: error.response && error.response.data ? error.response.data : error, status: 0 };
        });
};

export const doSoftwareUpdate = (data, newArray) => dispatch => {
    return httpservice()
        .put(`${constants.API.SOFTWARE}/${data.id}`, data)
        .then(function (response) {
            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: SOFTWARE.GET.SUCCESS
            })
            return { data: response.data, status: 1 };
        })
        .catch(function (error) {
            return { data: error.response && error.response.data ? error.response.data : error, status: 0 };
        });
};

export const doSoftwareDelete = (info, data) => dispatch => {
    return httpservice()
        .delete(`${constants.API.SOFTWARE}/${info}`)
        .then(function (response) {
            dispatch({
                payload: { data: data, count: data.length },
                type: SOFTWARE.GET.SUCCESS
            })
            return { data: response.data, status: 1 };
        })
        .catch(function (error) {
            return { data: error.response && error.response.data ? error.response.data : error, status: 0 };
        });
};

export const updateSoftwarePublicKey = (updateData) => {
    return httpservice()
        .put(`${constants.API.UPLOAD_SOFTWARE_PUBLIC_KEY}`, updateData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

