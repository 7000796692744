
import React, { Component } from 'react';
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/FormStyle';
import { CheckIfNotEmpty, CheckIfNumbericValid, CheckIfValidIp } from '../../helper/StringUtils';
import { CheckIfEmailIsValid } from '../../helper/StringUtils';
import { CheckIfPasswordIsValid } from '../../helper/StringUtils';
import { CheckPasswordFormat } from '../../helper/StringUtils';
import { CheckIfNewPasswordIsValid } from '../../helper/StringUtils';
import { CheckIfIterationCountIsValid } from '../../helper/StringUtils';
import { CheckIfphoneNumberIsValid } from '../../helper/StringUtils';
import { TextField } from "@material-ui/core";
import { Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

let password;
let confirmPassword;
let newPassword;
let oldPassword;

class SInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    /*
     * Function will check
     * 1) Field are empty or not
     * 2) Email, Phone number, Iteration count are valid or not
     * 3) Password and confirmPassword are equal or not
     */

    validateInput = e => {
        let tempName = this.props.name;
        let dom = e.target;
        if (
            !(
                tempName === 'password' ||
                tempName === 'confirmPassword' ||
				tempName === 'oldPassword' ||
				tempName === 'newPassword' ||
                tempName === 'phoneNumber' ||
                tempName === 'iterationCount' ||
				tempName === 'oldIterationCount'

            )
        ) {
            if (CheckIfNotEmpty(e.target.value)) {
                this.props.checkError(dom, false);
            } else {
                this.props.checkError(dom, true);
                return false;
            }
        }

        switch (this.props.name) {
            case 'email':
                CheckIfEmailIsValid(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.EMAIL_NVALID");
                return false;
            case 'ipaddress':
                CheckIfValidIp(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.VALID_IP");
                return false;
            case 'versionNumber':
                CheckIfNumbericValid(e.target.value)
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true, "VALIDATION.NUMERIC_VERSION_NUMBER");
                return false;
            case 'phoneNumber':
                let phoneNumber = e.target.value;
                if (phoneNumber) {
                    CheckIfphoneNumberIsValid(e.target.value)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true);
                }
                return false;
            case 'password':
                password = e.target.value;
                return false;
			case 'oldPassword':
                oldPassword = e.target.value;
				if (oldPassword && oldPassword !== "") {
                    CheckPasswordFormat(oldPassword)
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
                }else{
					this.props.checkError(dom, true,"VALIDATION.REQ_FIELD");
				}
                return false;
			case 'newPassword':
                newPassword = e.target.value;
				if (newPassword) {
					if(CheckIfNewPasswordIsValid(oldPassword, newPassword)){
						CheckPasswordFormat(newPassword)
							? this.props.checkError(dom, false)
							: this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
					}else{
						this.props.checkError(dom, true, <Trans>VALIDATION.NEW_C_PASS</Trans>);
                    }
                }else{
					this.props.checkError(dom, true,"VALIDATION.REQ_FIELD");
				}
                return false;
            case 'confirmPassword':
                confirmPassword = e.target.value;
                if (confirmPassword) {
                    if(CheckIfPasswordIsValid(newPassword, confirmPassword)){
						CheckPasswordFormat(confirmPassword)
							? this.props.checkError(dom, false)
							: this.props.checkError(dom, true, "VALIDATION.INVALID_PASSWORD_FORMAT");
					}else{
						this.props.checkError(dom, true, <Trans>VALIDATION.CONFIRM_N_PASS</Trans>);
					}
                }else{
					this.props.checkError(dom, true,"VALIDATION.REQ_FIELD");
				}
                return false;
            case 'iterationCount':
				if (e.target.value) {
					CheckIfIterationCountIsValid(e.target.value)
						? this.props.checkError(dom, false)
						: this.props.checkError(dom, true, "Invalid iteration count");
				}else{
					this.props.checkError(dom, true,"VALIDATION.REQ_FIELD");
				}
				return false;
			case 'oldIterationCount':
				if (e.target.value) {
					CheckIfIterationCountIsValid(e.target.value)
						? this.props.checkError(dom, false)
						: this.props.checkError(dom, true, "Invalid old iteration count");
				}else{
					this.props.checkError(dom, true,"VALIDATION.REQ_FIELD");
				}
				return false;
            case 'passCode':
                e.target.value.length === 6
                    ? this.props.checkError(dom, false)
                    : this.props.checkError(dom, true);
                return false;
            case 'licenseLimit':
                if (e.target.value) {
                    e.target.value < 10000 
                        ? this.props.checkError(dom, false)
                        : this.props.checkError(dom, true, <Trans>VALIDATION.LICENSE_ALLOWED_INSTANCES_FORMAT</Trans>);
				}else{
					this.props.checkError(dom, true,"VALIDATION.REQ_LICENSE_ALLOWED_INSTANCES");
				}
                return false;
            default:
                this.props.checkError(dom, false);
        }
    };

    isValidFunction = e => {
        if (this.props.validateForm) this.validateInput(e);
    };

    removeBorder = e => {
        if (this.props.validateForm) this.props.checkError(e.target, false);
    };

    checkType = input => {
        if(input === 'password' || input === 'confirmPassword' || input === 'newPassword' || input === 'oldPassword'){
            return 'password';
        }else if(input === 'expiryDate'){
            return 'date';
        }else if(input === 'licenseLimit'){
            return 'number';
        }else if(input === 'expiryDateTime'){
            return 'datetime-local'
        }else{
            return 'text';
        }
    }

    render() {
        const { classes, randomKey } = this.props;
        return (
            // <>
            // <Grid container alignItems="flex-end" className={classes.marginBottom}>
            //             <Grid item className={classes.formInput}>
        
            //         <TextField
            //             {...input}
            //             className={ classes.loginCredentials + ' ' + classes.inputNew}
            //             type={type}
            //             disabled={disabled}
            //             name={name}
            //             placeholder={placeholder}
            //             // label={label}
            //             autoComplete={autoComplete || "off"}
            //             />
            //             </Grid>
            //             </Grid>
            //         <FormHelperText className={classes.error}>{touched && error && <Trans>{touched && error}</Trans>}</FormHelperText>
            //     </>
            <React.Fragment key={randomKey ? randomKey : 1}>
                <Grid container alignItems="flex-end" className={classes.marginBottom}>
                <Grid item className={classes.formInputSignUp}>
                <TextField
                    className={ classes.loginCredentials + ' ' + classes.inputNew}
                    name={this.props.name}
                    type={this.checkType(this.props.name)}
                    InputProps={{
                        className: this.props.theme.logo ? "darkTheme " : "lightTheme"
                    }}
                    // label={this.props.label}
                    placeholder={this.props.placeholder}
                    id={this.props.id}
                    defaultValue={this.props.info}
                    onChange={this.props.onChange ? this.props.onChange : this.isValidFunction}
                    // onChange={this.props.handleChange}
                    disabled={this.props.readonly ? this.props.readonly : false}
                    error={this.props.isError}
                    helperText={this.props.helperText}
                    format={this.checkType(this.props.name) === 'date' ? 'YYYY/MM/DD' : null}
                />
                </Grid>
                </Grid>
                <FormHelperText className={classes.error}>{this.props.isError && <Trans>{this.props.errorVal}</Trans>}</FormHelperText>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SInput);
// export default SInput;
