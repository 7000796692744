
import {httpservice} from '../services';
import {dateFormat} from "../helper/StringUtils";
import {constants} from "../constant/constant";
import {CLIENT} from '../constant/actionTypes';
import {ENTITY} from '../constant';

export default (Id, object) => dispatch => {
    const url = constants.API.CLINET_WHITELISTED_IP.replace(ENTITY.CLIENT_ID, Id);
    let query;
    if (!object) {
        query = url;
    } else {
        switch (object.purpose) {
            case 'search':
                query = `${url}?queryOptions=like:${object.option}:=$:${object.page}`;
                break;
            case 'sort':
                query = `${url}?queryOptions=sort:${object.page}:${object.limit}`;
                break;
            case 'totalRecords':
                query = url;
                break;
            default:
                query = url;
        }
    }
    dispatch({
        payload: [],
        type: CLIENT.WHITE_IP_LIST.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const count = response.data && response.data.response ? response.data.response.length : 0;
            const responseData = response.data.response.map(data => {
                data.created_at = dateFormat(data.createdAt);
                return data;
            });
            dispatch({
                payload: {data: responseData, count: count},
                type: CLIENT.WHITE_IP_LIST.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: CLIENT.WHITE_IP_LIST.ERROR
            });
        });
};


