// Notification.js (customize as needed)

import React from 'react';
import './Notifications.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Menu, MenuItem } from '@material-ui/core';
import { Trans, withTranslation } from 'react-i18next';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Person from '../../assets/images/svgIcons/personalSettingsIcon';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/headerStyle';
import ukMap from '../../assets/images/uk.svg';
import useStyles from '../../hooks/useStyles';
const Notification = ({ notifications, showNotification, onCloseNotify }) => {
  const [messages, setMessages] = useState([]);
  const token = localStorage.getItem('authToken');
  const classes = useStyles(styles)();
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ADMIN_ENDPOINT + '/api/v1/notifications', {
        headers: {
          Authorization: `Bearer ${token}` // Include the access token as a Bearer token
        }
      })
      .then(response => {
        console.log(response);
        setMessages(response.data.response);
      });
    let credData = localStorage.getItem('cred');
    console.log('credData', credData);
    credData = JSON.parse(credData || '');
    // getMyChatsData(credData?.userData?.id);
  }, []);
  return (
    <>
      {/* <div className="notification-container"> */}
      {/* {console.log(showNotification)} */}
      <Menu
        className={classes.menuDropdown}
        anchorEl={showNotification}
        keepMounted
        open={Boolean(showNotification)}
        onClick={onCloseNotify}
      >
        {messages && messages.length > 0 ? (
          messages.map((notification, index) => (
            <MenuItem key={index}>
              {/* onClick={this.userProfile} */}
              {/* <Person /> */}
              <strong>
                {notification.userData.firstName + ' ' + notification.userData.lastName}
              </strong>{' '}
              ({notification.userData.email}): {notification.message}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No notifications</MenuItem>
        )}
      </Menu>
      {/* <ul>
        {messages.map((notification, index) => (
          // <li key={index}>{notification.message}</li>
          <li key={index}>
            <strong>
              {notification.userData.firstName + ' ' + notification.userData.lastName}
            </strong>{' '}
            ({notification.userData.email}): {notification.message}
          </li>
        ))}
      </ul> */}
      {/* </div> */}
    </>
  );
};

export default Notification;
