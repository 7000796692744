import { USER } from '../constant/actionTypes';
import { constants } from '../constant/constant';

export default () => dispatch => {
  /**
   * USER Roles has been moved from system
   */
  let modules = [
    { moduleName: 'emotions', create: true, read: true, update: true, delete: true },
    { moduleName: 'secondEmotion', create: true, read: true, update: true, delete: true },
    { moduleName: 'thirdEmotion', create: true, read: true, update: true, delete: true },
    { moduleName: 'whatICanDo', create: true, read: true, update: true, delete: true },
    { moduleName: 'journalEntries', create: true, read: true, update: true, delete: true },
    { moduleName: 'gallery', create: true, read: true, update: true, delete: true },
    { moduleName: 'video', create: true, read: true, update: true, delete: true },
    { moduleName: 'billing', create: true, read: true, update: true, delete: true },
    { moduleName: 'user', create: true, read: true, update: true, delete: true },
    { moduleName: 'dashboard', create: true, read: true, update: true, delete: true },
  ];

  if (
    process.env.REACT_APP_AUDIENCE_TYPE &&
    constants.USER_TYPE.CLIENT.toUpperCase().trim() ===
      process.env.REACT_APP_AUDIENCE_TYPE.toUpperCase()
  ) {
    // return only first two module
    modules = modules.splice(0, 9);
    modules = modules.map(item => {
      item.create = false;
      item.update = false;
      item.delete = false;
      item.lock = false;
      item.copy = false;
      item.upload = false;
      if (item.moduleName === 'licenseInstance') item.delete = true;
      if (item.moduleName === 'clientUser') {
        item.create = true;
        item.update = true;
        item.delete = true;
      }
      if (item.moduleName === 'whiteListedIp') {
        item.create = true;
        item.update = true;
        item.delete = true;
      }
      return item;
    });
  }
  dispatch({
    type: USER.ROLE_LIST.SUCCESS,
    payload: modules
  });
};
