
import { USER } from "../constant/actionTypes";

export default (state, action) => {
    if (typeof state == 'undefined') {
        state = {};
    }

    switch (action.type) {
        case USER.GET.INITIATE:
            return { isFetching: true, data: action.payload };
        case USER.GET.SUCCESS:
            return { isFetching: false, data: action.payload };
        case USER.GET.ERROR:
            return { isFetching: false, data: action.payload };
        default:
            return state;
    }
};
