import React from "react";

export default () => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       width="399px" height="496px" viewBox="61.5 0 399 496">
    <g>
      <g>
        <path d="M343.406,122.131c-37.149-37.151-114.881-30.048-146.578-25.27c-9.984,1.504-17.23,9.954-17.23,20.089v22.629
			c0,5.425,4.399,9.823,9.824,9.823c5.424,0,9.823-4.398,9.823-9.823v-22.63c0-0.334,0.221-0.62,0.514-0.665
			c12.66-1.908,37.784-4.852,63.813-3.117c30.634,2.04,52.819,9.728,65.944,22.854c3.837,3.836,10.056,3.836,13.892,0
			C347.242,132.187,347.242,125.966,343.406,122.131z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M147.411,434.339c-5.425,0-9.823,4.397-9.823,9.822v42.011c0,5.426,4.398,9.824,9.823,9.824s9.823-4.398,9.823-9.824
			v-42.011C157.233,438.737,152.835,434.339,147.411,434.339z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M378.473,434.339c-5.424,0-9.822,4.397-9.822,9.822v42.011c0,5.426,4.398,9.824,9.822,9.824
			c5.425,0,9.824-4.398,9.824-9.824v-42.011C388.297,438.737,383.897,434.339,378.473,434.339z"/>
      </g>
    </g>
    <g>
      <path fill="none" d="M256.585,287.095c23.27,1.507,45.55-5.205,63.742-19.018c0.056-0.04,0.111-0.083,0.167-0.121
		c2.602-1.985,5.119-4.115,7.542-6.387c19.419-18.2,30.111-42.874,30.111-69.474v-27.97c0-1.479,0.335-2.938,0.977-4.271
		c6.322-13.089,9.526-26.978,9.526-41.28V23.368H231.434c-40.914,0-74.2,33.286-74.2,74.199v21.005
		c0,14.302,3.206,28.19,9.525,41.279c0.643,1.332,0.978,2.793,0.978,4.271v24.371C167.737,240.559,206.763,283.868,256.585,287.095z
		"/>
      <path fill="none" d="M191.449,340.216l30.548,45.825c0.054,0.08,0.18,0.269,0.5,0.298c0.321,0.034,0.479-0.126,0.548-0.194
		l24.58-24.581l-46.345-31.678L191.449,340.216z"/>
      <path fill="none" d="M278.261,361.567l24.58,24.58c0.068,0.067,0.227,0.236,0.548,0.194c0.319-0.029,0.445-0.216,0.499-0.298
		l30.549-45.824l-9.831-10.33L278.261,361.567z"/>
      <path fill="none" d="M316.136,293.936c-16.233,8.521-34.311,13.011-53.092,13.011c-2.567,0-5.14-0.081-7.729-0.25
		c-16.391-1.062-31.804-5.714-45.566-13.154v18.336l53.193,36.361l53.194-36.361V293.936z"/>
    </g>
    <g>
      <path d="M437.479,375.394c-0.025-0.027-0.051-0.057-0.077-0.083c-2.31-2.539-4.855-4.829-7.62-6.804
		c-4.228-3.021-10.02-5.826-15.728-7.815l-0.034,0.011l-57.262-20.823c0.205-2.701-0.706-5.449-2.677-7.52l-18.298-19.224v-32.272
		c1.934-1.593,3.841-3.23,5.688-4.962c23.083-21.637,36.321-52.184,36.321-83.81v-25.772c6.971-15.168,10.504-31.218,10.504-47.748
		V13.545c0-5.425-4.399-9.823-9.824-9.823H231.434c-51.748,0-93.846,42.1-93.846,93.844v21.007c0,16.53,3.533,32.58,10.502,47.748
		v22.175c0,36.719,16.326,69.8,42.012,91.611v33.03l-18.299,19.224c-1.971,2.071-2.883,4.818-2.676,7.521l-60.437,21.975
		c-4.379,1.594-8.449,3.751-12.144,6.364l-0.092-0.045c-10.951,8.196-18.734,20.323-21.229,34.269
		c-0.428,2.663-0.654,5.377-0.654,8.128v75.604c0,5.423,4.397,9.822,9.822,9.822c5.425,0,9.823-4.399,9.823-9.822v-75.604
		c0-13.485,8.515-25.641,21.189-30.25l63.72-23.17l26.525,39.79c3.391,5.087,8.825,8.349,14.909,8.952
		c0.677,0.065,1.353,0.099,2.025,0.099c5.367,0,10.508-2.112,14.351-5.953l16.184-16.185v102.321c0,5.424,4.397,9.822,9.822,9.822
		s9.822-4.398,9.822-9.822V383.855l16.184,16.184c3.841,3.844,8.981,5.955,14.352,5.955c0.672,0,1.347-0.032,2.024-0.099
		c6.085-0.604,11.52-3.865,14.909-8.952l26.526-39.789l63.719,23.17c12.674,4.608,21.188,16.766,21.188,30.25v75.604
		c0,5.424,4.399,9.822,9.824,9.822s9.823-4.398,9.823-9.822v-75.604c0-6.697-1.312-13.189-3.717-19.187
		C445.088,385.495,441.682,380.03,437.479,375.394z M167.737,188.495v-24.371c0-1.479-0.335-2.939-0.978-4.271
		c-6.321-13.088-9.525-26.977-9.525-41.279V97.568c0-40.913,33.286-74.199,74.2-74.199H368.65v95.206
		c0,14.302-3.205,28.191-9.526,41.279c-0.642,1.333-0.977,2.793-0.977,4.271v27.97c0,26.601-10.694,51.274-30.111,69.474
		c-2.423,2.271-4.94,4.402-7.542,6.387c-0.057,0.038-0.112,0.081-0.167,0.121c-18.192,13.813-40.473,20.524-63.742,19.018
		C206.763,283.868,167.737,240.559,167.737,188.495z M223.044,386.146c-0.069,0.068-0.227,0.229-0.548,0.194
		c-0.32-0.029-0.445-0.217-0.5-0.298l-30.548-45.825l9.83-10.329l46.345,31.678L223.044,386.146z M262.941,348.238l-53.193-36.36
		v-18.335c13.764,7.44,29.175,12.094,45.566,13.154c2.59,0.168,5.163,0.25,7.729,0.25c18.781,0,36.858-4.489,53.092-13.011v17.941
		L262.941,348.238z M303.885,386.043c-0.053,0.082-0.18,0.269-0.499,0.298c-0.32,0.041-0.478-0.127-0.547-0.194l-24.579-24.58
		l46.344-31.679l9.833,10.33L303.885,386.043z"/>
    </g>
  </svg>
);