
import { SOFTWARE } from "../constant/actionTypes";

export default (state, action) => {
    if (typeof state === 'undefined') {
        state = [];
    }
    switch (action.type) {
        case SOFTWARE.SUBMODULE_DATA.INITIATE:
            return {isFetching: true};
        case SOFTWARE.SUBMODULE_DATA.SUCCESS:
            return {isFetching: false, data: action.payload};
        case SOFTWARE.SUBMODULE_DATA.ERROR:
            return {isFetching: false, data: action.payload};
        default:
            return state;
    }
};