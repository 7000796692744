
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default smsData => {
    return httpservice()
        .post(constants.API.SEND_SMS, smsData)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response.data;
        });
};