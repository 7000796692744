// import React, { useState, useEffect } from 'react';
// // import './App.css';
// import {constants} from "../../constant/constant";
// // const socket = new WebSocket(constants.SOCKET_URL);
// import SocketService from '../../services/SocketService'

// function GroupChat() {
//   const [message, setMessages] = useState('');
//   const [chat, setChat] = useState([]);

//   useEffect(() => {
//     // SocketService((message) => {
//     //   setMessages((prevMessages) => [...prevMessages, message]);
//     // });
//     SocketService.onmessage = (event) => {
//       setChat((prevChat) => [...prevChat, event.data]);
//     };
//   }, []);

//   const sendMessage = () => {
//     // Send the message to the server
//     SocketService.send(message);
//     setMessages('');
//   };

//   return (
//     <div className="App">
//       <div className="chat-window">
//         {chat.map((message, index) => (
//           <div key={index} className="message">
//             {message}
//           </div>
//         ))}
//       </div>
//       <div className="input-container">
//         <input
//           type="text"
//           placeholder="Type your message..."
//           value={message}
//           onChange={(e) => setMessages(e.target.value)}
//         />
//         <button onClick={sendMessage}>Send</button>
//       </div>
//     </div>
//   );
// }

// export default GroupChat;












import React, { useState, useEffect } from 'react';
import SocketService from '../../services/SocketService';
function GroupChat() {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);

  useEffect(() => {
    SocketService.connect((message) => {
      console.log("messages---------------",message)
      setChat((prevChat) => [...prevChat, message]);
    });

    return () => {
      SocketService.close();
    };
  }, []);

  const sendMessage = () => {
    console.log("message-----------",message)
    SocketService.send(message);
    setMessage('');
  };

  return (
    <div className="App">
      <div className="chat-window">
        {chat.map((message, index) => (
          <div key={index} className="message">
            {message}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default GroupChat;
