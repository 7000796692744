

import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default () => {
    return httpservice()
        .get(constants.API.REGENERATE_BACKUP_CODES)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response.data;
        });
};