import React from "react";

export default () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            width="200px" height="200px" viewBox="0 0 200 200">
        <path d="M178.946,181.809c3.657,0,6.592-2.98,6.592-6.592V73.011c0-3.656-2.979-6.591-6.592-6.591H22.931
        c-3.657,0-6.591,2.979-6.591,6.591v102.25c0,3.657,2.979,6.59,6.591,6.59h156.016V181.809z M126.535,126.913v49.297H75.342v-49.297
        H126.535z M75.342,121.315V72.018h51.193v49.297H75.342z M178.946,176.21h-46.813v-49.297h47.762v48.304
        C179.94,175.759,179.488,176.21,178.946,176.21z M179.94,73.011v48.304h-47.807V72.018h46.813
        C179.488,72.018,179.94,72.469,179.94,73.011z M22.931,72.018h46.813v49.297H21.938V73.011
        C21.938,72.469,22.389,72.018,22.931,72.018z M21.938,175.217v-48.304h47.762v49.297H22.931
        C22.389,176.21,21.938,175.759,21.938,175.217z"/>
        <path d="M186.531,23.94h-8.939v-8.532c0-3.656-2.979-6.591-6.591-6.591h-5.281c-3.656,0-6.591,2.979-6.591,6.591v8.532h-15.214
        v-8.532c0-3.656-2.979-6.591-6.59-6.591h-5.282c-3.657,0-6.592,2.979-6.592,6.591v8.532h-15.258v-8.532
        c0-3.656-2.979-6.591-6.592-6.591H98.32c-3.657,0-6.591,2.979-6.591,6.591v8.532H76.516v-8.532c0-3.656-2.979-6.591-6.591-6.591
        h-5.281c-3.657,0-6.591,2.979-6.591,6.591v8.532H42.838v-8.532c0-3.656-2.979-6.591-6.591-6.591h-5.281
        c-3.657,0-6.591,2.979-6.591,6.591v8.532h-9.028c-5.733,0-10.383,4.65-10.383,10.383v34.58c0,1.535,1.264,2.799,2.799,2.799
        s2.798-1.264,2.798-2.799v-9.028h180.71v80.266c0,1.533,1.264,2.799,2.799,2.799s2.799-1.266,2.799-2.799V34.368
        C196.914,28.635,192.264,23.94,186.531,23.94z M164.727,15.408c0-0.542,0.451-0.993,0.993-0.993h5.281
        c0.543,0,0.993,0.452,0.993,0.993v22.707c0,0.542-0.45,0.993-0.993,0.993h-5.281c-0.542,0-0.993-0.451-0.993-0.993V15.408z
        M131.004,15.408c0-0.542,0.452-0.993,0.994-0.993h5.281c0.542,0,0.993,0.452,0.993,0.993v22.707c0,0.542-0.451,0.993-0.993,0.993
        h-5.281c-0.542,0-0.994-0.451-0.994-0.993V15.408z M97.327,15.408c0-0.542,0.451-0.993,0.993-0.993h5.281
        c0.542,0,0.994,0.452,0.994,0.993v22.707c0,0.542-0.452,0.993-0.994,0.993H98.32c-0.542,0-0.993-0.451-0.993-0.993V15.408z
        M63.605,15.408c0-0.542,0.451-0.993,0.993-0.993h5.282c0.542,0,0.993,0.452,0.993,0.993v22.707c0,0.542-0.451,0.993-0.993,0.993
        h-5.282c-0.542,0-0.993-0.451-0.993-0.993V15.408z M29.882,15.408c0-0.542,0.452-0.993,0.994-0.993h5.281
        c0.542,0,0.994,0.452,0.994,0.993v22.707c0,0.542-0.451,0.993-0.994,0.993h-5.281c-0.542,0-0.994-0.451-0.994-0.993V15.408z
        M10.606,54.277V34.368c0-2.618,2.123-4.74,4.741-4.74h8.938v8.532c0,3.657,2.979,6.591,6.591,6.591h5.281
        c3.657,0,6.591-2.979,6.591-6.591v-8.577h15.213v8.532c0,3.657,2.979,6.592,6.591,6.592h5.281c3.657,0,6.591-2.98,6.591-6.592
        v-8.532h15.214v8.532c0,3.657,2.979,6.592,6.59,6.592h5.282c3.657,0,6.592-2.98,6.592-6.592v-8.532h15.212v8.532
        c0,3.657,2.979,6.592,6.592,6.592h5.282c3.656,0,6.591-2.98,6.591-6.592v-8.532h15.213v8.532c0,3.657,2.98,6.592,6.591,6.592h5.282
        c3.656,0,6.591-2.98,6.591-6.592v-8.532h8.938c2.618,0,4.739,2.122,4.739,4.74v19.954H10.606z"/>
        <path d="M194.115,150.522c-1.535,0-2.8,1.266-2.8,2.799V182.8c0,2.618-2.121,4.739-4.739,4.739H15.347
        c-2.618,0-4.741-2.121-4.741-4.739V82.084c0-1.535-1.264-2.799-2.798-2.799c-1.535,0-2.799,1.264-2.799,2.799V182.8
        c0,5.733,4.649,10.383,10.383,10.383h171.185c5.733,0,10.383-4.649,10.383-10.383v-29.479
        C196.914,151.74,195.649,150.522,194.115,150.522z"/>
        <path d="M138.272,95.898l5.462,5.328l-1.309,7.492c-0.226,1.401,0.136,2.845,1.083,3.929c0.948,1.082,2.303,1.76,3.701,1.76
        c0.769,0,1.582-0.181,2.258-0.542l6.727-3.564l6.727,3.564c0.722,0.361,1.49,0.542,2.257,0.542c1.444,0,2.799-0.633,3.702-1.76
        c0.902-1.084,1.31-2.527,1.084-3.929l-1.31-7.492l5.462-5.328c1.354-1.309,1.806-3.205,1.219-4.965s-2.077-3.025-3.927-3.296
        l-7.54-1.083l-3.385-6.817c-0.813-1.67-2.483-2.708-4.379-2.708c-1.852,0-3.522,1.038-4.38,2.708l-3.385,6.817l-7.54,1.083
        c-1.851,0.271-3.34,1.535-3.927,3.296C136.466,92.694,136.918,94.589,138.272,95.898z M149.693,92.016
        c1.581-0.226,2.935-1.219,3.657-2.663l2.844-5.824l2.845,5.824c0.722,1.444,2.075,2.438,3.656,2.663l6.409,0.948l-4.648,4.515
        c-1.13,1.128-1.671,2.709-1.4,4.289l1.084,6.364l-5.733-3.022c-0.678-0.361-1.489-0.542-2.257-0.542
        c-0.769,0-1.58,0.181-2.257,0.542l-5.733,3.022l1.083-6.364c0.271-1.58-0.27-3.206-1.399-4.289l-4.65-4.515L149.693,92.016z"/>
    </svg>
);