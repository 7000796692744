
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { ML_SCAN } from "../constant/actionTypes";
import { dateFormat } from "../helper/StringUtils";

export default (obj) => dispatch => {
    dispatch({
        payload: [],
        type: ML_SCAN.GET.INITIATE
    });

    return httpservice()
        .get(`${constants.API.GET_ALL_ML_SCAN_DATA}?page=${obj.page}&size=${obj.size}`)
        .then(mlScanResult => {
            const rows = mlScanResult.data.response.data.scans;
            const count = mlScanResult.data.response.data.totalItems;
            const responseData = rows.map(data => {
                const mlScanExtractedScanResult = extractScanResult(data);
                if(!data.fileName) data.fileName = 'N/A';
                if(!data.malwareName) data.malwareName = 'N/A';
                data.mlResult = mlScanExtractedScanResult.mlResult;
                data.mlScore = mlScanExtractedScanResult.mlScore;
                data.finalResult = mlScanExtractedScanResult.finalResult;
                data.sigScanResult = mlScanExtractedScanResult.sigScanResult;
                data.created_at = dateFormat(data.createdAt);
                return data;
            });
            dispatch({
                payload: {data: responseData , count: count},
                type: ML_SCAN.GET.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: ML_SCAN.GET.ERROR
            });
        });


    function extractScanResult(mlScanResult) {
        var maliciousPoint = 0;
        var isSignature = false, isML = false, finalResult, sigScanResult, mlResult, mlScore;

        if (mlScanResult) {
            isSignature = (mlScanResult.sigScanResult === 1 || mlScanResult.sigScanResult === 2);
            isML = (mlScanResult.mlResult === 1 || mlScanResult.mlResult === 2);
        }

        if (isSignature) {
            maliciousPoint += 1;
            if (mlScanResult && mlScanResult.malwareName) {
                sigScanResult = mlScanResult.malwareName;
            } else {
                sigScanResult = 'Malicious';
            }
        } else {
            sigScanResult = 'No Match';
        }
        
        if (isML) {
            maliciousPoint += 1;
            mlResult = 'Malicious';
            mlScore = (mlScanResult && mlScanResult.mlScore) ? (mlScanResult.mlScore * 100).toFixed(2) + '%' : 'N/A';
        } else {
            mlResult = 'Inconclusive';
            mlScore = 'N/A';
        }

        if (maliciousPoint > 1) {
            finalResult = 'Malicious';
        } else if (maliciousPoint === 1) {
            finalResult = 'Potentially Malicious';
        } else {
            finalResult = 'Clean';
        }

        return {
            finalResult: finalResult,
            sigScanResult: sigScanResult,
            mlResult: mlResult,
            mlScore: mlScore
        };
    }
};