
import {constants} from "../constant/constant";
import { httpservice} from '../services';
import {dateFormat} from '../helper/StringUtils';
import {CLIENT} from '../constant/actionTypes';

export default (object) => dispatch => {
    let query;
    if (!object) {
        query = `${constants.API.CLIENT}`;
    } else {
        query = `${constants.API.CLIENT}/${object.id}`;
    }
    dispatch({
        payload: [],
        type: CLIENT.GET.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
           let  rows = [];
           let  count = 0 ;
            if(response.data.response.length){
                   rows  = response.data.response;
                  count = response.data.response.length
            }else{
                 rows = response.data.response.rows;
                 count = response.data.response.count;
            }
            const responseData = rows &&  rows.length ? rows.map(data => {
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'admin';
                data.created_at = dateFormat(data.createdAt);
                data.apiKeyValidity = dateFormat(data.apiKeyValidity);
                return data;
            }) : [];
            dispatch({
                payload: {data: responseData, count: count},
                type: CLIENT.GET.SUCCESS
            });
            return responseData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: CLIENT.GET.ERROR
            });
        });
};


