
import { constants } from "../constant/constant";
import { httpservice } from '../services';
import { dateFormat } from "../helper/StringUtils";
import { SOFTWARE } from '../constant/actionTypes';

export default (softwareId, object, setIsPageLoad = null) => dispatch => {
    if (setIsPageLoad) {
        setIsPageLoad(true);
    }
    dispatch({
        payload: [],
        type: SOFTWARE.VERSION.INITIATE
    });
    return httpservice()
        .get(`${constants.API.SOFTWARE_VERSION}/${softwareId}`)
        .then(response => {
            if (setIsPageLoad) {
                setIsPageLoad(false);
            }
            const count =  response.data.response ? response.data.response.length : 0 ;
            const responseData = response.data.response.map(data => {
                data.platform = data.platform.charAt(0).toUpperCase() + data.platform.slice(1);
                data.createdAt = dateFormat(data.createdAt);
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'admin';
                return data;
            });
            dispatch({
                payload: { data: responseData, count: count },
                type: SOFTWARE.VERSION.SUCCESS
            });
            return responseData;
        })
        .catch(function (error) {
            if (setIsPageLoad) {
                setIsPageLoad(false);
            }
            dispatch({
                payload: error,
                type: SOFTWARE.VERSION.ERROR
            });
        })
};


