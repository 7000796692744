

import {httpservice} from '../services';
import {constants} from '../constant/constant';
import {ENTITY} from '../constant';
let apiEndPoint = constants.ADMIN_URL;
const loginUser =localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData  :false;
if(loginUser){
    if(loginUser.role.roleName==="client"){
        apiEndPoint=constants.CLIENT_URL
    }
}
export const doClientIPCreate = data => {
    return httpservice()
        .post(`${apiEndPoint}${constants.API.WHITELISTED_IP}`, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doClientIPUpdate = (Id, data) => {
    const endPointUri = constants.API.WHITELISTED_IP_BY_ID.replace(ENTITY.WHITE_IP_ID, Id);
    return httpservice()
        .put(endPointUri, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doClientIPDelete = Id => {
    const endPointUri = constants.API.WHITELISTED_IP_BY_ID.replace(ENTITY.WHITE_IP_ID, Id);
    return httpservice()
        .delete(endPointUri)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};