import React from "react";

export default () => (
<svg width="496px" height="496px" viewBox="0 0 496 496">
    <path d="M496,256v-64.191c0-17.801-11.457-33.199-28.504-38.313L416,138.047v-12.152c14.488-10.133,24-26.91,24-45.887V56
c0-30.879-25.121-56-56-56s-56,25.121-56,56v24c0,18.984,9.512,35.762,24,45.887v12.152l-45.383,13.617
c-17.031-15.23-38.73-23.656-61.77-23.656c-22.945,0-43.938,8.406-60.16,22.258L144,138.047v-12.152
c14.488-10.133,24-26.91,24-45.887V56c0-30.879-25.121-56-56-56S56,25.121,56,56v24c0,18.984,9.512,35.762,24,45.887v12.152
l-51.496,15.449C11.457,158.609,0,174.008,0,191.809V256h16v-64.191c0-10.68,6.871-19.922,17.105-22.984l51.512-15.457
C89.273,159.414,98.281,168,112,168s22.727-8.586,27.383-14.633l32.809,9.84c-10.414,13.098-17.352,29.039-19.457,46.48
C142.52,198.832,128.055,192,112,192c-30.879,0-56,25.121-56,56v24c0,18.984,9.512,35.762,24,45.887v12.152l-51.496,15.449
C11.457,350.609,0,366.008,0,383.809V456h96c4.406,0,8,3.594,8,8v32h288v-32c0-4.406,3.594-8,8-8h96v-72.191
c0-17.801-11.457-33.199-28.504-38.313L416,330.047v-12.152c14.488-10.133,24-26.91,24-45.887v-24c0-30.879-25.121-56-56-56
c-15.664,0-29.824,6.48-40,16.879v-7.582c0-15.801-8.586-30.023-21.984-37.543l34.602-10.387C361.273,159.414,370.281,168,384,168
s22.727-8.586,27.383-14.633l51.52,15.457C473.129,171.887,480,181.129,480,191.809V256H496z M112,16c22.055,0,40,17.945,40,40
h-36.688c-5.082,0-10.066-2.063-13.656-5.656L96,44.688l-5.656,5.656C86.754,53.938,81.77,56,76.688,56H72
C72,33.945,89.945,16,112,16z M72,80v-8h4.688c6.969,0,13.625-2,19.313-5.734C101.688,70,108.344,72,115.313,72H152v8
c0,22.055-17.945,40-40,40S72,102.055,72,80z M112,152c-8.504,0-14.016-7.145-16-10.199v-8.152c5.07,1.512,10.441,2.352,16,2.352
s10.93-0.84,16-2.359v8.152C126.016,144.863,120.504,152,112,152z M331.902,292.414c4.074,10.348,11.105,19.195,20.098,25.48v12.152
l-51.488,15.449c-1.695,0.504-3.344,1.16-4.953,1.887L288,345.641v-15.762c8.551-6.871,15.289-15.895,19.426-26.238
C316.641,302.832,325.281,298.91,331.902,292.414z M248,344c8.488,0,16.586-1.695,24-4.711V348l-24,18l-24-18v-8.711
C231.414,342.305,239.512,344,248,344z M214.176,360.633l19.504,14.633l-20.063,12.039l-5.074-25.367L214.176,360.633z M239.16,424
h17.68l6.223,56h-30.125L239.16,424z M258.23,408h-20.469l-4.594-13.777L248,385.328l14.824,8.895L258.23,408z M262.32,375.266
l19.504-14.633l5.633,1.297l-5.074,25.367L262.32,375.266z M321.762,280.145c-2.77,3.039-6.289,5.168-10.105,6.465
C311.879,284.434,312,282.23,312,280v-38.281c8.609,3.266,15.016,10.84,15.879,19.891
C328.535,268.496,326.367,275.07,321.762,280.145z M296,280c0,26.473-21.527,48-48,48s-48-21.527-48-48v-40h27.16
c17.434,0,34.398-4.352,49.566-12.664L283.055,240H296V280z M184.344,286.609c-3.816-1.297-7.336-3.426-10.105-6.465
c-4.605-5.066-6.773-11.648-6.117-18.527c0.863-9.051,7.27-16.633,15.879-19.891V280C184,282.23,184.121,284.434,184.344,286.609z
M188.574,303.641c4.129,10.344,10.867,19.367,19.426,26.238v15.762l-7.559,1.742c-1.617-0.727-3.258-1.375-4.945-1.887L144,330.047
v-12.152c8.984-6.285,16.023-15.133,20.098-25.48C170.719,298.91,179.359,302.832,188.574,303.641z M112,208
c22.055,0,40,17.945,40,40h-4.688c-5.082,0-10.066-2.063-13.656-5.656L128,236.688l-5.656,5.656
c-3.59,3.594-8.574,5.656-13.656,5.656H72C72,225.945,89.945,208,112,208z M72,272v-8h36.688c6.969,0,13.625-2,19.313-5.734
C133.688,262,140.344,264,147.313,264H152v8c0,22.055-17.945,40-40,40S72,294.055,72,272z M112,328c5.559,0,10.93-0.84,16-2.359
v8.152c-1.984,3.07-7.496,10.207-16,10.207s-14.016-7.145-16-10.199v-8.152C101.07,327.16,106.441,328,112,328z M96,440H64v-56H48
v56H16v-56.191c0-10.68,6.871-19.922,17.105-22.984l51.512-15.457C89.273,351.414,98.281,360,112,360s22.727-8.586,27.383-14.633
l30.336,9.105l-22.313,5.145C121.848,365.52,104,387.961,104,414.184V424h16v-9.816c0-18.734,12.754-34.758,31.008-38.977
l41.938-9.68l9.43,47.168l16.738-10.039l4.734,14.207L216.84,480H176v-64h-16v64h-40v-16C120,450.77,109.23,440,96,440z
M462.895,360.824C473.129,363.887,480,373.129,480,383.809V440h-32v-56h-16v56h-32c-13.23,0-24,10.77-24,24v16h-40v-64h-16v64
h-40.84l-7.016-63.137l4.734-14.207l16.738,10.039l9.43-47.168l41.945,9.68C363.246,379.426,376,395.449,376,414.184V424h16v-9.816
c0-26.223-17.848-48.664-43.406-54.566l-22.313-5.145l30.336-9.105C361.273,351.414,370.281,360,384,360s22.727-8.586,27.383-14.633
L462.895,360.824z M400,333.801c-1.984,3.063-7.496,10.199-16,10.199c-8.52,0-14.039-7.168-16-10.199v-8.16
c5.07,1.52,10.441,2.359,16,2.359s10.93-0.84,16-2.359V333.801z M384,312c-22.055,0-40-17.945-40-40v-8h4.688
c6.969,0,13.625-2,19.313-5.734C373.688,262,380.344,264,387.313,264H424v8C424,294.055,406.055,312,384,312z M384,208
c22.055,0,40,17.945,40,40h-36.688c-5.082,0-10.066-2.063-13.656-5.656L368,236.688l-5.656,5.656
c-3.59,3.594-8.574,5.656-13.656,5.656H344C344,225.945,361.945,208,384,208z M328,232.68c-7.078-5.359-15.871-8.68-25.375-8.68
h-9.68l-9.84-19.68l-7.539,5.023C261.176,218.938,244.441,224,227.16,224h-33.785c-9.504,0-18.297,3.32-25.375,8.68v-11.832
C168,178.48,202.48,144,244.848,144c20.527,0,39.832,7.992,54.344,22.504l8.488,8.488l1.793,0.602
C320.551,179.289,328,189.617,328,201.297V232.68z M384,16c22.055,0,40,17.945,40,40h-4.688c-5.082,0-10.066-2.063-13.656-5.656
L400,44.688l-5.656,5.656C390.754,53.938,385.77,56,380.688,56H344C344,33.945,361.945,16,384,16z M344,80v-8h36.688
c6.969,0,13.625-2,19.313-5.734C405.688,70,412.344,72,419.313,72H424v8c0,22.055-17.945,40-40,40S344,102.055,344,80z M384,152
c-8.52,0-14.039-7.168-16-10.199v-8.16c5.07,1.52,10.441,2.359,16,2.359s10.93-0.84,16-2.359v8.152
C398.016,144.863,392.504,152,384,152z"/>
</svg>


);


