import React from "react";

const RemedyChildIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 100 100" height="100px" id="Layer_1" version="1.1"
         viewBox="0 0 100 100" width="100px">
        <g>
            <path
                d="M50,44.637c10.229,0,18.55-10.857,18.55-21.085S60.229,5,50,5s-18.551,8.323-18.551,18.552   C31.451,33.78,39.771,44.637,50,44.637z"/>
            <path
                d="M35.754,75.52c-0.643-1.673-0.266-3.62,1.079-4.953l2.319-2.318c0.863-0.864,2.068-1.359,3.307-1.359   c0.563,0,1.116,0.101,1.634,0.293c0.727-1.635,2.367-2.748,4.264-2.748h3.283c1.888,0,3.523,1.109,4.258,2.743   c0.521-0.194,1.079-0.297,1.649-0.297c1.255,0,2.431,0.489,3.296,1.365l2.325,2.321c1.333,1.332,1.705,3.271,1.072,4.945   c1.631,0.727,2.741,2.364,2.741,4.262v0.071c10.541-2.096,17.666-6.079,17.666-10.649c0-12.285-8.973-22.469-20.722-24.368   c-3.599,2.741-8.088,4.373-12.95,4.373h-1.953c-4.863,0-9.353-1.632-12.951-4.373C24.324,46.727,15.352,56.91,15.352,69.195   c0,4.57,7.124,8.554,17.666,10.649v-0.071C33.018,77.89,34.125,76.256,35.754,75.52z"/>
            <path
                d="M63.587,79.773c0-0.657-0.493-1.201-1.114-1.262l-2.05-0.344c-0.195-0.632-0.451-1.247-0.758-1.831l1.189-1.666   c0.415-0.509,0.376-1.24-0.086-1.703l-2.324-2.32c-0.239-0.24-0.558-0.371-0.896-0.371c-0.294,0-0.58,0.1-0.78,0.269l-1.691,1.207   c-0.586-0.31-1.2-0.565-1.832-0.761l-0.336-2.019c-0.066-0.651-0.609-1.143-1.268-1.143h-3.283c-0.654,0-1.198,0.491-1.259,1.113   l-0.341,2.048c-0.635,0.195-1.248,0.451-1.835,0.761l-1.666-1.192c-0.226-0.184-0.511-0.275-0.797-0.275   c-0.332,0-0.663,0.123-0.904,0.363l-2.322,2.32c-0.467,0.464-0.502,1.197-0.105,1.68l1.208,1.689   c-0.307,0.584-0.563,1.199-0.758,1.831l-2.02,0.339c-0.652,0.065-1.144,0.609-1.144,1.267v3.283c0,0.657,0.492,1.201,1.115,1.261   l2.049,0.343c0.194,0.629,0.452,1.244,0.758,1.833l-1.187,1.665c-0.418,0.506-0.379,1.238,0.084,1.703l2.322,2.321   c0.239,0.238,0.558,0.373,0.898,0.373c0.293,0,0.577-0.103,0.779-0.272l1.69-1.205c0.588,0.311,1.2,0.563,1.835,0.761l0.335,2.019   C47.159,94.508,47.703,95,48.357,95h3.283c0.658,0,1.201-0.492,1.26-1.112l0.344-2.049c0.632-0.197,1.246-0.45,1.832-0.761   l1.666,1.188c0.227,0.186,0.514,0.278,0.8,0.278c0.332,0,0.663-0.123,0.902-0.363l2.321-2.321c0.465-0.463,0.504-1.195,0.106-1.679   l-1.207-1.688c0.307-0.586,0.563-1.201,0.758-1.836l2.017-0.34c0.654-0.06,1.147-0.604,1.147-1.261V79.773z M49.999,86.848   c-2.996,0-5.434-2.436-5.434-5.435c0-2.996,2.438-5.434,5.434-5.434c2.997,0,5.437,2.438,5.437,5.434   C55.436,84.412,52.996,86.848,49.999,86.848z"/>
        </g>
    </svg>
);

export default RemedyChildIcon;
