
/* jshint esversion: 6 */

import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Field, reduxForm, getFormValues} from "redux-form";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/LoginScreenStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {CheckIfNotEmpty} from '../../helper/StringUtils';
import {ROUTE} from '../../constant';
import {Trans, withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {changePassword} from '../../actions';
import {AuthService, EncryptionServices, toasterInstance} from '../../services';
import loginMainLogo from "../../assets/images/TEA-logo.png";
import {renderTextField} from '../Form/Fields';
import {required} from '../Form/validate';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LoginBackButton from './../../assets/images/login-button.svg';
import MobileLogo from "./../../assets/images/software_light_mobile.svg";
import { VisibilityOutlined as LockIcon ,  VisibilityOffOutlined as LockOpenIcon } from '@material-ui/icons';
import Autorenew from '@material-ui/icons/Autorenew'

import headerLogo from '../../assets/images/TEA-logo.png';

// import styles from '../../assets/jss/forgotPasswordstyle'

import {Route} from 'react-router-dom';
import LinkExpired from './LinkExpired';

import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';
import userIcon from "./../../assets/images/login-update-icon.png";

const requiredConfirmPassword = required("VALIDATION.REQ_CONFIRM_PASSWORD");
const requiredPassword = required("VALIDATION.REQ_PASSWORD");
// const requiredIterationCount = required("VALIDATION.REQ_ITRATION_COUNT");

const onSubmit = async (values, dispatch, props) => {
}

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.isExactPassword = false;
        this.isStrongPassword = false;
        let urlParams = new URLSearchParams(window.location.search);
        this.token = urlParams.get('token')
        EncryptionServices.decodeJwtToken(this.token).then(decVal => {
            this.userData = decVal
        });
        this.state = {redirectToReferrer: false,
          showPrivateKeyDialog: false,
          showConfirmPassword: true,
          showPassword: true
        };
    }

    componentDidMount() {
        document.title = "Therame Dashboard - Change Password";
    }

    validatePassword = event => {
        this.isStrongPassword = false;
        const password = event.target.value;

        // Create an array and push all possible values that you want in password
        // Special Character, Uppercase alphabets, Lowercase alphabets, Numbers
        let matchedCase = ["[$@$!%*#?&]","[A-Z]","[0-9]","[a-z]"];

        // Check the conditions
        let ctr = 0;
        for (let i = 0; i < matchedCase.length; i++) {
            if (new RegExp(matchedCase[i]).test(password)) {
                ctr++;
            }
        }
        if ( password && (ctr === 3 || password.length < 12) ) {
            this.isStrongPassword = true;
        }
    }

    submitSetPassword = event => {
        const {newpassword, confirmpassword} = this.props.formValues;
        if (newpassword === confirmpassword) {
            if (CheckIfNotEmpty(this.userData.email) && CheckIfNotEmpty(newpassword) ) {
                EncryptionServices.generateCredential(this.userData.email, newpassword).then(
                    credentials => {
                        const tokenData = {token: this.token}
                        // const passwordData = {...tokenData, ...credentials}
                        const passwordData = {...tokenData, ...{ email: this.userData.email, password: newpassword}}
                        changePassword(passwordData).then(res => {
                            const data = res;
                            if (data &&  data.data && data.data.status === 200) {
                                this.props.history.push('/login');
                                toasterInstance(<Trans>{"API." + data.data.message}</Trans>, 2, 'successChangePassword');
                            } else {
                                if (res && res.response) {
                                    if (typeof (res.response.errors) === "string") {
                                        toasterInstance(
                                            <Trans>{"API." + res.response.errors}</Trans>, 4, 'failedClient');
                                    } else {
                                        res.data.response.errors.forEach(innerData => {
                                            toasterInstance(<Trans>{"API." + innerData.msg}</Trans>, 4, 'failedClietn');
                                        })
                                    }
                                }
                            }
                        });
                    }
                );
            } else {
                event.target.newpassword.classList.add('warning');
                event.target.confirmpassword.classList.add('warning');
                // event.target.iterationCount.classList.add('warning');
                toasterInstance(<Trans>TOASTER.SET_PASSWORD_DATA</Trans>, 4, 'blankChangePassword');
            }
        } else {
            toasterInstance(<Trans>TOASTER.SET_PASSWORD_DATA</Trans>, 4, 'blankChangePassword');
        }

    };

    comparePassword = (event) => {
        const match = event.target.value;
        this.isExactPassword = false;
        if (this.props.formValues && match && this.props.formValues.newpassword !== match) {
            this.isExactPassword = true;
        }
    }

    render() {
        const { classes, ...other } = this.props;
        let {from} = this.props.location.state || {from: ROUTE.ROOT};
        let {redirectToReferrer} = this.state;
        if (redirectToReferrer || AuthService.isLoggedIn()) return <Redirect to={from}/>;

        if(this.props.expired && (this.props.type).trim() === 'set-request'){
            this.props.history.push('/login');
        }
        if(this.props.expired && (this.props.type).trim() === 'reset-request'){
            return <Route path={ROUTE.CHANGE_PASSWD} component={LinkExpired}/>
        }

        return (

      <div className={`${classes.loginScreen} loginPage`}>
      <div className={classes.login}>
        <div className={classes.loginLeft}>
          <div className={classes.headi} style={{ display: 'flex' }}>
            <Grid className={classes.divset}>
              <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
              <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo" />
            </Grid>
            <div className={classes.btnset}>
              <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
              {this.props.theme.mode == 'light' ? <img width="35" src={lightTheme} alt="theme"/> : <img width="35" src={darkTheme} alt="theme"/>}

              </Button>
              {/* <Button className={classes.createbtn} type="button">
                Create an Account
              </Button> */}
            </div>
          </div>

          <div style={{ display: 'flex',height:"74vh" }} className={classes.divMain}>
            <div className="rightLogo">
              <img src={userIcon} alt="" />
              <div>
                <p>
                Thera<span>Me</span>
                </p>
                <span className={classes.heading}>Therame Dashboard</span>
                {/* <span className={classes.heading}>Login To Your Account</span> */}
              </div>
            </div>

            <form
                        name="loginForm"
                        className={classes.loginRight}
                        onSubmit={this.props.handleSubmit(this.submitSetPassword)}
                        noValidate
                        autoComplete="off">
                        <div className={classes.mobileBackgroundImage} />

                        {/* <Grid container spacing={1} alignItems="flex-end" className={classes.marginBottom}> */}
                           <div className={classes.forEachQ}>
                            <Grid item className={classes.inputIcon + " "+ classes.helpIconOnLogin}>
                              <span style={{marginRight:2}}>
                                 {this.state.showPassword ? <LockOpenIcon  onClick={() => this.setState({ showPassword: !this.state.showPassword })}/> : <LockIcon onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}
                              </span>
                            <HelpOutlineIcon/>
                            <div className='loginPasswordHelpIcon'>{this.props.t('VALIDATION.PASSWORD_HELP')}<span /></div>

                            </Grid>
                            <Field
                                validate={[requiredPassword]}
                                component={renderTextField}
                                id="newpassword"
                                name="newpassword"
                                type= {this.state.showPassword ? "password": "text"}
                                placeholder="Password"
                                label={<Trans>LOGIN.NPASS</Trans>}
                                classes={classes}
                                onChange={(e) => {
                                    this.validatePassword(e)
                                }}
                            />
                            {this.isStrongPassword ?
                                <label className={classes.errorMessage}>Please enter strong password of at least 12 characters</label> : ''}
                        </div>
                            <Grid item className={classes.helpIconOnLogin}>
                              </Grid>

                        {/* </Grid> */}
                        <div className={classes.forEachQ}>
                            <Grid item className={classes.inputIcon}>
                    {this.state.showConfirmPassword ? <LockOpenIcon  onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}/> : <LockIcon onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} />}
                    </Grid>
                            <Field
                                    validate={[requiredConfirmPassword]}
                                    component={renderTextField}
                                    id="confirmpassword"
                                    name="confirmpassword"
                                    type= {this.state.showConfirmPassword ? "password": "text"}
                                    placeholder="Confirm Password"
                                    label={<Trans>LOGIN.CPASS</Trans>}
                                    classes={classes}
                                    onChange={(e) => {
                                        this.comparePassword(e)
                                    }}
                                />
                        </div>
                        {/* <Grid container spacing={1} alignItems="flex-end" className={classes.marginBottom}>
                            <Grid item className={classes.inputIcon}><Lock/></Grid>
                            <Grid item className={classes.formInput}>
                                <Field
                                    validate={[requiredConfirmPassword]}
                                    component={renderTextField}
                                    id="confirmpassword"
                                    name="confirmpassword"
                                    type="password"
                                    label={<Trans>LOGIN.CPASS</Trans>}
                                    classes={classes}
                                    onChange={(e) => {
                                        this.comparePassword(e)
                                    }}
                                />
                            </Grid>
                            {this.isExactPassword ?
                                <label className={classes.errorMessage}>Password do not match</label> : ''}
                        </Grid> */}
                         {/* <div className={classes.forEachQ}>
                            <Grid item className={classes.inputIcon}>
                            <Autorenew/>
                            </Grid>
                            <Field
                                    validate={[requiredIterationCount]}
                                    component={renderTextField}
                                    id="iterationCount"
                                    name="iterationCount"
                                    autoComplete="on"
                                    type="text"
                                    placeholder="Iteration Count"
                                    label={<Trans>LOGIN.ITC</Trans>}
                                    classes={classes}
                                />
                        </div> */}
                        {/* <Grid container spacing={1} alignItems="flex-end" className={classes.marginBottom}>
                            <Grid item className={classes.inputIcon}><Autorenew/></Grid>
                            <Grid item className={classes.formInput}>
                                <Field
                                    validate={[requiredIterationCount]}
                                    component={renderTextField}
                                    id="iterationCount"
                                    name="iterationCount"
                                    autoComplete="on"
                                    type="text"
                                    label={<Trans>LOGIN.ITC</Trans>}
                                    classes={classes}
                                />
                            </Grid>
                        </Grid> */}

                        <Grid container justifyContent="flex-end"
                              className={classes.linkCss + ' ' + classes.registerUser + ' ' + classes.loginUser}>

                            <Button type="submit"
                                    disabled={this.props.pristine || !this.props.valid || this.props.submitting ||this.isExactPassword || this.isStrongPassword}
                                    className={classes.activeButton}>
                                <Trans>SUBMIT</Trans>
                                <img src={LoginBackButton} alt="" />
                            </Button>
                        </Grid>
                        <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.linkCss + ' ' + classes.forgotPassword}
                  >
                        <Trans>REMEMBER_YOUR_PASSWORD</Trans>
                                <Link component={RouterLink} to="/login">
                                    <span className={classes.linkText} ><Trans>LOGIN.BTN</Trans></span>
                                </Link>
                                <Grid item xs={12}>
                                                <img src={headerLogo} className={classes.footerLogo} alt="header logo"/>

                                            </Grid>
                  </Grid>
                        {/* <div className={classes.registerButton}>
                                <Trans>REMEMBER_YOUR_PASSWORD</Trans>
                                <Link component={RouterLink} to="/login">
                                    <span className={classes.linkText} ><Trans>LOGIN.BTN</Trans></span>
                                </Link>

                            </div> */}
                        {/* <span className={classes.copyright}>&copy; {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.</span> */}
                    </form>

              {/* <form
                name="loginForm"
                className={classes.loginRight}
                onSubmit={this.props.handleSubmit(this.submitLogin)}
                noValidate
                autoComplete="off"
              >
                <div className={classes.mobileBackgroundImage} />
                <CustomMessages {...other} />
                <div className={classes.forEachQ}>
                  <Grid item className={classes.inputIcon}>
                    <Mail />
                  </Grid>
                  <Field
                    validate={[requiredUserName, email]}
                    component={renderTextField}
                    id="name"
                    name="userName"
                    autoComplete="on"
                    type="email"
                    label="Email"
                    classes={classes}
                    placeholder="Email"
                  />
                </div>
                <div className={classes.forEachQ}>
                  <Grid item className={classes.inputIcon}>
                    <Lock />
                  </Grid>
                  <Field
                    validate={[requiredPassword]}
                    component={renderTextField}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    classes={classes}
                  />
                </div>

                <div className={classes.forEachQ}>
                  <Grid item className={classes.inputIcon}>
                    <Autorenew />
                  </Grid>
                  <Field
                    validate={[requiredIterationCount]}
                    component={renderTextField}
                    id="iterationCount"
                    name="iterationCount"
                    autoComplete="on"
                    type="text"
                    placeholder="Iteration Count"
                    classes={classes}
                  />
                </div>

                <Grid
                  container
                  justifyContent="flex-end"
                  className={
                    classes.linkCss + ' ' + classes.registerUser + ' ' + classes.loginUser
                  }
                >
                  <Button
                    type="submit"
                    disabled={this.props.pristine || !this.props.valid || this.props.submitting}
                    className={classes.activeButton}
                  >
                    <Trans>LOGIN.BTN</Trans>
                    <img src={LoginBackButton} alt="" />
                  </Button>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes.linkCss + ' ' + classes.forgotPassword}
                >
                  <Link component={RouterLink} to="/forgot-password">
                    {<Trans>FORGOT_YOUR_PASSWORD</Trans>}
                  </Link>
                </Grid>
              </form> */}

          </div>
          <div className={classes.footi}>
            <span className={classes.copyright}>
              {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </div>

        );
    }
}

const mapStateToProps = state => ({
    formValues: getFormValues("changePassword")(state)
});

const stylesheets = reduxForm({
    form: "changePassword",
    onSubmit
})(withStyles(styles, {withTheme: true})(ChangePassword));
export default withTranslation()(
    connect(
        mapStateToProps
    )(stylesheets)
);
