import React from "react";

const ManageCompanyIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 512 512" id="Layer_1" version="1.1"
         viewBox="0 0 512 512">
        <g>
            <g>
                <g>
                    <path
                        d="M181.4,358.7h-19.2v0h-15.7v0h-19.2c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h54     c8.7,0,15.8-7.1,15.8-15.8v-25.1C197.2,365.7,190.1,358.7,181.4,358.7z"/>
                </g>
                <g>
                    <path
                        d="M79.8,358.7H60.6v0H45v0H25.8c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h54     c8.7,0,15.8-7.1,15.8-15.8v-25.1C95.6,365.7,88.5,358.7,79.8,358.7z"/>
                </g>
                <g>
                    <path
                        d="M283,358.7h-19.2v0h-15.7v0H229c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h54     c8.7,0,15.8-7.1,15.8-15.8v-25.1C298.8,365.7,291.7,358.7,283,358.7z"/>
                </g>
                <g>
                    <path
                        d="M486.2,358.7H467v0h-15.7v0h-19.2c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h54     c8.7,0,15.8-7.1,15.8-15.8v-25.1C502,365.7,494.9,358.7,486.2,358.7z"/>
                </g>
                <g>
                    <path
                        d="M384.6,358.7h-19.2v0h-15.7v0h-19.2c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h54     c8.7,0,15.8-7.1,15.8-15.8v-25.1C400.4,365.7,393.3,358.7,384.6,358.7z"/>
                </g>
            </g>
            <g>
                <path
                    d="M162.2,215v-17.5h85.9V215h15.7v-17.5h85.9V215h15.7v-25.4c0-4.3-3.5-7.8-7.8-7.8h-93.8v-28.4H283    c8.7,0,15.8-7.1,15.8-15.8v-25.1c0-8.7-7.1-15.8-15.8-15.8h-54c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h19.2    v28.4h-93.8c-4.3,0-7.8,3.5-7.8,7.8V215H162.2z"/>
            </g>
            <g>
                <path
                    d="M181.4,228.8h-19.2v0h-15.7v0h-19.2c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h19.2v30.2    H52.8c-4.3,0-7.8,3.5-7.8,7.8v21.5h15.7v-13.6h85.9v13.6h15.7v-59.5h19.2c8.7,0,15.8-7.1,15.8-15.8v-25.1    C197.2,235.9,190.1,228.8,181.4,228.8z"/>
            </g>
            <g>
                <path
                    d="M459.2,315.6h-93.8v-30.2h19.2c8.7,0,15.8-7.1,15.8-15.8v-25.1c0-8.7-7.1-15.8-15.8-15.8h-19.2v0h-15.7v0    h-19.2c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h19.2v30.2H256c-4.3,0-7.8,3.5-7.8,7.8v21.5h15.7v-13.6h85.9v13.6    h15.7v-13.6h85.9v13.6H467v-21.5C467,319.1,463.5,315.6,459.2,315.6z"/>
            </g>
            <g>
                <path
                    d="M162.2,215v-17.5h85.9V215h15.7v-17.5h85.9V215h15.7v-25.4c0-4.3-3.5-7.8-7.8-7.8h-93.8v-28.4H283    c8.7,0,15.8-7.1,15.8-15.8v-25.1c0-8.7-7.1-15.8-15.8-15.8h-54c-8.7,0-15.8,7.1-15.8,15.8v25.1c0,8.7,7.1,15.8,15.8,15.8h19.2    v28.4h-93.8c-4.3,0-7.8,3.5-7.8,7.8V215H162.2z"/>
            </g>
            <g>
                <path
                    d="M298.8,269.6v-25.1c0-8.7-7.1-15.8-15.8-15.8h-19.2v0h-15.7v0H229c-8.7,0-15.8,7.1-15.8,15.8v25.1    c0,8.7,7.1,15.8,15.8,15.8h54C291.7,285.4,298.8,278.3,298.8,269.6z"
                    fill="#00816f"/>
            </g>
        </g>
    </svg>
);

export default ManageCompanyIcon;
