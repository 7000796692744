import {httpservice} from '../services';
import {constants} from '../constant/constant';

export const updateLicenseInstance = (updateData) => {
    return httpservice()
        .put(`${constants.API.UPDATE_LICENSE_INSTANCE}`, updateData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const deleteLicenseInstance = (id) => {
    return httpservice()
        .delete(`${constants.API.DELETE_LICENSE_INSTANCE}?id=${id}`)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        })
}

export const getSingleLicenseInstance = (id) => dispatch => {
    let query = `${constants.API.GET_LICENSE_INSTANCE}/${id}`;
    dispatch({
        payload: [],
        type: 'GET_SINGLE_LICENSE_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (licenseData) {
            const rows = licenseData.data;
            const getData = rows.map(data => {
                data.status = data.status.charAt(0).toUpperCase() + data.status.slice(1);
                return data;
            });
            dispatch({
                payload: { data: getData },
                type: 'GET_SINGLE_LICENSE_REQUEST_SUCCESS'
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_SINGLE_LICENSE_REQUEST_ERROR'
            });
        });
}