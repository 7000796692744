import React from "react";

const SecuritySettingIcon = () => (
    <svg className="MuiSvgIcon-root" viewBox="0 0 24 24">
        <g data-name="Firewall Settings" id="Firewall_Settings">
            <path
                d="M11.79,21.59a.5.5,0,0,0-.74-.35l-.82.45a.51.51,0,0,1-.49,0,.5.5,0,0,1-.24-.43V13a.5.5,0,0,0-.5-.5H3.55A.5.5,0,0,1,3,12c0-.76,0-1.59,0-2.52V4.92a.5.5,0,0,1,.33-.47l6.5-2.29a.5.5,0,0,1,.67.47V11a.5.5,0,0,0,.5.5h5.45a.5.5,0,0,1,.35.14l.06.12a.5.5,0,0,0,.43.25h1.17a.5.5,0,0,0,.5-.48c0-.64,0-1.3,0-2v-6A.5.5,0,0,0,18.67,3l-8.5-3a.5.5,0,0,0-.33,0L1.33,3A.5.5,0,0,0,1,3.5v6c0,7.76,1.15,10.6,8.77,14.45A.5.5,0,0,0,10,24a.5.5,0,0,0,.22-.05c.63-.32,1.2-.62,1.74-.93a.5.5,0,0,0,.14-.75A1.47,1.47,0,0,1,11.79,21.59Z"/>
            <path
                d="M22.62,17l-1-.24.51-.85a.5.5,0,0,0-.08-.61L20.68,13.9a.5.5,0,0,0-.61-.08l-.85.51-.24-1A.5.5,0,0,0,18.5,13h-2a.5.5,0,0,0-.49.38l-.24,1-.85-.51a.5.5,0,0,0-.61.08L12.9,15.32a.5.5,0,0,0-.08.61l.51.85-1,.24a.5.5,0,0,0-.38.48v2a.5.5,0,0,0,.38.48l1,.24-.51.85a.5.5,0,0,0,.08.61l1.41,1.41a.5.5,0,0,0,.61.08l.85-.51.24,1a.5.5,0,0,0,.49.38h2a.5.5,0,0,0,.49-.38l.24-1,.85.51a.5.5,0,0,0,.61-.08l1.41-1.41a.5.5,0,0,0,.08-.61l-.51-.85,1-.24A.5.5,0,0,0,23,19.5v-2A.5.5,0,0,0,22.62,17ZM20,18.5A2.5,2.5,0,1,1,17.5,16,2.5,2.5,0,0,1,20,18.5Z"/>
        </g>
    </svg>
);

export default SecuritySettingIcon;
