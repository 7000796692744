import React from "react";

const LicenseManagementIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 64 64" width="512">
    <path d="m14 8h6v2h-6z"/>
    <path d="m22 8h8v2h-8z"/>
    <path d="m14 12h16v2h-16z"/>
    <path d="m22 18h18v2h-18z"/>
    <path d="m26 22h20v2h-20z"/>
    <path d="m42 18h4v2h-4z"/>
    <path d="m30 26h14v2h-14z"/>
    <path
      d="m62.192 14.437a3 3 0 0 0 -5.412-1.2l-4.036 5.763h-.744v-14a3 3 0 0 0 -3-3h-38a3 3 0 0 0 -3 3v1h-3a3 3 0 0 0 -3 3v50a3 3 0 0 0 3 3h50a3 3 0 0 0 3-3v-37c0-.014 0-.026 0-.04l3.7-5.282a2.978 2.978 0 0 0 .492-2.241zm-22.784 30.583-1.638-1.147 15.486-22.117 1.638 1.144zm-2.741.523 1.549 1.085-2.4 1.782zm17.733-25.425 1.15-1.638 1.638 1.146-1.147 1.639zm-14.4-16.118h4v6l-1.2-1.6a1.036 1.036 0 0 0 -1.6 0l-1.2 1.6zm-29 0h27v9a1 1 0 0 0 1.8.6l2.2-2.933 2.2 2.933a1 1 0 0 0 .8.4 1.019 1.019 0 0 0 .316-.051 1 1 0 0 0 .684-.949v-9h3a1 1 0 0 1 1 1v17.919l-5.658 8.081h-13.928l-12.707-12.707a1 1 0 0 0 -.707-.293h-7v-13a1 1 0 0 1 1-1zm-6 4h3v10h-4v-9a1 1 0 0 1 1-1zm51 51a1 1 0 0 1 -1 1h-50a1 1 0 0 1 -1-1v-39h12.586l12.707 12.707a1 1 0 0 0 .707.293h12.941l-7.957 11.364a.989.989 0 0 0 -.139.289l-1.8 6.062a1 1 0 0 0 1.555 1.085l5.079-3.767a1 1 0 0 0 .224-.23l10.363-14.803h5.734zm0-28h-4v-.476l4-5.713zm4.056-15.469-1.72 2.457-1.636-1.147 1.72-2.458a1 1 0 0 1 1.8.4.992.992 0 0 1 -.164.748z"/>
    <path d="m19 42a3.005 3.005 0 0 1 2.25 1.016l1.5-1.323a5 5 0 1 0 0 6.614l-1.5-1.323a3 3 0 1 1 -2.25-4.984z"/>
    <path d="m19 36a9 9 0 1 0 9 9 9.011 9.011 0 0 0 -9-9zm0 16a7 7 0 1 1 7-7 7.008 7.008 0 0 1 -7 7z"/>
  </svg>
);

export default LicenseManagementIcon;
