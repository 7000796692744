
/* jshint esversion: 6 */

import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/styles';
import styles from '../../../assets/jss/FormStyle';
import GridContainer from './../../../assets/jss/GridContainer';
import {Trans, withTranslation} from 'react-i18next';
import CModal from '../../Utils/CModal';
import {PriorityHigh} from '@material-ui/icons';
import SettingsIcon from "../../../helper/subMneuIcon/SettingsIcon";
class U2fInsertDeviceModal extends Component {

    render() {
        const {classes, id, open} = this.props;
        return (
            <React.Fragment key={id}>
                <CModal
                    title={'SETTINGS.U2F_VERIFY'}
                    open={open}
                    crossIcon={true}
                    handleClose={this.props.closeModel}
                    id={id}
                    icon={<SettingsIcon />}
                >
                    <GridContainer className={classes.extraTopMargin}>
                        <Grid item xs={12} sm={12} className={classes.insertDriveModal}>
                            <p>
                                <span><PriorityHigh/></span> <Trans>INFO.U2F_TEXT</Trans>
                            </p>
                        </Grid>
                    </GridContainer>
                </CModal>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(U2fInsertDeviceModal));
