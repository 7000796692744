
import {httpservice} from '../services';
import {constants} from "../constant/constant";
import { DASHBOARD } from "../constant/actionTypes";
let apiEndPoint = constants.ADMIN_URL;

export default (therapistId,object) => dispatch => {
    return httpservice()
        .get(`${apiEndPoint}${constants.API.THERAPISTS_USERS_COUNT}?therapistId=${therapistId}`)
        .then(response => {
            const resp = response.data;
            const count = resp.length;
            dispatch({
                payload: count,
                type: DASHBOARD.USER.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: DASHBOARD.USER.ERROR
            });
        })
};
