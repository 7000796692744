
import React from 'react'

export default () => (

    <svg version="1.1" id="svg8" svg="http://www.w3.org/2000/svg"
        x="0px" y="0px" width="512px" height="512px"
        viewBox="0 0 512 512">
        <g>
            <path d="M223.565,191.136c-5.971,0-10.812,4.841-10.812,10.812v75.685c0,5.97,4.841,10.813,10.812,10.813
            c5.97,0,10.811-4.843,10.811-10.813v-75.685C234.376,195.977,229.534,191.136,223.565,191.136z"/>
            <path d="M277.625,191.136h-21.625c-5.971,0-10.813,4.841-10.813,10.812v75.685c0,5.97,4.841,10.813,10.813,10.813
            s10.811-4.843,10.811-10.813v-21.625h10.813c17.914,0,32.435-14.523,32.435-32.436C310.06,205.659,295.539,191.136,277.625,191.136
            z M277.625,234.385h-10.813V212.76h10.813c5.97,0,10.812,4.841,10.812,10.812C288.437,229.543,283.595,234.385,277.625,234.385z"/>
            <path d="M497.725,84.578h-231.31c-3.441-0.239-6.912-0.373-10.415-0.376c-3.502,0.003-6.973,0.137-10.415,0.376H14.276
            C8.694,84.579,4.17,89.103,4.169,94.685v323.443c0.022,5.566,4.541,10.067,10.107,10.068H254.93h2.14h240.654
            c5.566-0.001,10.085-4.502,10.107-10.068V94.685C507.831,89.103,503.307,84.579,497.725,84.578z M386.554,104.803v55.275
            c-13.078-22.861-31.983-41.957-54.688-55.275H386.554z M256.001,404.69c-24.51-14.523-128.856-81.616-128.856-170.158
            c0-71.166,57.69-128.855,128.856-128.855c71.164,0,128.855,57.689,128.855,128.855C384.856,322.927,280.483,390.14,256.001,404.69z
            M106.196,246.295H85.008v-60.654h28.846c-5.283,15.328-8.166,31.773-8.185,48.892C105.67,238.501,105.861,242.419,106.196,246.295
            z M125.447,307.791c3.629,6.688,7.645,13.142,11.943,19.354h-11.943V307.791z M386.554,307.792v19.353h-11.941
            C378.909,320.932,382.924,314.478,386.554,307.792z M398.146,185.641h28.847v60.654h-21.188c0.334-3.876,0.525-7.794,0.525-11.762
            C406.312,217.414,403.429,200.969,398.146,185.641z M180.135,104.803c-22.705,13.318-41.609,32.414-54.688,55.275v-55.275H180.135z
            M24.384,104.803h80.838v60.614H24.384V104.803z M24.384,185.641h40.399v60.654H24.384V185.641z M24.384,266.52h80.838v60.624
            H24.384V266.52z M64.783,408.022H24.384v-60.664h40.399V408.022z M85.008,408.022v-60.664h67.976
            c10.578,12.395,21.974,23.534,33.126,33.275v27.389H85.008z M206.285,397.074c5.304,4.04,10.371,7.694,15.068,10.948h-15.068
            V397.074z M290.656,408.022c4.694-3.252,9.758-6.904,15.059-10.942v10.942H290.656z M426.992,408.022H325.891v-27.385
            c11.152-9.742,22.549-20.884,33.128-33.279h67.974V408.022z M487.617,408.022h-40.399v-60.664h40.399V408.022z M487.617,327.144
            h-80.839V266.52h80.839V327.144z M487.617,246.295h-40.399v-60.654h40.399V246.295z M487.617,165.417h-80.839v-60.614h80.839
            V165.417z"/>
        </g>
    </svg>
)