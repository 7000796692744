import React from "react";

const ManageProjectIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 72 72" id="Layer_1" version="1.1" viewBox="0 0 72 72">
        <path
            d="M63.7,8L32.6,8l-3-4.6c0,0,0-0.1-0.1-0.1C28.2,1.8,26.4,1,24.4,1H3.1C1.9,1,1,1.9,1,3.1v45.4    c0,1.8,1.5,3.2,3.3,3.2h19.1c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H4.3c-0.7,0-1.3-0.5-1.3-1.2V19.7h62v28.8c0,0.7-0.6,1.2-1.3,1.2    h-1.8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h1.8c1.8,0,3.3-1.4,3.3-3.2V11.3C67,10,65.6,8,63.7,8z M3,17.7V3.1C3,3,3,3,3.1,3h21.3    c1.4,0,2.7,0.6,3.5,1.6l3.3,5C31.4,9.9,31.7,10,32,10h31.6c0.7,0,1.3,0.9,1.3,1.2v6.5H3z"/>
        <path
            d="M51.3,42.8L62.1,33c0.7-0.6,1.1-1.4,1.1-2.3c0-0.9-0.3-1.8-0.9-2.4l-2.8-3.1c-1.3-1.4-3.4-1.5-4.8-0.2    L50.1,29c0,0-0.1,0-0.1,0.1L43.5,35L32.3,23.6c-0.4-0.4-1-0.4-1.4,0L20.5,33.8c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7    l10.8,10.9l-7.4,6.8c0,0,0,0,0,0c0,0-0.2,0.2-0.2,0.2l-7,12.4c-0.2,0.4-0.2,0.8,0.1,1.2c0.3,0.3,0.8,0.4,1.1,0.2l12.9-5.7    c0.1,0,0.3-0.1,0.4-0.2c0,0,0,0,0,0c0,0,0,0,0,0l7.8-7.1c0.7,0.7,12.4,12.6,12.4,12.6c0.2,0.2,0.5,0.3,0.7,0.3    c0.3,0,0.5-0.1,0.7-0.3l10.4-10.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L51.3,42.8z M48.1,39.6l5.6-5l1.7,1.8l-5.5,5    L48.1,39.6z M56,26.4c0.6-0.5,1.4-0.5,2,0.1l2.8,3.1c0.2,0.3,0.4,0.6,0.4,1c0,0.4-0.2,0.7-0.4,1l-3.9,3.5l-2.3-2.6c0,0,0,0,0,0    c0,0,0,0,0,0l-2.3-2.6L56,26.4z M50.6,31.3l1.7,1.8l-5.6,5.1L45,36.4L50.6,31.3z M25.9,53.7l6.8-6.2l1.7,1.7l-6.9,6.2L25.9,53.7z     M24.7,55.3l4.2,4.6l-9,4L24.7,55.3z M30.6,58.8l-1.8-2l6.9-6.2l1.8,1.9L30.6,58.8z M52.2,64.4l-2.5-2.5l5.8-5.7    c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.8,5.7L45.8,58l3.2-3.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-3.2,3.2L42,54.1    l5.8-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.8,5.7l-2.4-2.5l3.2-3.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-3.2,3.2    l-2.4-2.5l5.8-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.8,5.7l-2.4-2.5l3.2-3.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0    L29,41l-2.4-2.5l5.8-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.8,5.7l-2.5-2.6l8.9-8.8l17.5,17.8h0h0l12,12.2L52.2,64.4z"/>
        <path d="M6.8,28.2h15.7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H6.8c-0.6,0-1,0.4-1,1C5.8,27.7,6.3,28.2,6.8,28.2z"/>
        <path d="M6.8,36.1h10.7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H6.8c-0.6,0-1,0.4-1,1C5.8,35.7,6.3,36.1,6.8,36.1z"/>
        <path d="M6.8,44.1h15.7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H6.8c-0.6,0-1,0.4-1,1C5.8,43.6,6.3,44.1,6.8,44.1z"/>
    </svg>
);

export default ManageProjectIcon;
