
import { SOFTWARE } from "../constant/actionTypes";

export default (state, action) => {
    switch (action.type) {
        case SOFTWARE.DATA.INITIATE:
            return {isFetching: true};
        case SOFTWARE.DATA.SUCCESS:
            return {isFetching: false, data: action.payload};
        case SOFTWARE.DATA.ERROR:
            return {isFetching: false, data: action.payload};
        default:
            return state ? state : [];
    }
};


