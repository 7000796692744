
/* jshint esversion: 6 */
export default {
    light: {
        mode:"light",
        overrides: {
            MuiTooltip: {
                tooltip: {
                  backgroundColor: "#000 !important"
                }
            }
         },
        menu: 280,
        logo: 0,
        abcd: 0,
        palette: {
            type: 'light',
            primary: {
                contrastText: '#fafafa',
                color: '#2AA9E0',
                dark: '#ebebeb',
                light: '#f3f3f3',
                main: '#c4c4c4',
                popup: '#a6a6a6',
                searchBorder: 'rgba(0,0,0,0.42)'
            },
            loaderPrimary: {
                color: "#ebebeb",
              },
              loaderSecondary: {
                color: "#f3f3f3",
              },
            secondary: {
                contrastText: '#1c1c1c',
                dark: '#161616',
                light: '#e0e0e0',
                main: '#161616',
            },
            background: {
                default: '#fafafa',
                paper: "#fff",
            },
            modalTextColor:{
                color:'#000'
            },
            sidebarBackground:{
                color:'#fff'
            },
            contentBackground:{
                color:'#ededed'
            },
            disableOpacity:{
                opacity:0.2
            },
            actionBtnBorder:{
                color:'#000'
            },
            tableBg:{
                color:'#fff'
            },
            iconLabelBg: {
                color: '#a6a6a6'
            },
            iconLabelBgDisabled: {
                color: '#fafafa'
            },
            iconLabelTextDisabled: {
                color: '#000;'
            },
            profileEditBg: {
                color: '#ffffff'
            },
            profileSectionSidebar: {
                color: '#ebebeb'
            },
            profileSectionSidebarContent: {
                color: '#fff'
            },
            backArrowBtn: {
                color: '#fff'
            },
            headerBgColor:{
                color:'#fff'
            },
            sidebarBtn:{
                color:'#E0E0E0'
            },
            mainLayout:{
                color:'#f7f8f9'
            },
            mainTableLayoutBg:{
                color:'#fff'
            },
            mainTableBorder:{
                color:'#ebebeb'
            },
            tableBorderTransparent:{
                color:'#d0d1d2'
            },
            modalsContentBg:{
                color:'#fff'
            },
            activeButton:{
                color:'#242A30'
            },
            headerIcons:{
                color:'#f9f9f9'
            },
            platform:{
                color:'#fff'
            },
            counterBorder:{
                color:'#dadee4'
            },
            modalHeaderBg: {
                color: '#161616'
            },
            mainTableLayout:{
                color:'#fff'
            },
            avatarBg:{
                color:'#ddd'
            },
            sidebarIconBg:{
                color:'#494962'
            },
            activeDropdown:{
                color:'#c4c4c4'
            },
            activeDropdownButton:{
                color:'#afafaf'
            },
            dropdownHover:{
                color:'rgba(255, 255, 255, 0.04)'
            },
            headerBorder:{
                color:'rgba(163,163,175,.35)'
            },
            sidebarBg:{
                color:'#fff'
            },
            activeMenuBg:{
                color:'rgba(37,39,53,.1)'
            },
            borderDark:{
                color:'#dedede'
            },
            tableTbodyBg:{
                color:'#fff'
            },
            defaultBtnShadow:{
                boxShadow:'0 0 10px rgb(0 0 0 / 10%), 0 0 5px rgb(0 0 0 / 10%)'
            },
            invert:{
                invert:'unset'
            },
            profileLogo:{
                color:'#eee'
            },
            login:{
                background:{
                    backgroundColor: "#e5e5e5",
                    backgroundImage:`url(${require("./../images/bg.png").default})`,
                    borderColor:'#2AA9E075'
                },
                rightLogo:{
                    textColor:'#1c2029',
                },
                activeButton:{
                    backgroundColor:"#2AA9E0",
                    color: "#ffff",
                },
                inputIcon:{
                    svgColor: '#2AA9E0',
                    color: 'green!important',
                },
                forgotPassword:{
                    color:'#1c2029d6',
                },
                copyright:{
                    color: '#1c2029',
                    borderTop:"0.25px solid #2AA9E075",
                },
                marginBottom:{
                    background:" #f3f3f3!important",
                    color:" #c2c2c2",
                },
                formInput:{
                    backgroundColor:"#f3f3f3!important",
                    color:"#555252  !important",
                }
                
            }

        }
    },
    dark: {
        mode:"dark",
        overrides: {
            MuiTooltip: {
                tooltip: {
                  backgroundColor: "#000 !important"
                }
            }
         },
        menu: 280,
        logo: 1,
        palette: {
            type: 'dark',
            primary: {
                contrastText: '#fafafa',
                color: '#2AA9E0',
                dark: '#191919',
                light: '#454545',
                main: '#1f1d2b',
                popup: '#454545',
                searchBorder: 'rgba(255,255,255,0.7)'
            },
            secondary: {
                contrastText: '#fff',
                dark: '#292929',
                light: '#454545',
                main: '#292929'
            },
            background: {
                default: '#191919',
                paper: "#2a2c39",
            },
            modalTextColor:{
                color:'#fff'
            },
            sidebarBackground:{
                color:'#1d1e28'
            },
            contentBackground:{
                color:'#3a3a3a'
            },
            disableOpacity:{
                opacity:0.5
            },
            actionBtnBorder:{
                color:'#2AA9E0'
            },
            tableBg:{
                color:'#2d2f3d'
            },
            iconLabelBg: {
                color: '#292929'
            },
            iconLabelBgDisabled: {
                color: '#484a54'
            },
            iconLabelTextDisabled: {
                color: '#fff'
            },
            profileEditBg: {
                color: '#141414'
            },
            profileSectionSidebarContent: {
                color: '#2a2c39'
            },
            profileSectionSidebar: {
                color: '#1f1d2b'
            },
            backArrowBtn: {
                color: '#01051a'
            },
            headerBgColor:{
                color:'#1d1e28'
            },
            sidebarBtn:{
                color:'#6F6F6F'
            },
            mainLayout:{
                color:'#252735'
            },
            mainTableLayoutBg:{
                color:'#252525'
            },
            mainTableBorder:{
                color:'#242424'
            },
            tableBorderTransparent:{
                color:'#333743'
            },
            modalsContentBg:{
                color:'#1f1d2b'
            },
            activeButton:{
                color:'#2e2e2e'
            },
            headerIcons:{
                color:'#353535'
            },
            platform:{
                color:'#1f1d2b'
            },
            counterBorder:{
                color:'#333743'
            },
            modalHeaderBg: {
                color: '#292929'
            },
            mainTableLayout:{
                color:'#252525'
            },
            avatarBg:{
                color:'#191919'
            },
            sidebarIconBg:{
                color:'#fff'
            },
            activeDropdown:{
                color:'#242424'
            },
            activeDropdownButton:{
                color:'#3e3e3e'
            },
            dropdownHover:{
                color:'rgba(255, 255, 255, 0.08)'
            },
            headerBorder:{
                color:'rgba(163,163,175,.35)'
            },
            sidebarBg:{
                color:'#2a2c39'
            },
            activeMenuBg:{
                color:'rgba(255,255,255,.1)'
            },
            borderDark:{
                color:'#dedede26'
            },
            tableTbodyBg:{
                color:'#2a2c39'
            },
            defaultBtnShadow:{
                boxShadow:'0 4px 10px rgb(0 0 0 / 50%)'
            },
            loaderPrimary: {
                color: "#2a2c39",
            },
            loaderSecondary: {
                color: "rgba(69,69,69,0.7)",
            },
            invert:{
                invert:'invert(1)'
            },
            profileLogo:{
                color:'#2a2c39'
            },
            login:{
                background:{
                    backgroundColor: "#252935",
                    backgroundImage:`url(${require("./../images/bg.png").default})`,
                },
                rightLogo:{
                    textColor:'#fff',
                },
                activeButton:{
                    backgroundColor:"#2AA9E0",
                    color: "#ffff",
                },
                inputIcon:{
                    svgColor: '#2AA9E0',
                    color: 'green!important',
                },
                forgotPassword:{
                    color:'#ffffff',
                },
                copyright:{
                    color: '#fff',
                    borderTop:"0.25px solid #2AA9E075",
                },
                marginBottom:{
                    background:" #1b1f29!important",
                    color:"#1b1f29",
                },
                formInput:{
                    backgroundColor:"#1b1f29!important",
                    color:"#c2c2c2  !important",
                }
                
            }
            
        }
    }
};
