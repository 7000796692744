

import { constants } from "../constant/constant";
import { httpservice } from '../services';
import { SOFTWARE } from '../constant/actionTypes';
import { dateFormat } from "../helper/StringUtils";

export default (object) => dispatch => {
    let query;
    if(object.clientId){
        query = `${constants.API.CLIENT}/${object.clientId}/software/${object.softwareId}/submodule`
    } else {
        query = `${constants.API.SOFTWARE_SUBMODULE}/${object.softwareId}`;
    }
    dispatch({
        payload: [],
        type: SOFTWARE.SUBMODULE_DATA.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            let rows = [];
			if(Array.isArray(response.data.response)){
				rows = response.data.response;
			}else{
				rows = response.data.response.rows;
			}
            const responseData = rows.map(data => {
                if(data.clientId){
                    return({
                        id : data.submoduleId,
                        name : data.submodule.name,
                        description : data.submodule.description,
                        created_at : dateFormat(data.submodule.createdAt),
                        createdBy : data.submodule.creator ? data.submodule.creator['firstName'] + ' ' + data.submodule.creator['lastName'] : 'admin'              
                    })
                }else{
                    data.created_at = dateFormat(data.createdAt);
                    data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'admin';
                    return data;
                }
            });
            dispatch({
                payload: { data: responseData },
                type: SOFTWARE.SUBMODULE_DATA.SUCCESS
            });
            return responseData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: SOFTWARE.SUBMODULE_DATA.ERROR
            });
        })
};


