

import React, { useState, useEffect } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/styles';
import {Grid, Tooltip, Card} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import CButton from "../Utils/CButton";
import CInput from '../Utils/CInput';
import UserChangePasswordPageLoader from '../Utils/PageLoader';
import styles from '../../assets/jss/FormStyle';
import GridContainer from './../../assets/jss/GridContainer';

import {EncryptionServices} from "../../services";
import { CheckIfPasswordIsValid } from '../../helper/StringUtils';
import './../../App.css';

const UserChangePasswordForm = props => { 
    const { classes } = props;
	const [error, setError] = useState({oldPassword: false, newPassword: false, confirmPassword: false, iterationCount: false});
    const [errorCheck, setErrorCheck] = useState({oldPassword: true, newPassword: true, confirmPassword: true, iterationCount: true});
    const [errorMsg, setErrorMsg] = useState({
        oldPassword: "VALIDATION.REQ_FIELD",
		newPassword: "VALIDATION.REQ_FIELD",
		confirmPassword: "VALIDATION.REQ_FIELD",
		iterationCount: "VALIDATION.REQ_FIELD",
		oldIterationCount: "VALIDATION.REQ_FIELD"
    });
    const [errorCount, setErrorCount] = useState(4);
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
	const [checkPassword, setCheckPasssword] = useState(null);
	
	const loginUser = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData : false;
	
	useEffect(() => {
        let err = {...errorCheck};
        let newErrCount = 0;
        Object.keys(err).forEach(function (key) {
            if (err[key]) {
                newErrCount++;
            }
        });
        setErrorCount(newErrCount);
    }, [errorCheck]);
	
    function handleFormSubmit(e) {
		e.preventDefault();
		let oldPassword = e.target.oldPassword.value;
		let newPassword = e.target.newPassword.value;
		let confirmPassword = e.target.confirmPassword.value;
		let iterationCount = e.target.iterationCount.value;
		let oldIterationCount = e.target.oldIterationCount.value;
				
		let data = {};
		EncryptionServices.generateCredential(loginUser.email, oldPassword, oldIterationCount).then(
			credentials => {
				data['oldPassword'] = credentials.password;
			}
		);
		
		EncryptionServices.generateCredential(loginUser.email, newPassword, iterationCount).then(
			credentials => {
				data['newPassword'] = credentials.password;
			}
		);
		EncryptionServices.generateCredential(loginUser.email, confirmPassword, iterationCount).then(
			(credentials) => {
				data['confirmPassword'] = credentials.password;
				data['iterationCount'] = iterationCount;
				props.handleSubmit(data);
			}
		)
    }
	
	function checkError(domElement, boolean, errMsg = null) {
		let err = { ...error };
        let errCheck = { ...errorCheck };
		if(domElement.name === 'confirmPassword'){
            setCheckPasssword(domElement.value)
			err['newPassword'] = false;
			errCheck['newPassword'] = false;
        }
        if(!boolean && domElement.name === 'newPassword'){
            setNewPasswordIsValid(false);
        }else if(boolean && domElement.name === 'newPassword'){
            setNewPasswordIsValid(true);
        }
		if(!boolean && domElement.name === 'newPassword' && checkPassword){
			if(CheckIfPasswordIsValid(domElement.value, checkPassword)){
				boolean = false;
			}else if(domElement.name === 'confirmPassword' && boolean){
				err['newPassword'] = false;
				errCheck['newPassword'] = false;
			}else{
				boolean = true;
				errMsg = <Trans>VALIDATION.NEW_CONFIRM_PASS</Trans>;
			}
        }
        let checkErroNewerrorMsg = { ...errorMsg };
        err[domElement.name] = boolean;
        errCheck[domElement.name] = boolean;
        if (errMsg) {
            checkErroNewerrorMsg[domElement.name] = errMsg;
            setErrorMsg(checkErroNewerrorMsg);
        }
        setError(err);
        setErrorCheck(errCheck);
    }
    return (
        <form name="userChangePasswordForm" onSubmit={handleFormSubmit}
                className={`${classes.headerBar} ${classes.createHeaderBarModal}`}>
            <UserChangePasswordPageLoader isLoading={props.isLoading}/>
            <div className={`${classes.paddingTwoFive} ${classes.createHeaderBarModal}`}>
                <Card className={`${classes.overflowVisible} ${classes.createHeaderBarModal}`}>
                    <Grid item xs={12}>
                        <GridContainer>
                            <div className={classes.freezeFooter}>
                                <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                    <CInput
                                        name={'oldPassword'}
                                        label={<><Trans>LOGIN.OPASS</Trans>*</>}
                                        validateForm={true}
                                        isError={error.oldPassword}
                                        checkError={checkError}
                                        errorVal={errorMsg.oldPassword}
                                    />
									<Tooltip title={props.t('VALIDATION.OLD_PASSWORD_HELP')}
                                        classes={{tooltip: classes.tooltipClass}}>
                                        <HelpOutlineIcon className={classes.helpIcon}/>
                                    </Tooltip>
                                </Grid>
								<Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                    <CInput
                                        name={'oldIterationCount'}
                                        label={<><Trans>LOGIN.OITC</Trans>*</>}
                                        validateForm={true}
                                        isError={error.oldIterationCount}
                                        errorVal={errorMsg.oldIterationCount}
                                        checkError={checkError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                    <CInput
                                        name={'newPassword'}
                                        label={<><Trans>LOGIN.NPASS</Trans>*</>}
                                        validateForm={true}
                                        isError={error.newPassword}
                                        errorVal={errorMsg.newPassword}
                                        checkError={checkError}
                                    />
									<Tooltip title={props.t('VALIDATION.NEW_PASSWORD_HELP')}
                                        classes={{tooltip: classes.tooltipClass}}>
                                        <HelpOutlineIcon className={classes.helpIcon}/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                    <CInput
                                        name={'confirmPassword'}
                                        label={<><Trans>LOGIN.CPASS</Trans>*</>}
                                        validateForm={true}
                                        isError={error.confirmPassword}
                                        errorVal={errorMsg.confirmPassword}
                                        checkError={checkError}
                                        readonly = {newPasswordIsValid}
                                        helperText = {newPasswordIsValid ? <Trans>VALIDATION.ENABLE_C_PASS</Trans> : null}
                                    />
									<Tooltip title={props.t('VALIDATION.CONFIRM_PASSWORD_HELP')}
                                        classes={{tooltip: classes.tooltipClass}}>
                                        <HelpOutlineIcon className={classes.helpIcon}/>
                                    </Tooltip>
                                </Grid>
								<Grid item xs={12} sm={12} md={12} className={classes.marginBottomSpacing}>
                                    <CInput
                                        name={'iterationCount'}
                                        label={<><Trans>LOGIN.NITC</Trans>*</>}
                                        validateForm={true}
                                        isError={error.iterationCount}
                                        errorVal={errorMsg.iterationCount}
                                        checkError={checkError}
                                    />
                                </Grid>
                            </div>
                        </GridContainer>
                    </Grid>
                    <CButton
                        type={'BUTTON.SUBMIT'}
                        onCancel={props.handleClose}
                        disabled={errorCount > 0}
                        isFull={true}
                    />
                </Card>
            </div>
        </form>
    );
};

export default (withTranslation()(withStyles(styles, {withTheme: true})(UserChangePasswordForm)));