

import {constants} from '../constant/constant';
import { httpservice} from '../services';
import moment from 'moment';
import { CLIENT } from '../constant/actionTypes';

export default (clientInfo,newData) => dispatch =>{
    return httpservice()
        .post(`${constants.API.CLIENT}`, clientInfo)
        .then(function (response) {
            const val =response.data.response;
            const newArray = [...newData];
            const data={...clientInfo}
            data.id = val.id.id;
            data.apiKey=val.id.apiKey;
            data.apiKeyValidity = moment(val.id.apiKeyValidity);
            data.created_at =moment().format("DD/MM/YYYY");
            const loginUser = localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData : false;
            data.createdBy = loginUser ? loginUser.firstName + ' ' + loginUser.lastName : '';
            newArray.push(data);
            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: CLIENT.GET.SUCCESS
            })
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};
