

import {CheckIfNotEmpty} from '../helper/StringUtils';

export default {
    isAuthenticated: true,
    isLoggedIn() {
        return this.isAuthenticated && CheckIfNotEmpty(localStorage['authToken']);
    },
    authenticate(token , cred) {
        if (CheckIfNotEmpty(token)) {
            let isUserFind = false ;
            this.isAuthenticated = true;
            let localStorageData = localStorage['authTokenData'] ? JSON.parse(localStorage['authTokenData']) : []
            if(localStorageData.length > 0 && cred){
                localStorageData = localStorageData.map(x => {
                    
                    if(JSON.parse(cred) && (x.cred.userData.email === JSON.parse(cred)['userData']['email'])){
                        isUserFind = true;
                    }
                    x['isActive'] = false; 
                    return x;
                })
            }
            if(!isUserFind){
                localStorageData.push({key:localStorageData.length + 1, cred:JSON.parse(cred),token: token, isActive:true})
            }
            localStorage['authTokenData'] = JSON.stringify(localStorageData);
           
            localStorage.setItem('cred', cred);
            localStorage['authToken'] = token;
        }
    },
    signOut() {
        this.isAuthenticated = false;
        localStorage.removeItem('authToken');
        localStorage.removeItem('cred');
        // sessionStorage.clear();
    },

    checkForClientApp() {
    let appType = process.env.REACT_APP_AUDIENCE_TYPE;
    return appType && (appType.toUpperCase()).trim() === ("CLIENTAPP").trim()
}

};
