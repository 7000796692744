import React from "react";

export default () => (
	<svg
    width="66"
    height="75"
    id="Layer_120"
    viewBox="0 0 66 75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3191 20.4514C38.458 23.0121 43.5298 25.6401 48.8366 27.8975C49.0046 28.2344 49.1725 28.5377 49.3405 28.8746C49.2733 34.0296 49.0046 39.1846 49.2733 44.3396C49.0382 44.6091 48.8031 44.8449 48.5679 45.1145C43.429 47.6751 38.3237 50.3032 33.3191 53.1334C32.9496 53.1334 32.5802 53.1334 32.2107 53.1334C27.1053 50.2695 21.9328 47.473 16.5924 45.0471C16.458 44.7102 16.3573 44.3732 16.2229 44.0363C16.458 39.0161 16.4244 33.9959 16.3237 28.9757C16.3908 28.6387 16.4916 28.2681 16.5588 27.9312C20.1527 26.5498 23.545 24.663 27.0046 23.0121C28.7511 22.2034 30.4641 21.2937 32.1771 20.4177C32.513 20.4514 32.916 20.4514 33.3191 20.4514ZM32.1099 51.5835C32.1099 46.8328 32.1099 42.3517 32.1099 37.8706C32.1099 37.1967 31.9756 36.725 31.3038 36.4218C27.0046 34.3328 22.7389 32.2102 18.4733 30.0538C17.6 29.6158 17.4321 29.8854 17.4656 30.7277C17.4656 34.8045 17.4656 38.915 17.3985 42.9918C17.3985 43.5983 17.5328 44.0363 18.1038 44.3396C22.7389 46.698 27.3069 49.0902 32.1099 51.5835ZM33.2519 51.3476C33.4534 51.3813 33.5878 51.4487 33.655 51.415C38.1893 49.0565 42.7237 46.698 47.258 44.3396C47.6947 44.1037 47.8962 43.8342 47.8962 43.2951C47.8626 38.8476 47.8962 34.4002 47.8962 29.8854C47.6275 29.9191 47.4595 29.8854 47.3252 29.9528C42.8916 32.1428 38.458 34.3328 34.0244 36.4892C33.5206 36.725 33.2519 37.0282 33.2519 37.6347C33.2855 42.2169 33.2519 46.7654 33.2519 51.3476ZM47.1572 28.6387C42.3878 26.314 37.8198 24.1239 33.2855 21.9002C32.6809 21.597 32.2443 21.8665 31.7405 22.1024C28.2809 23.787 24.8214 25.4379 21.3618 27.1226C20.3878 27.5943 19.313 27.9312 18.4397 28.7061C22.9405 30.9299 27.3069 33.1199 31.6733 35.3099C32.3786 35.6805 32.9496 35.6805 33.6886 35.3099C38.0886 33.1199 42.4885 30.9635 47.1572 28.6387Z"
     
    />
    <path
      d="M32.1435 53.1671C32.513 53.1671 32.8824 53.1671 33.2519 53.1671C33.2519 56.5364 33.3191 59.9057 33.2855 63.2412C33.2855 64.1509 33.5206 64.5889 34.4275 64.9259C36.9801 65.8693 38.2901 68.2951 37.8198 70.7884C37.3496 73.1806 35.0992 75.0337 32.6809 75.0337C30.1618 75.0337 28.0122 73.1469 27.542 70.5862C27.1389 68.1941 28.4824 65.8693 31.0015 64.9596C31.9084 64.6226 32.1435 64.2183 32.1435 63.2749C32.0763 59.872 32.1435 56.5364 32.1435 53.1671ZM36.6443 69.7776C36.6107 67.5202 34.8305 65.8019 32.5466 65.8693C30.3633 65.9366 28.7175 67.6887 28.7511 69.8787C28.7847 72.0687 30.5313 73.7197 32.7481 73.7197C34.9313 73.686 36.6779 71.934 36.6443 69.7776Z"
     
    />
    <path
      d="M33.3191 20.4514C32.9161 20.4514 32.5466 20.4514 32.1435 20.4177C32.1435 17.4865 32.1099 14.5889 32.1435 11.6576C32.1435 10.8153 31.9756 10.3773 31.1023 10.0741C28.4825 9.13068 27.0718 6.77219 27.5084 4.34632C27.9787 1.85306 30.229 -0.0337368 32.7481 -4.4065e-05C35.1328 0.0336487 37.2825 1.81936 37.7863 4.14416C38.3237 6.67111 36.9802 9.0633 34.2932 10.0741C33.4199 10.411 33.2519 10.849 33.2519 11.6913C33.3191 14.6226 33.3191 17.5539 33.3191 20.4514ZM32.7145 1.28028C30.4977 1.24659 28.7848 2.93122 28.7512 5.12125C28.7176 7.34497 30.3634 9.0633 32.5466 9.13068C34.797 9.19807 36.6443 7.41235 36.6443 5.22233C36.6443 3.0323 34.8977 1.31397 32.7145 1.28028Z"
     
    />
    <path
      d="M48.5007 45.1482C48.7358 44.8787 48.971 44.6428 49.2061 44.3733C51.1542 45.3167 53.1358 46.2264 55.0503 47.2372C55.89 47.6752 56.3939 47.7089 57.1328 46.9339C58.8458 45.1819 61.1633 44.9461 63.2794 46.1253C65.0595 47.1024 66.2015 49.2587 65.9328 51.1792C65.5969 53.5714 64.0183 55.256 61.6671 55.7614C58.5099 56.4353 55.4198 53.9083 55.6213 50.5728C55.7221 49.1577 55.1847 48.6186 54.0763 48.1132C52.1954 47.1698 50.3816 46.1253 48.5007 45.1482ZM60.7939 54.5485C63.0107 54.5148 64.69 52.8302 64.69 50.6064C64.69 48.3827 62.9771 46.6981 60.7603 46.6981C58.5435 46.6981 56.6626 48.5512 56.7297 50.7412C56.8305 52.8639 58.6107 54.5485 60.7939 54.5485Z"
     
    />
    <path
      d="M16.1557 44.0701C16.2901 44.407 16.3908 44.7439 16.5252 45.0808C14.8458 46.2601 12.8977 47.0013 11.0504 47.911C10.3114 48.2817 10.0763 48.5849 10.2443 49.5283C10.8488 52.628 8.93434 55.2897 5.91144 55.7951C3.15724 56.2331 0.537394 54.2453 0.0335774 51.314C-0.3023 49.4272 0.671745 47.372 2.35113 46.2938C4.26564 45.0808 6.51602 45.0472 8.26259 46.5296C9.16946 47.3046 9.80762 47.2035 10.7145 46.7318C12.5282 45.7884 14.3756 44.9461 16.1557 44.0701ZM5.13892 54.5485C7.32213 54.5822 9.13587 52.8639 9.23663 50.7412C9.30381 48.5512 7.45648 46.6981 5.23969 46.6981C3.02289 46.6981 1.30991 48.3827 1.30991 50.5728C1.27633 52.7965 2.92213 54.5148 5.13892 54.5485Z"
     
    />
    <path
      d="M16.5252 27.9312C16.458 28.2681 16.3573 28.6388 16.2901 28.9757C14.3756 28.066 12.4611 27.19 10.6138 26.1792C9.70688 25.6738 9.16948 25.6738 8.32979 26.3813C6.54963 27.8638 3.96337 27.7291 2.11604 26.3477C0.369479 25.0336 -0.335865 22.7425 0.335891 20.6536C1.04123 18.4972 2.98933 17.0822 5.2733 17.0822C7.62444 17.0822 9.43818 18.4299 10.2107 20.6536C10.513 21.5296 10.6473 22.5067 10.3451 23.2816C9.84124 24.5282 10.5466 24.7978 11.3863 25.2358C13.0657 26.0781 14.7786 27.0215 16.5252 27.9312ZM5.13895 26.2129C7.25498 26.2466 9.13589 24.4609 9.20307 22.3382C9.27024 20.2156 7.28856 18.194 5.17253 18.2277C3.12368 18.2614 1.34352 19.9797 1.27635 22.035C1.20917 24.3261 2.92215 26.1792 5.13895 26.2129Z"
     
    />
    <path
      d="M49.3069 28.8747C49.1389 28.5378 48.971 28.2345 48.803 27.8976C50.6168 26.9542 52.3969 25.9097 54.2443 25.0337C55.3191 24.5283 55.8229 24.0903 55.6549 22.7089C55.3191 19.8113 57.3007 17.3855 59.9542 17.0822C62.9771 16.7453 65.3282 18.4973 65.8656 21.496C66.2351 23.4838 65.2275 25.5391 63.4473 26.651C61.6 27.7628 59.0473 27.7628 57.5359 26.2466C56.5954 25.3033 55.9237 25.5054 54.9496 26.0108C53.1023 27.0216 51.1878 27.9313 49.3069 28.8747ZM64.6901 22.2035C64.6565 20.0472 62.8763 18.2278 60.7603 18.2278C58.6778 18.2278 56.7633 20.0809 56.7298 22.1698C56.6962 24.3262 58.6107 26.2466 60.7939 26.213C62.9771 26.1793 64.7236 24.3935 64.6901 22.2035Z"
     
    />
  </svg>

);