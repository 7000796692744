import React from 'react';

const DataIcon = () => (
  <svg
    id="Layer_1"
    className="MuiSvgIcon-root"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 112.99"
  >
    <title>multiple-layers</title>
    <path d="M120.62,39.52,63.1,68.12a3.75,3.75,0,0,1-3.33,0L2.1,39.45a3.78,3.78,0,0,1-.18-6.67L59.59.48a3.78,3.78,0,0,1,3.73,0L121,32.78a3.77,3.77,0,0,1-.33,6.74Zm-9.77,40.93a3.76,3.76,0,0,1,3.6-6.61l6.41,3.38a3.77,3.77,0,0,1,1.58,5.09A3.82,3.82,0,0,1,120.7,84L63.1,112.6a3.75,3.75,0,0,1-3.33,0L2.1,83.93a3.77,3.77,0,0,1-1.71-5A3.69,3.69,0,0,1,2,77.22l6-3.14a3.76,3.76,0,0,1,4,6.35L61.44,105l49.41-24.57ZM111,57.69a3.76,3.76,0,0,1,4.36-6l5.49,2.89a3.76,3.76,0,0,1-.16,6.74L63.1,89.92a3.75,3.75,0,0,1-3.33,0L2.1,61.25a3.78,3.78,0,0,1-1.71-5A3.72,3.72,0,0,1,2,54.54L7.9,51.43A3.77,3.77,0,0,1,12,57.74l49.47,24.6L111,57.69ZM61.44,60.54,111,35.87,61.44,8.09,11.83,35.87,61.44,60.54Z" />
  </svg>
);

export default DataIcon;
