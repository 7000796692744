import {httpservice, httpserviceWithMultipart} from '../services';
import {constants} from '../constant/constant';
import { dateFormat } from "../helper/StringUtils";
let apiEndPoint = constants.ADMIN_URL;

/**
 * get license templates
 * @returns {return license templates}
 */
export const getGeneratedLicenses = (obj) => dispatch => {

    let query;
    if(!obj){
        query = `${constants.API.GET_LICENSES}`;
    }else if(obj.clientId){
        query = `${constants.API.GET_LICENSES}/${obj.clientId}`;
    }else{
        query = `${constants.API.GET_LICENSES}?page=${obj.page}&size=${obj.size}`;
    }
    dispatch({
        payload: [],
        type: 'GET_GENERATED_LICENSES_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (licenseResponse) {
            const rows = licenseResponse.data.response.data.licenses;
            const count = licenseResponse.data.response.data.totalItems;
            const filteredData = rows.filter(data => data.forClient && data.forSoftware)
            const finalData = filteredData.map(data => {
                data.clientName = data.forClient ? data.forClient.name : 'N/A';
                data.softwareName = data.forSoftware ? data.forSoftware.name : 'N/A';
                data.softwareCode = data.forSoftware ? data.forSoftware.code : 'N/A';
                data.activationStatus = `${data.licenseInstance.length}/${data.instanceLimit}`;
                data.created_at = dateFormat(data.createdAt);
                data.expiryDate = dateFormat(data.expiryDate);
                return data;
            });
            dispatch({
                payload: {data: finalData , count: count},
                type: 'GET_GENERATED_LICENSES_REQUEST_SUCCESS'
            });
            return finalData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_GENERATED_LICENSES_REQUEST_ERROR'
            });
        });
}

export const getGeneratedLicense = (id) => dispatch => {
    let query = `${constants.API.LICENSE_GENERATE}/${id}`;
    dispatch({
        payload: [],
        type: 'GET_LICENSE_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (licenseData) {
            dispatch({
                payload: { data: licenseData.data },
                type: 'GET_LICENSE_REQUEST_SUCCESS'
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_LICENSE_REQUEST_ERROR'
            });
        });
}

export const createLicense = (postData) => {
    return httpservice()
        .post(constants.API.LICENSE_GENERATE, postData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const updateLicense = (updateData) => {
    return httpservice()
        .put(`${constants.API.LICENSE_GENERATE}`, updateData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const deleteLicense = (id) => {
    return httpservice()
        .delete(`${constants.API.LICENSE_GENERATE}?id=${id}`)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        })
}

export const uploadLicense = (licenseData) => {
    return httpserviceWithMultipart('post', licenseData, `${apiEndPoint}${constants.API.LICENSE_UPLOAD}`)
}