
/* jshint esversion: 6 */

import buttonsStyle from './buttonsStyle';

const confirmPopupStyles = theme => ({
    dialogLayout: {
        color: '#fff'
    },
    textAreaWidth: {
        minWidth: 920,
        background: 'none',
        padding: 10,
        color: theme.palette.primary.contrastText,
    },
    textAreaLabel: {
        color: theme.palette.secondary.dark,
    },
    dialogTitle: {
        color: theme.palette.modalTextColor.color,
        background: theme.palette.modalsContentBg.color,
        minWidth: 540,
        '& h2':{
            display: 'flex',
            alignItems: 'center',
        },
        '@media (max-width: 767px)': {
            minWidth: 'inherit',
            padding: '16px 10px'
        }
    },
    dialogContent: {
        // paddingTop:50,
        color: theme.palette.secondary.contrastText,
        background: theme.palette.modalsContentBg.color,
        minHeight: 80,
        padding: 24,
        '@media (max-width: 767px)': {
            minWidth: 'inherit',
            padding: '16px 10px'
        }
    },
    dialogAction: {
        background: theme.palette.modalsContentBg.color,
        justifyContent:'space-between',
        padding:'0 20px 10px'
    },
    defaultButton: {
        ...buttonsStyle.defaultButton,
            color: '#1e1c1c',
            background: '#ebebeb',
        '&:hover':{
            background: '#ebebeb',
            color:'#1e1c1c',
            boxShadow:theme.palette.defaultBtnShadow.boxShadow
        },
    },
    activeButton: {
        width:'auto',
        '&:hover':{
            background: theme.palette.primary.color,
            opacity:0.9
        },
        '&.Mui-disabled':{
          color:'#fff',
          cursor:'not-allowed',
          pointerEvents:'inherit',
          background: theme.palette.primary.color,
          '&:hover':{
            background: theme.palette.primary.color,
            cursor:'not-allowed',
        },
        },
        ...buttonsStyle.activeButton,
        color: '#fff',
        background: theme.palette.primary.color
    },
    rightArrow: {
        position: 'absolute',
        right: 0,
        top: '50%',
        minWidth: 42,
        maxWidth: 42,
        opacity: 0.8,
        borderRadius: '21px 0 0 21px',
        paddingRight: 10,
        background: theme.palette.primary.main
    },
    leftArrow: {
        position: 'absolute',
        left: 0,
        top: '50%',
        margin: 0,
        minWidth: 42,
        maxWidth: 42,
        opacity: 0.8,
        borderRadius: '0 21px 21px 0',
        paddingLeft: 10,
        background: theme.palette.primary.main
    },
    fullWidth: {
        width: '100%'
    },
    uploadImg: {
        width: '100%',
        float: 'left',
        height: '100%',
        borderRadius: 0
    },
    moduleImage:{
        background:'#189b5430',
        marginRight:10,
        width:40,
        height:40,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'50%',
        '& svg':{
            fill:theme.palette.primary.color,
            width:22,
            height:22
        }
    }
});

export default confirmPopupStyles;
