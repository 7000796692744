import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { dateFormat } from "../helper/StringUtils";

/*
    * Get IP Filter Data
*/
export const getIPFirewallData = (obj) => dispatch => {

    let query;
    if(!obj){
        query = `${constants.API.IP_FIREWALL}`;
    }else{
        query = `${constants.API.IP_FIREWALL}?page=${obj.page}&size=${obj.size}`;
    }
    dispatch({
        payload: [],
        type: 'GET_IP_FIREWALL_DATA_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (ipResponse) {
            const rows = ipResponse.data.response.data.items;
            const count = ipResponse.data.response.data.totalItems;
            const mappedData = rows.map(data => {
                data.reason = data.reason ? data.reason : "No Reason Provided";
                data.created_at = dateFormat(data.createdAt);
                return data;
            });
            dispatch({
                payload: {data: mappedData , count: count},
                type: 'GET_IP_FIREWALL_DATA_REQUEST_SUCCESS'
            });
            return mappedData;
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_IP_FIREWALL_DATA_REQUEST_ERROR'
            });
        });
}

export const getIPFirewallDataById = (id) => dispatch => {
    let query = `${constants.API.IP_FIREWALL}/${id}`;
    dispatch({
        payload: [],
        type: 'GET_IP_FIREWALL_DATA_BY_ID_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (ipFirewallData) {
            dispatch({
                payload: { data: ipFirewallData.data },
                type: 'GET_IP_FIREWALL_DATA_BY_ID_REQUEST_SUCCESS'
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_IP_FIREWALL_DATA_BY_ID_REQUEST_ERROR'
            });
        });
}

export const saveIPFirewallData = (saveData) => {
    return httpservice()
        .post(constants.API.IP_FIREWALL, saveData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const updateIPFirewallData = (id, modifiedData) => {
    return httpservice()
        .put(`${constants.API.IP_FIREWALL}/${id}`, modifiedData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export const deleteIPFirewallData = (id) => {
    return httpservice()
        .delete(`${constants.API.IP_FIREWALL}/${id}`)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        })
}