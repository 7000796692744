

export const ENTITY = {
    USER_ID: ':userId',
    PROJECT_ID: ':projectId',
    SOFTWARE_ID: ':softwareId',
    CLIENT_ID: ':clientId',
    SOFTWARE_VERSION_ID: ':versionId',
    WHITE_IP_ID: ':whiteIpId',
    VERSION_ID: ':versionId',
    PLATFORM_ID: ':platformId',
    EMOTION_ID: ':emotionId',
    SECOND_EMOTION_ID: ':secondEmotionId',
    WHAT_I_CAN_DO_ID: ':whatICanDoId',
    JOURNAL_ENTRIES_ID: ':journalEntriesId',
    GALLERY_ID: ':galleryId',
    VIDEO_ID: ':videoId',
    THIRD_EMOTION_ID: ':thirdEmotionId',
    SUBMODULE_ID: ':submoduleId',
    SYSLOG_SETTING_ID: ':syslogSettingId',
    LICENSE_ID: ':licenseId'
};

/**
 * Maintaining all react local routes(urls)
 *
 * ##################### NOTE #########################
 * This route declration is not applied at 'JSON' files
 * ####################################################
 */
export const ROUTE = {
    ALL: '*',
    ROOT: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    GROUP_CHAT: '/groupChat',
    NOT_FOUND: '/not-found',
    OTP: '/otp',
    FORGET_PASSWD: '/forgot-password',
    SIGN_UP:'/signup',
    CHANGE_PASSWORD:"/reset-password",
    USER_CHANGE_PASSWORD: "/change-password",
    
    VIDEOS: '/videos',
    GALLERY: '/gallery',
    CHAT: '/chat',
    JOURNAL_ENTRIES: '/journal-entries',
    WHAT_I_CAN_DO: '/whatICanDo',
    SECOND_EMOTIONS: '/second-emotions',
    THIRD_EMOTIONS: '/third-emotions',
    EMOTIONS: '/emotions',
    BILLING: '/billing',
    COMMENTS: '/comments',

    /** All User Related URL */
    USERS: '/users',
    USER_ADD: '/users/create',
    USER_EDIT: `/users/edit/${ENTITY.USER_ID}`,

    /** All Software and Submodule Related URL */
    SOFTWARE: '/software',
    SOFTWARES: '/softwares',
    SUBMODULE: '/submodule',
    SUBMODULES: '/submodules',
    SOFTWARE_VERSION: `/software/${ENTITY.SOFTWARE_ID}/versions`,
    SOFTWARE_SUBMODULE: `/software/${ENTITY.SOFTWARE_ID}/submodules`,
    SOFTWARE_SUBMODULE_VERSION: `/software/${ENTITY.SOFTWARE_ID}/submodule/${ENTITY.SUBMODULE_ID}/versions`,
    SOFTWARE_PLATFORMS: `/software/${ENTITY.SOFTWARE_ID}/platforms`,
    /** All Client Related URL */
    CLIENT: '/client',
    CLIENTS: '/clients',
    CLIENT_USERS: `/client/${ENTITY.CLIENT_ID}/users`,
    CLIENT_WHITE_IPS: `/client/${ENTITY.CLIENT_ID}/whitelisted-ips`,
    CLIENT_SOFTWARES: `/client/${ENTITY.CLIENT_ID}/softwares`,
    /** All Platform Related URL  */
    PLATFORM: '/platform',
    PLATFORMS: '/platforms',
    PLATFORM_SUBMODULE: `/platforms/${ENTITY.SOFTWARE_ID}/users`,//
    SYSTEM_EVENT: '/system-event',
    SYSTEM_EVENTS: '/system-events',
    SYSLOG_SETTING: '/syslog-setting',
    SYSLOG_SETTINGS: '/syslog-settings',
    ML_SCAN: '/ml-scan',
    ML_SCANS: '/ml-scans',
    LICENSE_MANAGEMENT: '/license-management',
    LICENSE_INSTANCES: `/license/${ENTITY.LICENSE_ID}/instances`,
    LICENSE_TEMPLATES: `/license-management/templates`,
    LICENSE_GENERATE: `/license-management/licenses`,
    IP_FIREWALL: '/ip-firewall',
    IP_FIREWALLS: '/ip-firewalls',
    CLIENT_ADMIN_LICENSE_INSTANCES: `/software/${ENTITY.SOFTWARE_ID}/license-activations`,
    CLIENT_SOFTWARE_SUBMODULE_VERSION: `/software-submodule/${ENTITY.SUBMODULE_ID}/versions`
};