

import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default otpData => {
    return httpservice()
        .post(constants.API.VERIFY_TWO_FA, otpData)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response.data;
        });
};