
import { httpservice } from '../services';
import { dateFormat } from '../helper/StringUtils';
import { CLIENT } from "../constant/actionTypes";
import { constants } from '../constant/constant';

export default id => dispatch => {
    dispatch({
        payload: { data: [], count: 0 },
        type: CLIENT.USER_LIST.INITIATE
    });
    return httpservice()
        .get(`${constants.API.CLINET_USER}/${id}`)
        .then(response => {
            const count = response.data.response.length;
            const responseData = response.data.response.map(data => {
                data.name = data.firstName + ' ' + data.lastName;
                data.createdAt = dateFormat(data.createdAt);
                data.company = data.company && data.company.name ? data.company.name : '';
                data.role = data.role ? data.role.roleName : '';
                data.createdBy = data.creator ? data.creator.firstName + ' ' + data.creator.lastName : 'admin';
                if (data.active) {
                    data.active = 'Active';
                } else {
                    data.active = 'Pending';
                }
                if (data.isBlocked) data.active = 'Blocked';

                return data;
            });
            dispatch({
                payload: { data: responseData, count: count },
                type: CLIENT.USER_LIST.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: { data: [], count: 0, error: error },
                type: CLIENT.USER_LIST.ERROR
            });
        });
};
