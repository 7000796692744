
import { httpservice } from '../services';
import { constants } from '../constant/constant';
import { USER, CLIENT } from '../constant/actionTypes';

export default (usersInfo, data, isClient = false) => dispatch => {
    return httpservice()
        .delete(`${constants.API.USER}/${usersInfo}`)
        .then(function (response) {
            if (isClient) {
                dispatch({
                    payload: { data: data, count: data.length },
                    type: CLIENT.USER_LIST.SUCCESS
                })
            } else {
                dispatch({
                    payload: { data: data, count: data.length },
                    type: USER.GET.SUCCESS
                })
            }
            return { status: 1, data: response.data };
        })
        .catch(function (error) {
            return { status: 0, data: error.response && error.response.data ? error.response.data : error };
        });
};
