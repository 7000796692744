

import forge from 'node-forge';
import CryptoJS from 'crypto-js';

const EncryptionServices = {

    /**
     * function to encode password with username and iteration count when user try to login and return login object
     * @param userName
     * @param password
     * @param iterationCount
     * @returns {Promise}
     */
    generateCredential: (userName, password) => {
        return new Promise((resolve, reject) => {
            userName = userName.trim().toLowerCase();
            // let encryptedPassword = EncryptionServices.generateSHA512(password, 421);
            let encryptedPassword = password;
            resolve({
                email: userName,
                password: encryptedPassword
            });
        });
    },

    generateSHA512 : (str, iteration) => {
        if (iteration < 1) {
            return str;
        }
        return EncryptionServices.generateSHA512(
            CryptoJS.SHA512(str)
                .toString()
                .toLowerCase(),
            iteration - 1,
        );
    },

    /**
     * generate strong password
     */
    randomPassword: () => {
        // let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        // let pass = "";
        // for (let x = 0; x < length; x++) {
        //     let i = Math.floor(Math.random() * chars.length);
        //     pass += chars.charAt(i);
        // }
        const specialCharsString = "~`!#$%^&*+=-[]\\\';,/{}|\":<>?";
        var firstRandomIndex = Math.floor(Math.random()*specialCharsString.length);
        var secondRandomIndex = Math.floor(Math.random()*specialCharsString.length);
        let pass = Math.random().toString(36).substr(2, 6) + specialCharsString[firstRandomIndex] + specialCharsString[secondRandomIndex] + Math.random().toString(36).toUpperCase().slice(2, 6);
        return pass;
    },

    /**
     * function to validate SSH key entered by users
     * @param sshKey
     * @returns {Promise}
     */
    checkSSHKey: sshKey => {
        return new Promise((resolve, reject) => {
            if (sshKey.indexOf('PRIVATE') !== -1) {
                try {
                    let temp = forge.pki.privateKeyFromPem(sshKey);
                    let t1 = forge.pki.privateKeyToPem(temp);
                    resolve({error: false, msg: t1});
                } catch (e) {
                    reject({error: true, msg: 'Invalid key passed', err: e});
                }
            } else {
                try {
                    let temp = forge.pki.publicKeyFromPem(sshKey);
                    let t1 = forge.pki.publicKeyToPem(temp);
                    resolve({error: false, msg: t1});
                } catch (e) {
                    reject({error: true, msg: 'Invalid key passed', err: e});
                }
            }
        });
    },

    /**
     * function to encrypt projectId with user private ssh Key
     * @param privateKey
     * @param data
     * @returns {Promise}
     */
    reEncryptProjectKeysWithPrivKey: (privateKey, data) => {
        return new Promise((resolve, reject) => {
            let tempPrivateProjectKeyArray = [];
            data.map(function (projectData, projectIndex) {
                EncryptionServices.decryptDataRSA(projectData.symmetricKey).then(
                    function (decryptedKey) {
                        let tempProjectIns = {},
                            tempPrivateData = ``;
                        try {
                            let myPk = forge.pki.privateKeyFromPem(privateKey);
                            let encrypted = forge.pki.rsa.encrypt(
                                forge.util.encodeUtf8(decryptedKey),
                                myPk,
                                0x01
                            );
                            tempPrivateData = forge.util.bytesToHex(encrypted);

                            tempProjectIns.project_id = projectData.projectId;
                            tempProjectIns.symkey = forge.util.encode64(tempPrivateData);

                            tempPrivateProjectKeyArray.push(tempProjectIns);

                            if (projectIndex + 1 === data.length) {
                                resolve({status: true, privProjectKey: tempPrivateProjectKeyArray});
                            }
                        } catch (error) {
                            reject({status: false, privProjectKey: null});
                        }
                    },
                    function (err) {
                        reject({status: false, privProjectKey: null});
                    }
                );
                return true;
            });
        });
    },

    /**
     * function to encrypt plain text for http request
     * @param plaintext
     * @param key
     * @param v
     * @returns {Promise}
     */
    encryptDataAES: (plaintext, key, v) => {
        return new Promise((resolve, reject) => {
            try {
                let cipher = forge.cipher.createCipher('AES-CBC', key);
                cipher.start({iv: v});
                cipher.update(forge.util.createBuffer(plaintext));
                cipher.finish();
                resolve(forge.util.encode64(cipher.output.data));
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * function to decrypt data to plain text
     * @param ciphertext
     * @param key
     * @param v
     * @returns {Promise}
     */
    decryptDataAES: (ciphertext, key, v) => {
        return new Promise((resolve, reject) => {
            try {
                let cipher = forge.util.decode64(ciphertext);
                let decipher = forge.cipher.createDecipher('AES-CBC', key);
                decipher.start({iv: v});
                decipher.update(forge.util.createBuffer(cipher));
                decipher.finish();
                resolve(decipher.output.data);
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * function to encrypt plain text using user public key
     * @param message
     * @returns {Promise}
     */
    encryptDataRSA: (message, key) => {
        return new Promise((resolve, reject) => {
            try {
                let serverPublicKey;
                if (key) {
                    serverPublicKey = forge.pki.publicKeyFromPem(key);
                } else {
                    serverPublicKey = forge.pki.publicKeyFromPem(sessionStorage['_clientpk']);
                }
                let plaintextBytes = forge.util.encodeUtf8(message);
                let encrypted = serverPublicKey.encrypt(plaintextBytes);
                resolve(forge.util.encode64(encrypted));
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * function to decrypt data using user private key
     * @param b64cipher
     * @returns {Promise}
     */
    decryptDataRSA: b64cipher => {
        return new Promise((resolve, reject) => {
            try {
                let msgDecode = forge.util.decode64(b64cipher);
                let privateKey = forge.pki.privateKeyFromPem(sessionStorage.getItem('_myPk'));
                let decrypted = privateKey.decrypt(msgDecode);
                resolve(forge.util.decodeUtf8(decrypted));
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * function to encode data before sending to server
     * @param data
     * @param multipleEncryption
     * @returns {Promise}
     */
    processDataToServer: (data, multipleEncryption) => {
        return new Promise((resolve, reject) => {
            let message = data.data;
            let projectId = data.projectId;
            if (multipleEncryption) {
                let initVector = data && data.v ? forge.util.decode64(data.v) : forge.random.getBytesSync(16);
                let sk = JSON.parse(sessionStorage.getItem('_ppak'));
                for (let k in sk) {
                    if (parseInt(sk[k].projectId) === parseInt(projectId)) {
                        EncryptionServices.decryptDataRSA(sk[k].symmetricKey).then(function (decryptedKey) {
                            EncryptionServices.encryptDataAES(message, decryptedKey, initVector)
                                .then(encryptedDataAES => {
                                    resolve({
                                        encrypted: encryptedDataAES,
                                        v: forge.util.encode64(initVector),
                                        projectId: projectId,
                                        status: true
                                    });
                                })
                                .catch(error => {
                                    reject({error: error, status: false});
                                });
                        });
                    }
                }
            } else {
                EncryptionServices.encryptDataRSA(message)
                    .then(encryptedDataRSA => {
                        resolve({encrypted: encryptedDataRSA, status: true});
                    })
                    .catch(error => {
                        reject({error: error, status: false});
                    });
            }
        });
    },


    /**
     * function to decode data coming from http request
     * @param data
     * @param multipleEncryption
     * @returns {Promise}
     */
    processDataFromServer: (data, multipleEncryption) => {
        return new Promise((resolve, reject) => {
            let message = data.encrypted;
            let projectId = data.projectId;
            let initVector = forge.util.decode64(data.v);
            if (multipleEncryption) {
                let sk = JSON.parse(sessionStorage.getItem('_ppak'));
                for (let k in sk) {
                    if (parseInt(sk[k].projectId) === parseInt(projectId)) {
                        EncryptionServices.decryptDataRSA(sk[k].symmetricKey).then(function (decryptedKey) {
                            EncryptionServices.decryptDataAES(message, decryptedKey, initVector)
                                .then(decryptedData => {
                                    resolve(decryptedData);
                                })
                                .catch(error => {
                                    reject({error: error});
                                });
                        });
                    }
                }
            } else {
                EncryptionServices.decryptDataRSA(message)
                    .then(info => {
                        resolve(info);
                    })
                    .catch(error => {
                        reject({error: error});
                    });
            }
        });
    },

    /**
     * CHECK IF private and public key match or not
     * @param privKey
     * @param pubKey
     * @returns {Promise<any>}
     */
    validateWithPrivKey: (privKey, pubKey) => {
        return new Promise((resolve, reject) => {
            try {
                let msg = 'penportal';
                let serverPublicKey = forge.pki.publicKeyFromPem(pubKey);
                let plaintextBytes = forge.util.encodeUtf8(msg);
                let encrypted = serverPublicKey.encrypt(plaintextBytes);
                let privateKey = forge.pki.privateKeyFromPem(privKey);
                let decrypted = privateKey.decrypt(encrypted);
                resolve(forge.util.decodeUtf8(decrypted));
            } catch (error) {
                reject(error);
            }
        });
    },

    generateAESSecretKey: function () {
        return new Promise((resolve, reject) => {
            let randomKey = forge.random.getBytesSync(32);
            let serverPublicKey = forge.pki.privateKeyFromPem(sessionStorage.getItem('_myPk'));
            let encrypted = forge.pki.rsa.encrypt(forge.util.encodeUtf8(randomKey), serverPublicKey, 0x01);
            encrypted = forge.util.bytesToHex(encrypted);
            resolve({project_id: 0, symkey: forge.util.encode64(encrypted)});
        });
    },

    decodeJwtToken: function (token) {
        return new Promise((resolve, reject) => {
            if(token){
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            resolve(JSON.parse(jsonPayload));
        }else{
            reject("Token not found")
        }
        });
    },

    base64ToHex: function (base64) {
        return forge.util.bytesToHex(base64);
    },
    hexToBase64: function (hex) {
        return forge.util.hexToBytes(hex);
    },
    encode64: function (text) {
        return forge.util.encode64(text);
    },
    decode64: function (base64) {
        return forge.util.decode64(base64);
    }

};

export default EncryptionServices;
