
import {CheckIfEmailIsValid, CheckIfNotEmpty, CheckIfphoneNumberIsValid} from '../helper/StringUtils';

export default {
    validateForm(domElement) {
        if (!CheckIfNotEmpty(domElement.value)) {
            return false;
        } else {
            let result = true;
            console.log('else');
            switch (domElement.name.toLowerCase()) {
                case 'email' :
                    if (!CheckIfEmailIsValid(domElement.value)) {
                        result = false;
                    }
                    break;
                case 'phonenumber' :
                    if (!CheckIfphoneNumberIsValid(domElement.value)) {
                        result = false;
                    }
                    break;
                case 'passcode' :
                    if (domElement.value.length !== 6) {
                        result = false;
                    }
                    break;
                case 'severity' :
                case 'status' :
                case 'clientname' :
                    if (domElement.value === 'none') {
                        result = false;
                    }
                    else if (domElement.value.length > 40) {
                        result = false;
                    }
                    break;
                default:
                    return true;
            }
            return result;
        }
    },
}
