
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { CLIENT } from '../constant/actionTypes';

export default (id,status,newData) => dispatch=> {
    return httpservice()
        .put(`${constants.API.CLIENT_FREEZE_UNFREEZE}/${id}`, {isFreeze: status})
        .then(function (response) {
            const newArray = [];
            newData.forEach(data => {
                if (data.id === id) {
                    data.isFreeze =status;
                }
                newArray.push(data);
            });
            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: CLIENT.GET.SUCCESS
            })
            return response.data;
        })
        .catch(function (error) {
            console.log(error)
            return error.response && error.response.data ? error.response.data : error;
        });
};
