
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { CLIENT } from '../constant/actionTypes';

export default (id,days,date,newData) => dispatch=>{

    return httpservice()
        .put(`${constants.API.VALIDITY_CHANGE}/${id}`, {days:days})
        .then(function (response) {
            const newArray = [];

            newData.forEach(data => {

                if (data.id === id) {
                    data.apiKeyValidity =date;
                }
                newArray.push(data);
            });
            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: CLIENT.GET.SUCCESS
            })
            return response.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};
