
/* jshint esversion: 6 */

import React, {useState} from 'react';
import useStyles from "../../hooks/useStyles"
import styles from '../../assets/jss/linkExpired';
import resendVerification from "../../actions/resendVerification";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function LinkExpired(props) {
    const classes = useStyles(styles)();
    const tokenPath = props.location.search;
    const [isResend, changeResend] = useState(false);

    let token = '';
    if (tokenPath) {
        token = tokenPath.replace('?token=','')
    }
    function sendEmailLink() {
        resendVerification(token).then(res => {
            changeResend(true);
        });
    }

    return (
        <div className={classes.linkExpired}>
            {
                !isResend ?
                    <div>
                        Link has been expired
                        {/* <div className={classes.linkResend}>
                            <span onClick={sendEmailLink}>
                               <a href="" onClick={(event) => event.preventDefault()}> Click here</a>
                            </span> to Request for new link
                        </div> */}
                        <div className={classes.linkResend}>
                            Please try Again.
                        </div>
                    </div>
                    :
                    <div className={classes.newLink}>
                        <CheckCircleIcon className={classes.successIcon} /> New Link has been sent to your email
                    </div>
            }

        </div>
    );
}

LinkExpired.propTypes = {};

export default LinkExpired;