
import {AuthService} from '../services';

export default {
    requestHandler(request) {
        return request;
    },
    errorHandler(error) {
        if (error && error.response.status === 401 && localStorage['authToken']) {
             
            AuthService.signOut();
            window.location.href = '/login';
            return {};
        } else {
            return Promise.reject({...error});
        }
    },
    successHandler(response) {
        return response;
    }
};
