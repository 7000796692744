
import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/FormStyle';

class PageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /*
     * TODO
     */
    componentWillMount() {
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.props.isLoading ? (
                    <div className={classes.pageLoader}>
                        <CircularProgress className={classes.svg} variant={'indeterminate'}/>
                    </div>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PageLoader);
