import React from "react";

export default () => (
<svg viewBox="0 0 368 368">
    <g>
    <g>
    <path d="M236.8,183.216l17.856-26.784c2.448-3.672,1.456-8.648-2.216-11.096c-3.672-2.456-8.648-1.464-11.096,2.216
l-16.704,25.056C213.064,164.672,199.072,160,184,160c-39.696,0-72,32.304-72,72s32.304,72,72,72s72-32.304,72-72
C256,213.168,248.68,196.064,236.8,183.216z M184,288c-30.88,0-56-25.12-56-56s25.12-56,56-56c11.792,0,22.728,3.68,31.76,9.936
L184,233.576l-9.344-14.016c-2.456-3.672-7.416-4.68-11.096-2.216c-3.672,2.448-4.664,7.416-2.216,11.096l16,24
c1.488,2.224,3.984,3.56,6.656,3.56s5.168-1.336,6.656-3.56l36.976-55.456C235.352,206.576,240,218.752,240,232
C240,262.88,214.88,288,184,288z"/>
</g>
</g>
<g>
<g>
<path d="M333.656,66.344l-64-64C268.16,0.84,266.12,0,264,0h-16H56c0,0,0,0-0.008,0c-6.408,0-12.432,2.496-16.96,7.024
C34.496,11.56,32,17.584,32,24v320c0,13.232,10.768,24,24,24h256c13.232,0,24-10.768,24-24V88V72
C336,69.88,335.16,67.84,333.656,66.344z M320,344c0,4.416-3.592,8-8,8H56c-4.408,0-8-3.592-8-8V24
c0-2.136,0.832-4.152,2.344-5.664C51.848,16.832,53.856,16,56,16h184v32H88c-4.416,0-8,3.584-8,8s3.584,8,8,8h152v24
c0,4.416,3.584,8,8,8h72V344z M320,80h-64V16h4.688L320,75.312V80z"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

);


