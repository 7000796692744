
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default otp => {
    return httpservice()
        .post(constants.API.VERIFY_PHONE, otp)
        .then(function (response) {
             
            return response.data;
        })
        .catch(function (error) {
             
            return error.response.data;
        });
};