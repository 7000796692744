
import {httpservice} from '../services';
import {dateFormat} from '../helper/StringUtils';
import { USER } from "../constant/actionTypes";
import {constants} from "../constant/constant";


export default (therapisDetails, object) => dispatch => {
    const query = `${constants.API.THERAPISTS_USERS}?therapistId=${therapisDetails.id}`;
    dispatch({
        payload: {data: [], count: 0},
        type: USER.GET.INITIATE
    });
    return httpservice()
        .get(query)
        .then(response => {
            const values = response.data.response;
            const count = values.length;
            // console.log("....................",response.data.response)
            const responseData = response.data.response.map(data => {
                data.name = data?.userDetail?.firstName + ' ' + data?.userDetail?.lastName;
                data.email = data?.userDetail?.email ;
                data.createdAt = dateFormat(data?.userDetail?.createdAt);
                // data.company = data.userDetail.company && data.userDetail.company.name ? data.userDetail.company.name : '';
                data.role = 'user';
                data.createdBy = therapisDetails.firstName + ' ' + therapisDetails.lastName;
                if (data?.userDetail?.active) {
                    data.active = 'Active';
                } else {
                    data.active = 'Pending';
                }
                // if (data.userDetail.isBlocked) data.active = 'Blocked';
                
                return data;
            });
            // const filteredResponseData = responseData.filter(data => data.email !== null);
            const filteredResponseData = responseData.filter(data => {
                return data.email && data.email.trim() !== ''; // Filter out rows with non-empty email values
            });
            console.log("00000000000000000",filteredResponseData)
            dispatch({
                payload: {data: filteredResponseData, count: count},
                type: USER.GET.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: {data: [], count: 0, error: error},
                type: USER.GET.ERROR
            });
        });
};
