import React, { Component } from 'react';

import { FormHelperText, InputAdornment, IconButton, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff , Lock as LockIcon ,  LockOpen as LockOpenIcon } from '@material-ui/icons';

import { Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

class PasswordField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }
    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
        ;
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
  render() {
    const {
    input,
    type,
    disabled,
    name,
    autoComplete,
    label,
    design,
    meta: { touched, error },
    classes,
} = this.props
      return <> {  design === 'new' ?  <>
        <Grid container alignItems="flex-end" className={classes.marginBottom}>
                <Grid item className={classes.formInput}>
            <TextField
                {...input}
                className={classes.formInput + ' ' + classes.loginCredentials + " " + classes.loginCredentials2}
                // type={this.state.showPassword ? 'text' : 'password'}
                type={this.props.showPassword ? 'text' : 'password'}
                    
                disabled={disabled}
                name={name}
                // label={label}
                placeholder={label}
                autoComplete={autoComplete || "off"}
                // InputProps={{                    
                //     endAdornment:
                //         <InputAdornment position="end">
                //             <IconButton aria-label="toggle password visibility" className={classes.inputIconPwd  }
                //                 onClick={this.handleClickShowPassword}
                //                 onMouseDown={this.handleMouseDownPassword}
                //             >
                //                   {this.state.showPassword ? <LockOpenIcon /> : <LockIcon />}   
                //                 {/* {this.props.showPassword ? <Visibility /> : <VisibilityOff />}                     */}
                //             </IconButton>                    
                //         </InputAdornment>                     
                // }}
            />
            </Grid>
            </Grid>
            <FormHelperText className={classes.error}>{touched && error && <Trans>{touched && error}</Trans>}</FormHelperText>
        </> :  <>
            <TextField
                {...input}
                className={classes.formInput + ' ' + classes.loginCredentials}
                type={this.state.showPassword ? 'text' : 'password'}
                    
                disabled={disabled}
                name={name}
                label={label}
                autoComplete={autoComplete || "off"}
                InputProps={{                    
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" style={{padding: '0px', color: 'black'}}
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                            >
                                {/* {this.state.showPassword ? <Visibility /> : <VisibilityOff />}  */}
                                {this.state.showPassword ? <LockOpenIcon /> : <LockIcon />}                  
                            </IconButton>                    
                        </InputAdornment>                     
                }}
            />
            <FormHelperText className={classes.error}>{touched && error && <Trans>{touched && error}</Trans>}</FormHelperText>
        </>}
    </>
  }
}
export default PasswordField;