
/* jshint esversion: 6 */

const customMessageStyles = theme => ({
    message: {
        position: 'relative',
        width: '80%',
        margin:"auto",
        marginBottom: 20,
        marginTop: 10,
        left: 0,
        right: 0,
        padding: '0 20px',
        display: 'flex',
        top: 0,
        alignItems: 'center',
        borderRadius: 6,
        '& h5': {
            margin: 0,
            color:"#1c1c1c",
            width: '100%'
        },
        '& svg': {
            fill: '#fff',
            borderRadius: 50,
            width: 28,
            height: 28,
            fontSize: 28,
            padding: 2,
            marginRight: 10,
        },
        '& p': {
            cursor: 'pointer'
        }
    },
        error: {
            background: '#ffe4e0',
            border: '1px solid #ee4326',
        '& svg': {
            background: '#ee4326'
        }
    },
        warning: {
            background: '#fdf9e9',
            border: '1px solid #fee47c',
        '& svg': {
            background: '#fee47c'
        }
    },
        success: {
            background: '#e7fec261',
            border: '1px solid #8FFE55',
        '& svg': {
            background: '#88c768',
        }
    }
});

export default customMessageStyles;
