
import {ENTITY} from '.';
import {SECOND_EMOTION, WHAT_I_CAN_DO, JOURNAL_ENTRIES, GALLERY, THIRD_EMOTION} from './actionTypes';

export const constants = {

    ADMIN_URL: process.env.REACT_APP_ADMIN_ENDPOINT,
    CLIENT_URL: process.env.REACT_APP_CLIENT_ENDPOINT,
    SOCKET_URL: 'wss://localhost:8080', //  wss://localhost:8080
    API: {
        DASHBOARD: '/api/v1/dashboard/clients-softwares/total-count',
        CLIENT_SOFTWARE_CHART: '/api/v1/dashboard/software/total-clients',
        DOWNLOAD_SOFTWARE_CHART: '/api/v1/dashboard/software/total-download-requests',
        ACTIVITY: '/api/v1/activity',
        LOGIN: '/api/v1/login',
        PLATFORM_VERIFY: '/api/v1/client-platform/verify',
        PLATFORM_USERS: '/api/v1/client-platform/list-of-platform-users',
        // SIGNUP: '/api/v1/signup',
        THERAPISTS_USERS: '/api/v1/therapists/data',
        THERAPISTS_USERS_COUNT: 'api/v1/therapists/dashboardcounts',
        THERAPISTS_USERS_CONTACTED: 'api/v1/therapists/chatdashboardcounts',
        SOCKET_TOKEN: '/api/v1/login-session/socket-token',
        USER_CHANGE_PASSWORD: `/api/v1/user/change-password`,
        ROLE: '/api/v1/role',
        IMAGE: process.env.REACT_APP_STATIC_FILE_ENDPOINT + '/images',
        USER: '/api/v1/user',
        COMMENTS: '/api/v1/comments',
        USER_SERVER_CHECK: '/api/v1/user/check-server',
        REDIRECT_TOKEN: `/api/v1/check/redirect-token-validity`,
        TOKEN: `/api/v1/check/token-validity`,
        USER_BY_COMPANY: '/api/v1/project/users/',
        VERIFY_TOKEN: '/api/v1/verify-2fa-token',
        CHANGE_PASSWORD: '/api/v1/user/reset-password',
        SIGNUP: '/api/v1/user/signup',
        FORGOT_PASSWORD: '/api/v1/user/forgot-password',
        RESEND_VERIFICATION_MAIL: '/api/v1/user/resend-verification-email',
        MAIL_SETTING: '/api/v1/settings',
        REPORT: '/api/v1/report',
        NOTIFICATION_SETTING: '/api/v1/settings/notifications',
        CHANGE_KEY: '/api/v1/user/change-key',
        USER_SKEY: '/api/v1/projects/list',
        ADD_COMMENT: '/api/v1/project',
        DOWNLOAD_PDF: '/api/v1/download-report',
        VERIFY_EMAIL: '/api/v1/user/verify-email',
        SOFTWARE: '/api/v1/client-platform',
        EMOTION: '/api/v1/emotion',
        EMOTION_BY_ID: `/api/v1/emotion/${ENTITY.EMOTION_ID}`,
        SECOND_EMOTION: '/api/v1/second-emotion',
        SECOND_EMOTION_BY_ID: `/api/v1/second-emotion/${ENTITY.SECOND_EMOTION_ID}`,
        WHAT_I_CAN_DO: '/api/v1/whatICanDo',
        WHAT_I_CAN_DO_BY_ID: `/api/v1/whatICanDo/${ENTITY.WHAT_I_CAN_DO_ID}`,
        JOURNAL_ENTRIES: '/api/v1/journal-entries',
        JOURNAL_ENTRIES_BY_ID: `/api/v1/journal-entries/${ENTITY.JOURNAL_ENTRIES_ID}`,
        GALLERY: '/api/v1/gallery',
        GALLERY_BY_ID: `/api/v1/gallery/${ENTITY.GALLERY_ID}`,
        VIDEO: '/api/v1/video',
        VIDEO_BY_ID: `/api/v1/video/${ENTITY.VIDEO_ID}`,
        THIRD_EMOTION: '/api/v1/third-emotion',
        THIRD_EMOTION_BY_ID: `/api/v1/third-emotion/${ENTITY.THIRD_EMOTION_ID}`,
        DOWNLOAD_VERSION: `/api/v1/version/download`,
        VERSION: '/api/v1/version',
        SOFTWARE_VERSION: '/api/v1/version/software',
        PLATFORM: '/api/v1/platform',
        PLATFORM_BY_ID: `/api/v1/platform/${ENTITY.PLATFORM_ID}`,
        CLIENT: '/api/v1/client',
        REGENRATE_API_KEY: '/api/v1/client/api-key',
        SET_PREVIOUS_API_KEY: '/api/v1/client/previous-api-key',
        VALIDITY_CHANGE: '/api/v1/client/api-key-validity',
        CLIENT_SOFTWARE: '/api/v1/clients-software',
        CLIENT_MODULE_SOFTWARE: '/api/v1/client/software',
        CLIENT_SOFTWARE_VERSION: `/api/v1/install-software/client/${ENTITY.CLIENT_ID}`,
        SOFTWARE_VERSION_INSTALL: '/api/v1/install-software',
        SHOW_HISTORY: `/api/v1/install-software-history/client`,
        WHITELISTED_IP: '/api/v1/white-listed-ip',
        WHITELISTED_IP_BY_ID: `/api/v1/white-listed-ip/${ENTITY.WHITE_IP_ID}`,
        CLINET_WHITELISTED_IP: `/api/v1/white-listed-ip/client/${ENTITY.CLIENT_ID}`,
        CLINET_USER: `/api/v1/client-user`,
        SUBMODULE: `/api/v1/software-submodules`,
        SOFTWARE_SUBMODULE: `/api/v1/software-submodules/software`,
        SUBMODULE_BY_ID: `/api/v1/software-submodules/${ENTITY.SUBMODULE_ID}`,
        SOFTWARE_MODULE_PLATFORM: `/api/v1/software/platform`,
        SOFTWARE_PLATFORM: `/api/v1/softwares-platform`,
        SUBMODULE_VERSION: `/api/v1/submodule-version`,
        SOFTWARE_SUBMODULE_VERSION: `/api/v1/submodule-version/submodule`,
        DOWNLOAD_SUBMODULE_VERSION: `/api/v1/submodule-version/download`,
        SOFTWARE_SUBMODULE_LIST: `/api/v1/software-submodules-all`,
        CLIENT_SOFTWARE_SUBMODULE: '/api/v1/clients-software-submodule',
        GET_SYSTEM_EVENTS: `/api/v1/event-viewer`,
        CLIENT_FREEZE_UNFREEZE: `/api/v1/client/is-freeze`,
        ENABLE_TWO_FA: `/api/v1/generate-2fa-token`,
        DISABLE_TWO_FA: `/api/v1/deactivate-2fa`,
        VERIFY_TWO_FA: `/api/v1/activate-2fa`,
        SEND_SMS: `/api/v1/send/sms`,
        VERIFY_PHONE: `/api/v1/verify/phone`,
        VERIFY_2FA_TOKEN: `/api/v1/verify-2fa-token`,
        ENABLE_SMS: `/api/v1/activate/sms`,
        DISABLE_SMS: `/api/v1/deactivate/sms`,
        REGENERATE_BACKUP_CODES: `/api/v1/regenrate-codes`,
        GET_U2F_DEVICES: `/api/v1/u2f/devices`,
        REQUEST_U2F_DEVICES: '/api/v1/u2f/register-request',
        VERIFY_U2F_DEVICES: '/api/v1/u2f/register-verify',
        MANAGE_U2F_DEVICES: '/api/v1/u2f',
        U2F_REQUEST_LOGIN: '/api/v1/u2f/auth-request',
        U2F_VERIFY_LOGIN: '/api/v1/u2f/auth-verify',
        SYSLOG_SETTINGS_LIST: '/api/v1/sysLogSettings',
        SYSLOG_SETTING: '/api/v1/sysLogSetting',
        SMS_GENERATE_LOGIN: '/api/v1/request-sms-sent',
        SMS_VERIFY_LOGIN: '/api/v1/verify-sms-login',
        GET_ALL_ML_SCAN_DATA: `/api/v1/ml-scan`,
        SHARED_AUTH_URLS: '/api/v1/shared-auth-urls',
        GET_ALL_ACTIVE_SESSON_DATA: '/api/v1/login-sessions',
        SINGLE_ACTIVE_SESSION: '/api/v1/login-session',
        LICENSE_TEMPLATE: '/api/v1/license-template',
        GET_LICENSE_TEMPLATES: '/api/v1/get-license-templates',
        GET_LICENSES: '/api/v1/get-licenses',
        LICENSE_GENERATE: '/api/v1/license',
        LICENSE_INSTANCES: '/api/v1/get-license-instance-list',
        UPDATE_LICENSE_INSTANCE: `/api/v1/update-license-instance`,
        DELETE_LICENSE_INSTANCE: `/api/v1/delete-license-instance`,
        GET_LICENSE_INSTANCE: `/api/v1/get-license-instance-list`,
        LICENSE_UPLOAD: `/api/v1/upload-encrypted-license`,
        UPLOAD_SOFTWARE_PUBLIC_KEY: `/api/v1/add-software-key`,
        CLIENT_WHITELIST_IP_SETTING: `/api/v1/client/whitelist-ip-setting`,
        IP_FIREWALL: `/api/v1/ipfilter`
    },
    PATTERN: {
        EMAIL: /\S+@\S+\.\S+/,
        PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        NUMERICONLY: /^[^a-zA-Z]*$/,
        IP: /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
    },
    API_END_POINT: 'https://api.myjson.com/',
    publickey: 'pen-test-0e95d56c-3a0b-4833-9c7b-3236ebd96b42',

    privateKey: `-----BEGIN RSA PRIVATE KEY-----
		  MIIEowIBAAKCAQEAoMIv1tvdWNgKEfgjMmKpG7LGc/fcQApk8E6ysV1bqQzhcZe+
		  +54SMg8InCN7XkTLE4QcoCMx1xZyW6jXhqPxZnnqlJCuHX3JShO9dEw9bqlZQU27
		  u3gV4o3nYJatVx5gvO923O7gRwlBM0uC7daGMS1VWxpyqW5sfRfd3K2yyS3cJzCk
		  siS0rha270nqndKIsLAM+M0LnV0K19nIzhdOnugQI1vwiYFhwEEuCexOoFxrc+8F
		  fKR0d1P3nl8MaaTLPt14ZMMcEwhYizJgES/RYSIWLeHV6Pt8LMV6vtVil8apXRnf
		  FKA5la+hFyE17IKBHpU0HAt28sxGSgdNJNa5swIDAQABAoIBAGTyzBu+ITcjIt+5
		  1FBViB/umZRFNze3zz7gDHI4T9DOV9t/ENzsnDx3GE8EMPo3dNaihV9B+pA4dkN8
		  uSrBVFunf1kfqVa3oGk2ECgxn/oXuga6C4Njm0qTFZKfy+YDG3hJX3jJQGkbNKhD
		  aMp1hfrnnswAxbQDtXiwPg/Pu498j/pQZbVEvwrAcS5MVwHQ3Ag4IR8wUzFUUP7L
		  wdlOTrsyXEbmRyAq45DTmekEkyGjzQDpGIvyw7CwhQTwxPcP17MjHzFwdbwKXTG0
		  d0REHmO92EQwOnSQj8D0ea6WvEqAH8qLaHKkWVKS+rNomGYtCu3bk5tK+be1Ta+L
		  SPb/04ECgYEA0LJ8XV2KidZhbEgB4VOERDFXdX8+nYzLT5QRzt7hOUfoHhz7q2hx
		  JSzh2d1ZAGHEtjGL0tW4FbPko9lxFAWmubAK9j2BYrsML5FOroxRByu5hhZpjkFa
		  CRujLhfeRDL3tc70NZQr/nvVHL1Mz0DIE8whFVY7t3nn6GS/8tbsRhMCgYEAxTIX
		  pTaBIkx4itHlIlIba/2741UR9GF21PYLbYSQvcH8ekWPkiL6sLoxl0Cm/IrpraQW
		  LbTZYyiUWopiGIoU/iTLWk0UazK8XfMmAsEzbCR8tHTskzeK7d0EUy1Z6Gd+O1xp
		  pXullbdOUrI3KNV4tNcY4XUqM2GogQx/2DGeseECgYEAr3vVrSBRtLNqOgWWZyS1
		  zC19MBRtz0arUl/cVYn98g9vs5qFWE07Dppd0rcX9oU/RkAFJaSF5OHeOJRHTCGw
		  XuUTYpi3KhDEH19cvNLJ83Cl0IUZ/v8euRffL4vsXoZI2jyKBl9wWs0/lqnqYz9C
		  vbrgerOHBTFhenz1Fjn4dLcCgYANBQSopNOb1x6yU69kN7WVyLfqB4ja8MBuyRvy
		  DCmeTbuEuykChgq1NmXlzZ/41pcdHtNUCze7I0fAPpjBdAR0zysZ+DLb7QqgzVf6
		  IJVrk3EkXP/trGIg2FHbyAfCn2qnMq/K/EHvg53JOfkIWifREM6UvC6gG9eLRa7/
		  MC+BIQKBgBa142X9o+d6Nr3txsY4R3UBsXoEtKZ4zYNFvAUHrTfWwHekmod+56vs
		  DTcpSptCm9Za5bFxvHQ2UfIGvfyYdq8hTjjrnrd8Kv7aHLwi93OaMnT0RZ/4cChi
		  q9mx9jhNCgLHW93qt86JlinlL/Vf0ow0Z/SfG9X/S6GIXT6vOggn
		  -----END RSA PRIVATE KEY-----`,
    publicKey: `-----BEGIN PUBLIC KEY-----
	  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoMIv1tvdWNgKEfgjMmKp
	  G7LGc/fcQApk8E6ysV1bqQzhcZe++54SMg8InCN7XkTLE4QcoCMx1xZyW6jXhqPx
	  ZnnqlJCuHX3JShO9dEw9bqlZQU27u3gV4o3nYJatVx5gvO923O7gRwlBM0uC7daG
	  MS1VWxpyqW5sfRfd3K2yyS3cJzCksiS0rha270nqndKIsLAM+M0LnV0K19nIzhdO
	  nugQI1vwiYFhwEEuCexOoFxrc+8FfKR0d1P3nl8MaaTLPt14ZMMcEwhYizJgES/R
	  YSIWLeHV6Pt8LMV6vtVil8apXRnfFKA5la+hFyE17IKBHpU0HAt28sxGSgdNJNa5
	  swIDAQAB
	  -----END PUBLIC KEY-----`,

    projectSymmetricKey: `E8sTJnxx5/QmU99+zUDub0bkYpBVgPbSBe4n3efHyWVuVemzAjHIU6OCMUQ5vpC8osXfvm884g0vicBC1G0NQgYuGLkQmD4FVswZHEhgK94JU+FpJ10VgnaUIDSbUX1aQoU06oD69IB4MV4ntG7dxnxmLxWE4o/9SIG7Sa37UeBFsqMEEnj2s1/okz8CB0P7nY0A4kzr1iAs8DbOT35ZAS8zPTM1kHL4Vhsuhap+9WGYEdJii+oeySZsfyguMOPTZDkc/r0eRGg21ukOSpvNgxtBofhKM9v9JIpbhtZKbpjS4ZpYvYzPzDDHoOns2UafwsdCUg9xa/Yl3PW0MI+zLQ==`,
    GRAPH_COLOR: {
        LOW: '#92D050',
        MEDIUM: "#4472C4",
        HIGH: "#FFA500",
        CRITICAL: "#FF0000",
        NOTES: "#808080",
        DEFAULT: '#ff000075'
    },
    EXCEL_COLOR: {
        LOW: 'FF92D050',
        MEDIUM: 'FF4472C4',
        HIGH: 'FFFFA500',
        CRITICAL: 'FFFF0000',
        NOTES: 'FF808080',
        DEFAULT: 'FF808080'
    },
    viewProject: [
        'name', 'projectDescription', 'ipAddress', 'client', 'applicationName', 'hostName', 'pointOfContact'
    ],
    viewVulnerability: [
        'title', 'description', 'stepToReproduce', 'solution', 'cvss', 'severity', 'status', 'location', 'cvssScore'
    ],
    USER_TYPE: {
        CLIENT: 'CLIENTAPP',
        ADMIN: 'ADMINAPP'
    },
    INACTIVITY_TIME: process.env.REACT_APP_AUTO_LOGOUT_SECONDS,
    TFA_METHODS: {
        token2fa: 1,
        sms2fa: 2,
        u2f: 3,
        recoveryCode: 4
    },
    URL_AUTH: {
        otp: 1,
        'verify-sms': 2,
        'verify-securityKey': 3,
        'verify-recoveryCode': 4
    },
    LOGIN_METHODS_TEXT: {
        token2fa: ' OTP',
        sms2fa: ' SMS',
        u2f: ' security key',
        recoveryCode: ' recovery code'
    },
    LOGIN_METHODS_TYPE: {
        1: 'otp',
        2: 'verify-sms',
        3: 'verify-securityKey',
        4: 'verify-recoveryCode'
    }
};
