
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export const requestForSmsSendAtLogin = (object) => {
    return httpservice()
        .post(`${constants.API.SMS_GENERATE_LOGIN}`, object)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};

export const verifySmsAtLogin = object => {
    return httpservice()
        .post(`${constants.API.SMS_VERIFY_LOGIN}`, object)
        .then(function (response) {
            return response.data.response;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};