

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationEN from './locales/en/locale-en';
import translationRU from './locales/ru/locale-ru';

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en-US',
    debug: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
