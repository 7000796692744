
/* jshint esversion: 6 */

import React, { Component } from 'react';
import styles from '../../assets/jss/sideNavigationStyle';
import { withStyles } from '@material-ui/styles';
import ExpendedMenuBar from './ExpendedMenuBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShortMenuBar from './ShortMenuBar';
import Collapse from '@material-ui/core/Collapse/Collapse';
import menuItems from '../../menu';
import adminMenuItems from '../../adminMenu';

class SideNavigationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showExpendedMenu: true
        };
        this.currentSelectedMenu = "";
    }

    componentDidMount() {
        // getting the complete current URL
        const pathname = window.location.pathname;
        if (pathname && pathname.split('/') && pathname.split('/')[1]) {
            const activeLink = pathname.split('/')[1]; // getting the tab
            if (activeLink.includes('user')) {
                this.currentSelectedMenu = 'user';
            } else if (activeLink.includes('client')) {
                this.currentSelectedMenu = 'client';
            } else if (activeLink.includes('software')) {
                this.currentSelectedMenu = 'software';
            } else if (activeLink.includes('platform')) {
                this.currentSelectedMenu = 'platform';
            } else if (activeLink.includes('system-event')) {
                this.currentSelectedMenu = 'systemEvent';
            } else if (activeLink.includes('syslog-setting')) {
                this.currentSelectedMenu = 'syslogSetting';
            }
        } else {
            this.currentSelectedMenu = 'dashboard';
        }
    }

    /**
     * change menu type , i.e expended mode or min variant
     */
    changeTheme = () => {
        let currentState = this.state.showExpendedMenu;
        this.setState(prevState => ({
            showExpendedMenu: !currentState
        }));
        this.props.onChangeMenuType();
    };

    onMenuSelect = (activeMenu) => {
        if (activeMenu && this.currentSelectedMenu != activeMenu) {
            this.currentSelectedMenu = activeMenu;
        }
    }
    render() {
        const { classes, isWhitelistIP } = this.props;

        let roles = this.props.userRoles;
        let menuList = [];
        // if (roles && roles.length && this.props.loginUser.role.roleName==="superAdmin") {
        //     menuList = menuItems.data.filter(item => roles.indexOf(item.module) > -1);
        // }else{
        // const clientDataResult = this.props.clientData;
        // let whitelist = null;
        // if(clientDataResult.length !== 0){
        //     whitelist = clientDataResult.data[0].isWhitelistIP;
        // }
        // if(whitelist !== null && !whitelist){
        //     adminMenuItems.data = adminMenuItems.data.filter(item => item.module !== "whiteListedIp")
        // }
        // menuList = adminMenuItems.data.filter(item => roles.indexOf(item.module) > -1);
        menuList = adminMenuItems.data;
        // let menuList = adminMenuItems.data;
        if (this.props.loginUser.role.roleName !== "therapist") {
            // If the user is not a therapist, remove the second last menu item
            menuList = menuList.filter(item => item.module !== "billing");
        }
        // }
        return (
            <>
                {/* {console.log(menuList, this.currentSelectedMenu, roles)} */}
                <div className={classes.sidebar + ' ' + (this.state.showExpendedMenu ? ' ' : 'sidebarClosed')}>
                    <Collapse in={this.state['showExpendedMenu']} timeout="auto" unmountOnExit>
                        <ExpendedMenuBar menuList={roles} menuItems={menuList} defaultSelectedMenu={this.currentSelectedMenu} onMenuSelect={this.onMenuSelect} />
                    </Collapse>
                    <Collapse in={!this.state['showExpendedMenu']} timeout="auto" unmountOnExit>
                        <ShortMenuBar menuList={roles} menuItems={menuList} defaultSelectedMenu={this.currentSelectedMenu} onMenuSelect={this.onMenuSelect} />
                    </Collapse>

                    <div className={classes.expendMenu} onClick={this.changeTheme}>
                        <ArrowBackIcon />
                    </div>

                </div>
                <div className={classes.mobileExpendMenu} onClick={this.changeTheme}>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                </div>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SideNavigationView);
