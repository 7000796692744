
/* jshint esversion: 6 */

const sideNavigationStyle = theme => ({
    sidebar: {
        background: theme.palette.sidebarBg.color,
        width: `${theme.menu}px`,
        height: 'calc(100vh - 60px)',
        position: 'fixed',
        left: 0,
        top: '60px',
        zIndex: '999',
        transition:'0.3s width linear',
        '&.sidebarClosed':{
            '@media (max-width:1024px)':{
                display:'none'
            },
            '& > div':{
                overflow:'inherit'
            }
        },
        '& > div':{
            height:'100% !important',
            overflow: 'auto',
            '& > div':{
                height:'100% !important',
            }
        }
    },
    sidebarUl: {
        width: '100%',
        padding: 0,
        margin: '25px 0 0'
    },
    sidebarUlUl: {
        padding: 0,
        margin: 0,
        position: 'absolute',
        left: '100%',
        top: 0,
        zIndex: 9,
        background: theme.palette.primary.main,
        width: '250px'
    },
    sidebarParentMenu: {
        position: 'relative',
        minHeight: 45,
        '& svg':{
            minWidth:24,
            height:24,
            fill:theme.palette.modalTextColor.color
        }
    },
    expendMenu: {
        position: 'fixed',
        bottom: '20px',
        left: `${theme.menu - 20}px`,
        background: theme.palette.secondary.main,
        zIndex: 9999,
        borderRadius: '50%',
        textAlign: 'center',
        padding: '10px 10px 6px',
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
        transform: theme.menu > 57 ? 'rotate(0)' : 'rotate(180deg)',
        display:'none',
    },
    mobileExpendMenu:{
            display:'flex',
            color:theme.palette.modalTextColor.color,
            position: 'fixed',
            top:0,
            left:0,
            zIndex:'1299',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            height:60,
            width:60,
            cursor:'pointer',
            '& .icon-bar':{
                width:17,
                borderRadius:1,
                height:1.69,
                display:'block',
                background:theme.palette.sidebarIconBg.color,
                '& + .icon-bar':{
                  marginTop:2.5
                }
              },
        '@media (max-width:1024px)':{
                background:'none',
                width:40,
        }
    },
    list: {
        width: '100%',
        top: '60px',
        paddingTop: 0,
        background: theme.palette.sidebarBg.color,
        height:'100%',
        borderRight:0,
        position:'static',
        overflowY:'inherit',
        '& > div':{
            height:'100% !important'
        },
        '& ul':{
            padding:0,
            height:'100% !important'
        }
    },
    links: {
        textDecoration: 'none',
        color: theme.palette.secondary.contrastText,
        borderBottom:0,
        padding: 0,
        '& svg':{
            height: 24,
            fontSize: 24,
            width: 24,
            fill:theme.palette.modalTextColor.color
        }
    },
    drawer: {
        top: '60px'
    },
    removePadding: {
        padding: 0,
        overflowY: 'inherit',
        maxHeight: 'calc(100vh - 74px)'
    },
    subMenu: {
        float: 'left',
        width: '100%'
    },
    subMenuList: {
        background:'none !important',
        padding: '8px 10px 8px 27px',
        '&:hover':{
            backgroundColor:theme.palette.dropdownHover.color + '!important'
        }
    },
    menuColor: {
        color: theme.palette.secondary.contrastText,
        minHeight: 61,
        padding: '8px 14px 8px 14px'
    },
    menuColors: {
        minHeight: 61,
        fontWeight: 'bold',
        width: '95%',
        borderTopRightRadius: 33,
        borderBottomRightRadius: 33,
        borderLeft:'3px solid transparent',
        '&:hover':{
            borderLeft:'3px solid '+theme.palette.primary.color,
            backgroundColor: theme.palette.activeMenuBg.color + ' !important',
        }
    },
    activeMenu: {
        background: theme.palette.activeMenuBg.color + ' !important',
        color: theme.palette.modalTextColor.color,
        borderLeft:'3px solid '+ theme.palette.primary.color,
        '& svg':{
            fill: theme.palette.primary.color,
            minWidth:24,
            height:24,
            '& + div span':{
                fontWeight:'400'
            }
        },   
        '&:hover':{
            backgroundColor: theme.palette.activeMenuBg.color + ' !important',
        }
    },
    iconChange: {
        fontSize: 50
    },
    selectedSubMenuTextColor: {   
        color: theme.palette.secondary.dark,
        fontWeight: 700,
        backgroundColor: theme.palette.activeMenuBg.color + ' !important',
        '& svg':{
            fill:theme.palette.primary.color +' !important',
        },
        '& span':{
            backgroundColor:'transparent !important',
        },
        '&:hover':{
            backgroundColor: theme.palette.activeMenuBg.color + ' !important',
            '& span':{
                backgroundColor: 'transparent !important',
            }
        },
    },
    submenuColor:{
        minHeight:61,
        '&.Mui-selected':{
            backgroundColor:'unset !important',
            '&:hover':{
                backgroundColor:'unset !important',
            },
        },
        '& svg':{
            fill:theme.palette.modalTextColor.color,
            minWidth:24,
            height:24,
            maxWidth:24
        }
    },
    dropdownMenu:{
      '& div + div':{
            
      },
      '& > div + div a span':{
          fontSize:14,
          color:theme.palette.modalTextColor.color,
          fontWeight:400
      }
    },
    menuColorDropdown:{
        '&:hover':{
            backgroundColor:'transparent !important'
        }
    }
});

export default sideNavigationStyle;
