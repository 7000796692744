
import {httpservice} from '../services';
import {constants} from "../constant/constant";
import { CLIENT_SOFTWARE } from "../constant/actionTypes";
let clientSoftwareChartDataApiEndPoint = constants.ADMIN_URL;

export default object => dispatch => {
    return httpservice()
        .get(`${clientSoftwareChartDataApiEndPoint}${constants.API.CLIENT_SOFTWARE_CHART}`)
        .then(clientSoftwareChartData => {
            dispatch({
                payload: clientSoftwareChartData.data,
                type: CLIENT_SOFTWARE.GET.SUCCESS
            });
        })
        .catch(function (clientSoftwareChartError) {
            dispatch({
                payload: clientSoftwareChartError,
                type: CLIENT_SOFTWARE.GET.ERROR
            });
        })
};
