import React, {Component} from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "@material-ui/core/Button";
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/CropImageStyle';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import {convertBlobToFileObject} from "../../helper/StringUtils";
import Clear from '@material-ui/icons/Clear';
import {withTranslation, Trans} from "react-i18next";

class CImageCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crop: {
                unit: "%",
                width: 30,
                aspect: 16 / 9
            },
            closeModal: true
        }
        this.croppedImageUrl = null;
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            this.croppedImageUrl = await this.getCroppedImg(this.imageRef, crop);

        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                convertBlobToFileObject(blob).then((file) => {
                    resolve(file);
                });
            }, "image/jpeg");
        });
    }

    modalDismiss = event => {
        this.setState({
            closeModal: false
        })
        this.props.closeCropImageModal();
    }
    modalDismiss1 = event => {
        this.setState({
            closeModal: false
        })
        this.props.getImage(this.croppedImageUrl);
        this.props.closeCropImageModal1();
    }

    render() {

        const {classes} = this.props;
        const isModalOpen = this.props.openModal;
        return (

            <React.Fragment>
                <Dialog
                    open={isModalOpen}
                    onClose={this.modalDismiss}
                    fullWidth={true}
                    maxWidth="sm"
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    className={classes.cropModal}
                >
                    <div className={classes.crop}>
                        <DialogTitle className={classes.dialogTitle}
                                     id="simple-dialog-title"><Trans>COMMON.CROP_IMG</Trans></DialogTitle>
                        <Button className={classes.cancelCrossIcon} onClick={this.modalDismiss}>
                            <Clear/>
                        </Button>
                        <ReactCrop
                            className={classes.cropImage}
                            src={this.props.imageToCrop}
                            crop={this.state.crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                        <DialogActions className={classes.dialogAction}>
                            <Button className={classes.defaultButton} onClick={this.modalDismiss}>
                                <Trans>BUTTON.ABORT</Trans>
                            </Button>
                            <Button className={classes.activeButton} onClick={this.modalDismiss1}>
                                <Trans>BUTTON.EDIT</Trans>
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(CImageCrop));
