

import React, {Component} from 'react';
import {Close, Done, PriorityHigh} from '@material-ui/icons';
import {Trans, withTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/styles';
import styles from '../../assets/jss/customMessageStyles';

class CustomMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: false
        }
    }

    hideSuccessMessage = (e) => {
        e.preventDefault();
        this.props.history.state = {status: 'success', msg: 'TOASTER.EMAIL_SUCCESS', show: false}
        this.setState({hide: !this.state.hide});
    }

    render() {
        const {classes} = this.props;
        const state = this.props.history && this.props.history.state ? this.props.history.state : {};
        let icon;
        if (state && state.status) {
            switch (state.status) {
                case 'error':
                    icon = <Close/>
                    break;
                case 'success':
                    icon = <Done/>
                    break;
                case 'warning':
                    icon = <PriorityHigh/>
                    break;
                default:
                    icon = <Close/>
            }
        }

        return (
            <React.Fragment>
                {state && state.show ?
                    <div className={`${classes.message} ${classes[state.status]}`}>
                        {icon}
                        <h5><Trans i18nKey={state.msg}></Trans></h5>
                        <p onClick={this.hideSuccessMessage}><strong>x</strong></p>
                    </div> : null}
            </React.Fragment>
        )
    }
}
export default withTranslation()(withStyles(styles, {withTheme: true})(CustomMessages));

