import React from "react";

export default () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
	 width="683.333px" height="683.986px" viewBox="0 0 683.333 683.986">
        <g>
            <path d="M49.946,153.734c8.793,0,15.947-7.152,15.947-15.944v-29.173c0-23.379,19.02-42.398,42.398-42.398h29.171
                c8.793,0,15.947-7.153,15.947-15.945c0-8.794-7.154-15.948-15.947-15.948h-29.171C67.327,34.326,34,67.653,34,108.617v29.173
                C34,146.582,41.153,153.734,49.946,153.734z"/>
            <path d="M545.872,66.218h29.17c23.379,0,42.398,19.02,42.398,42.398v29.173c0,8.792,7.152,15.944,15.945,15.944
                s15.947-7.152,15.947-15.944v-29.173c0-40.963-33.326-74.29-74.291-74.29h-29.17c-8.795,0-15.949,7.154-15.949,15.947
                C529.922,59.065,537.077,66.218,545.872,66.218z"/>
            <path d="M137.462,617.767h-29.171c-23.378,0-42.398-19.02-42.398-42.396v-29.173c0-8.794-7.154-15.948-15.947-15.948
                c-8.792,0-15.946,7.154-15.946,15.948v29.173c0,40.964,33.327,74.29,74.291,74.29h29.171c8.793,0,15.947-7.154,15.947-15.947
                S146.255,617.767,137.462,617.767z"/>
            <path d="M633.385,530.249c-8.793,0-15.945,7.154-15.945,15.948v29.173c0,23.377-19.02,42.396-42.396,42.396h-29.172
                c-8.793,0-15.947,7.153-15.947,15.946s7.154,15.947,15.947,15.947h29.172c40.963,0,74.291-33.326,74.291-74.29v-29.173
                C649.333,537.403,642.18,530.249,633.385,530.249z"/>
            <path d="M627.487,341.991c0-8.586-6.984-15.569-15.57-15.569h-540.5c-8.586,0-15.571,6.983-15.571,15.569
                c0,8.588,6.985,15.572,15.571,15.572h540.5C620.502,357.563,627.487,350.579,627.487,341.991z"/>
            <path d="M568.506,454.31c0-8.845-1.263-17.572-3.76-26.035c9.208-7.309,17.017-16.384,22.818-26.573
                c1.674-2.94,1.658-6.55-0.043-9.475c-1.701-2.926-4.829-4.726-8.213-4.726h-23.61c-2.788,0-5.436,1.225-7.24,3.35
                c-4.52,5.32-9.97,9.666-16.2,12.916c-9.364,4.886-13.028,16.489-8.168,25.862c3.985,7.692,6.006,15.997,6.006,24.683
                c0,20.398-11.329,38.789-29.562,47.993c-5.215,2.629-8.907,7.414-10.13,13.126c-2.619,12.229-11.629,23.656-25.37,32.176
                c-14.476,8.974-32.873,13.916-51.805,13.916c-19.208,0-38.008-5.137-52.373-14.203l-0.057-39.253h18.728
                c6.771,15.756,22.26,26.226,39.928,26.226c23.989,0,43.506-19.521,43.506-43.514c0-23.995-19.517-43.517-43.506-43.517
                c-15.895,0-30.434,8.771-38.025,22.395h-20.686l-0.063-43.302h74.187c7.237,14.605,22.39,24.31,39.018,24.31
                c23.987,0,43.502-19.521,43.502-43.514c0-4.417-0.666-8.786-1.979-12.984c-1.24-3.966-4.913-6.665-9.067-6.665H484.36
                c-3.813,0-7.258,2.28-8.746,5.792c-1.489,3.511-0.733,7.572,1.918,10.313c0.934,0.964,1.447,2.222,1.447,3.541
                c0,2.816-2.285,5.107-5.094,5.107c-2.81,0-5.096-2.291-5.096-5.107c0-1.778,0.908-2.983,1.45-3.544
                c2.649-2.741,3.403-6.802,1.915-10.313c-1.489-3.51-4.933-5.79-8.746-5.79h-21.956c-0.994,0-1.961,0.154-2.874,0.444h-84.56
                c-0.908-0.289-1.876-0.444-2.88-0.444h-19.554c-1.004,0-1.971,0.155-2.88,0.444H307.12c-0.914-0.29-1.88-0.444-2.873-0.444h-24.776
                c-4.234,0-7.957,2.802-9.129,6.87c-1.172,4.069,0.49,8.422,4.076,10.674c1.7,1.068,2.96,2.839,3.138,4.407
                c0.049,0.438,0.181,1.604-1.472,3.257c-3.626,3.628-5.623,8.451-5.622,13.582c0.001,5.131,2,9.953,5.627,13.577
                c3.533,3.531,8.486,5.558,13.589,5.558c5.165,0,9.983-1.976,13.571-5.563c3.902-3.905,7.002-8.489,9.164-13.508h9.719
                c0.026,15.215,0.052,29.781,0.079,43.303H255.59c-5.129,0-9.952,1.998-13.584,5.628c-16.954,16.965-16.955,44.568,0,61.532
                c7.891,7.896,19.101,12.425,30.757,12.425c11.667,0,22.884-4.527,30.775-12.424c7.483-7.492,7.479-19.675-0.009-27.158
                c-0.573-0.572-1.175-1.104-1.804-1.594h20.568c0.034,13.441,0.083,30.661,0.11,39.219c-14.581,9.09-33.353,14.237-52.299,14.237
                c-18.931,0-37.329-4.942-51.804-13.916c-13.742-8.52-22.751-19.946-25.369-32.175c-1.222-5.711-4.913-10.495-10.125-13.124
                c-6.929-3.498-12.878-8.293-17.591-14.082c2.413,0.257,4.854,0.386,7.319,0.386c15.427,0,31.092-5.605,42.978-15.379
                c8.036-6.611,9.251-18.988,2.651-27.029c-3.605-4.393-9.043-6.911-14.919-6.911c-4.462,0-8.76,1.51-12.1,4.248
                c-5.321,4.357-11.763,6.66-18.631,6.66c-6.75,0-13.095-2.233-18.256-6.364c0.973-4.922,2.643-9.684,4.986-14.207
                c4.86-9.378,1.195-20.98-8.171-25.863c-6.229-3.249-11.679-7.594-16.199-12.915c-1.805-2.125-4.452-3.35-7.24-3.35h-23.609
                c-3.384,0-6.512,1.8-8.213,4.726c-1.701,2.925-1.717,6.534-0.043,9.475c5.801,10.189,13.61,19.265,22.818,26.573
                c-2.497,8.459-3.758,17.187-3.758,26.035c0,31.642,16.372,61.158,42.97,77.933c13.646,40.045,59.163,67.69,112.305,67.69
                c25.757,0,50.875-6.846,71.466-19.38c20.31,12.534,45.493,19.38,71.66,19.38c53.145,0,98.661-27.646,112.305-67.69
                C552.134,515.468,568.506,485.952,568.506,454.31z M276.375,509.663c-0.814,0.813-2.16,1.3-3.601,1.3
                c-1.443,0-2.789-0.484-3.599-1.296c-0.471-0.472-0.834-1.016-1.085-1.601h10.091C277.551,508.557,276.948,509.09,276.375,509.663z
                M419.456,495.884c-2.809,0-5.093-2.29-5.093-5.105c0-2.816,2.284-5.107,5.093-5.107c2.81,0,5.096,2.291,5.096,5.107
                C424.551,493.594,422.265,495.884,419.456,495.884z"/>
            <path d="M114.829,250.098c0,11.152,1.954,21.968,5.817,32.258c-0.001,0.001-0.002,0.002-0.004,0.003
                c-3.27,2.454-4.603,6.725-3.309,10.604c1.293,3.878,4.923,6.494,9.012,6.494h29.058c5.141,0,9.349-4.089,9.496-9.228
                c0.112-3.918-0.946-7.717-3.061-10.986c-5.626-8.692-8.6-18.77-8.6-29.145c0-11.934,15.238-28.466,39.645-43.583
                c6.124,17.604,17.41,32.956,32.598,44.072c3.218,2.356,7.098,3.602,11.222,3.602c6.226,0,12.065-2.897,15.62-7.752
                c3.03-4.139,4.267-9.211,3.483-14.28c-0.784-5.07-3.496-9.531-7.635-12.561c-20.927-15.319-27.96-44.305-16.36-67.422
                c9.055-18.049,27.901-29.711,48.013-29.711c16.377,0,31.875,7.543,42.021,20.316c-0.03,7.075-0.04,20.087-0.029,41.01h-44.482
                c-7.794,0-14.758,4.653-17.741,11.853c-2.984,7.2-1.353,15.414,4.158,20.928c11.466,11.472,11.466,30.138,0,41.61
                c-5.232,5.236-12.804,8.239-20.773,8.24c-7.975,0.001-15.551-3.003-20.786-8.24c-3.627-3.63-8.451-5.628-13.583-5.628
                c-5.128,0-9.949,1.996-13.578,5.623c-7.489,7.487-7.491,19.67-0.006,27.157c1.674,1.676,3.507,3.32,5.446,4.888
                c0.548,0.443,1.145,0.824,1.777,1.135l14.489,7.128c1.305,0.642,2.74,0.976,4.194,0.976h47.18c1.415,0,2.811-0.316,4.088-0.924
                c7.003-3.338,13.301-7.78,18.719-13.201c16.652-16.661,23.22-40.708,18.118-63.134h12.812c0.019,18.393,0.045,40.705,0.081,67.771
                c0.007,5.242,4.258,9.487,9.5,9.487h19.554c0.006,0,0.014,0,0.02,0c5.247,0,9.5-4.253,9.5-9.5c0-0.213-0.007-0.425-0.021-0.635
                l-0.097-67.122h12.455c7.301,14.535,22.141,23.892,38.843,23.892c23.987,0,43.502-19.521,43.502-43.516
                s-19.515-43.516-43.502-43.516c-16.851,0-32.08,9.873-39.225,24.73h-12.129l-0.06-41.146c9.897-12.687,25.844-20.18,43.243-20.18
                c29.604,0,53.688,24.103,53.688,53.729c0,5.394,2.07,12.874,11.935,17.873c44.994,22.792,60.961,44.43,60.962,56.033
                c0,12.141-4.723,24.995-12.326,33.546c-2.486,2.796-3.1,6.791-1.567,10.204c1.533,3.413,4.926,5.609,8.667,5.609h32.119
                c0.007,0,0.013,0,0.02,0c5.247,0,9.5-4.253,9.5-9.5c0-3.115-1.499-5.88-3.815-7.613c3.861-10.291,5.813-21.103,5.813-32.246
                c0-37.526-39.029-67.081-73.676-85.92c-5.941-45.341-45.063-80.124-91.318-80.124c-23.509,0-45.788,8.372-62.543,23.248
                c-16.733-14.874-38.522-23.248-61.146-23.248c-31.907,0-62.067,17.002-78.711,44.371c-6.76,11.117-11.104,23.64-12.694,36.506
                c-0.641,0.342-1.302,0.697-1.984,1.065c-6.746,3.641-16.941,9.542-27.205,16.975C129.767,204.302,114.829,226.886,114.829,250.098z
                M411.682,207.681c-2.81,0-5.096-2.291-5.096-5.106c0-2.816,2.286-5.106,5.096-5.106c2.809,0,5.094,2.291,5.094,5.106
                C416.776,205.39,414.491,207.681,411.682,207.681z"/>
            <path d="M217.674,401.078c-3.577,3.269-5.77,7.729-6.173,12.538c-0.441,5.136,1.147,10.123,4.455,14.021
                c3.304,3.917,7.933,6.316,13.035,6.757c0.053,0.005,0.105,0.009,0.159,0.013c0.502,0.034,0.988,0.053,1.493,0.053
                c4.799,0,9.398-1.786,12.948-5.029c3.563-3.256,5.759-7.702,6.181-12.521c0.019-0.212,0.03-0.426,0.034-0.639
                c0.548-10.306-7.21-19.281-17.533-20.147C226.949,395.664,221.638,397.467,217.674,401.078z"/>
            <path d="M445.134,288.981c0.053,0.004,0.105,0.009,0.158,0.012c0.503,0.035,0.988,0.053,1.493,0.053
                c4.799,0,9.397-1.787,12.948-5.03c3.563-3.254,5.758-7.7,6.182-12.519c0.019-0.218,0.03-0.437,0.034-0.655
                c0.54-10.299-7.216-19.267-17.534-20.133c-0.544-0.047-1.097-0.071-1.644-0.071c-4.799,0-9.396,1.782-12.956,5.027
                c-3.578,3.27-5.771,7.731-6.171,12.536c-0.442,5.138,1.147,10.126,4.454,14.021C435.402,286.141,440.032,288.542,445.134,288.981z"
                />
        </g>
    </svg>
);