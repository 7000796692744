
// import React, {Component} from 'react';
// import {withStyles} from '@material-ui/styles';
// import styles from '../../assets/jss/LoginScreenStyles';
// import {Button, Grid} from '@material-ui/core';
// import CTextField from '../Utils/CTextField';
// import {Trans, withTranslation} from "react-i18next";
// import {connect} from "react-redux";
import { Comment } from '@material-ui/icons';

import {
  requestForSmsSendAtLogin,
  verifySmsAtLogin,
  verify2FAToken,
  requestAtLogin,
  verificationAtLogin
} from '../../actions';
// import CustomMessages from './CustomMessages';
import { isSupported, sign } from 'u2f-api';
import headerLogo from '../../assets/images/TEA-logo.png';
import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import styles from '../../assets/jss/OTPScreenStyle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CheckIfNotEmpty, CheckIfIterationCountIsValid } from '../../helper/StringUtils';
import { ROUTE } from '../../constant';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { doLogin, getPlatform } from '../../actions';
import { AuthService, EncryptionServices } from '../../services';
import loginMainLogo from '../../assets/images/TEA-logo.png';
import { renderTextField } from '../Form/Fields';
import { required, email } from '../Form/validate';
import Mail from '@material-ui/icons/MailOutline';
import Lock from '@material-ui/icons/LockOpen';
import Autorenew from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';
import U2fInserstDeviceModal from '../User/AuthorisationModals/U2fInsertDeviceModal';

import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import LoginBackButton from './../../assets/images/login-button.svg';
import CustomMessages from './CustomMessages';
import MobileLogo from './../../assets/images/software_light_mobile.svg';
import webLines from './../../assets/images/update_login.svg';
import userIcon from './../../assets/images/login-update-icon.png';
import { constants } from '../../constant/constant';
import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';
import { TextField } from '@material-ui/core';

const onSubmit = async (values, dispatch, props) => {};

class OTPScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: { otp: '', sms: '', recoveryCode: '' },
      showPrivateKeyDialog: false,
      fromBackupCode: false,
      isError: false,
      timer: 60,
      smsSent: false,
      isValidOtp: false,
      otpKey: Math.random() * 100,
      showDeviceList: false,
      isU2FModelOpen: false
    };
    this.timerInterval = '';
    this.url = '';
    this.activeMethod = null;
    this.deviceList = 0;
    this.token = JSON.parse(localStorage.getItem('cred'))?.token;
  }

  componentDidMount() {
    if (this.url === 'verify-sms') this.verifySentSms(); // function call
    if (this.url === 'verify-securityKey') {
      EncryptionServices.decodeJwtToken(this.token).then(decryptToken => {
        this.deviceList = decryptToken.deviceList;
        this.validateU2F();
      });
    }
  }

  handleIsU2FModel = () => {
    this.setState({ isU2FModelOpen: !this.state.isU2FModelOpen });
  };

  /**
   * verifySentSms sent sms on the registered mobile number
   */
  verifySentSms = () => {
    requestForSmsSendAtLogin({ token: this.token }).then(res => {
      if (res.status === 200) {
        let inputValue = this.state.inputValue;
        inputValue.sms = null;
        clearInterval(this.timerInterval);
        this.setState({ timer: 60, inputValue: inputValue, smsSent: true });
        this.setTimer();
        return;
      } else {
        if (res.data) {
          if (typeof res.data.response.errors === 'string') {
            this.props.history.state = {
              status: 'error',
              msg: `API.${res.data.response.errors}`,
              show: true
            };
            this.setState({ isError: !this.state.isError });
            return;
          } else {
            res.data.response.errors.forEach(data => {
              this.props.history.state = { status: 'error', msg: `API.${data.msg}`, show: true };
              this.setState({ isError: !this.state.isError });
              return;
            });
          }
        }
      }
    });
  };

  /**
   * handleCloseRequired is for hidding the error border
   * @param elementDom
   */
  handleCloseRequired = elementDom => {
    elementDom.classList.remove('warning');
  };

  /**
   * handleSmsSubmit validate sms otp
   *  @param event
   */
  handleSmsSubmit = event => {
    event.preventDefault();
    verifySmsAtLogin({ smsOtp: this.state.inputValue.sms, token: this.token })
      .then(res => {
        this.clearState();
        if (res.userData) {
          // localStorage.setItem('cred', JSON.stringify(res));
          AuthService.authenticate(res.token, JSON.stringify(res));
          this.props.getPermission();
          this.props.history.push('/');
        } else {
          if (typeof res.response.errors === 'string') {
            this.clearState();
            this.props.history.state = {
              status: 'error',
              msg: `API.${res.response.errors}`,
              show: true
            };
            this.setState({ isError: !this.state.isError, isValidOtp: false });
          } else {
            res.response.errors.forEach(data => {
              this.clearState();
              this.props.history.state = { status: 'error', msg: `API.${data.msg}`, show: true };
              this.setState({ isError: !this.state.isError, isValidOtp: false });
            });
          }
        }
      })
      .catch(err => {
        this.clearState();
        this.props.history.state = { status: 'error', msg: 'TOASTER.INVALID_OTP', show: true };
        this.setState({ isError: !this.state.isError, isValidOtp: false });
      });
  };

  /**
   * handleOtpSubmit validate 2fa otp
   *  @param event
   */
  handleOtpSubmit = event => {
    event.preventDefault();
    const credential = {
      challengeToken: this.token,
      otp: this.state.inputValue.otp
    };
    this.submitOTPLogin(credential);
  };

  submitOTPLogin = credential => {
    this.clearState();
    verify2FAToken(credential)
      .then(result => {
        if (result && result.response && result.response.userData && result.status === 200) {
          // localStorage.setItem('cred', JSON.stringify(result.response));
          AuthService.authenticate(result.response.token, JSON.stringify(result.response));
          this.props.getPermission();
          this.props.history.push('/');
        } else {
          this.clearState();
          this.props.history.state = {
            status: 'error',
            msg: `TOASTER.2FA_VERIFY_FAIL`,
            show: true
          };
          this.setState({ isError: !this.state.isError, isValidOtp: false });
        }
      })
      .catch(error => {
        this.clearState();
        this.props.history.state = { status: 'error', msg: `TOASTER.2FA_VERIFY_FAIL`, show: true };
        this.setState({ isError: !this.state.isError, isValidOtp: false });
      });
  };

  /**
   * handleSmsSubmit validate recoveryCode otp
   *  @param event
   */

  handleRecoveryCodeSubmit = event => {
    event.preventDefault();
    const recoverCodeCredential = {
      challengeToken: this.token,
      recovery: this.state.inputValue.recoveryCode
    };
    this.submitRecoverCodeLogin(recoverCodeCredential);
  };

  submitRecoverCodeLogin = recoverCodeCredential => {
    this.clearState();
    verify2FAToken(recoverCodeCredential)
      .then(data => {
        if (data && data.response && data.response.userData && data.status === 200) {
          // localStorage.setItem('cred', JSON.stringify(data.response));
          AuthService.authenticate(data.response.token, JSON.stringify(data.response));
          this.props.getPermission();
          this.props.history.push('/');
        } else {
          this.clearState();
          this.props.history.state = {
            status: 'error',
            msg: `TOASTER.RECOVERY_CODE_VERIFY_FAIL`,
            show: true
          };
          this.setState({ isError: !this.state.isError, isValidOtp: false });
        }
      })
      .catch(err => {
        this.clearState();
        this.props.history.state = {
          status: 'error',
          msg: `TOASTER.RECOVERY_CODE_VERIFY_FAIL`,
          show: true
        };
        this.setState({ isError: !this.state.isError, isValidOtp: false });
      });
  };

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  switchOtpMode = () => {
    this.setState({
      fromBackupCode: !this.state.fromBackupCode
    });
  };
  setTimer = bool => {
    let time = 60;
    if (time >= 0) {
      clearInterval(this.timerInterval);
    }
    this.timerInterval = setInterval(() => {
      time = time - 1;
      if (time >= 0) {
        this.setState({ timer: time });
      }
    }, 1000);
  };

  clearState = () => {
    this.setState({
      inputValue: { otp: null, sms: null, recoveryCode: null },
      otpKey: Math.random() * 100
    });
  };

  inputValue = classes => {
    let object, title, button;
    object = {
      name: 'otp',
      label: 'Verification code',
      validateForm: {
        check: true,
        message: 'VALIDATION.2FA_RANGE',
        field: 'otp'
      },
      removeErr: this.handleCloseRequired
    };
    switch (this.url) {
      case 'otp':
        object = {
          name: 'otp',
          label: 'Verification code',
          validateForm: {
            check: true,
            message: 'VALIDATION.2FA_RANGE',
            field: 'otp'
          },
          removeErr: this.handleCloseRequired
        };
        title = (
          <>
            <h2>Verify through OTP</h2>
            <p>Please enter the verification code generated from the OTP application.</p>
          </>
        );
        button = (
          <div className={classes.alignResendButton}>
            <Button
              type="submit"
              className={classes.createbtn}
              disabled={!this.state.isValidOtp}
              onClick={this.handleOtpSubmit}
            >
              <Trans>BUTTON.VERIFY</Trans>
            </Button>
          </div>
        );
        break;
      case 'verify-sms':
        object = {
          name: 'sms',
          label: 'Verification code',
          validateForm: {
            check: true,
            message: 'VALIDATION.2FA_RANGE',
            field: 'sms'
          },
          removeErr: this.handleCloseRequired
        };
        title = (
          <>
            <h2>Verify through SMS</h2>
            <p>Send a verification code to your phone number</p>
          </>
        );
        button = (
          <div className={classes.alignResendButton}>
            <Button
              type="submit"
              className={classes.createbtn}
              disabled={!(this.state.smsSent && this.state.isValidOtp)}
              onClick={this.handleSmsSubmit}
            >
              <Trans>BUTTON.VERIFY</Trans>
            </Button>
            <Button
              type="button"
              className={
                classes.createbtn + ' ' + classes.mailButton + ' ' + classes.commentIconSvg
              }
              style={{ backgroundColor: '#292929' }}
              disabled={this.state.timer > 0}
              onClick={this.verifySentSms}
            >
              <Comment />
              {this.state.timer === 0 ? (
                <Trans>SETTINGS.RESEND_SMS</Trans>
              ) : (
                <>
                  <Trans>SETTINGS.RESEND_SMS</Trans> <p className="timer">({this.state.timer})</p>
                </>
              )}
            </Button>
          </div>
        );
        break;
      case 'verify-recoveryCode':
        object = {
          name: 'recoveryCode',
          label: 'Enter Code',
          validateForm: {
            check: true,
            message: 'VALIDATION.2FA_RANGE',
            field: 'recoveryCode'
          },
          removeErr: this.handleCloseRequired
        };
        title = <h2>Recovery Code</h2>;
        button = (
          <div className={classes.alignResendButton}>
            <Button
              type="submit"
              className={classes.createbtn}
              disabled={!this.state.isValidOtp}
              onClick={this.handleRecoveryCodeSubmit}
            >
              <Trans>BUTTON.VERIFY</Trans>
            </Button>
          </div>
        );
        break;
      case 'verify-securityKey':
        title = (
          <>
            <h2>Verify Through Security Key</h2>
            <p>Login using a registered security key.</p>
          </>
        );
        button = (
          <div className={classes.alignResendButton}>
            <Button
              type="submit"
              className={classes.createbtn}
              disabled={this.state.showDeviceList}
              onClick={this.validateU2F}
            >
              <Trans>BUTTON.VERIFY</Trans>
            </Button>
          </div>
        );
        break;
    }
    return { title, object, button };
  };

  showAnotherMethods = () => {
    this.props.history.state = { method: this.url };
    this.props.history.push('/login-methods');
  };

  /**
   * setInputValue set otp value to state
   * @param e
   */

  setInputValue = e => {
    let inputValue = this.state.inputValue;
    // check sms otp is valid or not
    if (e.target.name === 'sms' || e.target.name === 'otp') {
      let otpLength;
      if (e.target.value) otpLength = e.target.value.length;
      if (otpLength === 6) this.setState({ isValidOtp: true });
      if (otpLength > 6 || otpLength < 6) this.setState({ isValidOtp: false });
    }
    if (e.target.name === 'recoveryCode') {
      let recoveryCodeLength;
      if (e.target.value) recoveryCodeLength = e.target.value.length;
      if (recoveryCodeLength > 1) this.setState({ isValidOtp: true });
      else this.setState({ isValidOtp: false });
    }

    inputValue[e.target.name] = e.target.value;
    this.setState({ inputValue: inputValue });
  };

  /**
   * Show list Of U2F if more than one Devices
   */
  validateU2F = () => {
    // If single device than it open model directly
    if (this.deviceList.length) {
      if (this.deviceList.length === 1) {
        this.validateU2FRequest(this.deviceList[0]);
      } else {
        // Else show Devices List
        this.setState({ showDeviceList: true });
      }
    }
  };

  /**
   *  Get the authRequest of particular device
   */

  validateU2FRequest = async (device, e) => {
    const deviceId = device.id;
    try {
      const supported = await isSupported();
      if (supported) {
        const responseObj = await requestAtLogin({ deviceid: deviceId, token: this.token });
        const authRequest = responseObj.response.data.authRequest;
        const authResponse = await sign(authRequest);
        const u2fResponse = { authRequest, authResponse, deviceId, token: this.token };
        this.onVerifyAtLogin(u2fResponse);
      } else {
        this.props.history.state = {
          status: 'error',
          msg: 'TOASTER.BROWSER_SUPPORT_ERROR',
          show: true
        };
        this.handleIsU2FModel();
      }
    } catch (error) {
      this.props.history.state = { status: 'error', msg: 'TOASTER.FAILED_DEVICE', show: true };
      this.setState({ showDeviceList: true });
      this.handleIsU2FModel();
    }
  };

  onVerifyAtLogin = u2fResponse => {
    verificationAtLogin(u2fResponse)
      .then(res => {
        if (res.response.userData) {
          // localStorage.setItem('cred', JSON.stringify(res.response));
          AuthService.authenticate(res.response.token, JSON.stringify(res.response));
          this.props.getPermission();
          this.props.history.push('/');
        } else {
          this.props.history.state = {
            status: 'error',
            msg: 'TOASTER.INVALID_SECURITY_KEY',
            show: true
          };
        }
        this.handleIsU2FModel();
      })
      .catch(err => {
        this.clearState();
        this.props.history.state = {
          status: 'error',
          msg: 'TOASTER.INVALID_SECURITY_KEY',
          show: true
        };
        this.handleIsU2FModel();
      });
  };

  onSelectDevice = id => {
    this.setState({ showDeviceList: false, isU2FModelOpen: true });
    setTimeout(() => {
      this.validateU2FRequest({ id });
    }, 3000);
  };

  render() {
    const { classes, ...other } = this.props;
    this.url = this.props.location.pathname.split('/').join('');
    const { title, object, button } = this.inputValue(classes);

    return (
      <React.Fragment>
        <div className={`${classes.loginScreen} loginPage`}>
          <div className={classes.login}>
            <div className={classes.loginLeft}>
              <div className={classes.headi} style={{ display: 'flex' }}>
                <Grid className={classes.divset}>
                  <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
                  <img
                    className={classes.mobileLogo}
                    width="450"
                    src={MobileLogo}
                    alt="login logo"
                  />
                </Grid>
                <div className={classes.btnset}>
                  <Button onClick={this.props.changeTheme} className={classes.removePaddingTB}>
                    {this.props.theme.mode == 'light' ? (
                      <img width="35" src={lightTheme} alt="theme" />
                    ) : (
                      <img width="35" src={darkTheme} alt="theme" />
                    )}
                  </Button>
                </div>
              </div>
              <div style={{ display: 'flex' }} className={classes.divMain}>
                <div className="rightLogo">
                  <img src={userIcon} alt="" />
                  <div>
                    <p>
                    Thera<span>Me</span>
                    </p>
                    <span className={classes.heading}>Therame Dashboard</span>
                  </div>
                </div>
                <div className={classes.rightdiv}>
                  <form
                    name="otpForm"
                    className={classes.formi}
                    // className={classes.loginRight}
                    noValidate
                    autoComplete="off"
                  >
                    <div className={classes.mobileBackgroundImage} />
                    {/* <Grid container spacing={1} alignItems="flex-end">
                            <img className={classes.loginLogo} src={loginMainLogo} alt="login logo"/>
                            <img className={classes.mobileLogo} width="450" src={MobileLogo} alt="login logo"/>
                        </Grid> */}
                    <CustomMessages {...other} />
                    {!this.state.showDeviceList ? (
                      <React.Fragment>
                        {/* <Grid container spacing={1} alignItems="flex-end" className={classes.marginBottom}> */}
                        {/* <Grid container spacing={1} alignItems="flex-end" className={classes.titleAlign}> */}
                        {title}
                        {/* </Grid> */}
                        {this.url !== 'verify-securityKey' ? (
                          <div className={classes.forEachQ}>
                            <TextField
                              className={classes.inputNew}
                              type="text"
                              name={object.name}
                              // label={object.label}
                              placeholder={object.label}
                              validateForm={object.validateForm}
                              removeErr={object.removeErr}
                              onChange={this.setInputValue}
                              key={this.state.otpKey}
                            />
                          </div>
                        ) : (
                          <span></span>
                        )}
                        <Grid
                          container
                          spacing={1}
                          alignItems="flex-end"
                          className={classes.titleAlign}
                        >
                          <p className={classes.methodsText} onClick={this.showAnotherMethods}>
                            Use another method(s)
                          </p>
                        </Grid>
                        {/* </Grid> */}
                        {button}
                      </React.Fragment>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        alignItems="flex-end"
                        className={classes.marginBottom}
                      >
                        <Grid
                          container
                          spacing={1}
                          alignItems="flex-end"
                          className={classes.alignCenter}
                        >
                          <h2>Choose Your Security Key</h2>
                        </Grid>
                        {this.deviceList.map(data => {
                          return (
                            <Grid
                              key={data.id}
                              item
                              className={`${classes.formInput} ${classes.alignCenter}`}
                            >
                              <Button
                                className={classes.createbtn}
                                // className={`${classes.methodsBorder}`}
                                onClick={this.onSelectDevice.bind(this, data.id)}
                              >
                                {data.devicename}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
  <Grid container justifyContent="flex-end"
                                              className={classes.linkCss + ' ' + classes.forgotPassword}>

                                            <Grid item xs={12}>
                                                <img src={headerLogo} className={classes.footerLogo} alt="header logo"/>

                                            </Grid>

                                        </Grid>
                  </form>
                </div>
              </div>
              <div className={classes.footi}>
                <span className={classes.copyright}>
                  {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        <U2fInserstDeviceModal
          closeModel={this.handleIsU2FModel}
          open={this.state.isU2FModelOpen}
          id={this.state.otpKey}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  formValues: getFormValues('OTPScreen')(state)
});

const stylesheets = reduxForm({
  form: 'OTPScreen',
  onSubmit
})(withStyles(styles, { withTheme: true })(OTPScreen));
export default withTranslation()(connect(mapStateToProps)(stylesheets));
