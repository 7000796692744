
import {httpservice} from '../services';
import {constants} from "../constant/constant";

export default passwordInfo => {
    return httpservice()
        .post(`${constants.API.CHANGE_PASSWORD}`, passwordInfo)
        .then(function (response) {
            return {status:1, data:response.data};
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};
