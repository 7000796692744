
import { USER } from "../constant/actionTypes";

export default (state, action) => {
    if (typeof state == 'undefined') {
        state = [];
    }

    switch (action.type) {
        case USER.ROLE_LIST.SUCCESS:
            return action.payload;
        case USER.ROLE_LIST.ERROR:
            return action.payload;
        default:
            return state;
    }
};
