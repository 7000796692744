
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import { CLIENT } from '../constant/actionTypes';
import moment from 'moment';

export default (id,newData) => dispatch=> {

    return httpservice()
        .put(`${constants.API.REGENRATE_API_KEY}/${id}`, {})
        .then(function (response) {
            const newArray = [];
            newData.forEach(data => {
                if (data.id === id) {
                    data.apiKey =response.data.response.apiKey;
                    data.apiKeyValidity =moment(response.data.response.apiKeyValidity);
                }
                newArray.push(data);
            });
            dispatch({
                payload: { data: newArray, count: newArray.length },
                type: CLIENT.GET.SUCCESS
            })
            return response.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};
