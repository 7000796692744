import React from "react";

const MailSettingIcon = () => (
    <svg className="MuiSvgIcon-root" viewBox="0 0 24 24">
        <g>
            <path
                d="M24,19V17H22.9a5,5,0,0,0-.73-1.75l.78-.78-1.41-1.41-.78.78A5,5,0,0,0,19,13.1V12H17v1.1a5,5,0,0,0-1.75.73l-.78-.78-1.41,1.41.78.78A5,5,0,0,0,13.1,17H12v2h1.1a5,5,0,0,0,.73,1.75l-.78.78,1.41,1.41.78-.78A5,5,0,0,0,17,22.9V24h2V22.9a5,5,0,0,0,1.75-.73l.78.78,1.41-1.41-.78-.78A5,5,0,0,0,22.9,19Zm-6,1a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z"/>
            <path d="M12,10.59,22.51.07A2,2,0,0,0,22,0H2a2,2,0,0,0-.51.07Z"/>
            <g>
                <path
                    d="M14.46,11h0a2,2,0,0,1,.71.13A2,2,0,0,1,17,10h2a2,2,0,0,1,1.83,1.18,2,2,0,0,1,.71-.13h0a2,2,0,0,1,1.41.59L24,12.69V2a2,2,0,0,0-.07-.51l-9.57,9.57Z"/>
                <path
                    d="M10,17a2,2,0,0,1,1.18-1.83,2,2,0,0,1,.46-2.12l.48-.48H12A2,2,0,0,1,10.59,12L.07,1.49A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2h8Z"/>
            </g>
        </g>
    </svg>
);

export default MailSettingIcon;
