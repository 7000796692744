
import { httpservice} from '../services';
import {constants} from '../constant/constant';

export default (clientId, updateObject) => {
    return httpservice()
        .put(`${constants.API.CLIENT_WHITELIST_IP_SETTING}/${clientId}`, updateObject)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}