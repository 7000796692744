
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default (softwaresData, submodulesData) => {
    return httpservice()
        .post(`${constants.API.CLIENT_SOFTWARE_SUBMODULE}`, {softwares: softwaresData ,submodules: submodulesData})
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.response && error.response.data ? error.response.data : error;
        });
};
