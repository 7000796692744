
import {CLIENT} from '../constant/actionTypes';

export default (state, action) => {
    switch (action.type) {
        case CLIENT.GET.INITIATE:
            return {isFetching: true, data: action.payload};
        case CLIENT.GET.SUCCESS:
            return {isFetching: false, data: action.payload};
        case CLIENT.GET.ERROR:
            return {isFetching: false, data: action.payload};
        default:
            return state ? state : [];
    }
};
