import React from "react";

const ManageUserIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 100 100" height="100px" id="Layer_1" version="1.1"
         viewBox="0 0 100 100" width="100px">
        <g>
            <circle cx="45.501" cy="31.958" r="17.125"/>
            <path
                d="M53.282,68.043c0-5.081,1.881-9.719,4.979-13.267c-4.075-1.068-8.351-1.638-12.761-1.638l0,0   c-14.168,0-26.961,5.872-36.088,15.312v9.923h46.713C54.321,75.351,53.282,71.818,53.282,68.043z"/>
            <path
                d="M89.059,60.973L75.493,74.537l-8.252-8.251L80.91,52.618c-2.253-1.09-4.778-1.701-7.448-1.701   c-9.457,0-17.125,7.667-17.125,17.126c0,9.457,7.668,17.124,17.125,17.124c9.458,0,17.125-7.667,17.125-17.124   C90.587,65.521,90.038,63.129,89.059,60.973z M63.802,77.977l2.668-10.919l8.252,8.251L63.802,77.977z"/>
        </g>
    </svg>
);

export default ManageUserIcon;
