import { httpservice } from '../services';
import { constants } from '../constant/constant';
import { USER } from '../constant/actionTypes';

export default data => dispatch => {
  if (Object.prototype.toString.call(data) === '[object Object]') {
    data = data.id || null;
  }
  dispatch({
    payload: [],
    type: USER.DATA.INITIATE
  });

  return httpservice()
    .get(`${constants.API.USER}/${data}`)
    .then(response => {
      dispatch({
        payload: response.data.response,
        type: USER.DATA.SUCCESS
      });
    })
    .catch(function(error) {
      dispatch({
        payload: error,
        type: USER.DATA.ERROR
      });
    });
};
