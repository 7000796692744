
import {constants} from "../constant/constant";
import {httpservice} from '../services';
import { DOWNLOAD_SOFTWARE } from "../constant/actionTypes";
let downloadSoftwareChartDataApiEndPoint = constants.ADMIN_URL;

export default object => dispatch => {
    return httpservice()
        .get(`${downloadSoftwareChartDataApiEndPoint}${constants.API.DOWNLOAD_SOFTWARE_CHART}`)
        .then(downloadSoftwareChartData => {
            dispatch({
                payload: downloadSoftwareChartData.data,
                type: DOWNLOAD_SOFTWARE.GET.SUCCESS
            });
        })
        .catch(function (downloadSoftwareChartError) {
            dispatch({
                payload: downloadSoftwareChartError,
                type: DOWNLOAD_SOFTWARE.GET.ERROR
            });
        })
};
