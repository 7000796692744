
import {combineReducers} from 'redux';
import { reducer as reduxFormReducer } from "redux-form";
import usersList from './getUsersList';
import clientUsersList from './getClientUsersList';
import userData from './getUserData';
import userRoles from './getUserRoles';
import roleList from './getRoleList';
import dashboardData from './getDashboardData'
import softwareList from './getSoftwareList';
import clientList from './getClientList';
import clientData from './getClientData';
import softwareData from './getSoftwareData';
import clientSoftwares from './getClientSoftwares';
import clientSoftwareChartData from './getClientSoftwareChartData';
import downloadSoftwareChartData from './getDownloadSoftwareChartData';
import platformList from './getPlatformList';
import softwarePlatforms from './getSoftwarePlatforms';
import getSoftwareSubmoduleData from './getSoftwareSubmoduleData';
import softwareSubmoduleAll from './getSoftwareSubmoduleAll';
import clientSoftwareSubmodules from './getClientSoftwareSubmodules';
import getSystemEventList from './getSystemEventList';
import getMLScanList from './getMLScanList';
import sharedAuthUrls from './getSharedAuthUrlsData';
import getActiveSessionData from './getActiveSessionData';
import getLicenseTemplates from './getLicenseTemplates';
import getGeneratedLicenses from './getGeneratedLicenses';
import getGeneratedLicense from './getGeneratedLicense';
import getLicenseInstances from './getLicenseInstances';
import getSingleLicenseInstance from './getSingleLicenseInstance';
import getIPFirewallData from './getIPFirewallData';
import getServerDetail from './getServerDetail';

export default combineReducers({
    form: reduxFormReducer,
    usersList,
    clientUsersList,
    userData,
    userRoles,
    roleList,
    dashboardData,
    softwareList,
    softwareData,
    clientList,
    clientData,
    getServerDetail,
    clientSoftwares,
    downloadSoftwareChartData,
    clientSoftwareChartData,
    platformList,
    softwarePlatforms,
    getSoftwareSubmoduleData,
    softwareSubmoduleAll,
    clientSoftwareSubmodules,
    getSystemEventList,
    getMLScanList,
    sharedAuthUrls,
    getActiveSessionData,
    getLicenseTemplates,
    getGeneratedLicenses,
    getGeneratedLicense,
    getLicenseInstances,
    getSingleLicenseInstance,
    getIPFirewallData
});
