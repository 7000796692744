
/* jshint esversion: 6 */

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import useStyles from '../../hooks/useStyles';
import styles from '../../assets/jss/forgotPasswordstyle';
import loginMainLogo from '../../assets/images/TEA-logo.png';
import { forgotPassword } from '../../actions';
import Grid from '@material-ui/core/Grid';
import { Trans, useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import CustomMessages from './CustomMessages';
import userIcon from './../../assets/images/login-update-icon.png';
import lightTheme from '../../assets/images/theme-light.png';
import darkTheme from '../../assets/images/theme-dark.png';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import headerLogo from '../../assets/images/TEA-logo.png';
function ForgetPassword(props) {
  const [emailId, setEmailId] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [isError, setIsError] = useState(false);
  document.title = 'Therame Dashboard - Forget Password';

  const updateEmailId = event => {
    setEmailId(event.target.value);
    if (emailId && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const classes = useStyles(styles)();
  const { t } = useTranslation('translation');

  function sendMail() {
    forgotPassword({ email: emailId }).then(res => {
      const data = res;
      if (data && data.data && data.data.status === 200) {
        props.history.state = { status: 'success', msg: 'API.' + data.data.message, show: true };
        setEmailId('');
        setIsError(!isError);
      } else {
        if (res && res.response) {
          if(res.response.errors === "USER_DOES_NOT_EXIST"){
            props.history.state = { status: 'error', msg: 'API.USER_DOES_NOT_EXIST', show: true };
 
          } else{

            props.history.state = {
              status: 'success',
              msg: 'API.PLEASE_CHECK_YOUR_EMAIL',
              show: true
            };
          }
          setEmailId('');
          setIsError(!isError);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <div className={`${classes.loginScreen} loginPage`}>
        <div className={classes.login}>
          <div className={classes.loginLeft}>
            <div className={classes.headi} style={{ display: 'flex' }}>
              <Grid className={classes.divset}>
                <img className={classes.loginLogo} src={loginMainLogo} alt="login logo" />
              </Grid>
              <div className={classes.btnset}>
                <Button onClick={props.changeTheme} className={classes.removePaddingTB}>
                  {props.theme.mode == 'light' ? (
                    <img width="35" src={lightTheme} alt="theme" />
                  ) : (
                    <img width="35" src={darkTheme} alt="theme" />
                  )}
                </Button>
              </div>
            </div>

            <div style={{ display: 'flex' }} className={classes.divMain}>
              <div className="rightLogo">
                <img src={userIcon} alt="" />
                <div>
                  <p>
                    Thera<span>Me</span>
                  </p>
                  <span className={classes.heading}>Therame Dashboard</span>
                </div>
              </div>
              <div className={classes.rightdiv}>
                <div className={classes.formi}>
                  <CustomMessages {...props} />
                  <p className={classes.pp}>
                    {t('ENTER')} <span>{t('EMAIL_ADDRESS')}</span> {t('FORGOT_PASSWORD_MESSAGE')}
                  </p>
                  <div className={classes.forEachQ}>
                    <MailOutlineIcon
                      className={classes.mailIcon}
                    />
                    <TextField
                      id="standard-name"
                      value={emailId}
                      type="email"
                      className={classes.inputNew}
                      placeholder="Your Email"
                      autoComplete="on"
                      required={true}
                      onChange={updateEmailId}
                    ></TextField>
                    {validEmail ? (
                      <label className={classes.errorMessage}>Please enter valid email</label>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={classes.btns}>
                    <Button className={classes.createbtn} disabled={validEmail || !emailId} onClick={sendMail}>
                      {t('Submit')}
                    </Button>
                  </div>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.linkCss + ' ' + classes.forgotPassword}
                  >
                    {t('REMEMBER_YOUR_PASSWORD')}
                    <Link component={RouterLink} to="/login" style={{ display: 'flex' }}>
                      <span className={classes.loginText}>
                        <Trans>LOGIN.BTN</Trans>
                      </span>
                    </Link>
                    <Grid item xs={12}>
                      <img src={headerLogo} className={classes.footerLogo} alt="header logo" />

                    </Grid>
                  </Grid>

                </div>
              </div>
            </div>
            <div className={classes.footi}>
              <span className={classes.copyright}>
                &copy; {new Date().getFullYear()} Ngxoft Solutions. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

ForgetPassword.propTypes = {};

export default ForgetPassword;
