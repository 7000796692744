const linkExpired = theme => ({
    linkExpired: {
        background: '#fff',
        height: '100vh',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '55px',
        flexDirection: 'column'
    },
    linkResend: {
        fontSize: '25px',
    },
    newLink: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 45
    },
    successIcon: {
        color: 'green',
        fontSize: 50,
        marginRight: 6
    }
});

export default linkExpired;

