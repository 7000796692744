
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function reduxStore() {
    if (process.env.NODE_ENV !== 'production') {
        /**
         * Store will be visible to browser while in mode is development
         */
        return createStore(
            rootReducer,
            composeEnhancer(applyMiddleware(thunk))
        );
    }
    return createStore(rootReducer, applyMiddleware(thunk));
}
