
import {httpservice} from '../services';
import {constants} from '../constant/constant';

export default ( ssoObject) => {
    return httpservice()
        .get(`${constants.API.PLATFORM_VERIFY}?sso=${ssoObject.sso}&redirect_uri=${ssoObject.redirectURI}&portal=${ssoObject.portal}`)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};
