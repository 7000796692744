
/* jshint esversion: 6 */

import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Drawer from '@material-ui/core/Drawer';
import {withStyles} from '@material-ui/core/styles';
import styles from '../../assets/jss/sideNavigationStyle';
import {Link} from 'react-router-dom';
import {hideOtherSubMenu, manageIcon} from '../../helper/NavigationMenu';
import {Trans, withTranslation} from 'react-i18next';

class ExpendedMenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag:false
        };
        this.activeTab = '';
        this.activeSubTab = '';
    }

    componentDidUpdate(prevProps) {
        const path = window.location.pathname;
        const {menuItems = []} = this.props;
        menuItems.map(menu => {
            if (menu.path && menu.path.split('/').join('') === path.split('/').join('')) {
                this.activeSubTab = menu.module;
                this.activeTab = menu.module;
            } else if (menu.children) {
                menu.children.map(submenu => {
                    if (submenu.path && (submenu.path === path || (submenu.path + '/') === path)) {
                        this.activeSubTab = submenu.module;
                        this.activeTab = menu.module;
                    }
                    return true;
                });
            }
            return true;
        });
    }

    /*
     * function to sets the current state of a menu item
     * i.e whether it is in expanded or collapsed or a collapsed state
     * */
    handleClick(item) {
        let newState = hideOtherSubMenu(item, this.state[item]);
        this.setState(newState);
    }

    /**
     * function to check and update for current active menu
     * @param item
     */
    checkActiveMenu = item => {
        this.activeTab = item;
        this.props.onMenuSelect(item);
    };
    checkActiveSubMenu = item => {
        this.activeSubTab = item;
        this.activeTab = item;
        this.setState({flag: !this.state.flag});
        this.props.onMenuSelect(item);
    };

    /*
     * function to handle menu , if the menu item doesn't have any child,
     * this method simply returns a clickable menu item that redirects to any location
     * and if there is no child this method uses recursion to go until the last level of
     * children and then returns the item by the first condition.
     * */
    menuHandler(children) {
        const {classes, menuList} = this.props;
        const {state} = this;
        
        if (children) {
            return children.map(subOption => {
                // if (menuList.indexOf(subOption.module) > -1) {
                    if(!this.activeTab){
                        this.activeTab = this.props.defaultSelectedMenu;
                    }
                    if (!subOption.children) {
                        return (
                            <div key={subOption.title} onClick={() => this.checkActiveSubMenu(subOption.module)}>
                                <Link
                                    to={{
                                        pathname: subOption.path,
                                        state: {
                                            permission: menuList
                                        }
                                    }}
                                    className={`${classes.links}  ${subOption.isSubMenu ? classes.subMenu : ''} `}>
                                    <ListItem
                                        className={`${classes.menuColors}  ${subOption.isSubMenu ? classes.subMenuList : ''}
                                         ${(this.activeTab === subOption.module) ? classes.activeMenu : ''} ${(subOption.isSubMenu && this.activeSubTab === subOption.module) ? classes.selectedSubMenuTextColor : ''}`}
                                        button
                                        selected={this.activeTab === subOption.module}
                                        key={subOption.title}>
                                        {!subOption.icon ? '' : manageIcon(subOption.icon)}
                                        <ListItemText inset>
                                            <span
                                                className={`${(subOption.isSubMenu && this.activeSubTab === subOption.module) ? classes.selectedSubMenuTextColor : ''}
                                         `}><Trans i18nKey={subOption.title}></Trans></span>
                                        </ListItemText>
                                    </ListItem>
                                </Link>
                            </div>
                        );
                    } else {
                        return (
                            <div key={subOption.title} onClick={() => this.checkActiveMenu(subOption.module)} className={classes.dropdownMenu}>
                                <ListItem
                                    className={`${classes.submenuColor}  ${(state[subOption.title]) ? classes.menuColorDropdown : ''}`}
                                    button
                                    selected={this.activeTab === subOption.module}
                                    onClick={() => this.handleClick(subOption.title)}>
                                    {!subOption.icon ? '' : manageIcon(subOption.icon)}
                                    <ListItemText inset>
                                        <span><Trans i18nKey={`${subOption.title}`}></Trans></span>
                                    </ListItemText>
                                    {state[subOption.title] ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={state[subOption.title]} timeout="auto" unmountOnExit>
                                    {this.menuHandler(subOption.children)}
                                </Collapse>
                            </div>
                        );
                    }
                // } else {
                //     return '';
                // }
            });
        }
    }

    render() {
        const {classes} = this.props;
        let menuList = this.props.menuItems;

        return (
            <div className={classes.list}>
                <Drawer variant="persistent" anchor="left" open classes={{paper: classes.list}}>
                    <div className={classes.removePadding}>
                        <List className={classes.removePadding}>{this.menuHandler(menuList)}</List>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const stylesheets = withStyles(styles, {withTheme: true})(ExpendedMenuBar);
export default withTranslation()(stylesheets);
