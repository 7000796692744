import { SERVER_DETAIL } from "../constant/actionTypes";

export default (state, action) => {
    if (typeof state == 'undefined') {
        state = {};
    }
    switch (action.type) {
        case SERVER_DETAIL.GET.INITIATE:
            return { isFetching: true, data: action.payload };
        case SERVER_DETAIL.GET.SUCCESS:
            return { isFetching: false, data: action.payload };
        case SERVER_DETAIL.GET.ERROR:
            return { isFetching: false, data: action.payload };
        default:
            return state ? state : [];
    }
};
