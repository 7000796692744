import {httpservice} from '../services';
import {constants} from '../constant/constant';

/**
 * get U2F Devices
 * @returns {return U2F devices}
 */
export const getU2FDevicesData = () => dispatch => {
    let query = `${constants.API.GET_U2F_DEVICES}`;
    dispatch({
        payload: [],
        type: 'GET_U2F_DEVICES_REQUEST_INITIATED'
    });

    return httpservice()
        .get(query)
        .then(function (response) {
            dispatch({
                payload: response.data.response.rows,
                type: 'GET_U2F_DEVICES_REQUEST_SUCCESS'
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: 'GET_U2F_DEVICES_REQUEST_ERROR'
            });
        });
}


/**
 * Request U2F Devices
 @returns {}
 */
export function RequestU2FDeviceChallenge() {
    return httpservice()
        .post(constants.API.REQUEST_U2F_DEVICES)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

/**
 * Request U2F Devices At Login
 @returns {}
 */
export function requestAtLogin(obj) {
    return httpservice()
        .post(constants.API.U2F_REQUEST_LOGIN, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

/**
 * Verify U2F Devices At Login
 @returns {}
 */
export function verificationAtLogin(obj) {
    return httpservice()
        .post(constants.API.U2F_VERIFY_LOGIN, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}


/**
 * Verify U2F Devices
 @returns {}
 */
export function VerifyU2FDevicesChallenge(data) {
    return httpservice()
        .post(constants.API.VERIFY_U2F_DEVICES, data)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}


/**
 * get U2F Devices
 @returns {}
 */
export function deleteU2FDevices(id) {
    return httpservice()
        .delete(`${constants.API.MANAGE_U2F_DEVICES}/${id}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

/**
 * get U2F Devices
 @returns {}
 */
export function updateU2FDevices(data) {
    return httpservice()
        .put(`${constants.API.MANAGE_U2F_DEVICES}/${data.id}`, data)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

/**
 * get U2F Devices
 @returns {}
 */
export function enableU2FVerification() {
    return httpservice()
        .post(`${constants.API.MANAGE_U2F_DEVICES}/enable`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

/**
 * get U2F Devices
 @returns {}
 */
export function disableU2FVerification() {
    return httpservice()
        .post(`${constants.API.MANAGE_U2F_DEVICES}/disable`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}