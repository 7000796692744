
import { httpservice } from '../services';
import { constants } from '../constant/constant';
import {ENTITY} from '../constant';
let apiEndPoint = constants.ADMIN_URL;

export const doSoftwareSubmoduleCreate = data => {
    return httpservice()
        .post(`${apiEndPoint}${constants.API.SUBMODULE}`, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doSoftwareSubmoduleDelete = Id => {
    const endPointUri = constants.API.SUBMODULE_BY_ID.replace(ENTITY.SUBMODULE_ID, Id);
    return httpservice()
        .delete(endPointUri)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};

export const doSoftwareSubmoduleUpdate = (Id, data) => {
    const endPointUri = constants.API.SUBMODULE_BY_ID.replace(ENTITY.SUBMODULE_ID, Id);
    return httpservice()
        .put(endPointUri, data)
        .then(function (response) {
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};
