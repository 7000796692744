import React from "react";

const ManageTemplateIcon = () => (
    <svg className="MuiSvgIcon-root" enableBackground="new 0 0 64 64" id="Layer_1" version="1.1" viewBox="0 0 64 64">
        <g>
            <path
                d="M51.871,61H26.452c-5.375,0-9.748-4.373-9.748-9.748v-14.5c0-5.375,4.373-9.748,9.748-9.748h25.419   c5.375,0,9.748,4.373,9.748,9.748v14.5C61.619,56.627,57.246,61,51.871,61z M26.452,32.309c-2.45,0-4.443,1.993-4.443,4.443v14.5   c0,2.45,1.993,4.443,4.443,4.443h25.419c2.45,0,4.443-1.993,4.443-4.443v-14.5c0-2.45-1.993-4.443-4.443-4.443H26.452z"/>
            <g>
                <path
                    d="M49.595,50.368h-6.012c-2.535,0-4.598-2.062-4.598-4.598v-3.537c0-2.535,2.063-4.598,4.598-4.598h6.012    c2.535,0,4.598,2.062,4.598,4.598v3.537C54.192,48.306,52.13,50.368,49.595,50.368z M43.582,40.112    c-1.17,0-2.122,0.952-2.122,2.122v3.537c0,1.17,0.952,2.122,2.122,2.122h6.012c1.17,0,2.122-0.952,2.122-2.122v-3.537    c0-1.17-0.952-2.122-2.122-2.122H43.582z"
                    fill="#00816f"/>
                <g>
                    <path
                        d="M35.389,48.327h-10.02c-0.683,0-1.238-0.554-1.238-1.238s0.554-1.238,1.238-1.238h10.02     c0.683,0,1.238,0.554,1.238,1.238S36.073,48.327,35.389,48.327z"
                        fill="#00816f"/>
                    <path
                        d="M35.389,42.904h-10.02c-0.683,0-1.238-0.554-1.238-1.238s0.554-1.238,1.238-1.238h10.02     c0.683,0,1.238,0.554,1.238,1.238S36.073,42.904,35.389,42.904z"
                        fill="#00816f"/>
                </g>
            </g>
            <g>
                <path
                    d="M12.042,55.599c-2.476-0.467-4.356-2.643-4.356-5.253V13.654c0-2.949,2.4-5.349,5.349-5.349h22.546    c2.949,0,5.349,2.4,5.349,5.349V21.7h5.305v-8.046C46.235,7.779,41.456,3,35.581,3H13.035C7.16,3,2.381,7.779,2.381,13.654v36.692    C2.381,56.221,7.16,61,13.035,61h1.96C13.667,59.44,12.649,57.608,12.042,55.599z"/>
            </g>
            <g>
                <path
                    d="M20.27,24.573h-6.012c-2.535,0-4.598-2.062-4.598-4.598v-3.537c0-2.535,2.063-4.598,4.598-4.598h6.012    c2.535,0,4.598,2.062,4.598,4.598v3.537C24.868,22.511,22.805,24.573,20.27,24.573z M14.258,14.317    c-1.17,0-2.122,0.952-2.122,2.122v3.537c0,1.17,0.952,2.122,2.122,2.122h6.012c1.17,0,2.122-0.952,2.122-2.122v-3.537    c0-1.17-0.952-2.122-2.122-2.122H14.258z"
                    fill="#00816f"/>
                <g>
                    <path
                        d="M37.717,21.449h-10.02c-0.683,0-1.238-0.554-1.238-1.238s0.554-1.238,1.238-1.238h10.02     c0.683,0,1.238,0.554,1.238,1.238S38.401,21.449,37.717,21.449z"
                        fill="#00816f"/>
                    <path
                        d="M37.717,16.026h-10.02c-0.683,0-1.238-0.554-1.238-1.238c0-0.683,0.554-1.238,1.238-1.238h10.02     c0.683,0,1.238,0.554,1.238,1.238C38.955,15.472,38.401,16.026,37.717,16.026z"
                        fill="#00816f"/>
                </g>
            </g>
        </g>
    </svg>
);

export default ManageTemplateIcon;
