
import {httpservice} from '../services';
import {constants} from '../constant/constant';
import {CLIENT} from "../constant/actionTypes";
import { ENTITY } from '../constant';

export default id => dispatch => {
    const url = constants.API.CLIENT_SOFTWARE_VERSION.replace(ENTITY.CLIENT_ID, id);
    dispatch({
        payload: [],
        type: CLIENT.SOFTWARES_INSTALLED_LIST.INITIATE
    });

    return httpservice()
        .get(url)
        .then(response => {
            const softwareList = response.data.response.map(softwareListData=>{
                if(softwareListData.version){
                    return ({
                        id:softwareListData.id,
                        clientId: softwareListData.clientId,
                        createdAt: softwareListData.createdAt,
                        softwareName: softwareListData.software.name,
                        softwareId: softwareListData.softwareId,
                        submoduleName: softwareListData.submodule,
                        submoduleId: softwareListData.submoduleId,
                        versionName: softwareListData.version.versionNumber,
                        isLatest: softwareListData.version.isLatest,
                        version: softwareListData.version,
                        platform: (softwareListData.version.platform).charAt(0).toUpperCase()+(softwareListData.version.platform).slice(1),
                        architecture: softwareListData.version.architecture,
                        versionId: softwareListData.version.id,
                        software: softwareListData.software
                    })
                }else{
                    return ({
                        id:softwareListData.id,
                        clientId: softwareListData.clientId,
                        createdAt: softwareListData.createdAt,
                        softwareName: softwareListData.software.name,
                        softwareId: softwareListData.softwareId,
                        submoduleName: softwareListData.submodule.name,
                        submoduleId: softwareListData.submoduleId,
                        versionName: softwareListData.submoduleVersion.versionNumber,
                        isLatest: softwareListData.submoduleVersion.isLatest,
                        version: softwareListData.submoduleVersion,
                        platform: (softwareListData.submoduleVersion.platform).charAt(0).toUpperCase()+(softwareListData.submoduleVersion.platform).slice(1),
                        architecture: softwareListData.submoduleVersion.architecture,
                        versionId: softwareListData.submoduleVersion.id,
                        software: softwareListData.software
                    })
                }
            })
            dispatch({
                payload: softwareList,
                type: CLIENT.SOFTWARES_INSTALLED_LIST.SUCCESS
            });
        })
        .catch(function (error) {
            dispatch({
                payload: error,
                type: CLIENT.SOFTWARES_INSTALLED_LIST.ERROR
            });
        });
};
