
/* jshint esversion: 6 */

const layoutStyle = theme => ({
    mainApp: {
        width: '100%',
        float: 'left'
    },
    appContent: {
        width: '100%',
        float: 'left'
    }
});

export default layoutStyle;
