

import {constants} from '../constant/constant';
import { USER } from '../constant/actionTypes';
import moment from 'moment';
import {httpserviceWithMultipart} from "../services/HttpServices";
let apiEndPoint = constants.ADMIN_URL;
const loginUser =localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')).userData  :false;
if(loginUser){
    if(loginUser.role.roleName==="client"){
        apiEndPoint=constants.CLIENT_URL
    }
}

export default (data, newData,ssoObject) => dispatch =>{
    let url; 
    url = ssoObject ? apiEndPoint + `${constants.API.SIGNUP}?sso=${ssoObject.sso}&redirect_uri=${ssoObject.redirectURI}&portal=${ssoObject.portal}` : apiEndPoint + `${constants.API.SIGNUP}`
     
    return httpserviceWithMultipart('post', data, url)
        .then(function (response) {
            const val =response.data.response;
            // const newArray = [...newData];

            let userObject = {};
            /*converting to form data to json object*/
            for (const [key, value]  of data.entries()) {
                userObject [key] = value;
            }
            const updatedData={...userObject};
            updatedData.id = val.id;
            updatedData.name = userObject.firstName + ' ' + userObject.lastName;
            updatedData.createdAt =moment().format("DD/MM/YYYY");
            updatedData.company = val.company && val.company.name ? val.company.name : '';
            updatedData.role = 'admin';
            updatedData.createdBy = val.creator ? val.creator.firstName + ' ' + val.creator.lastName : '';
            updatedData.avatar = val.avatar;
            if (val.active) {
                updatedData.active = 'Active';
            } else {
                updatedData.active = 'Pending';
            }
            if (val.isBlocked) updatedData.active = 'Blocked';
            // newArray.push(updatedData);
            // dispatch({
            //     payload: { data: newArray, count: newArray.length },
            //     type: USER.GET.SUCCESS
            // })
            return {status:1,data:response.data};
        })
        .catch(function (error) {
            return {status:0,data:error.response && error.response.data ? error.response.data : error};
        });
};
